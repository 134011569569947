import {
	RECEIVE_SEND_PAYMENT,
	RECEIVE_SEND_PAYMENT_EXHIBITOR,
	REQUEST_RESET_INVALID_CREDIT_CARD,
	REQUEST_ERASE_COMMON_DATA,
	REQUEST_CLEAR_CREDIT_CARD_NOT_ACCEPTED_MSG,
	REQUEST_RESET_PAYMENT_SUCCESS,
	REQUEST_PAYMENT_LOADER
} from 'constants/ActionTypes';

const INIT_STATE = {
	paymentProgress: '',
	paymentSuccess: '',
	paymentErrorMsg: '',
	status: '',
	ERRORMESSAGE: '',
	mobileThreshold: 500,
	isPaymentLoader: false
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case REQUEST_RESET_PAYMENT_SUCCESS: {
			return {
				...state,
				paymentSuccess: '',
			};
		}
		case RECEIVE_SEND_PAYMENT: {
			return {
				...state,
				paymentProgress: action.payload,
				paymentSuccess: action.payload?.success
					? action.payload?.success
					: action.payload?.SUCCESS,
				paymentErrorMsg: action.payload.error_message,
				status: action.payload.status,
			};
		}
		case RECEIVE_SEND_PAYMENT_EXHIBITOR: {
			return {
				...state,
				paymentProgress: action.payload,
				paymentSuccess: action.payload.success,
				paymentErrorMsg: action.payload.error_message,
				status: action.payload.status,
			};
		}
		case REQUEST_RESET_INVALID_CREDIT_CARD: {
			return {
				...state,
				paymentSuccess: '',
				paymentErrorMsg: '',
				paymentProgress: '',
			};
		}
		case REQUEST_CLEAR_CREDIT_CARD_NOT_ACCEPTED_MSG: {
			return {
				...state,
				paymentSuccess: '',
				paymentErrorMsg: '',
				paymentProgress: '',
			};
		}
		case REQUEST_ERASE_COMMON_DATA:
			return INIT_STATE;
			
		case REQUEST_PAYMENT_LOADER: {
			return { ...state, isPaymentLoader: action.payload }
		}
		default:
			return state;
	}
};
