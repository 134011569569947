import { Form, Input } from 'antd';
import React from 'react';
import Title from '../components/Title';
import useRules from '../hooks/useRules';
import Description from '../components/Description';

interface Props {
	name: string;
	label: string;
	value: string;
	onChange: any;
	required: string;
	description: string;
	primaryColor: string;
	secondaryColor: string;
	width: number;
	height: number;
	error: string;
}

export const Textarea = ({
	name,
	label,
	value,
	onChange,
	required,
	description,
	primaryColor,
	secondaryColor,
	width,
	height,
	error,
}: Props) => {
	const rules = useRules(required);
	return (
		<div>
			<Title
				label={label}
				color={primaryColor}
				error={error}
				required={required}
				name={name}
			/>
			<Description label={description} color={secondaryColor} />
			<Form.Item name={name} rules={rules}>
				<Input.TextArea
					className="form-component-text-area-radius fg-secondary-lighter"
					rows={5}
					value={value}
					onChange={onChange}
				/>
			</Form.Item>
		</div>
	);
};
