import { useEffect, useMemo } from 'react';

import { useProducts } from './useProducts';
import { useProductPrices } from './useProductPrices';

export const useBundleInfo = bundleId => {
	const [, products] = useProducts();
	const [productPrices, getPrices, pricesLoading] = useProductPrices();

	const bundle = useMemo(() => products.find(p => p.id === bundleId), [products]);
	const children = useMemo(
		() => products.filter(p => bundle?.childrenIds.some(childId => childId === p.id)),
		[products, bundle],
	);

	const bundlePrice = useMemo(() => productPrices.find(price => price.productid === bundleId), [
		productPrices,
	]);
	const childrenPrices = useMemo(
		() =>
			productPrices.filter(price =>
				bundle?.childrenIds.some(childId => childId === price.productid),
			),
		[productPrices, bundle],
	);

	const totalPrice = useMemo(
		() =>
			childrenPrices.reduce(
				(prev, curr) => prev + (curr.price_collection?.primary.price ?? 0),
				0,
			),
		[childrenPrices],
	);

	const bundleSavings = totalPrice - bundlePrice?.price_collection?.primary.price;
	const savingsPercentage = Math.trunc((bundleSavings / totalPrice || 0) * 100);

	useEffect(() => {
		if (bundle) getPrices([bundleId, ...bundle.childrenIds]);
	}, [bundle]);

	return [
		bundle,
		children,
		bundlePrice,
		childrenPrices,
		totalPrice,
		bundleSavings,
		savingsPercentage,
		pricesLoading,
	];
};
