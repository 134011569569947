import React, { useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router';

import { useProducts } from '../hooks/useProducts';

import ConsumptionHeader from '../components/ConsumptionHeader';
import ConsumptionArea from '../components/ConsumptionArea';
import '../styles/custom.less';

const BundlePage = () => {
	const { bundleId, productId } = useParams();
	const history = useHistory();

	const [sidebarOpen, setSidebarOpen] = useState(true);

	const [prodsLoading, products] = useProducts();
	const bundle = useMemo(() => products.find(prod => prod.id === parseInt(bundleId)), [products]);
	const parentBundle = useMemo(() => products.find(prod => prod.id === bundle?.parentId), [products]);

	const loading = prodsLoading;

	const goBack = () => {
		let route = 'selection';
		if (bundle?.parentId && !productId) {
			route = `selection/${bundle?.parentId}`
		} else if (productId) {
			route = '../selection';
		} else {
			route = 'selection';
		}
		history.push(`../${route}${history.location.search}`);
	};

	return (
		<main className="gx-bg-white gx-d-flex gx-flex-column" /* style={{ minHeight: '100vh', maxHeight: '100vh', overflowY: 'auto' }} */>
			<ConsumptionHeader
				loading={prodsLoading}
				product={bundle}
				superBundle={parentBundle}
				goBack={goBack}
			/>

			<section className="gx-flex-1 gx-d-flex">
				<article
					className="content-area"
					style={{
						// width: `calc(100% - ${toggleWidth + (sidebarOpen ? sidebarWidth : 0)}px)`,
						width: `100%`,
					}}
				>
					<ConsumptionArea
						loading={loading}
						product={bundle}
						expanded={!sidebarOpen}
					/>
				</article>
			</section>
		</main>
	);
};

export default BundlePage;
