import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'antd';
import { useParams } from 'react-router';
import { requestAttendeeSettings } from '../../../appRedux/actions/EcommerceStore';
import { ReactComponent as RegistrationSummary } from '../../../assets/icons/RegistrationSummary.svg';

const MainSection = props => {
	/* 
    right now profile home is getting its variable from cookies, 
    but if the user logs in via find account tokenize url via another browser
    this page will be blank
    can this return more info? like all the info below? 
    */
	const dispatch = useDispatch();

	const appdir = useSelector(state => state.loginInfo.appdir);
	const cartItems = useSelector(state => state.ecommerce.cartItems);
	const oldCartItems = useSelector(state => state.ecommerce.oldCartItems);

	const attendeeSettingsFetched = useSelector(state => state.ecommerce.attendeeSettingsFetched);

	const { moduleUrlId } = useParams();

	const hasCartItems =
		(cartItems.simpleinvoiceobj && Object.keys(cartItems.simpleinvoiceobj).length) ||
		cartItems.NonFinancialItemsArray?.length ||
		(oldCartItems.simpleinvoiceobj && Object.keys(oldCartItems.simpleinvoiceobj).length) ||
		oldCartItems.NonFinancialItemsArray?.length;

	useEffect(() => {
		if (!attendeeSettingsFetched) {
			dispatch(requestAttendeeSettings({ appdir, moduleUrlId }));
		}
	}, []);

	return (
		<div className="gx-mt-3">
			{hasCartItems && (
				<Card className="card">
					<div className="gx-d-flex gx-align-items-center gx-justify-content-between">
						<h2>Registration Summary</h2>
						<RegistrationSummary
							className="gx-ml-3 gx-mb-3"
							style={{ width: '1.5rem', fill: 'white' }}
						/>
					</div>
					<div className="gx-mt-1">
						<div dangerouslySetInnerHTML={{ __html: props.companyHtml }}></div>
					</div>
				</Card>
			)}
		</div>
	);
};

export default MainSection;
