import React, { useEffect, useState } from "react";
import { Form } from "antd";

interface Props {
	label: string;
	color: string;
}

export default ({ label, color }: Props) =>
	!label || label === "" ? null : (
		<Form.Item style={{ marginBottom: 0 }}>
			<div
				dangerouslySetInnerHTML={{ __html: label }}
				style={{ fontSize: 14, margin: 0, color }}
			/>
		</Form.Item>
	);
