import React from 'react';

import { ReactComponent as Right } from 'assets/icons/right.svg';
import { ReactComponent as Left } from 'assets/icons/left.svg';

import { toggleWidth } from '../constants';

const SidebarToggle = ({ isOpen, close }) => {
	return (
		<div
			className="gx-text-center gx-pointer content-toggle shadow-left-1"
			onClick={close}
			style={{ zIndex: 9, width: toggleWidth }}
		>
			{isOpen ? (
				<Right
					className="gx-mx-auto fg-grey-light"
					style={{ width: '.6rem', marginTop: '5rem' }}
				/>
			) : (
				<Left
					className="gx-mx-auto fg-grey-light"
					style={{ width: '.6rem', marginTop: '5rem' }}
				/>
			)}
		</div>
	);
};

export default SidebarToggle;
