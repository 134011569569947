import React, { useMemo, useState } from 'react';
import { Dropdown, Menu, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { RefinementList } from 'react-instantsearch-dom';
import _isEmpty from 'lodash/isEmpty';

import FilterContent from './FilterContent';
import { useTags } from '../hooks/useTags';

const SelectionFilter = () => {
    const [visible, setVisibleFlag] = useState(false);
    const [tagsLoading, tagsMap] = useTags();
    const categoriesMap = useSelector(state => state.ecommerce.storeCategoriesMap);
    const showTags = !useSelector(
        state =>
            state.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject?.hide_all_tags,
    );

    const handleVisibleChange = (flag) => {
        setVisibleFlag(flag);
    };

    const MenuItem = useMemo(() => {
        if (tagsLoading || _isEmpty(categoriesMap)) {
            return (
                <main>
                    <Skeleton.Button active size="large" shape="round"></Skeleton.Button>
                    <h3 className="gx-algolia-category-title gx-mb-2 gx-mt-5">Category</h3>
                    <hr className="gx-mt-0 filter-divider" />
                    <Skeleton active title={false} paragraph={{ rows: 6 }}></Skeleton>
                    {showTags && (
                        <>
                            <h3 className="gx-algolia-category-title gx-mb-2 gx-mt-5">Tags</h3>
                            <hr className="gx-mt-0 filter-divider" />
                            <Skeleton active title={false} paragraph={{ rows: 6 }}></Skeleton>{' '}
                        </>
                    )}
                </main>
            );
        }
        return (
            <Menu className="refinements-filter-body-wrapper">
                <Menu.Item>
                    <div className="gx-algolia-sidebar">
                        <h3 className="gx-algolia-category-title gx-mb-2 gx-mt-3">Category</h3>
                        <hr className="gx-mt-0 filter-divider" />
                        <RefinementList
                            className="gx-algolia-refinementList"
                            attribute="category"
                            limit={Number.POSITIVE_INFINITY}
                            transformItems={categories =>
                                categories
                                    .map(category => ({
                                        ...category,
                                        label: categoriesMap[category.label],
                                    }))
                                    .sort((a, b) => parseInt(a.label) - parseInt(b.label))
                            }
                        />
                        {showTags && (
                            <>
                                <h3 className="gx-algolia-category-title gx-mb-2 gx-mt-3">Tags</h3>
                                <hr className="gx-mt-0 filter-divider" />
                                <RefinementList
                                    className="gx-algolia-refinementList"
                                    attribute="tag"
                                    limit={Number.POSITIVE_INFINITY}
                                    transformItems={tags =>
                                        tags
                                            .map(tag => ({
                                                ...tag,
                                                label: tagsMap[tag.label],
                                            }))
                                            .sort((a, b) => parseInt(a.label) - parseInt(b.label))
                                    }
                                />
                            </>
                        )}
                    </div>
                </Menu.Item>
            </Menu>
        );
    }, [tagsLoading, showTags, categoriesMap]);

    return (
        <div className="refinements-filter-wrapper gx-mr-2">
            <Dropdown
                trigger={"click"}
                overlay={MenuItem}
                visible={visible}
                onVisibleChange={handleVisibleChange}
            >
                <div>
                    <FilterContent popOverVisible={visible} />
                </div>
            </Dropdown>
        </div>
    )
}

export default SelectionFilter;
