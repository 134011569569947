function css_code(str) {
	var hash = 0,
		i = 0,
		len = str.length;
	while (i < len) {
		hash = ((hash << 5) - hash + str.charCodeAt(i++)) << 0;
	}
	return hash;
}

function available(surl) {
	return new Promise((resolve, reject) => {
		fetch(surl, { method: 'HEAD' }).then(function (response) {
			if (response.status == 200) {
				resolve(true);
			} else {
				resolve(false);
			}
		});
	});
}

function load(path) {
	var link = document.createElement('link');
	link.rel = 'stylesheet';
	link.type = 'text/css';
	link.href = path;
	document.getElementsByTagName('HEAD')[0].appendChild(link);
}

function setStyle(config) {
	return new Promise((resolve, reject) => {
		let code = '';

		if (!config.styling) {
			code = css_code(
				`dark=${config.primary_dark_color}&primary-color=${config.primary_color}`,
			);
		} else {
			code = css_code(JSON.stringify(config.styling));
		}

		let css = `/css/${code}.css`;
		if (window.location.host.endsWith('econference.io')) {
			available(css).then((avail) => {
				if (avail) {
					load(css);
					resolve();
				} else {
					if (!config.styling) {
						fetch(
							`https://css.econference.io/request?dark=${encodeURIComponent(
								config.primary_dark_color,
							)}&primary=${encodeURIComponent(config.primary_color)}`,
						).then(() => {
							available(css).then((avail) => {
								if (avail) {
									load(css);
								} else {
									load('/css/style.css');
								}
								resolve();
							});
						});
					} else {
						fetch(`https://css.econference.io/request?id=${code}`, {
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify(config.styling),
						}).then(() => {
							available(css).then((avail) => {
								if (avail) {
									load(css);
								} else {
									load('/css/style.css');
								}
								resolve();
							});
						});
					}
				}
			});
		} else {
			load('/css/style.css');
			resolve();
		}
	});
}

function setTitle(title) {
	document.title = title;
}
function setGenerics(config) {
	setTitle(config.title);
	setFavicon(config);
	return setStyle(config);
}

function setFavicon(config) {
	var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
	link.rel = 'shortcut icon';
	link.href = config.core_app_images.icon;
	document.getElementsByTagName('head')[0].appendChild(link);
}

export default setGenerics;
