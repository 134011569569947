import {
	REQUEST_USER_PRODUCT_LIST,
	RECEIVE_USER_PRODUCT_LIST,
	REQUEST_USER_PRODUCTS_LIST_SELECTION_VIEW,
	RECEIVE_PRODUCT_CATEGORY,
	REQUEST_ERASE_USER_PRODUCTS_DATA,
	REQUEST_TOGGLE_CALENDAR_VIEW,
	RECEIVE_USER_PRODUCT_TAGS,
} from 'constants/ActionTypes';

const INIT_STATE = {
	userProductList: '',
	userProductListFetched: false,
	selectionView: 'grid',
	productCategory: '',
	productCategoryHashmap: {},
	productCategoryFetched: false,
	calendarView: false,
	calendarHashMap: {},
	selectionView: 'grid',
	tags: '',
	tagsFetched: false,
	tagsHashmap: {},
	tagsExist: false,
};

const timeConverter = (timestamp) => {
	var a = new Date(timestamp * 1000);
	var months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	var year = a.getFullYear();
	var month = months[a.getMonth()];
	var date = a.getDate();
	var hour = a.getHours();
	var min = a.getMinutes();
	var sec = a.getSeconds();
	var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
	return time;
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case REQUEST_USER_PRODUCT_LIST: {
			return {
				...state,
				userProductListFetched: true,
			};
		}
		case RECEIVE_USER_PRODUCT_LIST: {
			//mapping product category id to product category name
			for (let i = 0; i < action.payload.length; i++) {
				action.payload[i]['productCategory'] =
					state.productCategoryHashmap[action.payload[i].product_categoryid];
			}

			//mapping product id to tags
			for (let i = 0; i < action.payload.length; i++) {
				let currTags = [];
				// looping through the array of tags
				for (let j = 0; j < action.payload[i].config.tagid.length; j++) {
					currTags.push(state.tagsHashmap[action.payload[i].config.tagid[j]]);
				}
				action.payload[i]['productTags'] = currTags;
			}

			//checking date time property
			for (let i = 0; i < action.payload.length; i++) {
				if (action.payload[i].config.hasOwnProperty('timestamp')) {
					//do nothing
				} else {
					//adding infinite allow the items with no timestamp become the latest date
					action.payload[i].config['timestamp'] = 9999999999999999999999999999;
				}
			}

			//converting unix time to datetime <-- might not even need this
			// for (let i = 0; i < action.payload.length; i++) {
			// 	let timeStamp = action.payload[i].config.timestamp;
			// 	action.payload[i]['dateTime'] = timeConverter(timeStamp);
			// }

			// sorting by timestamp
			action.payload.sort(function (x, y) {
				return y.config.timestamp - x.config.timestamp;
			});

			// check if any of products have tags
			let n = action.payload.length;
			let i = 0;
			let tempTagsExist = false;
			while (i < n) {
				if (action.payload[i].config.tagid.length !== 0) {
					tempTagsExist = true;
					break;
				}
				i++;
			}

			return {
				...state,
				userProductList: action.payload,
				tagsExist: tempTagsExist,
			};
		}
		case REQUEST_USER_PRODUCTS_LIST_SELECTION_VIEW: {
			return {
				...state,
				selectionView: action.payload,
			};
		}
		case RECEIVE_PRODUCT_CATEGORY: {
			// building a hashmap of categories so we don't have to loop n^2 later
			let myHashmap = {};
			for (let i = 0; i < action.payload.length; i++) {
				myHashmap[action.payload[i].category_id] = action.payload[i].category_label;

				if (action.payload[i].hasOwnProperty('category_config')) {
					if (action.payload[i].category_config.store_grid_display_calendar === true) {
						state.calendarHashMap[action.payload[i].category_label] = true;
					} else {
						state.calendarHashMap[action.payload[i].category_label] = false;
					}
				} else {
					state.calendarHashMap[action.payload[i].category_label] = false;
				}
			}
			return {
				...state,
				productCategory: action.payload,
				productCategoryHashmap: myHashmap,
				productCategoryFetched: true,
			};
		}
		case REQUEST_TOGGLE_CALENDAR_VIEW: {
			return {
				...state,
				calendarView: !state.calendarView,
			};
		}
		case RECEIVE_USER_PRODUCT_TAGS: {
			//mapping product category id to product category name
			let myHashmap = {};
			for (let i = 0; i < action.payload.length; i++) {
				myHashmap[action.payload[i].id] = action.payload[i].label;
			}
			return {
				...state,
				tags: action.payload,
				tagsHashmap: myHashmap,
				tagsFetched: true,
			};
		}
		case REQUEST_ERASE_USER_PRODUCTS_DATA:
			return INIT_STATE;
		default:
			return state;
	}
};
