import { formDataFetching, formDataFetchSuccess, formDataFetchError } from '../actions';
import auth from '../../loginAuth/loginAuth';
import { get } from 'lodash';
import { createHashKey, parseForm } from '../../util/form';

function encodeQueryData(data) {
	const ret = [];
	for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
	return ret.join('&');
}

const formDataRequest = (formInfo) => {
	var requestOptions = {
		method: 'GET',
		redirect: 'follow',
	};
	return fetch(
		`https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm?${encodeQueryData(
			formInfo,
		)}`,
		{
			method: 'GET',
			headers: {
				'accept': 'application/json, text/plain, */*',
				'accept-language': 'en-US,en;q=0.9',
				'authorization': JSON.parse(auth.getCookie('jwt')),
				'sec-fetch-dest': 'empty',
				'sec-fetch-mode': 'cors',
				'sec-fetch-site': 'cross-site',
			},
		},
		requestOptions,
	).then((response) => response.json());
};

export const generateFormIdentifier = (formInfo) => `${formInfo.module}.`;

export const fetchFormData = (formInfo) => async (dispatch, getState) => {
	if (
		get(getState().form.data, `${[createHashKey(formInfo)]}.isFetched`) ||
		get(getState().form.data, `${[createHashKey(formInfo)]}.isFetching`) ||
		get(getState().form.data, `${[createHashKey(formInfo)]}.fetchError`)
	)
		return;
	dispatch(formDataFetching(formInfo, true));
	try {
		let formData = await formDataRequest(formInfo);
		dispatch(
			formDataFetchSuccess({
				...formInfo,
				...parseForm(formData),
			}),
		);
		dispatch(formDataFetching(formInfo, false));
	} catch (e) {
		dispatch(formDataFetching(formInfo, false));
		dispatch(formDataFetchError(formInfo));
	}
};
