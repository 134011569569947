import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requestToFetchNewsItems } from '../../../appRedux/actions';
import '../../Ecommerce/Store/SelectionPage/selection.css';
import { ReactComponent as News } from '../../../assets/icons/News.svg';
import { ReactComponent as Date } from '../../../assets/icons/date.svg';

const NotificationCenter = props => {
	return (
		<div className="gx-app-login-container">
			{props.loginInfo.newsItemsFetched && (
				<Card
					className="gx-mb-0 gx-h-100 product-card card"
					size="large"
					style={{ borderRadius: '23px' }}
				>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<h2
							className="title-text"
							style={{ marginTop: '0.15rem', marginRight: '0.5rem' }}
						>
							Notification
						</h2>
						<News
							className="fg-primary-dark-light"
							style={{
								width: '1.5rem',
								marginBottom: '1rem',
								textAlign: 'right',
							}}
						/>
					</div>
					<br />
					{props.loginInfo.newsItems.map(newsItem => {
						return (
							<Card
								style={{
									borderRadius: '23px',
									boxShadow: '5px 5px 2px 1px rgba(45, 45, 45, 0.65)',
								}}
							>
								{
									<div style={{ paddingTop: '5%' }}>
										<div className="gx-d-flex gx-align-items-center">
											<Date
												className="gx-mr-2 gx-mt-1 fg-primary-dark-light"
												style={{ width: '1.5rem', marginBottom: '1rem' }}
											/>
											<h2>{newsItem['date']}</h2>
										</div>

										<h4 className="title-text">{newsItem['title']}</h4>

										<div
											dangerouslySetInnerHTML={{
												__html: newsItem['content'],
											}}
										></div>
									</div>
								}
							</Card>
						);
					})}
				</Card>
			)}
		</div>
	);
};

function mapStateToProps(state) {
	return {
		loginInfo: state.loginInfo,
	};
}

export default connect(mapStateToProps)(NotificationCenter);
