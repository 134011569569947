import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Steps } from 'antd';
import { requestToUpdateStepDetails } from '@appRedux/actions';
import { publicMembershipSteps, publicMembershipStepsDisableJoinRenew } from '@components/Membership/helpers/constant';
import ExhibitorMembershipForm from './ExhibitorMembershipForm';
import ExhibitorCompanyProfile from './ExhibitorCompanyProfile';
import ExhibitorMembershipReview from './ExhibitorMembershipReview';
import ExhibitorAdditionalSlots from './ExhibitorAdditionalSlots';
import ExhibitorHomeRegistration from './ExhibitorHomeRegistration';
import CircularProgress from '@components/CircularProgress';
import isEmpty from "lodash/isEmpty"
import { expirationStateFinder, isWebView } from '../helpers/generalHelpers';
import { EXPIRED, IN_GOOD_STANDING, IN_RENEWAL_PERIOD } from "../helpers/constant";
import { useHistory } from 'react-router-dom';

const ExhibitorFinancialItemSteps = (props) => {
  const dispatch = useDispatch();
  const publicEmbed = useSelector(state => state.settings.publicEmbed);
  const auth = useSelector(state => state.auth);
  const ignorePurchaseRequiredRedirect = useSelector(state => state.corporateMembership.ignorePurchaseRequiredRedirect);
  const currentStep = useSelector(state => state.corporateMembership.membershipStepDetails.currentStep);
  const corporateMembership = useSelector(state => state.corporateMembership);
  const loading = !useSelector(state => state.corporateMembership.menuFetched);
  const { menu } = useSelector(({ corporateMembership }) => corporateMembership);
  const subMenu = menu.find(data => data.screentype === "SubMenu")?.submenu ?? [];
  const [steps, setSteps] = useState([]);
  const [currentScreen, setCurrentScreen] = useState({});
  const [propsObj, setPropsObj] = useState({});
  const [showStep, setShowStep] = useState(true);
  const location = useSelector(state => state.router.location);
  const isTokenInUrl = location?.search?.includes('token');
  const [membershipState, setMembershipState] = useState({ message: 'Expired', color: 'error' });
  const companyExpireDate = corporateMembership?.company?.expiry_date;
  const expirationState = expirationStateFinder({ expiryDate: companyExpireDate, renewal: corporateMembership.generalSettings.renewal_period_days });
  const companyExpired = !companyExpireDate || expirationState === EXPIRED;
  const isCompanyInRenewalPeriod = expirationState === IN_RENEWAL_PERIOD;
  const isNewMember = !Boolean(corporateMembership?.company?.membership_type);
  const history = useHistory();
  const [membershipSteps, setMembershipSteps] = useState(
		corporateMembership.generalSettings.disable_join_renew
			? publicMembershipStepsDisableJoinRenew
			: publicMembershipSteps,
  );
  const goForward = () => { dispatch(requestToUpdateStepDetails({ currentStep: currentStep + 1 })) };
  const goBackward = (isReviewPage = false) => {
    // if (isReviewPage) {
      // if (currentStep != 0) {
        // dispatch(requestToUpdateStepDetails({ currentStep: 0 }));
      // }
    // } else {
      dispatch(requestToUpdateStepDetails({ currentStep: currentStep - 1 }));
    // }
  };
  useEffect(() => {
    if(corporateMembership.exhibitorCartDetails.purchaseRequired && !ignorePurchaseRequiredRedirect){
      if (publicEmbed) {
        history.push(`/main/public/company/${props.match.params.moduleUrlId}/checkout`);
    } else {
        history.push(`/main/company/${props.match.params.moduleUrlId}/checkout`);
    }
    }
    if (
      !isEmpty(corporateMembership.exhibitorCartDetails.pending_payment_data) &&
      (companyExpired || isCompanyInRenewalPeriod)
    ) {
      setMembershipState({ message: 'Pending', color: 'warning' });
      if (window.location.pathname.includes('/public/')) {
        // This is public_embed view
        history.push(`../../../company/${props.match.params.moduleUrlId}/home`);
      } else {
        // This is an dashboard view
        history.push(`./home`);
      }
    }

    if (companyExpired) {
      // membership Expired
      setMembershipState({ message: 'Expired', color: 'error' });
    } else if (isCompanyInRenewalPeriod) {
      // In renewal period
      setMembershipState({ message: 'Renewal Required', color: 'warning' });
    } else if (isNewMember) {
      //New Member
      setMembershipState({ message: 'Non - Member', color: 'warning' });
    } else {
      setMembershipState({ message: 'In Good Standing', color: 'success' });
      // Redirect user
      if (window.location.pathname.includes('/public/')) {
        //This is public_embed view
        history.push(`../../../company/${props.match.params.moduleUrlId}/home`);
      } else {
        // This is an dashboard view
        history.push(`./home`);
      }
    }
  }, [companyExpired, companyExpireDate, isCompanyInRenewalPeriod, corporateMembership.exhibitorCartDetailsFetched]);

  useEffect(() => {
    let tempCurrentScreen = {}, tempSteps = [];
    if (subMenu.length) {
      if (publicEmbed) { // Corp. Membership Public
        if (corporateMembership.generalSettings.HideAdditionalMemberTab && isTokenInUrl && auth.jwt && auth.ssotoken) {
          let publicSteps = membershipSteps.filter(step => (step.screentype !== "additionalslots"
            // && step.screentype !== "contactinfo"
          ));
          tempCurrentScreen = publicSteps.find((_, inx) => inx === currentStep);
          tempSteps = publicSteps;
          // }
        } else if (corporateMembership.generalSettings.HideAdditionalMemberTab && isTokenInUrl && !auth.jwt) {
          let publicSteps = membershipSteps.filter(step => (step.screentype !== "additionalslots"));
          tempCurrentScreen = publicSteps.find((_, inx) => inx === currentStep);
          tempSteps = publicSteps;
        }
        else if (corporateMembership.generalSettings.HideAdditionalMemberTab) {
          let publicSteps = membershipSteps.filter(step => (step.screentype !== "additionalslots"));
          tempCurrentScreen = publicSteps.find((_, inx) => inx === currentStep);
          tempSteps = publicSteps;
        } else {
          tempCurrentScreen = membershipSteps.find((_, inx) => inx === currentStep);
          tempSteps = membershipSteps;
        }
      } else if (corporateMembership.generalSettings.HideAdditionalMemberTab) { // Corp. Membership Private
        const privateMembershipSteps = membershipSteps.filter((_, i) => i !== 1 && i !== 2);
        tempCurrentScreen = privateMembershipSteps.find((_, inx) => inx === currentStep);
        tempSteps = privateMembershipSteps;
      } else { // Corp. Membership Private
        const privateMembershipSteps = membershipSteps.filter((_, i) => i !== 1);
        tempCurrentScreen = privateMembershipSteps.find((_, inx) => inx === currentStep);
        tempSteps = privateMembershipSteps;
      }
      setPropsObj({
        order: currentStep + 1,
        showMakePayment: tempSteps.length - 1 === currentStep,
        showPrevious: currentStep !== 0,
        isRegistrationSteps : true
      })
      setSteps(tempSteps);
      if (tempCurrentScreen) {
        setCurrentScreen(tempCurrentScreen)
        if (tempCurrentScreen.screentype === "Floorplan") { setShowStep(false) }
        else { setShowStep(true) }
      }
    }
  }, [subMenu, currentStep])

  useEffect(() => {
    if (location?.search?.includes('verified')) {
      dispatch(requestToUpdateStepDetails({ currentStep: currentStep + 1 }))
    }
  }, [auth.jwt, auth.ssotoken])

  let Component = () => <></>;
  if (Object.values(currentScreen).length) {
    switch (currentScreen.screentype.toLowerCase()) {
      case "registration": { Component = ExhibitorMembershipForm; break; }
      case "submenu": { Component = ExhibitorMembershipForm; break; }
      case "additionalslots": { Component = ExhibitorAdditionalSlots; break; }
      case "review": { Component = ExhibitorMembershipReview; break; }
      case "contactinfo": { Component = ExhibitorCompanyProfile; break; }
      case "start": { Component = ExhibitorHomeRegistration; break;}
    }
  }
  // In public flow, There will be four steps on the UI, but two components only needed because of the flow.
  // For private flow there is three steps for the users to renew their companies.
  const changeStep = (e) => {
    dispatch(requestToUpdateStepDetails({ currentStep: e }));
  }

  const extractTitleDescription =  (button, blurb, screentype, getDescriptionOnly = false)=>{
          let tempButton = button;
          let tempDescription = blurb;
          if (corporateMembership.generalSettings?.menu_config) {
            switch (screentype) {
              case 'submenu':
                tempButton = isEmpty(
                  corporateMembership.generalSettings.menu_config
                    ?.registration_label,
                )
                  ? button
                  : corporateMembership.generalSettings.menu_config
                    ?.registration_label;
                tempDescription =
                  corporateMembership.generalSettings.menu_config
                    ?.registration_sub_label ?? blurb;
                break;
              case 'contactinfo':
                tempButton = isEmpty(
                  corporateMembership.generalSettings.menu_config
                    ?.create_profile_label,
                )
                  ? button
                  : corporateMembership.generalSettings.menu_config
                    ?.create_profile_label;
                tempDescription =
                  corporateMembership.generalSettings.menu_config
                    ?.create_profile_sub_label ?? blurb;
                break;
              case 'additionalslots':
                tempButton = isEmpty(
                  corporateMembership.generalSettings.menu_config
                    ?.staff_label,
                )
                  ? button
                  : corporateMembership.generalSettings.menu_config
                    ?.staff_label;
                tempDescription =
                  corporateMembership.generalSettings.menu_config
                    ?.staff_sub_label ?? blurb;
                break;
              case 'review':
                tempButton = isEmpty(
                  corporateMembership.generalSettings.menu_config
                    ?.review_label,
                )
                  ? button
                  : corporateMembership.generalSettings.menu_config
                    ?.review_label;
                tempDescription =
                  corporateMembership.generalSettings.menu_config
                    ?.review_sub_label ?? blurb;
                break;
            }
          }
          if(getDescriptionOnly){
            return tempDescription
          }
          return {tempButton, tempDescription}
  }
  return loading ? (
		<CircularProgress />
  ) : (
		<div>
			{showStep ? (
				corporateMembership.generalSettings.disable_join_renew && steps.length == 1 ? (
					<div
						className="gx-pr-2"
						dangerouslySetInnerHTML={{
							__html:  extractTitleDescription(
									steps[0].button,
									steps[0].blurb,
									steps[0].screentype,
                  true
								)
						}}
					/>
				) : (
					<Steps
						type={isWebView() ? 'navigation' : null}
						progressDot={false}
						current={currentStep}
						onChange={changeStep}
						className="registration-steps"
						style={{ textAlign: 'start' }}
						direction={isWebView() ? 'horizontal' : 'vertical'}
						size={isWebView() ? 'default' : 'small'}
					>
						{steps.map(({ button, blurb, screentype }, inx) => {
							let { tempButton, tempDescription } = extractTitleDescription(
								button,
								blurb,
								screentype,
							);

							if (!isWebView() && currentStep !== inx) {
								return null;
							}
							return (
								<Steps.Step
									key={inx}
									title={tempButton}
									disabled={inx < currentStep ? false : true}
									description={
										<div
											className="gx-pr-2"
											dangerouslySetInnerHTML={{ __html: tempDescription }}
										/>
									}
								/>
							);
						})}
					</Steps>
				)
			) : null}
			<div>
				<Component {...propsObj} nextStep={goForward} previousStep={goBackward} />
			</div>
		</div>
  );
};

export default ExhibitorFinancialItemSteps;
