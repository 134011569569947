import React from 'react';

import { connectCurrentRefinements } from 'react-instantsearch-dom';

const ClearRefinements = ({ items, refine }) => (
	<div
		onClick={(e) => {
			e.preventDefault();
			e.stopPropagation();
			refine(items)
		}}
		disabled={!items.length}
		className="button-secondary shadow-1 gx-pointer gx-px-2 gx-py-1"
	>
		<div className="gx-d-flex gx-align-items-center">
			<span className="gx-mb-0">Clear Filters</span>
		</div>
	</div>
);
export default connectCurrentRefinements(ClearRefinements);
