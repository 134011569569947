/**
 * This component takes form item information and decides which form component to render
 * The form components are in the folder ./formTypes/*
 */
import React, { useEffect, useState } from 'react';
import {
	Check,
	DropDown,
	LineBreak,
	RadioButton,
	RadioFinancial,
	Text,
	Textarea,
	RichTextEditor,
	Donation,
	Multidrop,
	RadioCard,
	Date,
} from './formTypes/index';

interface Props {
	item: any;
	value: any;
	primaryColor: string;
	secondaryColor: string;
	textColor: string;
	errors?: Array<string>;
	display?: boolean;
	filteredValues?: any;
	passDonationValue: any;
	passDateValue: any;
	passPreselectedRadioFinancial: any;
	filters: any;
}

export default ({
	item,
	primaryColor,
	secondaryColor,
	textColor,
	errors,
	value,
	display = true,
	filteredValues,
	passDonationValue,
	passDateValue,
	passPreselectedRadioFinancial,
	filters
}: Props) => {
	let Component;
	
	switch (item.type) {
		case 'Check':
			Component = Check;
			break;
		case 'Dropdown':
			Component = item.defintion === 'Donation' ? Donation : DropDown;
			break;
		case 'Linebreak':
			Component = LineBreak;
			break;
		case 'Radio':
			Component = RadioButton;
			if (item.pricing === true) {
				Component = RadioFinancial;
			}
			break;
		case 'RadioCard':
			Component = RadioCard;
			break;
		case 'Text':
			if( item?.options?.length > 0 && (item?.contentType === 'state' || item?.content_type === 'country')){
			    Component = DropDown
			}else{
				Component = Text;
			}
			break;
		case 'State':
			Component = Text;
			break;
		case 'Textarea':
			Component = Textarea;
			if (item.richText === true) {
				Component = RichTextEditor;
			}
			break;
		case 'Multidrop':
			Component = Multidrop;
			break;
		case 'Date':
			Component = Date;
			break;
		default:
			break;
	}

	if (!Component || !display) {
		return null;
	}

	return (
		<Component
			{...item}
			value={value}
			item={item}
			primaryColor={primaryColor}
			secondaryColor={secondaryColor}
			textColor={textColor}
			error={errors ? (errors.length ? errors[0] : '') : ''}
			filteredValues={filteredValues}
			passDonationValue={passDonationValue}
			passDateValue={passDateValue}
			passPreselectedRadioFinancial={passPreselectedRadioFinancial}
			filters={filters}
		/>
	);
};
