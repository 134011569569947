import React, { useState, useEffect } from 'react';
import { Table, Card, notification, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import CircularProgress from '../../CircularProgress';

import {
	requestAddRegistrationCart,
	requestIncrementScreenNum,
} from '../../../appRedux/actions/Ecommerce';

import { ReactComponent as AddtoCartWithoutPlus } from '../../../assets/icons/AddtoCartWithoutPlus.svg';
import { ReactComponent as CartEmpty } from '../../../assets/icons/CartEmpty.svg';

const DynamicCartProgress = props => {
	const dispatch = useDispatch();
	const [isShown, setIsShown] = useState(false);
	const [attenuuid, setAttenuuid] = useState(props.attenuuid);
	const [cartItems, setCartItems] = useState([]);
	const [loader, setLoader] = useState(true);
	const [submitLoad, setSubmitLoad] = useState(false);

	const { invoiceArray, screenNum, appdir, cartUuid, publicEmbed, cartFetching } = props;

	const formState = JSON.stringify(props.formState);

	const { moduleUrlId } = useParams();
	// jwt token, appdir, all global variables, etc, get this from get redux. use userselector instead
	useEffect(() => {
		setAttenuuid(props.attenuuid);
	});

	useEffect(() => {
		if (invoiceArray) {
			const payableItems = invoiceArray.filter(
				item => !item.invoiceno && item.invoice_description,
			);
			setCartItems(payableItems);
		}
	}, [invoiceArray]);

	useEffect(() => {
		if (props.menuObjects) {
			setLoader(false);
		}
	}, [props.menuObjects]);

	useEffect(() => {
		if (!cartFetching) {
			setSubmitLoad(false);
			props.addCartLoad(false);
		}
	}, [screenNum, cartFetching]);

	const clickNext = event => {
		setSubmitLoad(true);
		props.addCartLoad(true);

		event.preventDefault();
		props.setValidate(props.hiddenValidate);
		if (props.hiddenValidate) {
			openNotificationWithIcon();
		} else {
			if (publicEmbed) {
				dispatch(requestIncrementScreenNum(props));
			} else {
				const formData = new FormData();
				formData.append('itemstoadd', formState);

				dispatch(
					requestAddRegistrationCart({
						appdir,
						moduleUrlId,
						attenuuid,
						formData,
						cartUuid,
						publicEmbed,
						existingPayment: props.existingPayment,
					}),
				);
			}
		}
	};

	const columns = [
		{
			title: 'Invoice Description',
			dataIndex: 'invoice_description',
			key: 'invoice_description',
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: text => (
				<p
					style={{
						textAlign: 'right',
					}}
				>
					${text}
				</p>
			),
		},
	];
	const openNotificationWithIcon = () => {
		notification['error']({
			message: 'Error',
			description: 'Please complete all required fields',
		});
		setSubmitLoad(false);
		props.addCartLoad(false);
	};

	let locale = {
		emptyText: <CartEmpty style={{ width: '6em' }} />,
	};

	return (
		<Card className="gx-card-full gx-text-center gx-card-sm product-card card">
			{loader && (
				<Card>
					<CircularProgress className="gx-profile-content" />
				</Card>
			)}

			<div className="gx-app-login-container">
				<br />
				<div className="gx-d-flex gx-align-items-center gx-justify-content-sm-around">
					<h2 className="gx-text-primary">Cart Progress</h2>
					<AddtoCartWithoutPlus
						className="fg-secondary gx-ml-5 gx-mt-1"
						style={{
							width: '1.5rem',
							marginBottom: '1rem',
						}}
					/>
				</div>
				<hr />
				<Table
					rowKey="id"
					columns={columns}
					dataSource={cartItems}
					pagination={false}
					showHeader={false}
					locale={locale}
				/>
			</div>

			<div className="gx-pt-4">
				<Button
					className="button-primary gx-font-weight-semi-bold shadow-2 gx-mb-3 gx-mr-3"
					shape="round"
					size="medium"
					onClick={clickNext}
					loading={submitLoad}
					style={{ float: 'right' }}
				>
					Save and Continue
				</Button>
			</div>
		</Card>
	);
};

export default DynamicCartProgress;
