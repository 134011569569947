import { all, call, fork, put, takeEvery, takeLatest, select } from 'redux-saga/effects';

import {
	REQUEST_PREV_SCREEN,
	REQUEST_SCREEN_COUNT,
	REQUEST_MENU_OBJECTS,
	REQUEST_ATTENDEE_ARRAY,
	REQUEST_CART_ITEMS,
	REQUEST_FREE_SELECTION_ITEMS,
	REQUEST_OPEN_SHOPPING_STYLE,
	REQUEST_SELECTION_VIEW,
	REQUEST_VALIDATION,
	REQUEST_ATTENUUID_INFO,
	REQUEST_INVOICE_INFO,
	REQUEST_SELECTION_ARRAY,
	REQUEST_CART_INFO,
	REQUEST_ADD_REGISTRATION_CART,
	REQUEST_ADD_SELECTION_CART,
	REQUEST_DELETE_CART_ITEM,
	REQUEST_PAYMENT_SETTINGS,
	REQUEST_ERASE_ECOMMERCE_DATA,
	REQUEST_RESET_ECOMMERCE_CART,
	REQUEST_ADD_ITEM_NO_LOGIN,
	REQUEST_ECOMMERCE_MODULARURLID,
	REQUEST_DELETE_CART_ITEM_NOLOGIN,
	REQUEST_ADD_ITEM_API_NO_LOGIN,
	REQUEST_PUBLIC_TO_PRIVATE_CART,
	REQUEST_RESET_CUSTOM_CHECKOUT_COMPONENT,
	REQUEST_ECOMMERCE_DELETE_NON_PROCESSED_PAYMENT,
	REQUEST_INCREMENT_SCREEN_NUM,
	REQUEST_STORE_SHIPPING_DETAILS,
	REQUEST_FLUSH_CART_ITEMS,
} from 'constants/ActionTypes';

import {
	receivePrevScreen,
	receiveScreenCount,
	receiveMenuObjects,
	receiveAttendeeArray,
	receiveCartItems,
	receiveFreeSelectionItems,
	receiveOpenShoppingStyle,
	receiveSelectionView,
	receiveValidation,
	receiveAttenuuidInfo,
	receiveInvoiceInfo,
	receiveSelectionArray,
	loadingSelectionArray,
	receiveCartInfo,
	receiveAddRegistrationCart,
	receiveAddSelectionCart,
	receiveDeleteCartItem,
	receivePaymentSettings,
	receiveEraseEcommerceData,
	receiveResetEcommerceCart,
	receiveAddItemNoLogin,
	receiveModularUrlId,
	receiveDeleteCartItemNoLogin,
	receiveRequestAddItemApiNoLogin,
	receivePublicToPrivateCart,
	receiveResetCustomCheckoutComponent,
	receiveDeleteNonProcessedPayment,
	requestIncrementScreenNum,
	receiveStoreShippingDetails,
	receiveFlushCartItems,
} from '../../appRedux/actions/Ecommerce';

import EcommerceAPIs from '../../api/Ecommerce';
import { fetchError } from '../actions/Common';

const fetchAttenuuidInfo = async context => {
	let jsonData = await EcommerceAPIs.getAttenuuidInfo(
		context.data.appdir,
		context.data.moduleUrlId,
	);
	return jsonData;
};

const fetchInvoiceInfo = async context => {
	let jsonData = await EcommerceAPIs.getInvoiceInfo(
		context.data.appdir,
		context.data.moduleUrlId,
		context.data.attenuuid,
	);
	return jsonData;
};

const fetchSelectionArray = async context => {
	let jsonData = await EcommerceAPIs.getSelectionArray(
		context.data.appdir,
		context.data.moduleUrlId,
		context.data.publicEmbed,
	);
	return jsonData;
};

const fetchCartInfo = async context => {
	let jsonData = await EcommerceAPIs.getCartInfo(
		context.data.appdir,
		context.data.moduleUrlId,
		context.data.attenuuid,
		context.data.publicEmbed,
		context.data.cartUuid,
	);
	return jsonData;
};

const fetchAddRegistrationCart = async context => {
	let jsonData = await EcommerceAPIs.addToRegistrationCart(
		context.data.appdir,
		context.data.moduleUrlId,
		context.data.attenuuid,
		context.data.formData,
		context.data.cartUuid,
		context.data.publicEmbed,
		context.data.existingPayment,
	);
	return jsonData;
};

const fetchAddSelectionCart = async context => {
	let jsonData = await EcommerceAPIs.addToSelectionCart(
		context.data.appdir,
		context.data.moduleUrlId,
		context.data.attenuuid,
		context.data.formData,
		context.data.checkoutForm,
	);
	return jsonData;
};

const fetchDeleteCartItem = async context => {
	let jsonData = await EcommerceAPIs.deleteCartItem(
		context.data.appdir,
		context.data.moduleUrlId,
		context.data.attenuuid,
		context.data.formData,
	);
	return jsonData;
};

const fetchDeleteNonProcessedPayment = async context => {
	let jsonData = await EcommerceAPIs.getDeleteNonProcessedPayment(
		context.data.moduleUrlId,
		context.data.attenuuid,
		context.data.formData,
	);
	return jsonData;
};

const fetchPaymentSettings = async context => {
	let jsonData = await EcommerceAPIs.getPaymentSettings(
		context.data.moduleUrlId,
		context.data.appdir,
		context.data.publicEmbed,
	);
	return jsonData;
};

const fetchAddToSelectionCartNoLogin = async context => {
	let jsonData = await EcommerceAPIs.addToSelectionCartNoLogin(
		context.data.appdir,
		context.data.moduleUrlId,
		context.data.formData,
		context.data.cartUuid,
	);
	return jsonData;
};

const fetchPublicToPrivateCart = async context => {
	let jsonData = await EcommerceAPIs.getPublicToPrivateCart(
		context.data.moduleUrlId,
		context.data.cartUuid,
		context.data.attenuuid,
	);
	return jsonData;
};

function* getAttenuuidInfo(action) {
	try {
		let attenuuidInfo = yield call(fetchAttenuuidInfo, action);
		yield put(receiveAttenuuidInfo(attenuuidInfo));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getInvoiceInfo(action) {
	try {
		let invoiceInfo = yield call(fetchInvoiceInfo, action);
		yield put(receiveInvoiceInfo(invoiceInfo));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getSelectionArray(action) {
	const fetchStatus = yield select(state => state.ecommerce.selectionArrayFetched);
	if (fetchStatus) return;
	yield put(loadingSelectionArray());

	try {
		let result = yield call(fetchSelectionArray, action);
		yield put(receiveSelectionArray(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getCartInfo(action) {
	try {
		let result = yield call(fetchCartInfo, action);
		yield put(receiveCartInfo(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getAddRegistrationCart(action) {
	try {
		let result = yield call(fetchAddRegistrationCart, action);
		yield put(receiveAddRegistrationCart(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getAddSelectionCart(action) {
	try {
		let result = yield call(fetchAddSelectionCart, action);
		yield put(receiveAddSelectionCart(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getDeleteCartItem(action) {
	try {
		let result = yield call(fetchDeleteCartItem, action);
		yield put(receiveDeleteCartItem(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getDeleteNonProcessedPayment(action) {
	try {
		let result = yield call(fetchDeleteNonProcessedPayment, action);
		yield put(receiveDeleteNonProcessedPayment(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getPaymentSettings(action) {
	try {
		let result = yield call(fetchPaymentSettings, action);
		yield put(receivePaymentSettings(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getAddToSelectionCartNoLogin(action) {
	try {
		let result = yield call(fetchAddToSelectionCartNoLogin, action);
		yield put(receiveRequestAddItemApiNoLogin(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getPublicToPrivateCart(action) {
	try {
		let result = yield call(fetchPublicToPrivateCart, action);
		yield put(receivePublicToPrivateCart(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}
// -----------

function* getEraseEcommerceData(action) {
	try {
		yield put(receiveEraseEcommerceData(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getPrevScreen(action) {
	try {
		yield put(receivePrevScreen(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getScreenCount(action) {
	try {
		yield put(receiveScreenCount(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getMenuObjects(action) {
	try {
		yield put(receiveMenuObjects(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getAttendeeArray(action) {
	try {
		yield put(receiveAttendeeArray(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getCartItems(action) {
	try {
		yield put(receiveCartItems(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getFreeSelectionItems(action) {
	try {
		yield put(receiveFreeSelectionItems(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getOpenShoppingStyle(action) {
	try {
		yield put(receiveOpenShoppingStyle(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getSelectionView(action) {
	try {
		yield put(receiveSelectionView(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getValidation(action) {
	try {
		yield put(receiveValidation(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getResetEcommerceCart(action) {
	try {
		yield put(receiveResetEcommerceCart(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getAddItemNoLogin(action) {
	try {
		yield put(receiveAddItemNoLogin(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getModularUrlId(action) {
	try {
		yield put(receiveModularUrlId(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getDeleteCartItemNoLogin(action) {
	try {
		yield put(receiveDeleteCartItemNoLogin(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getResetCustomCheckoutComponent(action) {
	try {
		yield put(receiveResetCustomCheckoutComponent(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getStoreShippingDetails(action) {
	try {
		yield put(receiveStoreShippingDetails(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getRequestFlushCartItems(action) {
	try {
		yield put(receiveFlushCartItems(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

export function* selectionArray() {
	yield takeEvery(REQUEST_SELECTION_ARRAY, getSelectionArray);
}

export function* invoiceInfo() {
	yield takeEvery(REQUEST_INVOICE_INFO, getInvoiceInfo);
}

export function* attenuuidInfo() {
	yield takeEvery(REQUEST_ATTENUUID_INFO, getAttenuuidInfo);
}

export function* cartInfo() {
	yield takeLatest(REQUEST_CART_INFO, getCartInfo);
}

export function* addRegistrationCart() {
	yield takeLatest(REQUEST_ADD_REGISTRATION_CART, getAddRegistrationCart);
}

export function* addSelectionCart() {
	yield takeLatest(REQUEST_ADD_SELECTION_CART, getAddSelectionCart);
}

export function* deleteCartItem() {
	yield takeLatest(REQUEST_DELETE_CART_ITEM, getDeleteCartItem);
}

export function* deleteNonProcessedPayment() {
	yield takeLatest(REQUEST_ECOMMERCE_DELETE_NON_PROCESSED_PAYMENT, getDeleteNonProcessedPayment);
}

export function* paymentSettings() {
	yield takeLatest(REQUEST_PAYMENT_SETTINGS, getPaymentSettings);
}

export function* addToSelectionCartNoLogin() {
	yield takeLatest(REQUEST_ADD_ITEM_API_NO_LOGIN, getAddToSelectionCartNoLogin);
}

export function* publicToPrivateCart() {
	yield takeLatest(REQUEST_PUBLIC_TO_PRIVATE_CART, getPublicToPrivateCart);
}

// ---------

export function* eraseEcommerceData() {
	yield takeEvery(REQUEST_ERASE_ECOMMERCE_DATA, getEraseEcommerceData);
}

export function* prevScreen() {
	yield takeEvery(REQUEST_PREV_SCREEN, getPrevScreen);
}

export function* screenCount() {
	yield takeEvery(REQUEST_SCREEN_COUNT, getScreenCount);
}

export function* menuObjects() {
	yield takeEvery(REQUEST_MENU_OBJECTS, getMenuObjects);
}

export function* attendeeArray() {
	yield takeEvery(REQUEST_ATTENDEE_ARRAY, getAttendeeArray);
}

export function* cartItems() {
	yield takeLatest(REQUEST_CART_ITEMS, getCartItems);
}

export function* freeSelectionItems() {
	yield takeEvery(REQUEST_FREE_SELECTION_ITEMS, getFreeSelectionItems);
}

export function* openShoppingStyle() {
	yield takeEvery(REQUEST_OPEN_SHOPPING_STYLE, getOpenShoppingStyle);
}

export function* selectionView() {
	yield takeEvery(REQUEST_SELECTION_VIEW, getSelectionView);
}

export function* validation() {
	yield takeEvery(REQUEST_VALIDATION, getValidation);
}

export function* resetEcommerceCart() {
	yield takeEvery(REQUEST_RESET_ECOMMERCE_CART, getResetEcommerceCart);
}

export function* addItemNoLogin() {
	yield takeEvery(REQUEST_ADD_ITEM_NO_LOGIN, getAddItemNoLogin);
}

export function* modularUrlId() {
	yield takeEvery(REQUEST_ECOMMERCE_MODULARURLID, getModularUrlId);
}

export function* deleteCartItemNoLogin() {
	yield takeEvery(REQUEST_DELETE_CART_ITEM_NOLOGIN, getDeleteCartItemNoLogin);
}

export function* resetCustomCheckoutComponent() {
	yield takeEvery(REQUEST_RESET_CUSTOM_CHECKOUT_COMPONENT, getResetCustomCheckoutComponent);
}

export function* storeShippingDetails() {
	yield takeEvery(REQUEST_STORE_SHIPPING_DETAILS, getStoreShippingDetails);
}

export function* requestFlushCartItems() {
	yield takeEvery(REQUEST_FLUSH_CART_ITEMS, getRequestFlushCartItems);
}

export default function* mySaga() {
	yield all([
		fork(selectionArray),
		fork(invoiceInfo),
		fork(attenuuidInfo),
		fork(cartInfo),
		fork(addRegistrationCart),
		fork(addSelectionCart),
		fork(deleteCartItem),
		fork(deleteNonProcessedPayment),
		fork(paymentSettings),
		fork(prevScreen),
		fork(addToSelectionCartNoLogin),
		fork(publicToPrivateCart),
		//------
		fork(eraseEcommerceData),
		fork(screenCount),
		fork(menuObjects),
		fork(attendeeArray),
		fork(cartItems),
		fork(freeSelectionItems),
		fork(openShoppingStyle),
		fork(selectionView),
		fork(validation),
		fork(resetEcommerceCart),
		fork(addItemNoLogin),
		fork(modularUrlId),
		fork(deleteCartItemNoLogin),
		fork(resetCustomCheckoutComponent),
		fork(storeShippingDetails),
		fork(requestFlushCartItems),
	]);
}
