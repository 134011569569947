import {
	REQUEST_PREV_SCREEN,
	RECEIVE_PREV_SCREEN,
	REQUEST_SCREEN_COUNT,
	RECEIVE_SCREEN_COUNT,
	REQUEST_MENU_OBJECTS,
	RECEIVE_MENU_OBJECTS,
	REQUEST_ATTENDEE_ARRAY,
	RECEIVE_ATTENDEE_ARRAY,
	REQUEST_CART_ITEMS,
	RECEIVE_CART_ITEMS,
	REQUEST_FREE_SELECTION_ITEMS,
	RECEIVE_FREE_SELECTION_ITEMS,
	REQUEST_OPEN_SHOPPING_STYLE,
	RECEIVE_OPEN_SHOPPING_STYLE,
	REQUEST_SELECTION_VIEW,
	RECEIVE_SELECTION_VIEW,
	REQUEST_VALIDATION,
	RECEIVE_VALIDATION,
	REQUEST_ATTENUUID_INFO,
	RECEIVE_ATTENUUID_INFO,
	REQUEST_INVOICE_INFO,
	RECEIVE_INVOICE_INFO,
	REQUEST_SELECTION_ARRAY,
	RECEIVE_SELECTION_ARRAY,
	LOADING_SELECTION_ARRAY,
	REQUEST_CART_INFO,
	RECEIVE_CART_INFO,
	REQUEST_ADD_REGISTRATION_CART,
	RECEIVE_ADD_REGISTRATION_CART,
	REQUEST_ADD_SELECTION_CART,
	RECEIVE_ADD_SELECTION_CART,
	REQUEST_DELETE_CART_ITEM,
	RECEIVE_DELETE_CART_ITEM,
	REQUEST_PAYMENT_SETTINGS,
	RECEIVE_PAYMENT_SETTINGS,
	REQUEST_ERASE_ECOMMERCE_DATA,
	RECEIVE_ERASE_ECOMMERCE_DATA,
	REQUEST_RESET_ECOMMERCE_CART,
	RECEIVE_RESET_ECOMMERCE_CART,
	REQUEST_ADD_ITEM_NO_LOGIN,
	RECEIVE_ADD_ITEM_NO_LOGIN,
	REQUEST_ECOMMERCE_MODULARURLID,
	RECEIVE_ECOMMERCE_MODULARURLID,
	REQUEST_DELETE_CART_ITEM_NOLOGIN,
	RECEIVE_DELETE_CART_ITEM_NOLOGIN,
	REQUEST_ADD_ITEM_API_NO_LOGIN,
	RECEIVE_ADD_ITEM_API_NO_LOGIN,
	REQUEST_PUBLIC_TO_PRIVATE_CART,
	RECEIVE_PUBLIC_TO_PRIVATE_CART,
	REQUEST_RESET_CUSTOM_CHECKOUT_COMPONENT,
	RECEIVE_RESET_CUSTOM_CHECKOUT_COMPONENT,
	REQUEST_ECOMMERCE_DELETE_NON_PROCESSED_PAYMENT,
	RECEIVE_ECOMMERCE_DELETE_NON_PROCESSED_PAYMENT,
	REQUEST_RESET_SCREEN_NUM,
	REQUEST_RESET_REGISTRATION_UPDATED,
	REQUEST_SET_SCREEN_NUM,
	REQUEST_INCREMENT_SCREEN_NUM,
} from 'constants/ActionTypes';
import {
	RECEIVE_FLUSH_CART_ITEMS,
	RECEIVE_STORE_SHIPPING_DETAILS,
	REQUEST_FLUSH_CART_ITEMS,
	REQUEST_STORE_SHIPPING_DETAILS,
} from '../../constants/ActionTypes';

export const requestPrevScreen = data => {
	return {
		type: REQUEST_PREV_SCREEN,
		payload: data,
	};
};

export const receivePrevScreen = data => {
	return {
		type: RECEIVE_PREV_SCREEN,
		payload: data,
	};
};

export const requestScreenCount = data => {
	return {
		type: REQUEST_SCREEN_COUNT,
		payload: data,
	};
};

export const receiveScreenCount = data => {
	return {
		type: RECEIVE_SCREEN_COUNT,
		payload: data,
	};
};

export const requestMenuObjects = data => {
	return {
		type: REQUEST_MENU_OBJECTS,
		payload: data,
	};
};

export const receiveMenuObjects = data => {
	return {
		type: RECEIVE_MENU_OBJECTS,
		payload: data,
	};
};

export const requestAttendeeArray = data => {
	return {
		type: REQUEST_ATTENDEE_ARRAY,
		payload: data,
	};
};

export const receiveAttendeeArray = data => {
	return {
		type: RECEIVE_ATTENDEE_ARRAY,
		payload: data,
	};
};

export const requestCartItems = data => {
	return {
		type: REQUEST_CART_ITEMS,
		payload: data,
	};
};

export const receiveCartItems = data => {
	return {
		type: RECEIVE_CART_ITEMS,
		payload: data,
	};
};

export const requestFreeSelectionItems = data => {
	return {
		type: REQUEST_FREE_SELECTION_ITEMS,
		payload: data,
	};
};

export const receiveFreeSelectionItems = data => {
	return {
		type: RECEIVE_FREE_SELECTION_ITEMS,
		payload: data,
	};
};

export const requestOpenShoppingStyle = data => {
	return {
		type: REQUEST_OPEN_SHOPPING_STYLE,
		payload: data,
	};
};

export const receiveOpenShoppingStyle = data => {
	return {
		type: RECEIVE_OPEN_SHOPPING_STYLE,
		payload: data,
	};
};

export const requestSelectionView = data => {
	return {
		type: REQUEST_SELECTION_VIEW,
		payload: data,
	};
};

export const receiveSelectionView = data => {
	return {
		type: RECEIVE_SELECTION_VIEW,
		payload: data,
	};
};

export const requestValidation = data => {
	return {
		type: REQUEST_VALIDATION,
		payload: data,
	};
};

export const receiveValidation = data => {
	return {
		type: RECEIVE_VALIDATION,
		payload: data,
	};
};

export const requestAttenuuidInfo = data => {
	return {
		type: REQUEST_ATTENUUID_INFO,
		data,
	};
};

export const receiveAttenuuidInfo = data => {
	return {
		type: RECEIVE_ATTENUUID_INFO,
		payload: data,
	};
};

export const requestInvoiceInfo = data => {
	return {
		type: REQUEST_INVOICE_INFO,
		data,
	};
};

export const receiveInvoiceInfo = data => {
	return {
		type: RECEIVE_INVOICE_INFO,
		payload: data,
	};
};

export const loadingSelectionArray = data => {
	return {
		type: LOADING_SELECTION_ARRAY,
		data,
	};
};

export const requestSelectionArray = data => {
	return {
		type: REQUEST_SELECTION_ARRAY,
		data,
	};
};

export const receiveSelectionArray = data => {
	return {
		type: RECEIVE_SELECTION_ARRAY,
		payload: data,
	};
};

export const requestCartInfo = data => {
	return {
		type: REQUEST_CART_INFO,
		data,
	};
};

export const receiveCartInfo = data => {
	return {
		type: RECEIVE_CART_INFO,
		payload: data,
	};
};

export const requestAddRegistrationCart = data => {
	return {
		type: REQUEST_ADD_REGISTRATION_CART,
		data,
	};
};

export const receiveAddRegistrationCart = data => {
	return {
		type: RECEIVE_ADD_REGISTRATION_CART,
		payload: data,
	};
};

export const requestAddSelectionCart = data => {
	return {
		type: REQUEST_ADD_SELECTION_CART,
		data,
	};
};

export const receiveAddSelectionCart = data => {
	return {
		type: RECEIVE_ADD_SELECTION_CART,
		payload: data,
	};
};

export const requestDeleteCartItem = data => {
	return {
		type: REQUEST_DELETE_CART_ITEM,
		data,
	};
};

export const receiveDeleteCartItem = data => {
	return {
		type: RECEIVE_DELETE_CART_ITEM,
		payload: data,
	};
};

export const requestPaymentSettings = data => {
	return {
		type: REQUEST_PAYMENT_SETTINGS,
		data,
	};
};

export const receivePaymentSettings = data => {
	return {
		type: RECEIVE_PAYMENT_SETTINGS,
		payload: data,
	};
};

export const requestEraseEcommerceData = data => {
	return {
		type: REQUEST_ERASE_ECOMMERCE_DATA,
		payload: data,
	};
};

export const receiveEraseEcommerceData = data => {
	return {
		type: RECEIVE_ERASE_ECOMMERCE_DATA,
		payload: data,
	};
};

export const requestResetEcommerceCart = data => {
	return {
		type: REQUEST_RESET_ECOMMERCE_CART,
		payload: data,
	};
};

export const receiveResetEcommerceCart = data => {
	return {
		type: RECEIVE_RESET_ECOMMERCE_CART,
		payload: data,
	};
};

export const requestAddItemNoLogin = data => {
	return {
		type: REQUEST_ADD_ITEM_NO_LOGIN,
		data,
	};
};

export const receiveAddItemNoLogin = data => {
	return {
		type: RECEIVE_ADD_ITEM_NO_LOGIN,
		payload: data,
	};
};

export const requestModularUrlId = data => {
	return {
		type: REQUEST_ECOMMERCE_MODULARURLID,
		data,
	};
};

export const receiveModularUrlId = data => {
	return {
		type: RECEIVE_ECOMMERCE_MODULARURLID,
		payload: data,
	};
};

export const requestDeleteCartItemNoLogin = data => {
	return {
		type: REQUEST_DELETE_CART_ITEM_NOLOGIN,
		data,
	};
};

export const receiveDeleteCartItemNoLogin = data => {
	return {
		type: RECEIVE_DELETE_CART_ITEM_NOLOGIN,
		payload: data,
	};
};

export const requestRequestAddItemApiNoLogin = data => {
	return {
		type: REQUEST_ADD_ITEM_API_NO_LOGIN,
		data,
	};
};

export const receiveRequestAddItemApiNoLogin = data => {
	return {
		type: RECEIVE_ADD_ITEM_API_NO_LOGIN,
		payload: data,
	};
};

export const requestPublicToPrivateCart = data => {
	return {
		type: REQUEST_PUBLIC_TO_PRIVATE_CART,
		data,
	};
};

export const receivePublicToPrivateCart = data => {
	return {
		type: RECEIVE_PUBLIC_TO_PRIVATE_CART,
		payload: data,
	};
};

export const requestResetCustomCheckoutComponent = data => {
	return {
		type: REQUEST_RESET_CUSTOM_CHECKOUT_COMPONENT,
		payload: data,
	};
};

export const receiveResetCustomCheckoutComponent = data => {
	return {
		type: RECEIVE_RESET_CUSTOM_CHECKOUT_COMPONENT,
		payload: data,
	};
};

export const requestDeleteNonProcessedPayment = data => {
	return {
		type: REQUEST_ECOMMERCE_DELETE_NON_PROCESSED_PAYMENT,
		data,
	};
};

export const receiveDeleteNonProcessedPayment = data => {
	return {
		type: RECEIVE_ECOMMERCE_DELETE_NON_PROCESSED_PAYMENT,
		payload: data,
	};
};

export const requestResetScreenNum = data => {
	return {
		type: REQUEST_RESET_SCREEN_NUM,
		data,
	};
};

export const requestResetRegistrationUpdated = () => {
	return {
		type: REQUEST_RESET_REGISTRATION_UPDATED,
	};
};

export const requestSetScreenNum = data => {
	return {
		type: REQUEST_SET_SCREEN_NUM,
		data,
	};
};

export const requestIncrementScreenNum = data => {
	return {
		type: REQUEST_INCREMENT_SCREEN_NUM,
		data,
	};
};

export const requestStoreShippingDetails = data => {
	return {
		type: REQUEST_STORE_SHIPPING_DETAILS,
		payload: data,
	};
};

export const receiveStoreShippingDetails = data => {
	return {
		type: RECEIVE_STORE_SHIPPING_DETAILS,
		payload: data,
	};
};

export const requestFlushCartItems = data => {
	return {
		type: REQUEST_FLUSH_CART_ITEMS,
		payload: data,
	};
};

export const receiveFlushCartItems = data => {
	return {
		type: RECEIVE_FLUSH_CART_ITEMS,
		payload: data,
	};
};
