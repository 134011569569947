import React from 'react';

import { connectPagination } from 'react-instantsearch-dom';

import { Pagination as AntPagination } from 'antd';

const Pagination = ({ currentRefinement, nbPages, refine, onPageChange }) => {
	const handleChange = (page, pageSize) => {
		refine(page, pageSize);
		if (onPageChange) {
			onPageChange(page, pageSize);
		}
	}
	return (
		<AntPagination current={currentRefinement} onChange={handleChange} total={nbPages} pageSize={1} />
	);
}

export default connectPagination(Pagination);
