import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';

const ProfileHomeLink = props => {
	const handleProfileHomeButtonClick = url => {
		if (url.includes('http')) {
			window.location.href = url;
			return;
		}
		props.history.push(url);
	};

	return (
		<div>
			{props.link.type === 'link' ? (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<h4 className="fg-grey">{props.link.label}</h4>
					<Button
						key={props.link.id}
						id={props.link.id}
						className="gx-ml-auto button-primary gx-font-weight-semi-bold shadow-2"
						shape="round"
						style={{
							border: 'none',
							padding: '0 2.5rem',
						}}
						onClick={() => {
							handleProfileHomeButtonClick(props.link.button_link);
						}}
					>
						GO
					</Button>
				</div>
			) : (
				''
			)}
			<h6>
				<div
					dangerouslySetInnerHTML={{
						__html: props.link.screen_text,
					}}
				></div>
			</h6>
			<br />
		</div>
	);
};

export default withRouter(ProfileHomeLink);
