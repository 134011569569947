import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { requestCategories } from 'appRedux/actions/Consumption';

export const useCategory = () => {
	const dispatch = useDispatch();
	const categoriesMap = useSelector(state => state.consumption.categoriesMap);
	const status = useSelector(state => state.consumption.categoriesStatus);
	const loading = status === 'loading' || status === 'idle';

	useEffect(() => {
		if (status === 'idle') dispatch(requestCategories());
	}, [status]);

	return [loading, categoriesMap];
};
