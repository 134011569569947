import React, { useEffect, useState } from 'react'
import { Input, Table } from 'antd'
import { useSelector } from 'react-redux';
import { convertLowercaseFormat, searchBasedOnEmail, searchBasedOnName, searchBasedonRoles } from '@components/Membership/helpers/generalHelpers';
import { debounce } from 'lodash';


const CommonTable = ({ columns, dataSource, scroll, staffPage,placeholderTitle}) => {
	const { roles } = useSelector(state => state.corporateMembership);
	const [searchMembers, setSearchMembers] = useState(dataSource);
	const { Search } = Input
	const emailRegex = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{1,15})$/

	const delayedSearch = debounce((searchValue) => {
		if (searchValue && emailRegex.test(searchValue)) {
			let emailBasedFilter = searchBasedOnEmail(dataSource, searchValue)
			setSearchMembers(emailBasedFilter)
		}
		else if (roles.some(data => convertLowercaseFormat(`${data?.label}`).includes(searchValue)) && searchValue.length && staffPage) {
			let rolesBasedFilter = searchBasedonRoles(dataSource, searchValue)
			setSearchMembers(rolesBasedFilter)
		}
		else if (searchValue.length) {
			let nameBasedFilter = searchBasedOnName(dataSource, searchValue)
			setSearchMembers(nameBasedFilter)
		} else {
			setSearchMembers(dataSource)
		}
	},500)
	const handleSearch = (e) => {
		let searchValue = convertLowercaseFormat(e.target.value)
		delayedSearch(searchValue);
	}

	useEffect(() => {
	if (dataSource.length >= 0 && searchMembers.length != dataSource.length) {
		setSearchMembers(dataSource)
	}
    }, [dataSource])

	return (
		<>
			<Search onKeyUp={handleSearch} allowClear placeholder={`Search ${placeholderTitle}`} className="gx-mb-3" />
			<Table columns={columns} dataSource={[...searchMembers]} className='default-table' scroll={scroll} pagination={false} />
		</>
	)
}

export default CommonTable