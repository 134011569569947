import React, { useEffect, useState } from 'react';
import { Button, Input, message, Form, Card, Col, Row, Alert } from 'antd';
import { useSelector, connect } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import {
	requestAuthUser,
	requestAuthenticateUser,
	requestValidateEmail,
	requestLoginFormData,
	requestProfileData,
	requestToUpdateEmail,
	requestUserCompanyAffiliationData,
	requestEraseAuthData,
	requestEraseProfileData,
	requestToDeleteUserCompanyAffiliation,
	requestToSendMagicLink,
} from 'appRedux/actions';
import CircularProgress from '@components/CircularProgress';
import auth from '../../../loginAuth/loginAuth';
import { bindActionCreators } from 'redux';
import { NotificationContainer } from 'react-notifications';
import GoBack from '@components/Common/GoBack';
import { PROFILE_FORM_VIEW, SIGN_UP_VIEW } from '@components/Membership/helpers/constant';

const ExhibitorSignIn = props => {
	const [submitLoad, setSubmitLoad] = useState(false);
	const [validateEmail, setValidateEmail] = useState(false);
	const [showPasswordInput, setShowPasswordInput] = useState(false);

	const [invalidLoginAlert, setInvalidLoginAlert] = useState(false);
	const [resetPasswordAlert, setResetPasswordAlert] = useState(false);

	const [email, setEmail] = useState('');

	const { alertMessage, showMessage, loader } = useSelector(({ auth }) => auth);
	const location = useSelector(state => state.router.location);
	const settings = useSelector(state => state.settings);
	const isTokenInUrl = location?.search?.includes('token');
	const publicEmbed = settings.publicEmbed || props.location?.search?.includes('public_embed=1');
	const { moduleUrlId } = useParams();
	const history = useHistory();
	const info = props.loginInfo;

	useEffect(() => {
		if (props.comeBackToSignInFromSignUp) {
			auth.deleteCookie('jwt');
			auth.deleteCookie('ssotoken');
			auth.deleteCookie('contact_uuid');
			auth.deleteCookie('appdir');
			auth.deleteCookie('source_hex');

			props.requestEraseAuthData();
			props.requestEraseProfileData();
			props.requestToDeleteUserCompanyAffiliation();
			setShowPasswordInput(false);
			setValidateEmail(false);
		}
	}, [props.comeBackToSignInFromSignUp]);

	useEffect(() => {
		if (!props.loginInfo.loginFormDataFetched) {
			let location = window.location;
			let appdir = location.hostname.substr(0, location.hostname.indexOf('.'));
			props.requestLoginFormData({ appdir: props.loginInfo.appdir || appdir });
		}
	}, [props.loginInfo.loginFormDataFetched]);

	useEffect(() => {
		setEmail(props.loginInfo.email);
	}, [props.loginInfo.email]);

	useEffect(() => {
		setShowPasswordInput(false);
		setValidateEmail(false);
		// if the user has an email and there is no request to reset password
		if (props.auth.emailExists === 1 && props.auth.password_reset === 0) {
			setShowPasswordInput(true);
			setValidateEmail(true);
		} else if (props.auth.emailExists === 1 && props.auth.password_reset === 1) {
			// if the user has an email but there is request to reset password (i.e. someone created an account for them)
			setResetPasswordAlert(true);
		} else if (props.auth.emailExists === 0 && !info.firstName) {
			// the email doesn't exist
			// Add email to redux so that it can propagated to the Sign Up page.
			props.requestToUpdateEmail({
				email: email,
			});
			if (!props.comeBackToSignInFromSignUp) { props.updateView(SIGN_UP_VIEW); }
		}
	}, [props.auth, props.auth.emailExists]);

	//watches change in jwt token or alert message after submit login is clicked
	useEffect(() => {
		if (props.auth.alertMessage === 'Email or Password was incorrect.') {
			setInvalidLoginAlert(true);
			setSubmitLoad(false);
		} else {
			let jwtToken = auth.getCookie('jwt');
			if (jwtToken !== '' && props.auth.contact_uuid && info.source_hex) {
				requestProfile(info.source_hex, props.auth.contact_uuid);
			}
		}
	}, [props.auth, info.source_hex]);

	//watches for when the api is retrieved the user profile data.
	useEffect(() => {
		let jwtToken = auth.getCookie('jwt');
		if (
			info.firstName !== '' &&
			jwtToken !== '' &&
			props.corporateMembership.userCompanyAffiliationFetched &&
			props.corporateMembership.userCompanyAffiliation.comp_uuid !== ''
		) { 
			// setSubmitLoad(true)
			props.updateView(PROFILE_FORM_VIEW) }
	}, [info.firstName, props.corporateMembership.userCompanyAffiliationFetched]);

	// useEffect(() => {
	// 	console.log("props",props);
	//  if(props.auth.jwt && publicEmbed ){

	//  }
	// }, [props.auth.jwt])
	

	// useEffect(() => {
	// 	console.log("submitLoad",submitLoad);
	// 	if (isTokenInUrl && props.auth.jwt && submitLoad && publicEmbed) {
	// 		let urlParams = new URLSearchParams(location.search);
	// 		const uuid = urlParams.get('uuid');
	// 		let url = `${window.location.pathname}?public_embed=1&uuid=${uuid}&token=${props.auth.ssotoken}&verified`
	// 		window.location.href = url
	// 		props.updateView(PROFILE_FORM_VIEW)
	// 	}
	// }, [props.auth.jwt,submitLoad])


	const submitLogin = values => {
		if (validateEmail) {
			setSubmitLoad(true);
			let formData = new FormData();
			formData.append('email', values.email);
			formData.append('password', values.password);
			formData.append('react_login', 1);
			props.requestAuthenticateUser({
				appdir: props.loginInfo.appdir,
				formData: formData,
			});
		}
	};

	const checkValidateEmail = () => {
		props.toggleComeBackToSignInFromSignUp(false);

		let formData = new FormData();
		formData.append('login_request', 1);
		formData.append('react_login', 1);
		props.requestValidateEmail({
			appdir: props.loginInfo.appdir,
			email: email,
			formData: formData,
		});
	};

	const onEmailChange = event => {
		setEmail(event.target.value);
	};

	const requestProfile = (source_hex, contact_uuid) => {
		setSubmitLoad(true);
		props.requestProfileData({ source_hex: source_hex, contact_uuid: contact_uuid });
		props.requestUserCompanyAffiliationData({
			appDir: props.loginInfo.appdir,
			urlId: moduleUrlId,
		});
	};

	const onInvalidLoginAlertClose = () => {
		setInvalidLoginAlert(false);
	};

	const onResetPasswordAlertClose = () => {
		setResetPasswordAlert(false);
	};

	const handleBackClick = () => {
		setShowPasswordInput(false);
		setValidateEmail(false);
	};

	const sendMagicLink = () => {
		props.requestToSendMagicLink({
			appDir: props.loginInfo.appdir,
			cartUuid: props.corporateMembership.exhibitorCartDetails.exhibitorCartUuid,
			moduleUrlId,
			email,
		});
	};
	const loginBox = login_form => {
		return (
			<div className="gx-app-login-container" style={{ textAlign: 'center' }}>
				{
					<Card className="card-shadow">
						<h2
							style={{
								fontFamily: 'NoirPro, sans-serif',
								fontSize: '14px',
								color: '#535353',
								fontWeight: '600',
								textTransform: 'uppercase',
							}}
						>
							{!showPasswordInput &&
								!validateEmail &&
								props.corporateMembership.languageObject
									?.account_signin_email_prompt_msg}
							{showPasswordInput &&
								validateEmail &&
								props.corporateMembership.languageObject
									?.account_signin_password_prompt_msg}
						</h2>
						{showPasswordInput && (
							<div style={{ width: '10%' }}>
								<GoBack handleBackClick={handleBackClick} />
							</div>
						)}
						<Form
							id="myForm"
							name="basic"
							layout="vertical"
							onFinish={submitLogin}
							className="gx-signin-form gx-form-row0"
						>
							<Form.Item
								validateTrigger="onSubmit"
								rules={[
									{
										type: info.login_form
											? info.login_form?.email_input_type
											: 'email',
										message: info.login_form
											? info.login_form?.invalid_email_label
											: 'The input is not valid E-mail!',
									},
									{
										required: true,
										message: info.login_form
											? info.login_form.no_email_label
											: 'Please enter your email',
									},
								]}
								name="email"
							>
								<Input
									placeholder="Email"
									disabled={showPasswordInput && email !== ''}
									onChange={e => {
										onEmailChange(e);
									}}
									value={email}
								/>
							</Form.Item>
							{info.login_form && info.login_form.password && showPasswordInput ? (
								<Form.Item
									rules={[
										{
											required: true,
											message: info.login_form
												? info.login_form.no_password_label
												: 'Please enter your password!',
										},
									]}
									name="password"
								>
									<Input
										type="password"
										placeholder={
											info.login_form
												? info.login_form.password_label
												: 'Password'
										}
									/>
								</Form.Item>
							) : (
								''
							)}
							{invalidLoginAlert ? (
								<Alert
									message={
										info
											? info.login_form.incorrect_password
											: 'Incorrect login. Please enter a valid login and password.'
									}
									type="error"
									closable="true"
									onClose={onInvalidLoginAlertClose}
								/>
							) : (
								''
							)}
							{resetPasswordAlert && (
								<Alert
									message={
										info
											? info.login_form.incomplete_password
											: 'A link has been sent to your email to reset your password.'
									}
									type="error"
									closable="true"
									onClose={onResetPasswordAlertClose}
								/>
							)}

							<Form.Item>
								{!validateEmail ? (
									<Button
										form="myForm"
										type="default"
										// key="submit"
										// htmlType="submit"
										onClick={checkValidateEmail}
										loading={submitLoad}
										className="login-form-button gx-mt-1 button-primary"
										style={{
											backgroundColor: login_form
												? login_form.primary_color
												: '#33b4fd',
											color: '#fff',
											width: '100%',
										}}
										shape={'round'}
									>
										Continue
									</Button>
								) : (
									<Button
										form="myForm"
										type="default"
										key="submit"
										htmlType="submit"
										loading={submitLoad}
										className="login-form-button gx-mt-1 button-primary"
										style={{
											backgroundColor: login_form
												? login_form.primary_color
												: '#33b4fd',
											color: '#fff',
											width: '100%',
										}}
										shape={'round'}
									>
										{info.login_form
											? info.login_form.login_button_label
											: 'Login'}
									</Button>
								)}
								{showPasswordInput && (
									<Button
										type="default"
										htmlType="button"
										onClick={sendMagicLink}
										className="login-form-button gx-mt-1 button-primary"
										style={{
											backgroundColor: login_form
												? login_form.primary_color
												: '#33b4fd',
											color: '#fff',
											width: '40%',
										}}
										shape={'round'}
									>
										Email Me Login Link
									</Button>
								)}

								<br />
								<br />
								<br />
							</Form.Item>
						</Form>
						<NotificationContainer />
					</Card>
				}
			</div>
		);
	};

	const showLoginScreen = () => {
		return (
			<Row style={{ height: '100%' }}>
				{info && !_isEmpty(info.login_form) && info.ads && info.ads.length > 0 && (
					<Col xl={24} lg={24} md={24} sm={24} xs={24}>
						{loginBox(info.login_form)}
					</Col>
				)}
				{info &&
					!_isEmpty(info.login_form) &&
					((info.ads && info.ads.length == 0) || !info.ads) && (
						<Col xl={24} lg={24} md={24} sm={24} xs={24}>
							{loginBox(info.login_form)}
						</Col>
					)}
			</Row>
		);
	};
	return (
		<div className="gx-app-login-wrap">
			{loader || _isEmpty(info.login_form) ? (
				<div className="gx-loader-view">
					<CircularProgress className="gx-profile-content" />
				</div>
			) : (
				showLoginScreen()
			)}
			{showMessage ? message.error(alertMessage.toString()) : null}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		auth: state.auth,
		loginInfo: state.loginInfo,
		corporateMembership: state.corporateMembership,
	};
};

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		Object.assign({
			requestAuthUser: requestAuthUser,
			requestProfileData: requestProfileData,
			requestLoginFormData: requestLoginFormData,
			requestAuthenticateUser: requestAuthenticateUser,
			requestValidateEmail: requestValidateEmail,
			requestToUpdateEmail: requestToUpdateEmail,
			requestUserCompanyAffiliationData: requestUserCompanyAffiliationData,
			requestEraseAuthData: requestEraseAuthData,
			requestEraseProfileData: requestEraseProfileData,
			requestToDeleteUserCompanyAffiliation: requestToDeleteUserCompanyAffiliation,
			requestToSendMagicLink: requestToSendMagicLink,
		}),
		dispatch,
	);
}

export default connect(mapStateToProps, matchDispatchToProps)(ExhibitorSignIn);
