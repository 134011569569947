import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { requestLoginFormData } from 'appRedux/actions';
import { requestTags } from 'appRedux/actions/Consumption';

export const useTags = () => {
	const dispatch = useDispatch();
	const appdir = useSelector(state => state.loginInfo.appdir);
	const source = useSelector(state => state.loginInfo.source_hex);
	const tagsMap = useSelector(state => state.consumption.tagsMap);

	const status = useSelector(state => state.consumption.tagsStatus);
	const loading = status === 'loading' || status === 'idle';

	useEffect(() => {
		if (!source && appdir) dispatch(requestLoginFormData({ appdir }));
		if (status === 'idle' && source) dispatch(requestTags({ source }));
	}, [appdir, source, status]);

	return [loading, tagsMap];
};
