import React from 'react';
import { Row } from 'antd';
import Auxiliary from 'util/Auxiliary';

const Widgets = () => {
	return (
		<Auxiliary>
			<Row></Row>
		</Auxiliary>
	);
};

export default Widgets;
