import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Avatar } from 'antd';
import moment from 'moment';
import { requestToFetchEmailMessagesCount } from '../../../appRedux/actions';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';

import '../../Ecommerce/Store/SelectionPage/selection.css';
import { ReactComponent as ProfileHomeCompany } from '../../../assets/icons/ProfileHomeCompany.svg';
import { ReactComponent as ProfileHomeDateWhite } from '../../../assets/icons/ProfileHomeDateWhite.svg';
import { ReactComponent as ProfileHomeLocation } from '../../../assets/icons/ProfileHomeLocation.svg';
import { ReactComponent as ProfileHomeMail } from '../../../assets/icons/ProfileHomeMail.svg';
import { ReactComponent as ProfileHomeLines } from '../../../assets/icons/ProfileHomeLines.svg';

const NewProfileCard = props => {
	const history = useHistory();

	useEffect(() => {
		if (!props.loginInfo.emailMessagesCountFetched) {
			props.requestToFetchEmailMessagesCount();
		}
	}, []);

	const viewMessages = () => {
		props.enableMessagesView(true);
	};

	const clickUpdateProfile = () => {
		history.push('/main/updateaccount');
	};

	const hasCompany = props.loginInfo.company && props.loginInfo.company !== 'undefined';
	const hasCity = props.loginInfo.city && props.loginInfo.city !== 'undefined';
	const hasCountry = props.loginInfo.country && props.loginInfo.country !== 'undefined';

	return (
		<div className="gx-app-login-container" style={{ textAlign: 'left' }}>
			<Card className="gx-mb-0 gx-h-100 product-card card bg-primary-gradient">
				<div style={{ display: 'flex', justifyContent: 'space-between', color: 'white' }}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
						}}
					>
						<div
							style={{ display: 'flex', alignItems: 'center', marginBottom: '2rem' }}
						>
							<Avatar size={64} src={props.loginInfo.picture} />
							<h2 style={{ marginTop: '0.6em', marginLeft: '1rem', color: 'white' }}>
								{props.loginInfo.firstName} {props.loginInfo.lastName}
							</h2>
						</div>

						<div style={{ marginLeft: '2rem' }}>
							{/* 
						props.loginInfo.company != undefined is necessary because sometimes variables literally comes
						back from the update profile api as 'undefined' in a string
						*/}

							{hasCompany ? (
								<div className="gx-d-flex gx-align-items-center">
									<ProfileHomeCompany
										className="gx-mr-2 gx-mt-1"
										style={{
											width: '1.5rem',
											marginBottom: '1rem',
											fill: 'white',
										}}
									/>
									<p
										style={{
											textAlign: 'left',
										}}
									>
										{props.loginInfo.company}
									</p>
								</div>
							) : (
								''
							)}

							<div className="gx-d-flex gx-align-items-center">
								{hasCity && hasCountry ? (
									<ProfileHomeLocation
										className="gx-mr-2 gx-mt-1"
										style={{
											width: '1.5rem',
											marginBottom: '1rem',
											fill: 'white',
										}}
									/>
								) : (
									''
								)}

								<p
									style={{
										textAlign: 'left',
									}}
								>
									{hasCity ? props.loginInfo.city : ''}

									{hasCity && hasCountry ? ', ' : ''}

									{hasCountry ? props.loginInfo.country : ''}
								</p>
							</div>

							<div className="gx-d-flex gx-align-items-center">
								<ProfileHomeMail
									className="gx-mr-2 gx-mt-1"
									style={{
										width: '1.5rem',
										marginBottom: '1rem',
										fill: 'white',
									}}
								/>
								<p
									style={{
										textAlign: 'left',
									}}
								>
									{props.loginInfo.email}
								</p>
							</div>

							{props.loginInfo.lastUpdated ? (
								<div className="gx-d-flex gx-align-items-center">
									<ProfileHomeDateWhite
										className="gx-mr-2 gx-mt-1"
										style={{
											width: '1.5rem',
											marginBottom: '1rem',
											fill: 'white',
										}}
									/>
									<p
										style={{
											textAlign: 'left',
										}}
									>
										Last Updated:{' '}
										{`${moment(props.loginInfo.lastUpdated).format(
											'MMM-DD-YYYY',
										)}`}
									</p>
								</div>
							) : (
								''
							)}
						</div>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
						}}
					>
						<div
							style={{
								color: 'white',
								marginTop: '1rem',
							}}
						>
							<Button
								className="gx-ml-auto gx-d-block gx-mb-3 button-primary gx-text-secondary"
								shape="round"
								style={{
									background: 'white',
									width: '9rem',
								}}
								onClick={clickUpdateProfile}
							>
								Manage Profile
							</Button>

							{props.loginInfo.emailMessagesCountFetched && (
								<Button
									className="gx-ml-auto button-primary gx-text-secondary"
									shape="round"
									style={{
										background: 'white',
										width: '9rem',
									}}
									onClick={viewMessages}
								>
									{`Messages (${
										parseInt(
											props.loginInfo.emailMessagesCount.unread_emails,
										) || 0
									})`}
								</Button>
							)}
						</div>
						<div style={{ alignSelf: 'flex-end', marginTop: '8rem' }}>
							<ProfileHomeLines
								style={{
									width: '10rem',
									fill: 'white',
								}}
							/>
						</div>
					</div>
				</div>
			</Card>
			<br />
		</div>
	);
};

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		Object.assign({
			requestToFetchEmailMessagesCount: requestToFetchEmailMessagesCount,
		}),
		dispatch,
	);
}

function mapStateToProps(state) {
	return {
		loginInfo: state.loginInfo,
	};
}

export default connect(mapStateToProps, matchDispatchToProps)(NewProfileCard);
