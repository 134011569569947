import React, { useState, useEffect } from 'react';
import Card from 'react-credit-cards';
import { Form, Button, Select, Alert } from 'antd';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from './utils';
import { connect } from 'react-redux';
import 'react-credit-cards/es/styles-compiled.css';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
	requestSendPayment,
	requestSendPaymentExhibitor,
	requestResetInvalidCreditCard,
	requestResetPaymentSuccess,
	requestPaymentLoader
} from '../../../../appRedux/actions/Common';

import { requestClearCreditCardNotAcceptedMsg } from '../../../../appRedux/actions/CorporateMembership';
import '../styles/custom.less';

const CreditCard = props => {
	const publicCheckout = useSelector(
		state =>
			state.ecommerce?.attendeeSettings?.attendeesiteadmin?.generalconfigobject
				?.public_checkout,
	);
	const cartUuid = useSelector(state => state.ecommerce.cartUuid);
	const creditCardNotAcceptedMsg = useSelector(
		state => state.corporateMembership?.paymentErrorMsg,
	);
	const paymentErrorMsg = useSelector(state => state.common?.paymentProgress.ERRORMESSAGE);
	const [state, setState] = useState({
		paymentType: 'creditCard',
		number: '',
		name: '',
		expiry: '',
		cvc: '',
		focused: '',
		address: '',
		city: '',
		province: '',
		zip: '',
		country: '',
		type: '',
		amount: props.netOwing,
		CURRENCYID: 1,
		TRYCOUNT: 1,
	});
	const [submitLoad, setSubmitLoad] = useState(false);
	const [validCard, setValidCard] = useState(''); // this is checking validation pre submit

	const [invalidCardAlert, setInvalidCardAlert] = useState(false);

	const dispatch = useDispatch();
	const { moduleUrlId } = useParams();

	const creditCardMap = {
		'American Express': 'amex',
		'Discover': 'discover',
		'Visa': 'visa',
		'Master Card': 'mastercard',
	};

	const handleInputFocus = ({ target }) => {
		setState({
			...state,
			focused: target.name,
		});
	};

	const handleCountryChange = value => {
		setState({
			...state,
			country: value,
		});
	};

	const handleProvinceChange = value => {
		setState({
			...state,
			province: value,
		});
	};

	const handleInputChange = ({ target }) => {
		if (target.name === 'number') {
			target.value = formatCreditCardNumber(target.value);
		} else if (target.name === 'expiry') {
			target.value = formatExpirationDate(target.value);
		} else if (target.name === 'cvc') {
			target.value = formatCVC(target.value);
		}
		setState({
			...state,
			[target.name]: target.value,
		});
	};

	const cardNumberChange = (type, isValid) => {
		setState({
			...state,
			type: type['issuer'],
		});
		setValidCard(isValid);
	};

	const submitPayment = () => {
		if (!validCard) {
			setInvalidCardAlert(true);
			return;
		}

		setSubmitLoad(true);
		let formData = new FormData();
		if (publicCheckout) {
			formData.append('public_checkout', true);
		}
		for (let key in state) {
			formData.append(key, state[key]);
		}
		if (cartUuid) {
			formData.append('CartUUID', cartUuid);
		}
		if (props.ecommerce.shippingDetailsStored) {
			formData.append('shipping_details', JSON.stringify(props.ecommerce.shippingDetails));
		}

		if (props.moduleIdentifier === 'ecommerce') {
			dispatch(
				requestSendPayment({
					appdir: props.loginInfo.appdir,
					moduleUrlId: moduleUrlId,
					attenuuid: props.attenuuid,
					formData: formData,
					moduleIdentifier: props.moduleIdentifier,
					publicEmbed: props.publicEmbed,
					publicCheckout: publicCheckout,
				}),
			);
		} else if (props.moduleIdentifier === 'exhibitor') {
			dispatch(requestPaymentLoader(true))
			dispatch(
				requestSendPaymentExhibitor({
					appdir: props.loginInfo.appdir,
					moduleUrlId: moduleUrlId,
					compuuid: props.compuuid,
					exhibuuid: props.exhibuuid,
					formData: formData,
					moduleIdentifier: props.moduleIdentifier,
				}),
			);
		}
	};

	const resetInvalidCreditCard = () => {
		dispatch(requestResetInvalidCreditCard(props));
	};

	const closeAlert = () => {
		setInvalidCardAlert(false);
	};

	const removeAlert = () => {
		dispatch(requestClearCreditCardNotAcceptedMsg(props));
	};

	useEffect(() => {
		props.paymentSubmitted(false);
	}, []);

	useEffect(() => {
		if (!submitLoad) {
			return;
		}
		if (props.common.paymentSuccess !== '') {
			setSubmitLoad(false);
		}

		if (props.common.paymentSuccess === false) {
			dispatch(requestResetPaymentSuccess(props));
		}
		// Done loading
		props.paymentSubmitted(props.common.paymentSuccess);
	}, [props.common.paymentSuccess, submitLoad]);

	useEffect(() => {
		if (props.settings.ccstatecanadafirst === 1) {
			setState({
				...state,
				country: 'Canada',
			});
		} else {
			setState({
				...state,
				country: 'United States',
			});
		}
	}, []);

	const labelCol = {};

	return (
		<div>
			<div>
				<Card
					number={state.number}
					name={state.name}
					expiry={state.expiry}
					cvc={state.cvc}
					focused={state.focused}
					acceptedCards={props.settings.cardtypes.map(card => {
						return creditCardMap[card];
					})}
					callback={cardNumberChange}
				/>
				<Form
					style={{ marginTop: '5%' }}
					layout="vertical"
					scrollToFirstError={true}
					onFinish={submitPayment}
				>
					{publicCheckout && (
						<div>
							<Form.Item
								label={'First Name'}
								labelCol={labelCol}
								required
								rules={[
									{
										required: true,
										message: 'Please input your first name!',
									},
								]}
							>
								<input
									type="text"
									name="firstName"
									className="ant-input form-control"
									placeholder="First Name"
									required={true}
									onChange={handleInputChange}
									onFocus={handleInputFocus}
								/>
							</Form.Item>

							<Form.Item
								label={'Last Name'}
								labelCol={labelCol}
								required
								rules={[
									{
										required: true,
										message: 'Please input your last name!',
									},
								]}
							>
								<input
									type="text"
									name="lastName"
									className="ant-input form-control"
									placeholder="Last Name"
									required={true}
									onChange={handleInputChange}
									onFocus={handleInputFocus}
								/>
							</Form.Item>

							<Form.Item
								label={'Company'}
								labelCol={labelCol}
								required
								rules={[
									{
										required: true,
										message: 'Please input your company!',
									},
								]}
							>
								<input
									type="text"
									name="company"
									className="ant-input form-control"
									placeholder="Company"
									required={true}
									onChange={handleInputChange}
									onFocus={handleInputFocus}
								/>
							</Form.Item>

							<Form.Item
								label={'Email'}
								labelCol={labelCol}
								required
								rules={[
									{
										required: true,
										message: 'Please input your email!',
									},
								]}
							>
								<input
									type="text"
									name="email"
									className="ant-input form-control"
									placeholder="Email"
									required={true}
									onChange={handleInputChange}
									onFocus={handleInputFocus}
								/>
							</Form.Item>
						</div>
					)}

					<Form.Item
						label={'Card Number'}
						labelCol={labelCol}
						required
						rules={[
							{
								required: validCard,
								message: 'Please input your Card Number!',
							},
						]}
					>
						<input
							type="tel"
							name="number"
							className="ant-input -form-control"
							placeholder="Card Number"
							pattern="[\d| ]{16,22}"
							required
							onChange={handleInputChange}
							onFocus={handleInputFocus}
						/>
					</Form.Item>
					<Form.Item
						label={'Expiry (MM/YY)'}
						labelCol={labelCol}
						required
						rules={[
							{
								required: true,
								message: 'Please input your card expiry!',
							},
						]}
					>
						<input
							type="tel"
							name="expiry"
							className="ant-input form-control"
							placeholder="Valid Thru"
							pattern="\d\d/\d\d"
							required
							onChange={handleInputChange}
							onFocus={handleInputFocus}
						/>
					</Form.Item>
					{props.settings.requirecardcvvcode === 1 && (
						<Form.Item
							label={'Card CVV'}
							labelCol={labelCol}
							required
							rules={[
								{
									required: true,
									message: 'Please input your Security Code!',
								},
							]}
						>
							<input
								type="tel"
								name="cvc"
								className="ant-input form-control"
								placeholder="Security Code"
								pattern="\d{3,4}"
								required
								onChange={handleInputChange}
								onFocus={handleInputFocus}
							/>
						</Form.Item>
					)}
					<Form.Item
						label={'Name'}
						labelCol={labelCol}
						required
						rules={[
							{
								required: true,
								message: 'Please input your name!',
							},
						]}
					>
						<input
							type="text"
							name="name"
							className="ant-input form-control"
							placeholder="Card Holder's Name"
							required={true}
							onChange={handleInputChange}
							onFocus={handleInputFocus}
						/>
					</Form.Item>
					{props.settings.requireemail === 1 && (
						<Form.Item
							label={'Email'}
							labelCol={labelCol}
							required
							rules={[
								{
									required: true,
									message: 'Please input your email!',
								},
							]}
						>
							<input
								type="text"
								name="email"
								className="ant-input form-control"
								placeholder="Card Holder's Email"
								required
								onChange={handleInputChange}
								onFocus={handleInputFocus}
							/>
						</Form.Item>
					)}
					{props.settings.creditcardformhideaddress === 0 && (
						<Form.Item
							label={'Address'}
							labelCol={labelCol}
							required
							rules={[
								{
									required: true,
									message: 'Please input your address!',
								},
							]}
						>
							<input
								type="text"
								name="address"
								className="ant-input form-control"
								placeholder="Card Holder's Billing Address"
								required
								onChange={handleInputChange}
								onFocus={handleInputFocus}
							/>
						</Form.Item>
					)}
					{props.settings.requirephonenumber === 1 && (
						<Form.Item
							label={'Phone Number'}
							labelCol={labelCol}
							required
							rules={[
								{
									required: true,
									message: 'Please input your Phone Number!',
								},
							]}
						>
							<PhoneInput
								placeholder="Card Holder's Phone Number"
								onChange={value => {
									// handleInputChange()
								}}
							/>
						</Form.Item>
					)}
					{props.settings.creditcardformhidecity === 0 && (
						<Form.Item
							label={'City'}
							labelCol={labelCol}
							required
							rules={[
								{
									required: true,
									message: 'Please input your city!',
								},
							]}
						>
							<input
								type="text"
								name="city"
								className="ant-input form-control"
								placeholder="Card Holder's Billing City"
								required
								onChange={handleInputChange}
								onFocus={handleInputFocus}
							/>
						</Form.Item>
					)}
					{props.settings.creditcardformhidecountry === 0 && (
						<Form.Item
							label={'Country'}
							labelCol={labelCol}
							required
							rules={[
								{
									required: true,
									message: 'Please input your country!',
								},
							]}
						>
							<Select
								style={{ width: '100' }}
								showSearch={true}
								defaultValue={
									props.settings.ccstatecanadafirst === 1
										? 'Canada'
										: 'United States'
								}
								autoComplete="chrome-off"
								onChange={value => {
									handleCountryChange(value);
								}} //if value is Canada, populate Canada
							>
								{props.settings.countries.map(country => {
									return (
										<Select.Option key={country} value={country}>
											{country}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
					)}
					{props.settings.creditcardformhidestate === 0 && (
						<Form.Item
							label={'State/Province'}
							labelCol={labelCol}
							required
							rules={[
								{
									required: true,
									message: 'Please input your state/province!',
								},
							]}
						>
							{state.country === 'Canada' || state.country === 'United States' ? (
								<Select
									style={{ width: '100' }}
									showSearch={true}
									onChange={value => handleProvinceChange(value)}
									required
									autoComplete="chrome-off"
								>
									{props.settings.ccstatecanadafirst === 1 ||
										state.country === 'Canada'
										? props.settings.can_states.map(province => {
											return (
												<Select.Option key={province} value={province}>
													{province}
												</Select.Option>
											);
										})
										: props.settings.us_states.map(state => {
											return (
												<Select.Option key={state} value={state}>
													{state}
												</Select.Option>
											);
										})}
								</Select>
							) : (
								<input
									name="province"
									className="ant-input form-control"
									placeholder="State/Province"
									required
									onChange={handleInputChange}
								/>
							)}
						</Form.Item>
					)}
					{props.settings.requirecardzipcode === 1 && (
						<Form.Item
							label={'Zip/Postal'}
							labelCol={labelCol}
							required
							rules={[
								{
									required: true,
									message: 'Please input your zip/postal!',
								},
							]}
						>
							<input
								type="text"
								name="zip"
								className="ant-input form-control"
								placeholder="Card Holder's Billing Zip/Postal"
								required
								onChange={handleInputChange}
								onFocus={handleInputFocus}
							/>
						</Form.Item>
					)}
					{creditCardNotAcceptedMsg == 'Credit card was not accepted.' && (
						<Alert
							message="Credit card was not accepted."
							type="error"
							closable
							onClose={removeAlert}
						/>
					)}
					{invalidCardAlert && (
						<Alert
							message="Please input a valid credit card"
							type="error"
							closable
							onClose={closeAlert}
						/>
					)}
					{paymentErrorMsg && (
						<Alert
							message={paymentErrorMsg}
							type="error"
							onClose={resetInvalidCreditCard}
							closable="true"
						/>
					)}
					<Form.Item>
						<Button
							shape={'round'}
							type="default"
							className="login-form-button gx-mt-1 button-primary"
							htmlType="submit"
							// disabled={!validCard}
							loading={submitLoad}
							// onClick={submitPayment}
							style={{
								color: '#fff',
								width: '100%',
								background: submitLoad ? 'grey' : '',
							}}
						>
							{submitLoad ? 'Processing' : 'Submit'}
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		auth: state.auth,
		loginInfo: state.loginInfo,
		common: state.common,
		ecommerce: state.ecommerce,
	};
}

export default connect(mapStateToProps)(CreditCard);
