import { useDispatch, useSelector } from 'react-redux';

import { requestFilterProductByPrice } from '../../../../appRedux/actions/EcommerceStore';

export const useProductFilter = () => {
    const dispatch = useDispatch();
    const productPrices = useSelector(state => state.ecommerce.storeProductPrices);

    const filterByPrice = () => {
        let temp = productPrices.filter(p => (p.productid && p.isFetched && !p.price_collection)).map(p => p.productid);
        if (temp.length) {
            dispatch(requestFilterProductByPrice(temp));
        }
    }

    return [filterByPrice];
};
