import React from 'react';
import { Skeleton } from 'antd';
import { useSelector } from 'react-redux';

import { ReactComponent as Left } from 'assets/icons/left.svg';

const ConsumptionHeader = ({ loading, product, superBundle, goBack }) => {

	const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);

	return (
		<header
			className="shadow-bottom-2"
			style={{ zIndex: 10 }}
		>
			<div
				className="gx-d-flex gx-align-items-stretch"
				style={{ backgroundColor: styling['primary-dark-color'] || "#993333" }}
			>
				<div
					onClick={() => loading || goBack()}
					className="gx-p-4 gx-pointer fg-white gx-mr-2 gx-d-flex"
				>
					<Left className="gx-my-auto" style={{ width: '.9rem' }} />
				</div>

				{loading ? (
					<Skeleton.Input
						style={{ width: 700 }}
						active
						className="gx-ml-2 gx-d-block gx-my-auto"
					/>
				) : (
					<div className="gx-p-3 gx-my-auto">
						<h2
							className="gx-text-white gx-font-weight-semi-bold gx-mb-0"
							style={{ fontSize: '1.3rem' }}
						>
							{superBundle?.title ? superBundle?.title : product?.title}
						</h2>
						{/* <p className="gx-text-light gx-mt-1 gx-mb-0">{superBundle?.title}</p> */}
					</div>
				)}
			</div>

			{superBundle?.title && product?.title ?
				<div className=' gx-p-3 gx-my-auto gx-pl-2' style={{ backgroundColor: styling['secondary-color'] || '#353535' }}>
					<h3 className='gx-text-white gx-font-weight-semi-bold gx-mb-0 gx-ml-5'>
						{product?.title}
					</h3>
				</div> :
				null
			}
		</header>
	);
};

export default ConsumptionHeader;
