import React, { useEffect, useState } from 'react';
import { Form } from 'antd';

interface Props {
	label: string;
	color: string;
}

export default ({ label, color }: Props) =>
	!label || label === '' ? null : (
		<label
			style={{
				display: 'block',
				color,
				margin: '2px 0px 15px 24px',
				// lineHeight:"100%"
			}}
			dangerouslySetInnerHTML={{ __html: label }}
		/>
	);
