import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import {
	REQUEST_USER_PRODUCT_LIST,
	REQUEST_USER_PRODUCTS_LIST_SELECTION_VIEW,
	REQUEST_PRODUCT_CATEGORY,
	REQUEST_ERASE_USER_PRODUCTS_DATA,
	REQUEST_TOGGLE_CALENDAR_VIEW,
	REQUEST_USER_PRODUCT_TAGS,
} from 'constants/ActionTypes';

import {
	receiveUserProductList,
	receiveUserProductListSelectionView,
	receiveProductCategory,
	receiveEraseUserProductsData,
	receiveToggleCalendarView,
	receiveUserProductTags,
} from '../../appRedux/actions/UserProducts';

import UserProductsAPIs from '../../api/UserProducts';
import { fetchError } from '../actions/Common';

const fetchUserProducts = async () => {
	let jsonData = await UserProductsAPIs.getUserProductList();
	return jsonData;
};

const fetchProductCategory = async () => {
	let jsonData = await UserProductsAPIs.getProductCategory();
	return jsonData;
};

const fetchUserProductTags = async (context) => {
	let jsonData = await UserProductsAPIs.getUserProductTags(context.data.source_hex);
	return jsonData;
};

function* getUserProductList(action) {
	try {
		let result = yield call(fetchUserProducts, action);
		yield put(receiveUserProductList(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getProductCategory(action) {
	try {
		let result = yield call(fetchProductCategory, action);
		yield put(receiveProductCategory(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getUserProductListSelectionView(action) {
	try {
		yield put(receiveUserProductListSelectionView(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getEraseUserProductsData(action) {
	try {
		yield put(receiveEraseUserProductsData(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getToggleCalendarView(action) {
	try {
		yield put(receiveToggleCalendarView(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getUserProductTags(action) {
	try {
		let result = yield call(fetchUserProductTags, action);
		yield put(receiveUserProductTags(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

export function* userProductList() {
	yield takeLatest(REQUEST_USER_PRODUCT_LIST, getUserProductList);
}

export function* productCategory() {
	yield takeLatest(REQUEST_PRODUCT_CATEGORY, getProductCategory);
}

export function* userProductListSelectionView() {
	yield takeLatest(REQUEST_USER_PRODUCTS_LIST_SELECTION_VIEW, getUserProductListSelectionView);
}

export function* eraseUserProductsData() {
	yield takeLatest(REQUEST_ERASE_USER_PRODUCTS_DATA, getEraseUserProductsData);
}

export function* toggleCalendarView() {
	yield takeLatest(REQUEST_TOGGLE_CALENDAR_VIEW, getToggleCalendarView);
}

export function* userProductTags() {
	yield takeLatest(REQUEST_USER_PRODUCT_TAGS, getUserProductTags);
}

export default function* mySaga() {
	yield all([
		fork(userProductList),
		fork(productCategory),
		fork(userProductListSelectionView),
		fork(eraseUserProductsData),
		fork(toggleCalendarView),
		fork(userProductTags),
	]);
}
