import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Tag, Descriptions, Table, Typography, Button, Row, Col, Avatar } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import _groupBy from 'lodash/groupBy';
import moment from "moment"
import { currencyFormatter, sortName, isWebView, handleMemberRenewal } from '@components/Membership/helpers/generalHelpers';
import CorporateRenderMemberInfo from '@components/Membership/utilComponents/CorporateRenderMemberInfo';

import { formatPayment } from '@components/Common/helpers/helper';

const MemberSelectionReview = ({ history, previousStep, showMakePayment = false, showPrevious = false }) => {
    const moduleUrlId = useSelector(state => state.loginInfo.corporateMembershipModuleUrlId);
    const corporateMembership = useSelector(state => state.corporateMembership);
    const invoiceItemArray = useSelector(state => state.corporateMembership.exhibitorCart.InvoiceItemArray);
    const netOwing = useSelector(state => state.corporateMembership.exhibitorCart.NetOwing);
    const membershipStepDetails = useSelector(state => state.corporateMembership.membershipStepDetails);
    const publicEmbed = useSelector(state => state.settings.publicEmbed);
    const languageObject = useSelector(state => state.corporateMembership.languageObject);
    const [cartData, setCartData] = useState({});
    const [newSlotDetails, setNewSlotDetails] = useState({});
    useEffect(() => {
        if (membershipStepDetails.members && membershipStepDetails.members.length) {
            setNewSlotDetails(_groupBy(
                membershipStepDetails.members.filter(mem => mem.futureGroupName.length && mem.futureGroupId != -1).filter(m=> !m.inGoodStanding),
                "futureGroupName"
            ))
        }
    }, [membershipStepDetails.members])
    const handleMakePaymentClick = () => {
        if (publicEmbed) {
            history.push(`/main/public/company/${moduleUrlId}/checkout`);
        } else {
            history.push(`/main/company/${moduleUrlId}/checkout`);
        }
    }
    useEffect(() => {
        const menuItems = corporateMembership.menu.find(menu => menu.screentype === 'SubMenu')
        if (Object.keys(menuItems).length) {
            const tempFinancialItems = { ...menuItems.submenu[0] };
            let hashObj = { membership: "", membershipCost: 0, donation: 0, additionalCount: 0, additionalDescription: "", additionalCost: 0, totalCost: 0, isShowDonation: false, hideAdditionalTab: corporateMembership.generalSettings.HideAdditionalMemberTab, taxAmount: 0 };
            let totalCost = 0;
            tempFinancialItems.fieldids.forEach(({ field_definition, fieldid }) => {
                const invoiceValue = invoiceItemArray?.find(({ field_id }) => field_id === fieldid)
                if (invoiceValue) {
                    if (field_definition === 'company_corp_member') {
                        hashObj['membership'] = invoiceValue.invoice_description;
                        hashObj['membershipCost'] = invoiceValue.amount;
                        hashObj['taxAmount'] = parseFloat(invoiceValue.tax_amount);
                        if (invoiceValue?.tax_amount) {
                            totalCost += invoiceValue.tax_amount + invoiceValue.amount;
                        } else {
                            totalCost += invoiceValue.amount;
                        }
                    } else if (field_definition === 'Donation') {
                        hashObj['donation'] = hashObj['donation'] ? hashObj['donation'] + invoiceValue.amount : invoiceValue.amount;
                        totalCost += hashObj['donation'];
                        hashObj['isShowDonation'] = true;
                    }
                }
            })
            let additionalCount = 0, tempDescription = [], additionalCost = 0, additionalDescription = "";
            membershipStepDetails.additionalInfo.forEach(({ reviewTotalCount, unitPrice, title }) => {
                if (reviewTotalCount) {
                    additionalCost += reviewTotalCount * unitPrice;
                    additionalCount += reviewTotalCount;
                    tempDescription.push(`${title} x ${reviewTotalCount}`);
                }
            });
            if (tempDescription.length) { additionalDescription = `(${tempDescription.join(', ')})` }
            totalCost += membershipStepDetails.totalAdditionalCost;
            totalCost = parseFloat(netOwing);
            hashObj = { ...hashObj, additionalCount, additionalDescription, additionalCost, totalCost }
            setCartData(hashObj)
        }
    }, [
        invoiceItemArray,
        corporateMembership.generalSettings.membership_reg_fields,
        corporateMembership.menu,
    ])
    const goToFirstStep = () => {
        previousStep(true);
    };
    const slotAllotmentColumns = [
        {
            title: 'Members', key: 'members', render: (record, i) => {
                return (
                    <Row style={{ flexWrap: "nowrap" }} key={i}>
                        <Col flex={1}>
                            <Avatar className='primary-background gx-pointer gx-mr-2 gx-ml-2' src={record.contact_picture ? record.contact_picture : ''} size={50}>
                                {record?.firstName[0].toLowerCase()}
                            </Avatar>
                        </Col>
                        <Col flex={4}>
                            <div className='gx-ml-3'>
                                <Typography>{record?.lastName}, {record?.firstName}</Typography>
                                <Typography>{record?.email}</Typography>
                                <div>
                                    {handleMemberRenewal(record, corporateMembership).isrenewalMember ?
                                        <div>
                                            <Tag color="warning" className='gx-mt-1'>{`Expiry: ${moment(record.expirationDate).format("'MMM DD, YYYY'")}`}</Tag>
                                        </div>
                                        : handleMemberRenewal(record, corporateMembership).isPastExpiryMember ?
                                            <div>
                                                <Tag color="red" className='gx-mt-1'>{`Expiry: ${moment(record.expirationDate).format("'MMM DD, YYYY'")}`}</Tag>
                                            </div> : null
                                    }
                                </div>
                                {record.showContactID ? <Typography>Contact ID: {record.reviewID}</Typography> : null}
                                {record.inGoodStanding ? <Typography>Expiry Date: {moment(record.expirationDate).format('MMM DD, YYYY')}</Typography> : null}
                                <Typography className='gx-mt-2 gx-mb-1 gx-font-weight-semi-bold ' style={{ fontSize: "14px" }}>Current {languageObject.checkout_membership_replace_keyword ? languageObject.checkout_membership_replace_keyword :'Membership'}:</Typography>
                                <Typography className='text-wrap' style={{ fontSize: "14px" }}>{record?.groupName ? record?.groupName : 'Not Available'}</Typography>
                                <Typography className='gx-mt-2 gx-mb-1 gx-font-weight-semi-bold ' style={{ fontSize: "14px" }}>Future {languageObject.checkout_membership_replace_keyword ? languageObject.checkout_membership_replace_keyword :'Membership'}:</Typography>
                                <Typography className='text-wrap' style={{ fontSize: "14px" }}>{record.futureGroupName}</Typography>
                            </div>
                        </Col>
                    </Row>
                )
            }, sorter: sortName, responsive: ['xs']
        },
        { title: 'Name', key: 'name', render: (record) => <CorporateRenderMemberInfo record={record} />, sorter: sortName, responsive: ['sm'] },
        { title: `Current ${languageObject.checkout_membership_replace_keyword ? languageObject.checkout_membership_replace_keyword :'Membership'}`, key: 'groupName', dataIndex: 'groupName', sorter: sortName, responsive: ['sm'] },
        { title: `Future ${languageObject.checkout_membership_replace_keyword ? languageObject.checkout_membership_replace_keyword :'Membership'}`, key: 'futureGroupName', dataIndex: 'futureGroupName', sorter: sortName, responsive: ['sm'] },
    ]
    return (
        <div className='gx-mt-3'>
            <Descriptions bordered title={`${languageObject.checkout_membership_replace_keyword ? languageObject.checkout_membership_replace_keyword :'Membership'} Details`} size='default' className='gx-mb-3' column={{
                sm: 2,
                xs: 1, md: 1, lg: 2
            }}>
                <Descriptions.Item label={`${languageObject.checkout_membership_replace_keyword ? languageObject.checkout_membership_replace_keyword :'Membership'} Plan`}>{cartData.membership} </Descriptions.Item>
                <Descriptions.Item label={`${languageObject.checkout_membership_replace_keyword ? languageObject.checkout_membership_replace_keyword :'Membership'} Amount`}>{currencyFormatter(cartData.membershipCost)}</Descriptions.Item>
                {cartData.isShowDonation && <Descriptions.Item label='Donation' span={2}>{currencyFormatter(cartData.donation)}</Descriptions.Item>}
                {!cartData.hideAdditionalTab && <Descriptions.Item label='Additional Memberships' span={3}>{cartData.additionalCount} {cartData.additionalDescription}</Descriptions.Item>}
                {!cartData.hideAdditionalTab && <Descriptions.Item label='Additional Cost' span={3}>{currencyFormatter(cartData.additionalCost)}</Descriptions.Item>}
                {(cartData?.taxAmount ?? "") && <Descriptions.Item label='Tax Amount'>${formatPayment(cartData.taxAmount)}</Descriptions.Item>}
                <Descriptions.Item label='Total Cost'>${formatPayment(cartData.totalCost)}</Descriptions.Item>
            </Descriptions>
            {!cartData.hideAdditionalTab &&
                <div className={isWebView() ? 'gx-d-flex gx-mb-2' : null}>
                    <Typography.Title level={5} className='gx-mr-2'>Future Individual Memberships:</Typography.Title>
                    {Object.keys(newSlotDetails).map(
                        (memberTypeTag) => <Tag color="geekblue" className='no-border-radius'>{`${memberTypeTag}:${newSlotDetails[memberTypeTag].length}`}</Tag>
                    )}
                </div>}
            {!cartData.hideAdditionalTab && <div>
                <Table
                    columns={slotAllotmentColumns}
                    dataSource={membershipStepDetails.members
                        // .filter(({ futureGroupId }) => futureGroupId != -1).filter(m=>!m.inGoodStanding)
                    }
                    className='default-table'
                    scroll={{
                        y: 425,
                    }}
                />
            </div>}
            <div className='gx-text-center'>
                {showPrevious ? <Button type='primary' className='gx-float-left' onClick={goToFirstStep}>Previous</Button> : null}
                <Button
                    type='primary'
                    className='gx-float-right'
                    onClick={handleMakePaymentClick}
                    icon={<RightOutlined />}>
                    {showMakePayment ? "Checkout" : "Next"}
                </Button>
            </div>
        </div>
    )
}

export default withRouter(MemberSelectionReview);