import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { Avatar, Popover } from 'antd';
import { withRouter } from 'react-router';
import auth from '../../loginAuth/loginAuth';

import { requestLogoutUser, requestEraseAuthData } from '../../appRedux/actions/Auth';
import { requestEraseProfileData, requestProfileData } from '../../appRedux/actions/LoginPageInfo';
import { requestEraseEcommerceData } from '../../appRedux/actions/Ecommerce';
import { requestEraseCorporateMembershipData } from '../../appRedux/actions/CorporateMembership';
import { requestEraseCommonData } from '../../appRedux/actions/Common';
import { requestEraseUserProductsData } from '../../appRedux/actions/UserProducts';

const UserProfile = (props) => {
	const dispatch = useDispatch();

	if (props.loginInfo.firstName == '') {
		const source_hex = JSON.parse(auth.getCookie('source_hex'));
		const contact_uuid = JSON.parse(auth.getCookie('contact_uuid'));

		dispatch(
			requestProfileData({
				source_hex: source_hex,
				contact_uuid: contact_uuid,
			}),
		);
	}

	const onLogout = () => {
		auth.deleteCookie('jwt');
		auth.deleteCookie('ssotoken');
		auth.deleteCookie('contact_uuid');
		auth.deleteCookie('appdir');
		auth.deleteCookie('source_hex');

		dispatch(requestLogoutUser(props));
		dispatch(requestEraseProfileData(props));
		dispatch(requestEraseAuthData(props));
		dispatch(requestEraseEcommerceData(props));
		dispatch(requestEraseCorporateMembershipData(props));
		dispatch(requestEraseCommonData(props));
		dispatch(requestEraseUserProductsData(props));

		props.history.push('/signin');
	};

	const userMenuOptions = (
		<ul className="gx-user-popover">
			<li onClick={() => onLogout()}>Logout</li>
		</ul>
	);

	return (
		<div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
			<Popover placement="bottomRight" content={userMenuOptions} trigger="click">
				<Avatar src={props.loginInfo.picture} className="gx-size-40 gx-pointer gx-mr-3" />
				<span className="gx-avatar-name" style={{ color: 'white' }}>
					{props.loginInfo.firstName} {props.loginInfo.lastName}
					<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
				</span>
			</Popover>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		loginInfo: state.loginInfo,
	};
}

export default connect(mapStateToProps)(withRouter(UserProfile));
