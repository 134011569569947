export const AdditionalSlotPriceCalculation = ({corporateMembership,members}) => {
	let subMenuFields = corporateMembership.menu.find(m => m.screentype === "SubMenu")?.submenu[0].fieldids;
    let filteredResult = subMenuFields.find((data) => data.field_definition === "individual_corp_member");
    let calculateTotalPrice = corporateMembership?.generalSettings?.membership_reg_fields.map((item) => {
      let filteredMembership = [...members].filter(mem=> !mem.inGoodStanding).filter((data) => data.futureGroupId == item.groupid)
      return filteredMembership
    }).flat()
	return {calculateTotalPrice, filteredResult}
}

export const getGroupDetails = ({id,corporateMembership}) => {
    let group = (corporateMembership.generalSettings?.corp_membership_value_map || []).find(item => {
      return corporateMembership.exhibitorCart?.InvoiceItemArray.filter((data) => data.value_id === item.valueid).length > 0
    }).membership_config?.membership_types?.find(ms => {
      return (ms.groupid == id)
    })
    return group
}

export const getAdditionalSlotMembershipCount = (additionalInformation)=> {
  let AdditionalMembershipCount = 0
  additionalInformation?.length > 0 && additionalInformation.map((item) => {
  AdditionalMembershipCount += item.additionalSlots
  return AdditionalMembershipCount
  }).reduce((accumulator, curr) => accumulator + curr)
  return AdditionalMembershipCount
}

export const SortBasedOnAdditionalMembership = (additionalInformation) => {
  let sortMembership  = additionalInformation.sort((slot1,slot2) => Number(slot1.unitPrice) - Number(slot2.unitPrice));
  return sortMembership
}

const sortMembership = (rec1, rec2, key) => {
  const grpName_1 = rec1[key].length ? rec1[key] : 'No Membership';
  const grpName_2 = rec2[key].length ? rec2[key] : 'No Membership';
  return grpName_1.localeCompare(grpName_2);
}
export const sortFutureMembership = (rec1, rec2) => sortMembership(rec1, rec2, 'futureGroupName');
export const sortCurrentMembership = (rec1, rec2) => sortMembership(rec1, rec2, 'groupName');