import {
	COMPANY_CREATED,
	USER_COMPANY_AFFILIATION_FETCHED,
	EXHIBITOR_CART_DETAILS_FETCHED,
	MENU_FETCHED,
	COMPANY_MEMBERS_FETCHED,
	COMPANY_FETCHED,
	USER_ADMIN_MADE,
	USER_ADMIN_REMOVED,
	USER_REMOVED_FROM_GROUP,
	USER_MEMBERSHIP_REMOVED,
	COMPANY_PROFILE_UPDATED,
	UPDATE_COMPANY_PROFILE,
	USER_MEMBERSHIP_ADDED,
	PAYMENT_SETTINGS_FETCHED,
	USER_CONTACT_INFORMATION_UPDATED,
	NEW_USER_CONTACT_ADDED,
	EXHIBITOR_CART_FETCHED,
	EMAIL_TO_USERS_SENT,
	REGISTRATION_ITEMS_ADDED_TO_CART,
	REGISTRATION_ITEM_DELETED_FROM_CART,
	USER_CONTACT_INFO_FIELD_VALUES_FETCHED,
	FETCH_USER_CONTACT_INFO_FIELD_VALUES_ERROR,
	COMPANY_LOGO_UPDATED,
	ALL_RENEWED,
	USER_ADDED_TO_COMPANY,
	REQUEST_ERASE_CORPORATE_MEMBERSHIP_DATA,
	RECEIVE_SEND_PAYMENT_EXHIBITOR,
	RECEIVE_RESET_CART,
	COMPANY_PROFILE_FIELD_VALUES_FETCHED,
	USER_COMPANY_AFFILIATION_DELETED,
	FINANCIAL_ITEMS_FORM_STATE_UPDATED,
	EXHIBITOR_CART_UUID_FETCHED,
	EXHIBITOR_SIGNEDUP,
	CART_MADE_PUBLIC_TO_PRIVATE,
	SET_CURRENT_FINANCIAL_ITEM,
	EXHIBITOR_PAYMENT_DELETED,
	ROLES_FETCHED,
	ROLES_UPDATED,
	COMPANY_MEDIA_FETCHED,
	UPLOADED_TO_S3,
	ADDED_UPDATED_DELETED_MEDIA_RESOURCE,
	SORTED_MEDIA_RESOURCE,
	REQUEST_CORPORATE_MEMBERSHIP_MODULEURLID,
	RECEIVE_MAKE_USER_PUBLIC,
	RECEIVE_REMOVE_USER_PUBLIC,
	REQUEST_CLEAR_NEW_USER_ADDED_ERROR,
	REQUEST_CLEAR_NEW_USER_ADDED_SUCCESS,
	REQUEST_CLEAR_CREDIT_CARD_NOT_ACCEPTED_MSG,
	RECEIVE_MINI_PROFILE_FIELDS,
	REQUEST_UPDATE_MINI_PROFILE_FIELDS,
	RECEIVE_UPDATE_MINI_PROFILE_FIELDS,
	REQUEST_CREATE_MINI_PROFILE_FIELDS,
	RECEIVE_CREATE_MINI_PROFILE_FIELDS,
	RECEIVE_ADD_COMPANY_USER,
	UPDATE_STEP_DETAILS,
	UPDATE_IS_IFRAME,
	IS_EXHIBITOR,
	RECEIVE_ATTENDEES_INFO,
	RECEIVE_COMPANY_DISCOUNT,
	RECEIVE_STAFF_REGISTRANT_FORM,
	RECEIVE_UPDATE_STAFF_REGISTRANT_FORM,
	RESET_UPDATE_STAFF_REGISTRANT_FORM,
	RECEIVE_STAFF_REMOVAL_OPTIONS,
	RECEIVE_STAFF_REMOVE_INFO,
	REQUEST_PAYMENT_LOADER,
	CUSTOM_STATE_HANDLER,
	RESET_DUPLICATE_USER_STATE,
	REQUEST_IGNORE_PURCHASE_REQUIRED_REDIRECT,
	RECEIVE_PROCESSING_FEES
} from '../../constants/ActionTypes';

import { NotificationManager } from 'react-notifications';
import _uniqBy from 'lodash/uniqBy';
import { membershipStepInitialState } from '@components/Membership/helpers/constant';

const INIT_STATE = {
	createdCompany: {},
	companyCreated: false,
	userCompanyAffiliation: {
		comp_uuid: '',
		compid: '',
		comp_admin: false,
	},
	userCompanyAffiliationFetched: false,
	exhibitorCartDetails: {
		exhibitorid: '',
		exhibuuid: '',
		progress: '',
		status: '',
		exhibitorCartUuid: '',
		exhibitorCartId: '',
		purchaseRequired: 0,
	},
	ignorePurchaseRequiredRedirect: false,
	exhibitorCartDetailsFetched: false,
	exhibitorCartUuidFetched: false,
	menu: [],
	generalSettings: {},
	languageObject: {},
	menuFetched: false,
	companyProfileFieldValuesFetched: false,
	companyMembers: [],
	companyMembersFetched: false,
	company: {
		companyname: '',
		city: '',
		state: '',
		country: '',
		complogo: '',
		website: '',
		date_joined: '',
		expiry_date: '',
		membership_type: '',
		memberships_available: [],
		affiliate: {
			name: '',
			id: '',
		},
		media: [],
		s3UploadStatus: {},
		mediaResourceSubmitResult: {},
	},
	companyFetched: false,
	companyMediaFetched: false,
	paymentSettings: {},
	paymentSettingsFetched: false,
	exhibitorCart: {
		InvoiceItemArray: [],
		TotalNetAmount: 0,
		TotalPayment: 0,
		NetOwing: 0,
		PaymentItemsArray: [],
	},
	exhibitorCartFetched: false,
	financialItemsFormState: {},
	oldExhibitorUuid: '',
	oldExhibitorCart: '',
	firstPaymentMade: false,
	isLoading: false,
	currentFinancialItem: -1,
	roles: [],
	rolesFetched: false,
	newUserContactAdded: false,
	newUserDuplicateExists : false,
	newUserDuplicateUsers: [],
	moduleUrlId: '',
	moduleUrlIdFetched: false,
	newMemberAddFailure: '',
	newMemberAddSuccess: '',
	paymentErrorMsg: '',
	miniProfileFields: '',
	miniProfileFieldsFetched: false,
	membershipStepDetails: membershipStepInitialState,
	isIframe: false,
	isExhibitor: false,
	attendeesInfo: [],
	badgeDiscountInfo: [],
	attendeeUpdateRegistrationForm: [],
	attendeeUpdateRegistrationFormValues: {},
	staffRemovalOptions: {},
	isUpdateMemberAfterPayment: false,
	membershipFormVisited: 0,
	membershipAvailableFromInvoice: [],
	showProcessingFees: false,
	paymentProcessingDetails : {}

};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case COMPANY_CREATED: {
			return {
				...state,
				createdCompany: action.payload,
				userCompanyAffiliation: action.payload,
				companyCreated: true,
			};
		}
		case USER_COMPANY_AFFILIATION_FETCHED: {
			return {
				...state,
				userCompanyAffiliation: {
					comp_uuid: action.payload['comp_uuid'],
					compid: action.payload['compid'],
					comp_admin: action.payload['comp_admin'],
				},
				userCompanyAffiliationFetched: true,
			};
		}
		case EXHIBITOR_CART_DETAILS_FETCHED: {
			const ret = {
				...state,
				exhibitorCartDetails: {
					...state.exhibitorCartDetails,
					exhibitorid: action.payload['exhibitorid'],
					exhibuuid: action.payload['exhibuuid'],
					old_exhibuuid: state.exhibitorCartDetails.exhibuuid,
					progress: action.payload['progress'],
					status: action.payload['status'],
					pending_payment_data: action.payload['pending_payment_data'],
					purchaseRequired: action.payload['purchaseRequired'],
				},
				exhibitorCartDetailsFetched: true,
			};

			return ret;
		}
		case MENU_FETCHED: {
			const generalSettings = action.payload.general_settings;
			if (generalSettings.membership_reg_fields?.length) { generalSettings.membership_reg_fields = _uniqBy(generalSettings.membership_reg_fields, 'groupid') }
			return {
				...state,
				menu: action.payload.attenmenu,
				menuFetched: true,
				generalSettings,
				languageObject: action.payload.language_object,

			};
		}
		case COMPANY_MEMBERS_FETCHED: {
			const uuids = [
				...new Set(
					action.payload['members'].map(member => {
						return member.uuid;
					}),
				),
			];
			return {
				...state,
				companyMembers: uuids.map(uuid => {
					return {
						...action.payload['members'].find(member => {
							return member['uuid'] === uuid;
						}),
						userContactInfoFieldValues: {
							status: 'initial',
						},
					};
				}),
				companyMembersFetched: true,
			};
		}
		case COMPANY_FETCHED: {
			let availableGroups = action.payload['memberships_available'] == '' ? {} : { memberships_available: action.payload['memberships_available'] }
			return {
				...state,
				company: {
					...state.company,
					...{
						companyname: action.payload['comp_name'],
						city: action.payload['comp_city'],
						state: action.payload['comp_state'],
						country: action.payload['comp_country'],
						complogo: action.payload['company_picture'],
						website: action.payload['comp_website'],
						date_joined: action.payload['date_joined'],
						expiry_date: action.payload['expiry_date'],
						membership_type: action.payload['membership_type'],
						...availableGroups,
						compid: action.payload['compid'],
						affiliate:
							action.payload.comp_hasaffiliations === 1
								? {
									name: action.payload.comp_affiliateof_name,
									id: action.payload.comp_affiliateof_uuid,
								}
								: {},
					},
				},
				companyFetched: true,
			};
		}
		case COMPANY_MEDIA_FETCHED: {
			return {
				...state,
				company: {
					...state.company,
					media: action.payload.success === 0 ? [] : action.payload.data,
				},
				companyMediaFetched: true,
			};
		}
		case UPLOADED_TO_S3: {
			return {
				...state,
				company: {
					...state.company,
					s3UploadStatus: {
						...state.company.s3UploadStatus,
						[action.payload.key]: { url: action.payload.url, status: true },
					},
				},
			};
		}
		case ADDED_UPDATED_DELETED_MEDIA_RESOURCE: {
			if (action.payload.data.success === 0) {
				return {
					...state,
					company: {
						...state.company,
						mediaResourceSubmitResult: action.payload,
					},
				};
			}
			if (action.operation === 'add') {
				return {
					...state,
					company: {
						...state.company,
						mediaResourceSubmitResult: action.payload,
						media: state.company.media.concat(action.payload.data.data),
					},
					companyMediaFetched: false,
				};
			} else if (action.operation === 'update') {
				return {
					...state,
					company: {
						...state.company,
						mediaResourceSubmitResult: action.payload,
						media: state.company.media.map(media =>
							media.RowID === action.payload.data.RowID
								? { ...action.payload.data.data }
								: media,
						),
					},
					companyMediaFetched: false,
				};
			} else if (action.operation === 'delete') {
				return {
					...state,
					company: {
						...state.company,
						mediaResourceSubmitResult: action.payload,
						media: state.company.media.filter(media => {
							return media.RowID != action.payload?.context?.payload?.resource?.RowID;
						}),
					},
					companyMediaFetched: false,
				};
			}
		}

		case SORTED_MEDIA_RESOURCE: {
			return {
				...state,
				company: {
					...state.company,
					media: state.company.media.map(media => {
						if (action.payload.sortOrder.includes(media.RowID)) {
							//Process the object by changing the Orderof
							return {
								...media,
								Orderof: action.payload.sortOrder.indexOf(media.RowID),
							};
						} else {
							return { ...media };
						}
					}),
				},
				companyMediaFetched: false,
			};
		}

		case USER_ADMIN_MADE: {
			return {
				...state,
				companyMembers: state.companyMembers.map(member => {
					if (member['uuid'] === action.payload['companycontactUUID']) {
						return {
							...member,
							compAdmin: 1,
						};
					}
					return member;
				}),
			};
		}
		case USER_ADMIN_REMOVED: {
			return {
				...state,
				companyMembers: state.companyMembers.map(member => {
					if (member['uuid'] === action.payload['companycontactUUID']) {
						return {
							...member,
							compAdmin: 0,
						};
					}
					return member;
				}),
			};
		}
		case RECEIVE_MAKE_USER_PUBLIC: {
			return {
				...state,
				companyMembers: state.companyMembers.map(member => {
					if (member['uuid'] === action.payload['companycontactUUID']) {
						return {
							...member,
							public_contact: true,
						};
					}
					return member;
				}),
			};
		}
		case RECEIVE_REMOVE_USER_PUBLIC: {
			return {
				...state,
				companyMembers: state.companyMembers.map(member => {
					if (member['uuid'] === action.payload['companycontactUUID']) {
						return {
							...member,
							public_contact: false,
						};
					}
					return member;
				}),
			};
		}
		case USER_REMOVED_FROM_GROUP: {
			return {
				...state,
				companyMembers: state.companyMembers.filter(member => {
					return member['uuid'] !== action.payload['companycontactUUID'];
				}),
			};
		}
		case USER_MEMBERSHIP_REMOVED: {
			return {
				...state,
				companyMembers: state.companyMembers.map(member => {
					if (member['uuid'] === action.payload['companycontactUUID']) {
						member['expirationDate'] = '';
						member['groupName'] = '';
						member['groupID'] = '';
					}
					return member;
				}),
			};
		}
		case COMPANY_PROFILE_UPDATED: {
			Object.keys(state.company).forEach(key => {
				if (action.payload[key]) {
					state.company[key] = action.payload[key];
				}
			});
			return {
				...state,
				company: {
					...state.company,
				},
				menu: state.menu.map(menuItem => {
					if (menuItem['screentype'] === 'ContactInfo') {
						menuItem['fieldvalues'] = action.payload;
					}
					return menuItem;
				}),
				isLoading: false,
			};
		}
		case COMPANY_PROFILE_FIELD_VALUES_FETCHED: {
			return {
				...state,
				menu: state.menu.map(menuItem => {
					if (menuItem.screentype === 'ContactInfo') {
						return {
							...menuItem,
							fieldvalues: action.payload,
						};
					}
					return menuItem;
				}),
				companyProfileFieldValuesFetched: true,
			};
		}
		case UPDATE_COMPANY_PROFILE: {
			return {
				...state,
				isLoading: true,
			};
		}
		case USER_MEMBERSHIP_ADDED: {
			if (action.payload.Success) {
				return {
					...state,
					newMemberAddSuccess: action.payload.Message,
					companyMembers: state.companyMembers.map(companyMember => {
						if (companyMember['uuid'] === action.payload.companycontactUUID) {
							companyMember['groupID'] = parseInt(action.payload.groupid);
							companyMember['expirationDate'] = action.payload.expirydate;
						}
						return companyMember;
					}),
				};
			} else {
				return {
					...state,
					newMemberAddFailure: action.payload.Message,
				};
			}
		}
		case PAYMENT_SETTINGS_FETCHED: {
			return {
				...state,
				paymentSettingsFetched: true,
				paymentSettings: action.payload,
				showProcessingFees: false,
				paymentProcessingDetails : {}
			};
		}
		case USER_CONTACT_INFORMATION_UPDATED: {
			return {
				...state,
				companyMembers: state.companyMembers.map(companyMember => {
					if (companyMember['uuid'] === action.payload.uuid) {
						return {
							...companyMember,
							userContactInfoFieldValues: {
								...action.payload,
								status: 'fetched',
							},
							lastName: action.payload.lastname,
							reviewID: action.payload.reviewid,
							firstName: action.payload.firstname,
							email: action.payload.email,
						};
					}
					return companyMember;
				}),
			};
		}
		case NEW_USER_CONTACT_ADDED: {
			// if (action.payload.userCreated) {
			return {
				...state,
				companyMembers: [
					...state.companyMembers,
					{
						userContactInfoFieldValues: {
							...action.payload,
							status: 'fetched',
						},

						// Add the companyMember table fields.
						uuid: action.payload.uuid,
						lastName: action.payload.lastname,
						reviewID: action.payload.reviewid,
						firstName: action.payload.firstname,
						email: action.payload.email,
					},
				],
				newUserContactAdded: true,
			};
			// }
		}
		case RECEIVE_ADD_COMPANY_USER: {
			return {
				...state,
				newUserContactAdded: false, // removing the flag to add next user
			};
		}

		case RECEIVE_CREATE_MINI_PROFILE_FIELDS: {
			if(!action.payload?.userCreated && !action.payload?.user_confirmed_no_duplicate && action.payload?.usersWithSameLastNames?.length > 0) {
				return {
					...state,
					newUserDuplicateExists : true,
					newUserDuplicateUsers : action.payload.usersWithSameLastNames,
					newUserContactAdded: false
				}
			}
			else{
				return {
					...state,
					companyMembers: [
						...state.companyMembers,
						{
							userContactInfoFieldValues: {
								...action.payload,
								status: 'fetched',
							},
	
							// Add the companyMember table fields.
							uuid: action.payload.uuid,
							lastName: action.payload?.lastname,
							reviewID: action.payload.reviewid,
							firstName: action.payload?.firstname,
							email: action.payload?.email,
						},
					],
					newUserContactAdded: true,
				};
			}
		}

		case EXHIBITOR_CART_FETCHED: {
			return {
				...state,
				exhibitorCart: action.payload,
				exhibitorCartFetched: true,
			};
		}
		case EMAIL_TO_USERS_SENT: {
			return {
				...state,
			};
		}
		case REGISTRATION_ITEMS_ADDED_TO_CART: {
			//TODO
			let filtermembershipGroups = (state?.generalSettings?.corp_membership_value_map).filter(item => {
				return action.payload?.InvoiceItemArray.find((data) => data?.value_id == item?.valueid)
			})
			return {
				...state,
				membershipAvailableFromInvoice: filtermembershipGroups[0]?.membership_config?.membership_types,
				exhibitorCart: {
					...action.payload,
				},
			};
		}
		case REGISTRATION_ITEM_DELETED_FROM_CART: {
			return {
				...state,
				exhibitorCart: {
					...action.payload,
				},
			};
		}
		case USER_CONTACT_INFO_FIELD_VALUES_FETCHED: {
			return {
				...state,
				companyMembers: state.companyMembers.map(member => {
					if (member['uuid'] === action.payload['uuid']) {
						return {
							...member,
							userContactInfoFieldValues: {
								...action.payload,
								status: 'fetched',
							},
						};
					}

					return member;
				}),
			};
		}
		case FETCH_USER_CONTACT_INFO_FIELD_VALUES_ERROR: {
			return {
				...state,
				companyMembers: state.companyMembers.map(member => {
					if (member['uuid'] === action.payload['userUuid']) {
						return {
							...member,
							userContactInfoFieldValues: {
								state: 'error',
							},
						};
					}
					return member;
				}),
			};
		}
		case COMPANY_LOGO_UPDATED: {
			return {
				...state,
				company: {
					...state.company,
					complogo: action.payload['company_logo_url'],
				},
			};
		}
		case ALL_RENEWED: {
			return {
				...state,
				companyMembers: state.companyMembers.map(member => {
					const memberInArray = action.payload['array_of_members'].find(memberInArray => {
						return memberInArray['uuid'] === member['uuid'];
					});

					if (memberInArray) {
						return {
							...member,
							groupID: memberInArray['groupid'],
							expirationDate: memberInArray['newExpiryDate'],
						};
					}
					return member;
				}),
			};
		}
		case USER_ADDED_TO_COMPANY: {
			return {
				...state,
				userCompanyAffiliation: {
					comp_uuid: action.payload.comp_uuid,
					compid: action.payload.compid,
					comp_admin: true,
				},
				userCompanyAffiliationFetched: true,
			};
		}
		case RECEIVE_SEND_PAYMENT_EXHIBITOR: {
			action.payload.success
				? NotificationManager.success(
					state.languageObject.checkout_payment_approved,
					'Success',
				)
				: NotificationManager.error(action.payload.error_message, 'Failed');
			return action.payload.success
				? {
					...state,
					oldExhibitorCart: action.payload,
					oldExhibitorUuid: state.exhibitorCartDetails.exhibuuid,
					exhibitorCartDetailsFetched: false,
					exhibitorCart: {},
					firstPaymentMade: true,
				}
				: {
					...state,
					paymentErrorMsg: action.payload?.error_message,
				};
		}
		case REQUEST_ERASE_CORPORATE_MEMBERSHIP_DATA: {
			return INIT_STATE;
		}
		case RECEIVE_RESET_CART: {
			return {
				...state,
				oldExhibitorCart: '',
				exhibitorCartDetailsFetched: false,
				exhibitorCartFetched: false,
			};
		}

		case USER_COMPANY_AFFILIATION_DELETED: {
			return {
				...state,
				userCompanyAffiliation: {
					comp_uuid: '',
					compid: '',
					comp_admin: false,
				},
				userCompanyAffiliationFetched: false,
			};
		}
		case FINANCIAL_ITEMS_FORM_STATE_UPDATED: {
			return {
				...state,
				financialItemsFormState: action.payload,
			};
		}
		case EXHIBITOR_CART_UUID_FETCHED: {
			return {
				...state,
				exhibitorCartDetails: {
					...state.exhibitorCartDetails,
					exhibitorCartUuid: action.payload.cart_uuid,
					exhibitorCartId: action.payload.cart_id,
					purchaseRequired: action.payload.purchaseRequired
				},
			};
		}
		case EXHIBITOR_SIGNEDUP: {
			// look at COMPANY_CREATED.
			return {
				...state,
				createdCompany: action.payload,
				userCompanyAffiliation: {
					...state.userCompanyAffiliation,
					comp_uuid:
						action.payload.companycreated === 1
							? action.payload.comp_uuid
							: state.userCompanyAffiliation.comp_uuid,
					compid:
						action.payload.companycreated === 1
							? action.payload.compid
							: state.userCompanyAffiliation.compid,
					comp_admin:
						action.payload.companycreated === 1
							? action.payload.comp_admin
							: state.userCompanyAffiliation.comp_admin,
				},
				userCompanyAffiliationFetched:
					action.payload.companycreated === 1
						? true
						: state.userCompanyAffiliationFetched,
				exhibitorCartDetails: {
					...state.exhibitorCartDetails,
					exhibuuid:
						action.payload.companycreated === 1
							? action.payload.exhibuuid
							: state.exhibitorCartDetails.exhibuuid,
					exhibitorid:
						action.payload.companycreated === 1
							? action.payload.exhibitorID
							: state.exhibitorCartDetails.exhibitorid,
				},
				exhibitorCartDetailsFetched:
					action.payload.companycreated === 1 ? true : state.exhibitorCartDetailsFetched,
				companyCreated: action.payload.companycreated === 1,
			};
		}
		case CART_MADE_PUBLIC_TO_PRIVATE: {
			return {
				...state,
				exhibitorCartDetails: {
					...state.exhibitorCartDetails,
					// These 2 are no longer requred.
					exhibitorCartUuid: '',
					exhibitorCartId: '',
				},
				exhibitorCart: {
					...action.payload,
				},
			};
		}
		case SET_CURRENT_FINANCIAL_ITEM: {
			return {
				...state,
				currentFinancialItem: action.payload,
			};
		}
		case EXHIBITOR_PAYMENT_DELETED: {
			if (action.payload.success) {
				NotificationManager.success(action.payload.message, 'Success');
			} else {
				NotificationManager.warning(action.payload.message, 'Failed');
			}
			return action.payload.success
				? {
					...state,
					exhibitorCart: {
						...action.payload,
					},
				}
				: {
					...state,
				};
		}
		case ROLES_FETCHED: {
			return {
				...state,
				roles: action.payload.roles,
				rolesFetched: true,
			};
		}
		case ROLES_UPDATED: {
			return {
				...state,
				companyMembers: state.companyMembers.map(companyMember => {
					return companyMember.uuid === action.payload.contact_uuid
						? {
							...companyMember,
							roles: action.payload.roleids,
						}
						: companyMember;
				}),
			};
		}
		case REQUEST_CORPORATE_MEMBERSHIP_MODULEURLID: {
			return {
				...state,
				moduleUrlId: action.payload.moduleUrlId,
				moduleUrlIdFetched: true,
			};
		}
		case REQUEST_CLEAR_NEW_USER_ADDED_ERROR: {
			return {
				...state,
				newMemberAddFailure: '',
			};
		}
		case REQUEST_CLEAR_NEW_USER_ADDED_SUCCESS: {
			return {
				...state,
				newMemberAddSuccess: '',
			};
		}
		case REQUEST_CLEAR_CREDIT_CARD_NOT_ACCEPTED_MSG: {
			return {
				...state,
				paymentErrorMsg: '',
			};
		}
		case RECEIVE_MINI_PROFILE_FIELDS: {
			return {
				...state,
				miniProfileFields: [
					{
						screen: 1,
						fieldids: action.payload,
						screentype: 'Staff',
						button: 'Company Contacts',
						hideifcomplete: 0,
					},
				],
				miniProfileFieldsFetched: true,
			};
		}
		case UPDATE_STEP_DETAILS: {
			const itemstoadd = action.payload.itemstoadd ? action.payload.itemstoadd : {};
			let membershipGroups = (state?.generalSettings?.corp_membership_value_map).filter(item => {
				return state?.exhibitorCart?.InvoiceItemArray?.find((data) => data?.value_id == item?.valueid)
			})
			return {
				...state,
				membershipAvailableFromInvoice: membershipGroups[0]?.membership_config?.membership_types,
				membershipStepDetails: {
					...state.membershipStepDetails,
					...action.payload,
					itemstoadd: { ...state.membershipStepDetails.itemstoadd, ...itemstoadd }
				}
			}
		}
		case UPDATE_IS_IFRAME: {
			return {
				...state,
				isIframe: action.payload
			}
		}
		case IS_EXHIBITOR: {
			return {
				...state,
				isExhibitor: action.payload
			}
		}
		case RECEIVE_ATTENDEES_INFO: {
			return {
				...state,
				companyMembers: state.companyMembers.map(data => {
					let attendee = action.payload.find(attendee => attendee.uuid === data.uuid)
					if (attendee) { return { ...data, ...attendee } }
					return data
				}),
				attendeesInfo: action.payload
			}
		}
		case RECEIVE_COMPANY_DISCOUNT: {
			return {
				...state,
				badgeDiscountInfo: action.payload
			}
		}
		case RECEIVE_STAFF_REGISTRANT_FORM: {
			return {
				...state,
				attendeeUpdateRegistrationForm: action.payload.attenmenu,
				attendeeUpdateRegistrationFormValues: action.payload.user_selected_values,
			}
		}
		case RECEIVE_UPDATE_STAFF_REGISTRANT_FORM: {
			return {
				...state,
				exhibitorCart: action.payload
			}
		}
		case RESET_UPDATE_STAFF_REGISTRANT_FORM: {
			return {
				...state,
				attendeeUpdateRegistrationForm: [],
				attendeeUpdateRegistrationFormValues: {},
			}
		}
		case RECEIVE_STAFF_REMOVAL_OPTIONS: {
			return {
				...state,
				staffRemovalOptions: action.payload
			}
		}
		case RECEIVE_STAFF_REMOVE_INFO: {
			action.payload.message
				? NotificationManager.success(action.payload.message)
				: NotificationManager.error(action.payload.message)
		}
		case REQUEST_PAYMENT_LOADER: {
			return {
				...state, isUpdateMemberAfterPayment: action.payload,
			}
		}
		case CUSTOM_STATE_HANDLER: {
			return Object.assign(state, { [action.payload.key]: action.payload.data })
		}
		case RESET_DUPLICATE_USER_STATE:{
			return {
				...state, 
				newUserDuplicateExists : false,
				newUserDuplicateUsers: [],
			}
		}
		case REQUEST_IGNORE_PURCHASE_REQUIRED_REDIRECT:{
			return {
				...state,
				ignorePurchaseRequiredRedirect: action.payload,
			}
		}
		case RECEIVE_PROCESSING_FEES:{
			return {
				...state,
				showProcessingFees : action.payload.showProcessingFees,
				paymentProcessingDetails : action.payload.paymentProcessingDetails
			}
		}
		default:
			return state;
	}
};
