import React from 'react';
import { Button } from 'antd';
import { ReactComponent as BackIcon } from '../../assets/icons/left.svg';
import { LeftOutlined } from '@ant-design/icons';

const GoBack = ({ handleBackClick }) => {
	return (
		<Button
			// className="login-form-button gx-mt-1 button-primary gx-mb-3"
			// shape={'round'}
			// style={{
			// 	boxShadow: '6px 5px 2px 1px rgba(45, 45, 45, 0.4)',
			// 	border: 'transparent',
			// }}
			type="primary"
			className="gx-mt-4"
			onClick={() => handleBackClick?.()}
			icon={<LeftOutlined />}
		>
		Back
		</Button>
	);
};

export default GoBack;
