import React, { useEffect, useState } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { Col, Row, Card, Form, Spin } from 'antd';

import DynamicCartProgress from './Dynamic/DynamicCartProgress';

import ProgressBar from './Dynamic/ProgressBar/ProgressBar';
import ProgressMenu from './Dynamic/ProgressBar/ProgressMenu';
import { parseScreen } from '../../util/form';
import { useParams } from 'react-router';

import GoBack from '../Common/GoBack';

import FormComponent from '../form/index';
import auth from '../../loginAuth/loginAuth';

import Widget from '../Widget';
import CircularProgress from '../CircularProgress';
import { useToken } from './Hooks/useToken';

import {
	requestAttenuuidInfo,
	requestInvoiceInfo,
	requestCartInfo,
	requestPrevScreen,
	requestResetEcommerceCart,
	requestResetCustomCheckoutComponent,
	requestSelectionArray,
	requestAddRegistrationCart,
} from '../../appRedux/actions/Ecommerce';

import {
	requestAttendeeSettings,
	requestStoreProductTags,
} from '../../appRedux/actions/EcommerceStore';

import { requestLoginFormData } from '../../appRedux/actions/LoginPageInfo';

import { requestEraseCommonData } from '../../appRedux/actions/Common';

const RegistrationSelection = props => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [formControl] = Form.useForm();
	const [loader, setLoader] = useState(true);
	const [validate, setValidate] = useState(false);
	const [hiddenValidate, setHiddenValidate] = useState(true);
	const [simpleinvoiceobj, setSimpleinvoiceobj] = useState(null);
	const [formState, setFormState] = useState([]);
	const [addItemPayload, setAddItemPayload] = useState('');
	const [validationLoading, setValidationLoading] = useState(true);
	const [addCartLoad, setAddCartLoad] = useState(false);
	const [existingPayment, setExistingPayment] = useState(false);
	const [len, setLen] = useState(0);

	const screenThreshold = 4; // used for switching from top progress bar to side progress bar
	const { moduleUrlId } = useParams();

	const appdir = useSelector(state => state.loginInfo.appdir);
	const login_form = useSelector(state => state.loginInfo.login_form);

	const screenNum = useSelector(state => state.ecommerce.screenNum);
	const cartFetching = useSelector(state => state.ecommerce.cartFetching);
	const attenuuid = useSelector(state => state.ecommerce.attenuuid);
	const cartUuid = useSelector(state => state.ecommerce.cartUuid);
	const cartInfoFetched = useSelector(state => state.ecommerce.cartInfoFetched);
	const cartItems = useSelector(state => state.ecommerce.cartItems);
	const publicEmbed = useSelector(state => state.settings.publicEmbed);
	const screenWidth = useSelector(state => state.settings.width);
	const publicCheckout = useSelector(
		state =>
			state.ecommerce?.attendeeSettings?.attendeesiteadmin?.generalconfigobject
				?.public_checkout,
	);
	const jwt = props.auth.jwt;
	const mobileThreshold = useSelector(state => state.common.mobileThreshold);
	const menuObjs = props.ecommerce.menuObjects;

	useToken();

	// make redux action to reset registration updated to false
	// onClick of buttons on start page make redux actions to conditionally set ScreenNum

	const onValidationChange = (validations, all) => {
		setHiddenValidate(validations.error);
		if (validations.error === false) {
			setValidate(validations.error);
		}
		setFormState(all);

		let itemsToAdd = {};
		for (let key in all) {
			if (all[key] != undefined) {
				itemsToAdd[key] = {
					valueid: all[key],
					qty: 1,
				};
			}
		}

		setAddItemPayload(itemsToAdd);
	};

	const clickBack = () => {
		dispatch(requestPrevScreen(props));
	};

	useEffect(() => {
		if (cartItems?.PaymentItemsArray?.length > 0) {
			setExistingPayment(true);
		}
	}, [cartItems]);

	useEffect(() => {
		if (props.ecommerce.tagsFetched && appdir) {
			dispatch(
				requestSelectionArray({
					appdir: appdir,
					moduleUrlId: moduleUrlId,
					publicEmbed: props.settings.publicEmbed,
				}),
			);
		}
	}, [props.ecommerce.tagsFetched, appdir]);

	useEffect(() => {
		if (!appdir) return;

		if (!props.initialData.source_hex) {
			dispatch(requestLoginFormData({ appdir }));
		}
		if (!publicEmbed) {
			dispatch(requestAttenuuidInfo({ appdir, moduleUrlId }));
		}

		if (!props.ecommerce.attendeeSettingsFetched) {
			dispatch(requestAttendeeSettings({ appdir, moduleUrlId }));
		}
	}, [appdir]);

	useEffect(() => {
		dispatch(requestResetEcommerceCart(props));
		dispatch(requestResetCustomCheckoutComponent(props));
		dispatch(requestEraseCommonData(props));
	}, []);

	useEffect(() => {
		if (props.ecommerce.oldAttenuuid === attenuuid && !publicEmbed) {
			dispatch(requestAttenuuidInfo({ appdir, moduleUrlId }));
		}
	}, [props.ecommerce.oldAttenuuid]);

	useEffect(() => {
		if (!props.ecommerce.tagsFetched && props.initialData.source_hex) {
			dispatch(requestStoreProductTags({ source: props.initialData.source_hex }));
		}
	}, [props.initialData.source_hex]);

	useEffect(() => {
		if (!appdir) return;

		if (attenuuid && !cartInfoFetched && jwt) {
			dispatch(
				requestInvoiceInfo({
					appdir,
					moduleUrlId,
					attenuuid,
				}),
			);

			dispatch(
				requestCartInfo({
					appdir,
					moduleUrlId,
					attenuuid,
					cartUuid,
				}),
			);
		} else if (cartUuid && publicEmbed && !cartInfoFetched) {
			dispatch(
				requestCartInfo({
					appdir,
					moduleUrlId,
					attenuuid,
					publicEmbed,
					cartUuid,
				}),
			);
		}
	}, [attenuuid, appdir, cartUuid]);

	useEffect(() => {
		if (props.ecommerce.menuObjects?.length !== screenNum) return;
		history.push(`/${publicEmbed ? 'public' : 'main'}/registration/${moduleUrlId}/checkout${publicEmbed ? '?public_embed=1' : ''}`,);
	}, [screenNum]);

	useEffect(() => {
		if (props.ecommerce.registrationUpdated) {
			history.push(`/${publicEmbed ? 'public' : 'main'}/registration/${moduleUrlId}/starthome${publicEmbed ? '?public_embed=1' : ''}`,);
		}
	}, [props.ecommerce.registrationUpdated]);

	// how to update component after props change?
	useEffect(() => {
		setSimpleinvoiceobj(cartItems.simpleinvoiceobj);
	}, [cartItems]);

	// for watching the loader
	useEffect(() => {
		if (props.ecommerce.menuObjects) {
			setLoader(false);
			setLen(props.ecommerce?.menuObjects?.length);
		}
	}, [props.ecommerce.menuObjects]);

	useEffect(() => {
		if (publicEmbed && Object.keys(formState).length > 0 && cartUuid) {
			const formData = new FormData();
			formData.append('itemstoadd', JSON.stringify(addItemPayload));

			dispatch(
				requestAddRegistrationCart({
					appdir,
					moduleUrlId,
					attenuuid,
					formData,
					cartUuid,
					publicEmbed,
					existingPayment,
				}),
			);
			setLoader(false)
		}
	}, [formState]);
	// To restrict loading for text box change used the activeElement
	const spinning = (loader && !validationLoading) || addCartLoad || (cartFetching && document.activeElement.type !== 'text')

	return (
		<div className={screenWidth > mobileThreshold ? 'container' : ''}>
			<Spin tip="Loading..." spinning={spinning}>
				{screenNum > 1 && !existingPayment ? (
					<GoBack style={{ marginBottom: '-10rem' }} handleBackClick={clickBack} />
				) : (
					''
				)}

				{len <= screenThreshold && screenWidth > 900 && !existingPayment && !publicCheckout ? (
					<ProgressBar login_form={login_form} screenNum={screenNum} />
				) : (
					''
				)}
				<Row>
					{len > screenThreshold &&
						screenWidth > 900 &&
						!existingPayment &&
						!publicCheckout ? (
						<Col xl={6} lg={6} md={24} sm={24} xs={24}>
							<div>
								<Card className={'card'}>
									<ProgressMenu screenNum={screenNum} />
								</Card>
							</div>
						</Col>
					) : (
						''
					)}

					{simpleinvoiceobj && props.ecommerce.menuObjects?.length && (
						<Col
							xl={len <= screenThreshold || existingPayment ? 18 : 12}
							lg={len <= screenThreshold || existingPayment ? 18 : 12}
							md={len <= screenThreshold ? 24 : 24}
							sm={len <= screenThreshold ? 24 : 24}
							xs={len <= screenThreshold ? 24 : 24}
						>
							<Card className="product-card card">
								{screenWidth < mobileThreshold && (
									<div>
										<h2 className="fg-primary">
											{'Step ' +
												screenNum +
												' of ' +
												menuObjs.length +
												': ' +
												menuObjs[screenNum - 1]?.button}
										</h2>
									</div>
								)}
								<FormComponent
									initialValues={simpleinvoiceobj}
									form={formControl}
									formData={parseScreen(
										props.ecommerce.menuObjects[screenNum - 1], // -1 for offset because formData count starts at 0 and sceenNum starts at 1
									)}
									validationConfig={{
										module: 'attendee',
										moduleurlid: moduleUrlId,
										menuid: props.ecommerce.menuObjects[screenNum - 1].menuid,
									}}
									validationLoading={val => setValidationLoading(val)}
									onChange={(validations, all) => {
										onValidationChange(validations, all);
									}}
									validate={validate} // set as false initally, make it true when I need to validate. I just have to pass it true. It will validate by itself
									primaryColor={'#0E3366'}
									secondaryColor={'#808080'}
									textColor={'#404040'}
								/>
							</Card>
						</Col>
					)}

					<Col
						xl={screenWidth > mobileThreshold ? 6 : 24}
						lg={screenWidth > mobileThreshold ? 6 : 24}
						md={screenWidth > mobileThreshold ? 6 : 24}
						sm={screenWidth > mobileThreshold ? 6 : 24}
						xs={screenWidth > mobileThreshold ? 6 : 24}
					>
						<DynamicCartProgress
							validationToggle={validate}
							invoiceArray={cartItems.InvoiceItemArray}
							login_form={login_form}
							hiddenValidate={hiddenValidate}
							setValidate={setValidate}
							screenNum={screenNum}
							formState={addItemPayload}
							attenuuid={props.ecommerce.attenuuid}
							appdir={props.initialData.appdir}
							cartUuid={cartUuid}
							publicEmbed={publicEmbed}
							menuObjects={props.ecommerce.menuObjects}
							addCartLoad={val => setAddCartLoad(val)}
							existingPayment={existingPayment}
							cartFetching={cartFetching}
						/>
					</Col>
				</Row>
			</Spin>
		</div>
	);
};

const mapStateToProps = state => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
		ecommerce: myState.ecommerce,
		settings: myState.settings,
	};
};

export default connect(mapStateToProps)(RegistrationSelection);
