import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { Modal, Button } from 'antd';
import moment from 'moment';
import _find from 'lodash/find';

const MembershipModal = props => {
	const todayDate = new Date();

	const { item, groupID, triggerModal = false, closeModal: closeMembersModal, usegroupID = false } = props;
	const [openModal, setOpenModal] = useState(triggerModal);
	const generalSettings = useSelector(state => state.corporateMembership?.generalSettings);

	const authUser = useSelector(state => state.auth?.authUser);

	const companyExpireDate = useSelector(state => state.corporateMembership?.company?.expiry_date);
	const companyMembers = useSelector(state => state.corporateMembership?.companyMembers);
	const membershipsAvailable = useSelector(
		state => state.corporateMembership?.company?.memberships_available,
	);
	const companyMembersFetched = useSelector(
		state => state.corporateMembership?.companyMembersFetched,
	);
	const clickOpenModal = () => { setOpenModal(true) };

	const closeModal = () => {
		setOpenModal(false);
		closeMembersModal && closeMembersModal();
	};

	const renewal = moment(companyExpireDate).diff(moment(todayDate), 'days') < generalSettings.renewal_period_days;
	return (
		<div>
			{authUser && (
				<div>
					<Button
						className='existing-membership-button'
						onClick={clickOpenModal}
						size='small'
					>
						{generalSettings?.corporate_membership?.company_members_modal?.open_modal_button_label}
					</Button>
					<Modal visible={openModal || triggerModal} onCancel={closeModal} footer={null}>
						<div>
							{membershipsAvailable && (
								<div className="gx-mt-3">
									<h2>
										{generalSettings?.corporate_membership?.company_members_modal?.modal_title}
									</h2>
									<div dangerouslySetInnerHTML={{
										__html: generalSettings?.corporate_membership?.company_members_modal?.modal_text,
									}}
									/>
									{companyMembersFetched &&
										membershipsAvailable.filter(mem => usegroupID ? mem.groupid === groupID : true).map(membership => {
											const membersOfThisGroup = companyMembers.filter(obj => parseInt(obj.groupID) === parseInt(membership.groupid))
											return (
												<div>
													<div className="gx-mb-3 fg-primary-gradient">
														<span>
															{membership.group_name}
															{': '}
															{membersOfThisGroup.length}
														</span>
													</div>
													<span>
														{membersOfThisGroup.map(item => {
															if (renewal && moment(companyExpireDate) < moment(item.expirationDate)) {
																return (
																	<div className="gx-mt-1">
																		<p>
																			<i>
																				{item.firstName}{' '}{item.lastName}{'  '}{item.email}{' '}
																				<b>(Prepaid - Expiry :{' '}{item.expirationDate}){' '}</b>
																			</i>
																		</p>
																	</div>
																);
															} else {
																return (
																	<div className="gx-mt-1">
																		<p>
																			{item.firstName}{' '}{item.lastName}{'  '}{item.email}{' '}
																			<b>(Expiry :{' '}{item.expirationDate}){' '}</b>
																		</p>
																	</div>
																);
															}
														})}
													</span>
												</div>
											);
										})}

									{companyMembersFetched && !usegroupID && (
										<div>
											<div className="gx-mb-3 fg-primary-gradient">
												<span>Not A Member </span>
											</div>

											{companyMembers
												.filter(obj => !obj.groupID) // double equal on purpose, sometimes type of the same
												.map(item => (
													<div>
														<p>
															{item.firstName} {item.lastName}
														</p>
													</div>
												))}
										</div>
									)}
								</div>
							)}
						</div>
					</Modal>
				</div>
			)}
		</div>
	);
};
export default MembershipModal;
