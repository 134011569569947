import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { myAuth } from '../../firebase/firebase';

import auth from '../../loginAuth/loginAuth';

import {
	SIGNIN_USER,
	SIGNOUT_USER,
	SIGNUP_USER,
	REQUEST_SIGNIN_TOKEN,
	SET_AUTH_USER,
	REQUEST_AUTHENTICATE_USER,
	REQUEST_LOGOUT_USER,
	REQUEST_RESET_PASSWORD,
	REQUEST_SEND_EMAIL,
	REQUEST_SEND_PASSCODE,
	REQUEST_VALIDATE_EMAIL,
	REQUEST_CLEAR_VALIDATE_EMAIL,
	REQUEST_ERASE_AUTH_DATA,
	REQUEST_DELETE_RESET_PASSWORD_MSG,
	REQUEST_CHECKOUT_CREATE_PROFILE,
	REQUEST_MAGIC_LOGIN,
} from 'constants/ActionTypes';
import {
	showAuthMessage,
	userSignInSuccess,
	userSignOutSuccess,
	userSignUpSuccess,
	receiveSigninToken,
	receiveAuthUser,
	receiveAuthenticateUser,
	receiveLogoutUser,
	receiveResetPassword,
	receiveSendEmail,
	receiveSendPasscode,
	receiveValidateEmail,
	receiveClearValidateEmail,
	receiveEraseAuthData,
	receiveDeleteResetPasswordMsg,
	receiveCheckoutCreateProfile,
	receiveMagicLogin,
} from '../../appRedux/actions/Auth';
import { fetchError } from '../actions/Common';

import AuthAPIs from '../../api/Auth';

const fetchAuthenticateUser = async context => {
	let jsonData = await AuthAPIs.getAuthenticateUser(context.data.appdir, context.data.formData);
	// get existing cookie and only set if its different from response
	// first you call it there won't be anything, then set it.

	auth.setCookie('contact_uuid', JSON.stringify(jsonData.contact_uuid ?? ''), 7);
	auth.setCookie('jwt', JSON.stringify(jsonData.jwt ?? ''), 7);
	auth.setCookie('ssotoken', JSON.stringify(jsonData.ssotoken ?? ''), 7);
	return jsonData;
};

const fetchResetPassword = async context => {
	let jsonData = await AuthAPIs.getResetPassword(context.data.appdir, context.data.formData);
	return jsonData;
};

const fetchSendEmail = async context => {
	let jsonData = await AuthAPIs.getSendEmail(context.data.appdir, context.data.uuid);
	return jsonData;
};

const fetchMagicLogin = async context => {
	let jsonData = await AuthAPIs.getMagicLogin(
		context.data.appdir,
		context.data.moduleUrlId,
		context.data.cartUuid,
		context.data.email,
	);
	return jsonData;
};

const fetchSendPasscode = async context => {
	let jsonData = await AuthAPIs.getSendPassCode(
		context.data.appdir,
		context.data.uuid,
		context.data.phoneNumber,
	);
	return jsonData;
};

const fetchValidateEmail = async context => {
	let jsonData = await AuthAPIs.getValidateEmail(
		context.data.appdir,
		context.data.email,
		context.data.formData,
	);
	return jsonData;
};
//------------
const createUserWithEmailPasswordRequest = async (email, password) =>
	await myAuth
		.createUserWithEmailAndPassword(email, password)
		.then(authUser => authUser)
		.catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
	await myAuth
		.signInWithEmailAndPassword(email, password)
		.then(authUser => authUser)
		.catch(error => error);

const signOutRequest = async () =>
	await myAuth
		.signOut()
		.then(authUser => authUser)
		.catch(error => error);

function* getAuthenticateUser(action) {
	try {
		let result = yield call(fetchAuthenticateUser, action);
		yield put(receiveAuthenticateUser(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getResetPassword(action) {
	try {
		let result = yield call(fetchResetPassword, action);
		yield put(receiveResetPassword(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getSendEmail(action) {
	try {
		let result = yield call(fetchSendEmail, action);
		yield put(receiveSendEmail(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getMagicLogin(action) {
	try {
		const result = yield call(fetchMagicLogin, action);
		yield put(receiveMagicLogin(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getSendPasscode(action) {
	try {
		let result = yield call(fetchSendPasscode, action);
		yield put(receiveSendPasscode(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getValidateEmail(action) {
	try {
		let result = yield call(fetchValidateEmail, action);
		yield put(receiveValidateEmail(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

const fetchCheckoutCreateProfile = async context => {
	let jsonData = await AuthAPIs.getCheckoutCreateProfile(
		context.data.appdir,
		context.data.moduleUrlId,
		context.data.formData,
	);
	auth.setCookie('contact_uuid', JSON.stringify(jsonData.UUID), 7);
	auth.setCookie('jwt', JSON.stringify(jsonData.jwt), 7);
	auth.setCookie('ssotoken', JSON.stringify(jsonData.sso_token), 7);
	return jsonData;
};

//------------------

function* getDeleteResetPasswordMsg(action) {
	try {
		yield put(receiveDeleteResetPasswordMsg(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getEraseAuthData(action) {
	try {
		yield put(receiveEraseAuthData(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getClearValidateEmail(action) {
	try {
		yield put(receiveClearValidateEmail(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getLogout(action) {
	try {
		yield put(receiveLogoutUser(action));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* createUserWithEmailPassword({ payload }) {
	const { email, password } = payload;
	try {
		const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
		if (signUpUser.message) {
			yield put(showAuthMessage(signUpUser.message));
		} else {
			localStorage.setItem('user_id', signUpUser.user.uid);
			yield put(userSignUpSuccess(signUpUser.user.uid));
		}
	} catch (error) {
		yield put(showAuthMessage(error));
	}
}

function* signInUserWithEmailPassword({ payload }) {
	const { email, password } = payload;
	try {
		const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
		if (signInUser.message) {
			yield put(showAuthMessage(signInUser.message));
		} else {
			localStorage.setItem('user_id', signInUser.user.uid);
			yield put(userSignInSuccess(signInUser.user.uid));
		}
	} catch (error) {
		yield put(showAuthMessage(error));
	}
}

function* signOut() {
	try {
		const signOutUser = yield call(signOutRequest);
		if (signOutUser === undefined) {
			localStorage.removeItem('user_id');
			yield put(userSignOutSuccess(signOutUser));
		} else {
			yield put(showAuthMessage(signOutUser.message));
		}
	} catch (error) {
		yield put(showAuthMessage(error));
	}
}

function* getSigninToken(action) {
	try {
		yield put(receiveSigninToken(action));

		auth.setCookie('contact_uuid', JSON.stringify(action.payload.contact_uuid), 7);
		auth.setCookie('jwt', JSON.stringify(action.payload.jwt), 7);
		auth.setCookie('ssotoken', JSON.stringify(action.payload.ssotoken), 7);
	} catch (e) {
		yield put(showAuthMessage(e));
	}
}

function* getCheckoutCreateProfile(action) {
	try {
		let result = yield call(fetchCheckoutCreateProfile, action);
		yield put(receiveCheckoutCreateProfile(result));
	} catch (e) {
		yield put(fetchError(e));
	}
}

function* getAuthUser(action) {
	try {
		yield put(receiveAuthUser(action));
	} catch (e) {
		yield put(showAuthMessage(e));
	}
}

export function* authenticateUser() {
	yield takeEvery(REQUEST_AUTHENTICATE_USER, getAuthenticateUser);
}

export function* resetPassword() {
	yield takeEvery(REQUEST_RESET_PASSWORD, getResetPassword);
}

export function* SendEmail() {
	yield takeEvery(REQUEST_SEND_EMAIL, getSendEmail);
}

export function* SendPasscode() {
	yield takeLatest(REQUEST_SEND_PASSCODE, getSendPasscode);
}

export function* MagicLogin() {
	yield takeEvery(REQUEST_MAGIC_LOGIN, getMagicLogin);
}

export function* validateEmail() {
	yield takeLatest(REQUEST_VALIDATE_EMAIL, getValidateEmail);
}

export function* checkoutCreateProfile() {
	yield takeLatest(REQUEST_CHECKOUT_CREATE_PROFILE, getCheckoutCreateProfile);
}

//----------

export function* deleteResetPasswordMsg() {
	yield takeEvery(REQUEST_DELETE_RESET_PASSWORD_MSG, getDeleteResetPasswordMsg);
}

export function* eraseAuthData() {
	yield takeEvery(REQUEST_ERASE_AUTH_DATA, getEraseAuthData);
}

export function* clearValidateEmail() {
	yield takeEvery(REQUEST_CLEAR_VALIDATE_EMAIL, getClearValidateEmail);
}

export function* Logout() {
	yield takeEvery(REQUEST_LOGOUT_USER, getLogout);
}

export function* signinToken() {
	yield takeEvery(REQUEST_SIGNIN_TOKEN, getSigninToken);
}

export function* createUserAccount() {
	yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
	yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
	yield takeEvery(SIGNOUT_USER, signOut);
}

export function* myAuthUser() {
	yield takeEvery(SET_AUTH_USER, getAuthUser);
}

export default function* rootSaga() {
	yield all([
		fork(eraseAuthData),
		fork(clearValidateEmail),
		fork(validateEmail),
		fork(SendPasscode),
		fork(SendEmail),
		fork(resetPassword),
		fork(getLogout),
		fork(authenticateUser),
		fork(signInUser),
		fork(createUserAccount),
		fork(signOutUser),
		fork(signinToken),
		fork(myAuthUser),
		fork(deleteResetPasswordMsg),
		fork(checkoutCreateProfile),
		fork(MagicLogin),
	]);
}
