import React from 'react';
import { Card, Button } from 'antd';
import { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { formatPayment } from './helpers/helper';
import { InfoCircleOutlined } from '@ant-design/icons';
import { requestProcessingFees } from '../../appRedux/actions';
const PaymentOptions = ({
	renderPaymentComponent,
	cartItems,
	languageObject,
	paymentSettings,
	currentPaymentMethod,
}) => {
	const dispatch = useDispatch();

	const buttonClicked = value => {
		if(value === 'wiretransfer') {
			dispatch(requestProcessingFees({
				showProcessingFees: paymentSettings?.wire_transfer_processing_fee?.enabled,
				paymentProcessingDetails: {
					...paymentSettings?.wire_transfer_processing_fee, 
					payment_type: 'wiretransfer'
				}
			}));
		}
		else{
			dispatch(requestProcessingFees({
				showProcessingFees: false,
				paymentProcessingDetails: {}
			}));
		}
		renderPaymentComponent(value);
	};
	const [onlyCreditCard, setOnlyCreditCard] = useState(false);

	const paymentSettingsFetched = useSelector(state => state.ecommerce.paymentSettingsFetched);

	useEffect(() => {
		// if only creditcard enabled, then show credit card right away
		if (paymentSettings) {
			if (
				!paymentSettings?.manualpaymentsettings?.wiretransfersettings
					?.wiretransferenabled &&
				!paymentSettings?.manualpaymentsettings?.purchaseorderenabled &&
				!paymentSettings?.manualpaymentsettings?.checksettings?.checksenabled &&
				!paymentSettings?.reacthostedsettings?.reacthostedoptionenabled &&
				paymentSettings?.creditcardsettings?.creditcardenabled
			) {
				setOnlyCreditCard(true);
				renderPaymentComponent('creditcard');
			}
		}
	}, [paymentSettingsFetched]);

	return (
		<div>
			{parseFloat(cartItems.NetOwing) > 0 && (
				<Card className="card bg-primary-gradient">
					<div>
						<div>
							<div>
								<span
									className="gx-font-weight-semi-bold"
									style={{ fontSize: '20px', color: 'white' }}
								>
									{languageObject?.net_owing_header ?? 'Balance Due'}:{' '}
								</span>
								<span style={{ fontSize: '17px', color: 'white' }}>
									${formatPayment(cartItems.NetOwing)}
								</span>
							</div>
							{!onlyCreditCard && (
								<div className="gx-mt-3 gx-mb-2" style={{ color: 'white' }}>
									{languageObject?.pay_now_by ?? 'PAY NOW BY:'}
								</div>
							)}
						</div>
						<div style={{ display: 'flex', justifyContent: 'flex-start', gap: '1.5rem', flexWrap: 'wrap'  }}>
						{paymentSettings?.reacthostedsettings?.reacthostedoptionenabled === 1 ? (
							<div style={{maxWidth:"25%"}}>
							<Button
								shape={'round'}
								className={`login-form-button gx-mt-3 button-white gx-text-secondary`}
								value="react_hosted"
								onClick={() => {
									buttonClicked('react_hosted');
									return true;
								}}
							>
								{paymentSettings?.reacthostedsettings?.reacthostedbuttonname ??
									'Credit Card'}
							</Button>
							{
							paymentSettings?.credit_card_processing_fee?.enabled && paymentSettings?.credit_card_processing_fee?.help_text && 
								<p style={{ fontSize: '0.9rem', color: '#dee1e3', marginTop: '0.3rem', marginBottom: '0', textAlign: 'left' }}><InfoCircleOutlined /> {paymentSettings?.credit_card_processing_fee?.help_text}</p>
							}
							</div>
						) : (
							paymentSettings?.creditcardsettings?.creditcardenabled === 1 &&
							!onlyCreditCard && (
								<div style={{maxWidth:"25%"}}>
								<Button
									shape={'round'}
									className={`login-form-button gx-mt-3 button-white gx-text-secondary ${
										currentPaymentMethod === 'creditcard'
											? 'shadow-hard gx-border-0'
											: ''
									}`}
									value="creditcard"
									onClick={() => {
										buttonClicked('creditcard');
									}}
								>
									Credit Card
								</Button>
								{
								paymentSettings?.credit_card_processing_fee?.enabled && paymentSettings?.credit_card_processing_fee?.help_text && 
								<p style={{ fontSize: '0.9rem', color: '#dee1e3', marginTop: '0.3rem', marginBottom: '0', textAlign: 'left' }}><InfoCircleOutlined /> {paymentSettings?.credit_card_processing_fee?.help_text}</p>
								}
								</div>
							)
						)}
						{paymentSettings?.manualpaymentsettings?.wiretransfersettings
							?.wiretransferenabled === 1 && (
							<div style={{maxWidth:"25%"}}>
							<Button
								shape={'round'}
								className={`login-form-button gx-mt-3 button-white gx-text-secondary ${
									currentPaymentMethod === 'wiretransfer'
										? 'shadow-hard gx-border-0'
										: ''
								}`}
								value="wiretransfer"
								onClick={() => {
									buttonClicked('wiretransfer');
								}}
							>
								Wire Transfer
							</Button>
							{
								paymentSettings?.wire_transfer_processing_fee?.enabled && paymentSettings?.wire_transfer_processing_fee?.help_text && 
								<p style={{ fontSize: '0.9rem', color: '#dee1e3', marginTop: '0.3rem', marginBottom: '0', textAlign: 'left' }}><InfoCircleOutlined /> {paymentSettings?.wire_transfer_processing_fee?.help_text}</p>
							}
							</div>
						)}

						{paymentSettings?.manualpaymentsettings?.checksettings?.checksenabled ===
							1 && (
							<div style={{maxWidth:"25%"}}>
							<Button
								shape={'round'}
								className={`login-form-button gx-mt-3 button-white gx-text-secondary ${
									currentPaymentMethod === 'check'
										? 'shadow-hard gx-border-0'
										: ''
								}`}
								value="check"
								onClick={() => {
									buttonClicked('check');
								}}
							>
								{
									paymentSettings?.manualpaymentsettings?.checksettings
										?.checkbuttonname
								}
							</Button>
							</div>
						)}
						{paymentSettings?.manualpaymentsettings?.purchaseorderenabled === 1 && (
							<div style={{maxWidth:"25%"}}>
							<Button
								shape={'round'}
								className={`login-form-button gx-mt-3 button-white gx-text-secondary ${
									currentPaymentMethod === 'purchaseorder'
										? 'shadow-hard gx-border-0'
										: ''
								}`}
								value="purchaseorder"
								onClick={() => {
									buttonClicked('purchaseorder');
								}}
							>
								Purchase Order
							</Button>
							</div>
						)}
						
						</div>
					</div>
				</Card>
			)}
		</div>
	);
};

export default PaymentOptions;
