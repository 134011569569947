import auth from '../loginAuth/loginAuth';

const xcdapi = 'https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm';

// api to authenticate user
const getAuthenticateUser = async (appdir, formData) => {
	let result = await fetch(
		`${xcdapi}?appdir=${appdir}&module=login&component=login&function=authenticate_user`,
		{
			method: 'POST',
			body: formData,
		},
	);
	let resultJson = await result.json();
	return resultJson;
};

const getResetPassword = async (appdir, formData) => {
	let result = await fetch(
		`${xcdapi}?appdir=${appdir}&module=login&component=login&function=reset_password`,
		{
			method: 'POST',
			headers: {
				Authorization: JSON.parse(auth.getCookie('jwt')),
			},
			body: formData,
		},
	);
	let resultJson = await result.json();
	return resultJson;
};

const getSendEmail = async (appdir, uuid) => {
	let result = await fetch(
		`${xcdapi}?appdir=${appdir}&module=login&component=login&function=send_magic_login_link&uuid=${uuid}&react=1`,
		{
			method: 'GET',
		},
	);
	let resultJson = await result.json();
	return resultJson;
};

const getSendPassCode = async (appdir, uuid, phoneNumber) => {
	let result = await fetch(
		`${xcdapi}?appdir=${appdir}&module=login&component=login&function=send_sms&uuid=${uuid}&sendSMS=1&textNumber=${phoneNumber}`,
		{
			method: 'GET',
		},
	);
	let resultJson = await result.json();
	return resultJson;
};

const getValidateEmail = async (appdir, email, formData) => {
	let result = await fetch(
		`${xcdapi}?appdir=${appdir}&module=login&component=login&function=find_existing_user_account&email=${email}`,
		{
			method: 'POST',
			body: formData,
		},
	);
	let resultJson = await result.json();
	return resultJson;
};

const getCheckoutCreateProfile = async (appdir, moduleUrlId, formData) => {
	let result = await fetch(
		`${xcdapi}?module=attendee&appdir=${appdir}&component=attendees&function=create_profile&URLID=${moduleUrlId}`,
		{
			method: 'POST',
			body: formData,
		},
	);
	let resultJson = await result.json();
	return resultJson;
};

const getMagicLogin = async (appdir, moduleUrlId, cartUuid, email) => {
	let result = await fetch(
		`${xcdapi}?appdir=${appdir}&module=attendee&component=attendees&function=email_login_link&URLID=${moduleUrlId}&cartuuid=${cartUuid}&email=${email}`,
		{
			method: 'GET',
		},
	);
	let resultJson = await result.json();
	return resultJson;
};

const AuthAPIs = {
	getAuthenticateUser,
	getResetPassword,
	getSendEmail,
	getSendPassCode,
	getValidateEmail,
	getCheckoutCreateProfile,
	getMagicLogin,
};

export default AuthAPIs;
