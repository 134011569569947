import { useState, useEffect } from 'react';
import validationObject, { checkDisplayPrerequisites } from '../Validation';
import { fetchFormValidationData } from '../../../appRedux/effects/formValidation';
import { createValidationHashKey } from '../../../util/form';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormValidationData } from '../../../appRedux/actions';

/**
 * finds validation errors, visibility, dependency information for
 * form elements after applying validation rules on form data
 */
const findErrors = (data, rules, formData) => {
	validationObject.setData(data, rules, formData);
	return validationObject.findError();
};

/**
 * If the form element is hidden because of config or dependency on
 * another form element, reset its values.
 * @param formValues stores the form data
 * @param newValidations validation information for the elements
 * @returns shouldSetFormValues Tells if there is a change in the values
 * @returns newFormValues The new form values after making the changes.
 * Empty if no change
 */
const updateFormSelectedOptions = (formValues, newValidations) => {
	let newFormValues = { ...formValues };
	let shouldSetFormValues = false;

	if (newValidations.filteredValues) {
		Object.keys(newValidations.filteredValues).map(key => {
			if (
				key in formValues &&
				formValues[key] &&
				!checkDisplayPrerequisites(formValues[key], newValidations.filteredValues[key])
			) {
				newFormValues[key] = null;
				shouldSetFormValues = true;
			}
		});
	}

	return [shouldSetFormValues, newFormValues];
};

/**
 * Pass the validation information, form data information to the callbacks
 */
const updateData = (newValidations, formValues, onChange, menu) => {
	let newFormValues = {};

	//do not pass the information regarding the hidden form elements
	formValues &&
		Object.keys(formValues).map(key => {
			if (!(key in newValidations.display && newValidations.display[key] === false)) {
				newFormValues[key] = formValues[key];
			}
		});

	let error = Object.keys(newValidations.errors).length > 0;

	onChange &&
		onChange(
			{
				error,
				validationErrors: newValidations.errors,
			},
			newFormValues,
			menu
		);
};

/**
 * This hook takes care of the validations. This hook controls the logic for almost everything in the form
 * Refer to form/index.tsx for information regarding function parameters.
 */
export default function useValidation(
	doValidate,
	formValues,
	validationConfig,
	onChange,
	setFormValues,
	formData,
	staffRegistration,
	localValidation,
	localValidationConfig,
) {
	/**
	 * @param key stores the unique identifier to locate form validation data in the redux
	 * @param validationRules stores the validation rules/info fetched from the redux
	 * @param showLoading whether to showLoading or not for the component
	 * @param validations stores the validation errors, visibility, dependency information for
	 * form elements after applying validation rules on form data
	 */
	const [key, setKey] = useState(createValidationHashKey(validationConfig));
	const validationData = useSelector(state => state.formValidation.data);
	const validationRules = validationData[key];
	const state = useSelector(state => state);

	//If there is no validation config, no need to show loading as there will be no backend request
	const [showLoading, setLoading] = useState(validationConfig ? true : false);
	const [validations, setValidations] = useState(findErrors(formValues, validationRules, formData),);
	const dispatch = useDispatch();

	//updates validation information on rules or form data change
	useEffect(() => {
		const newValidations = findErrors(formValues, validationRules, formData);

		// If false, don't show the errors
		if (!doValidate) {
			setValidations({
				...newValidations,
				errors: {},
			});
		} else {
			setValidations(newValidations);
		}

		// stop loading once the request hsa returned a response(success/fail)
		validationRules && validationRules.isFetched && setLoading(false);
		validationRules && validationRules.fetchError && setLoading(false);

		const [shouldSetFormValues, newFormValues] = updateFormSelectedOptions(
			formValues,
			newValidations,
		);

		shouldSetFormValues && setFormValues(newFormValues);

		//pass updated data in the callbacks
		updateData(newValidations, newFormValues, onChange, validationConfig?.menuid);
	}, [doValidate, formValues, validationRules]);

	//fetches validation data and stores in redux
	useEffect(() => {
		validationConfig && dispatch(fetchFormValidationData({ ...validationConfig, staff_registration: staffRegistration, localValidation, localValidationConfig }));
		validationConfig && setKey(createValidationHashKey(validationConfig));
	}, [validationConfig, localValidation]);

	return [validations, showLoading];
}
