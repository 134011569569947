import {
	RECEIVE_CONSUMPTION_PRODUCTS,
	RECEIVE_CONSUMPTION_TAGS,
	RECEIVE_CONSUMPTION_CATEGORIES,
	RECEIVE_CONSUMPTION_PRODUCT_INFO,
	RECEIVE_CONSUMPTION_BUNDLE_INFO,
	RECEIVE_CONSUMPTION_PRODUCT_ANALYSIS,
	RECEIVE_CONSUMPTION_FILES_ANALYSIS
} from 'constants/ActionTypes';
import {
	LOADING_CONSUMPTION_PRODUCTS,
	LOADING_CONSUMPTION_TAGS,
	LOADING_CONSUMPTION_CATEGORIES,
	LOADING_CONSUMPTION_CONFIG,
	RECEIVE_CONSUMPTION_CONFIG,
} from '../actions';

const initialState = {
	products: [],
	productsStatus: 'idle',
	tags: [],
	tagsStatus: 'idle',
	tagsMap: {},
	categories: [],
	categoriesStatus: 'idle',
	categoriesMap: {},
	productInfoMap: {},
	bundleInfoMap: {},
	config: null,
	configStatus: 'idle',
	productAnalaysisStatus: 'idle',
	fileAnalaysisStatus: 'idle'
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case LOADING_CONSUMPTION_CONFIG:
			return { ...state, configStatus: 'loading' };

		case RECEIVE_CONSUMPTION_CONFIG: {
			if (payload.success === 0) return { ...state };
			const config = { downloadPdf: payload.data.enable_product_download ?? false };
			return { ...state, config, configStatus: 'success' };
		}

		case LOADING_CONSUMPTION_PRODUCTS:
			return { ...state, productsStatus: 'loading' };

		case RECEIVE_CONSUMPTION_PRODUCTS: {
			const products = payload.data.map(product => ({
				id: product.product_id,
				uuid: product.product_uuid,
				title: product.product_label,
				description: product.description,
				image: product.image_url,
				categoryId: product.product_categoryid,
				tagIds: product.tags ?? [],
				isSuperBundle: product.super_bundle ?? false,
				isBundle: product.bundle ?? false,
				childrenIds: product.children?.map(childId => parseInt(childId)) ?? [],
				parentId: null,
				grandparentId: null,
				files: (typeof product.config_json === 'string'
					? JSON.parse(product.config_json)?.files_array
					: product.config_json?.files_array) ?? [],
				authorInfo: (typeof product.config_json === 'string'
					? JSON.parse(product.config_json)?.author_info_array
					: product.config_json?.author_info_array) ?? [],
			}));

			const parentMap = new Map();

			// compute parents
			for (const product of products)
				for (const childId of product.childrenIds)
					if (!parentMap.has(childId)) parentMap.set(childId, product.id);

			// assign parent to each product
			for (const product of products) product.parentId = parentMap.get(product.id);

			// compute grandparents
			for (const [productId, parentId] of parentMap.entries()) {
				const grandparentId = parentMap.get(parentId);
				if (grandparentId) parentMap.set(productId, grandparentId);
			}

			// assign grandparent to each product
			for (const product of products) product.grandparentId = parentMap.get(product.id);

			return {
				...state,
				products,
				productsStatus: 'success',
			};
		}

		case LOADING_CONSUMPTION_TAGS:
			return { ...state, tagsStatus: 'loading' };
		case RECEIVE_CONSUMPTION_TAGS: {
			const tags = payload.data;

			// map id -> label
			const tagsMap = {};
			for (const tag of tags) tagsMap[tag.id] = tag.label;

			return {
				...state,
				tags,
				tagsMap,
				tagsStatus: 'success',
			};
		}

		case LOADING_CONSUMPTION_CATEGORIES:
			return { ...state, categoriesStatus: 'loading' };
		case RECEIVE_CONSUMPTION_CATEGORIES: {
			const categories = payload.data;

			// map id -> label
			const categoriesMap = {};
			for (const category of categories)
				categoriesMap[category.category_id] = category.category_label;

			return {
				...state,
				categories,
				categoriesMap,
				categoriesStatus: 'success',
			};
		}
		case RECEIVE_CONSUMPTION_PRODUCT_INFO: {
			const { results, uuid } = payload;

			const productInfo = {
				id: results.data.product_id,
				uuid: results.data.product_uuid,
				title: results.data.product_label,
				description: results.data.product_config?.description,
				tagIds: results.data.product_config?.tags ?? [],
				files:
					results.data.product_config?.files_array.map(file => ({
						title: file.title,
						type: file.type,
						url: file.url,
						duration: file.duration,
						download: file.download ?? state.config?.downloadPdf ?? false, // config overwrite if exists
					})) ?? [],
			};

			return {
				...state,
				productInfoMap: {
					...state.productInfoMap,
					[uuid]: productInfo,
				},
			};
		}
		case RECEIVE_CONSUMPTION_BUNDLE_INFO: {
			const { results, uuid } = payload;

			let bundleInfo = results.data.map(child => ({
				id: child.product_id,
				uuid: child.product_uuid,
				title: child.product_label,
				description: child.product_config?.description,
				tagIds: child.product_config?.tagid ?? [],
				childrenIds: child.product_children ?? [],
				children: [],
			}));

			const childrenIds = [];
			// aggregate children into parents
			for (const product of bundleInfo) {
				for (const childId of product.childrenIds) {
					const child = bundleInfo.find(b => b.id === childId);
					if (!child) {
						console.error(`bundle ${product.id} has invalid childId`);
						continue;
					}
					childrenIds.push(childId);
					product.children.push(child);
				}
			}

			// remove childrens
			bundleInfo = bundleInfo.filter(product => !childrenIds.includes(product.id));

			return {
				...state,
				bundleInfoMap: {
					...state.bundleInfoMap,
					[uuid]: bundleInfo,
				},
			};
		}
		case RECEIVE_CONSUMPTION_PRODUCT_ANALYSIS: {
			return { ...state, productAnalaysisStatus: 'success' };
		}
		case RECEIVE_CONSUMPTION_FILES_ANALYSIS: {
			return { ...state, fileAnalaysisStatus: 'success' };
		}
		default:
			return state;
	}
};
