import React, { useEffect, useState } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import auth from '../../../loginAuth/loginAuth';
import { Col, Row, Button, Form, Card, Steps, Alert, message } from 'antd';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import PayNow from '../../Common/PayNow';
import RegistrationItems from '../../Common/RegistrationItems';
import PaymentOptions from '../../Common/PaymentOptions';
import FreeItemPayment from '../../Common/FreeItemPayment';
import ContactInfo from '../../UpdateAccount/ContactInfo';
import CheckoutSignIn from '../CheckoutSignin/CheckoutSignin';
import ShippingDetails from '../ShippingDetails/ShippingDetails';
import { getSelectionRoute, getSelectionPublicRoute } from '../Store/helpers';
import { getRegistrationRoute, getRegistrationPublicRoute } from '../../Registration/helpers.js';

import {
	requestDeleteCartItem,
	requestDeleteCartItemNoLogin,
	requestPaymentSettings,
	requestCartInfo,
	requestAttenuuidInfo,
	requestRequestAddItemApiNoLogin,
	requestPublicToPrivateCart,
	requestAddSelectionCart,
	requestSelectionArray,
	requestResetCustomCheckoutComponent,
	requestDeleteNonProcessedPayment,
	requestPrevScreen,
} from '../../../appRedux/actions/Ecommerce';
import {
	requestRegisterProfileFields,
	requestProfileInitialValues,
	requestProfileData,
} from '../../../appRedux/actions/LoginPageInfo';

import Widget from '../../Widget';
import CircularProgress from '../../CircularProgress';

import FormComponent from '../../form/index';
import { parseScreen } from '../../../util/form';

import { ReactComponent as BagIcon } from '../../../assets/icons/bag.svg';
const { Step } = Steps;
//PaymentItemsArray for existing payments
const FreeCheckout = props => {
	const dispatch = useDispatch();
	const appdir = props.initialData.appdir;
	const forceFullProfileUpdate =
		props.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject
			?.checkout_force_full_profile_update ?? false;
	const [profileUpdateComplete, setProfileUpdateComplete] = useState(() => {
		return forceFullProfileUpdate ? false : true;
	});
	const [manageProfileProcessed, setManageProfileProcessed] = useState(
		profileUpdateComplete ? true : false,
	);
	const [maintainSignInStep, setMaintainSignInStep] = useState(false);
	const [loader, setLoader] = useState(true);
	const [profileUpdateLoader, setProfileUpdateLoader] = useState(true);

	const { moduleUrlId } = useParams();
	const [formControl] = Form.useForm();
	const [validate, setValidate] = useState(false);
	const [addItemPayload, setAddItemPayload] = useState('');
	const [hiddenValidate, setHiddenValidate] = useState(true);
	const [formState, setFormState] = useState([]);
	const [simpleinvoiceobj, setSimpleinvoiceobj] = useState({}); // this is the initial values for the form component
	const [skipCheckoutForm, setSkipCheckoutForm] = useState(false);
	const history = useHistory();
	const [currentPaymentMethod, setCurrentPaymentMethod] = useState('');
	const [paymentResult, setPaymentResult] = useState(false);
	const [freePaymentResult, setFreePaymentResult] = useState(false);
	const [questionFormRequired, setQuestionFormRequired] = useState(false);

	const loading = false;

	const attenuuid = useSelector(state => state.ecommerce.attenuuid);
	const cartUuid = useSelector(state => state.ecommerce.cartUuid);
	const cartItems = useSelector(state => state.ecommerce.cartItems);
	const [shippingInfoRequired, setShippingInfoRequired] = useState(() => {
		let infoRequired = false;
		cartItems.InvoiceItemArray &&
			cartItems.InvoiceItemArray.forEach(item => {
				if (item.digitalgood == false || item.digitalgood == 0) {
					infoRequired = true;
				} else if (item.print_price == true || item.print_price == 1) {
					infoRequired = true;
				}
			});
		return infoRequired;
	});
	const [shippingDetailsComplete, setShippingDetailsComplete] = useState(() => {
		return shippingInfoRequired ? false : true;
	});
	const [shippingDetailsProcessed, setShippingDetailsProcessed] = useState(
		shippingDetailsComplete ? true : false,
	);
	const languageObject = useSelector(
		state => state.ecommerce.attendeeSettings?.pagelanguagestruct,
	);
	const paymentSettings = useSelector(state => state.ecommerce.paymentSettings);
	const attendeeStatus = useSelector(state => state.ecommerce.status);
	const paymentSuccess = useSelector(state => state.common?.paymentProgress.SUCCESS ?? false);
	const attenuuidInfoFetched = useSelector(state => state.ecommerce.attenuuidInfoFetched);

	const publicEmbed =
		useSelector(state => state.settings.publicEmbed) ||
		window.location.search.includes('public_embed=1'); // on the refresh the redux doesn't set public_embed, need windows.location.search for safety

	const module = window.location.pathname.includes('registration') ? 'registration' : '';

	const jwt = publicEmbed ? props.auth.jwt : JSON.parse(auth.getCookie('jwt'));
	const [currentStep, setCurrentStep] = useState(0);
	const [currentStepID, setCurrentStepID] = useState('sign_in');
	const [steps, setSteps] = useState([]);

	const deleteItem = product => {
		if (publicEmbed && !attenuuid) {
			let tempInvoiceItemArray = cartItems.InvoiceItemArray;
			tempInvoiceItemArray = tempInvoiceItemArray.filter(function (obj) {
				return obj.field_id !== product.fieldId;
			});

			let newCartItems = [];
			for (let i = 0; i < tempInvoiceItemArray.length; i++) {
				let currProduct = {};
				currProduct[tempInvoiceItemArray[i].field_name] = {
					valueid: tempInvoiceItemArray[i].value_id,
					qty: 1,
				};

				newCartItems.push(currProduct);
			}

			let formData = new FormData();
			formData.append('itemstoadd', JSON.stringify(newCartItems));
			formData.append('cartUuid', JSON.stringify(cartUuid));

			dispatch(
				requestRequestAddItemApiNoLogin({
					appdir,
					moduleUrlId,
					formData,
					cartUuid,
				}),
			);

			dispatch(requestDeleteCartItemNoLogin(product));
		} else {
			let myProduct = {};
			myProduct['field_id'] = product.fieldId;
			myProduct['invoice_id'] = product.invoiceId;
			myProduct = JSON.stringify(myProduct);

			let formData = new FormData();
			formData.append('itemstodelete', myProduct);

			dispatch(
				requestDeleteCartItem({
					appdir,
					moduleUrlId,
					attenuuid,
					formData,
				}),
			);
		}
	};

	const clickBackToSelection = publicEmbed => {
		if (module === 'registration') {
			dispatch(requestPrevScreen());
			history.push(
				publicEmbed
					? getRegistrationPublicRoute(moduleUrlId)
					: getRegistrationRoute(moduleUrlId),
			);
		} else {
			history.push(
				publicEmbed ? getSelectionPublicRoute(moduleUrlId) : getSelectionRoute(moduleUrlId),
			);
		}
	};

	const submitSpecialCheckoutForm = () => {
		let formData = new FormData();
		formData.append('itemstoadd', JSON.stringify(addItemPayload));
		//make this in effects or sagas, make an action in add it
		//in the cartprogress is getting data from redux

		dispatch(
			requestAddSelectionCart({
				appdir: appdir,
				moduleUrlId: moduleUrlId,
				attenuuid: attenuuid,
				formData: formData,
				checkoutForm: 1,
			}),
		);
	};

	const onValidationChange = (validations, all) => {
		setHiddenValidate(validations.error);
		if (validations.error === false) {
			setValidate(validations.error);
		}
		setFormState(all);

		let itemsToAdd = {};
		for (let key in all) {
			if (all[key] != undefined) {
				itemsToAdd[key] = {
					valueid: all[key],
					qty: 1,
				};
			}
		}

		setAddItemPayload(itemsToAdd);
	};

	const clickSkipCheckoutForm = () => {
		setSkipCheckoutForm(true);
	};

	const checkoutFormGoBack = () => {
		setSkipCheckoutForm(false);
		dispatch(requestResetCustomCheckoutComponent(props));
	};

	const deleteNonProcessedPayment = paymentId => {
		let formData = new FormData();
		formData.append('paymentid', JSON.stringify(paymentId));

		dispatch(requestDeleteNonProcessedPayment({ moduleUrlId, attenuuid, formData }));
	};

	const generateSteps = () => {
		let tempSteps = [];

		if (steps.length > 0) {
			let allKeys = steps.map(step => step.key);

			if (allKeys.includes('sign_in') || (!jwt && publicEmbed) || maintainSignInStep) {
				tempSteps.push({
					key: 'sign_in',
					title: languageObject?.Checkout_MenuSignIn ?? 'Sign In',
					description: languageObject?.Checkout_MenuSignInHelp ?? 'Login to your account',
				});
			}
			if (allKeys.includes('manage_profile') || forceFullProfileUpdate) {
				tempSteps.push({
					key: 'manage_profile',
					title: languageObject?.Checkout_MenuManageProfile ?? 'Manage Profile',
					description:
						languageObject?.Checkout_MenuManageProfileHelp ?? 'Update your Profile',
				});
			}
			if (allKeys.includes('shipping_details') || (jwt && shippingInfoRequired)) {
				tempSteps.push({
					key: 'shipping_details',
					title: languageObject?.Checkout_MenuShippingDetails ?? 'Shipping Details',
					description:
						languageObject?.Checkout_MenuShippingDetailsHelp ??
						'Enter address for shipping the items',
				});
			}
			tempSteps.push({
				key: 'checkout_payment',
				title: languageObject?.Checkout_MenuCheckout ?? 'Checkout & Payment',
				description:
					languageObject?.Checkout_MenuCheckoutHelp ?? 'Edit Cart items and make payment',
			});
		} else {
			if (!jwt && publicEmbed) {
				tempSteps.push({
					key: 'sign_in',
					title: languageObject?.Checkout_MenuSignIn ?? 'Sign In',
					description: languageObject?.Checkout_MenuSignInHelp ?? 'Login to your account',
				});
			}
			if (forceFullProfileUpdate) {
				tempSteps.push({
					key: 'manage_profile',
					title: languageObject?.Checkout_MenuManageProfile ?? 'Manage Profile',
					description:
						languageObject?.Checkout_MenuManageProfileHelp ?? 'Update your Profile',
				});
			}
			if (jwt && cartItems && shippingInfoRequired) {
				tempSteps.push({
					key: 'shipping_details',
					title: languageObject?.Checkout_MenuShippingDetails ?? 'Shipping Details',
					description:
						languageObject?.Checkout_MenuShippingDetailsHelp ??
						'Enter address for shipping the items',
				});
			}
			tempSteps.push({
				key: 'checkout_payment',
				title: languageObject?.Checkout_MenuCheckout ?? 'Checkout & Payment',
				description:
					languageObject?.Checkout_MenuCheckoutHelp ?? 'Edit Cart items and make payment',
			});
		}

		let foundStep = false;
		for (let i = 0; i < tempSteps.length; i++) {
			if (tempSteps[i].key == currentStepID) {
				foundStep = true;

				setCurrentStep(i);
				setCurrentStepID(tempSteps[i].key);
				break;
			}
		}
		if (!foundStep) {
			setCurrentStep(0);
			setCurrentStepID('sign_in');
		}

		setSteps(tempSteps);
	};

	const goNextStep = () => {
		let completedSteps = [];

		for (let i = 0; i < currentStep; i++) {
			completedSteps.push(steps[i].key);
		}

		if (!jwt && !completedSteps.includes('sign_in')) {
			//sign_in not complete
			//Find step index for sign_in in steps list
			let index = 0;
			for (let i = 0; i < steps.length; i++) {
				if (steps[i].key == 'sign_in') {
					index = i;
				}
			}
			setCurrentStep(index);
			setCurrentStepID('sign_in');
			return;
		} else if (!profileUpdateComplete && !completedSteps.includes('manage_profile')) {
			//Manage profile complete
			//Find step index for manage_profile in steps list
			let index = 0;
			for (let i = 0; i < steps.length; i++) {
				if (steps[i].key == 'manage_profile') {
					index = i;
				}
			}
			setCurrentStep(index);
			setCurrentStepID('manage_profile');
			return;
		} else if (!shippingDetailsComplete && !completedSteps.includes('shipping_details')) {
			//shipping details complete
			//Find step index for shipping_details in steps list
			let index = 0;
			for (let i = 0; i < steps.length; i++) {
				if (steps[i].key == 'shipping_details') {
					index = i;
				}
			}
			setCurrentStep(index);
			setCurrentStepID('shipping_details');
			return;
		} else if (!paymentSuccess && !completedSteps.includes('checkout_payment')) {
			//Checkout and Payment step complete
			//Find step index for payment and checkout in steps list
			let index = 0;
			for (let i = 0; i < steps.length; i++) {
				if (steps[i].key == 'checkout_payment') {
					index = i;
				}
			}
			setCurrentStep(index);
			setCurrentStepID('checkout_payment');
			return;
		}
	};

	useEffect(() => {
		if (!attenuuid) {
			dispatch(requestAttenuuidInfo({ appdir, moduleUrlId }));
		}
		if (cartItems?.simpleinvoiceobj && attenuuid) {
			setSimpleinvoiceobj(cartItems.simpleinvoiceobj);
		}
		generateSteps();
	}, []);

	useEffect(() => {
		if (props.initialData.registerProfileFields && props.initialData.profileInitialValues) {
			setProfileUpdateLoader(false);
		}
		if (
			props.initialData.userUpdatedMsg === 'User has been updated' &&
			manageProfileProcessed
		) {
			setProfileUpdateComplete(true);
		}
	}, [props.initialData]);

	useEffect(() => {
		generateSteps();
		if (!paymentSettings || !Object.keys(paymentSettings).length) {
			if (jwt) {
				dispatch(
					requestPaymentSettings({
						moduleUrlId,
					}),
				);
			}
			if (publicEmbed && jwt && !attenuuid) {
				dispatch(
					requestAttenuuidInfo({
						appdir,
						moduleUrlId,
					}),
				);
			}
		}
		//Required for Manage Profile
		if (forceFullProfileUpdate && !profileUpdateComplete && jwt) {
			if (!props.initialData.registerProfileFieldsFetched) {
				dispatch(requestRegisterProfileFields({ appdir: appdir }));
			}

			if (!props.initialData.profileInitialValuesFetched) {
				dispatch(requestProfileInitialValues());
			}

			if (!props.initialData.reviewerID && attenuuid) {
				dispatch(
					requestProfileData({
						source_hex: JSON.parse(auth.getCookie('source_hex')),
						contact_uuid: JSON.parse(auth.getCookie('contact_uuid')),
					}),
				);
			}
		}

		if (cartItems && Object.keys(paymentSettings).length !== 0) {
			goNextStep();
			setLoader(false);
		}

		if (cartItems?.simpleinvoiceobj && attenuuid) {
			setSimpleinvoiceobj(cartItems.simpleinvoiceobj);
		}
		if (jwt || cartItems) {
			goNextStep();
		}
	}, [cartItems, jwt, paymentSettings]);

	useEffect(() => {
		if (!attenuuid) return;

		if (publicEmbed && jwt) {
			dispatch(
				requestPublicToPrivateCart({
					moduleUrlId,
					cartUuid,
					attenuuid,
				}),
			);
		}

		// coming from public embed, need to first call publicToPrivate api first before we call this
		// if coming from normal, we call this right away
		if (!publicEmbed) {
			dispatch(
				requestCartInfo({
					appdir,
					moduleUrlId,
					attenuuid,
				}),
			);
		}
		goNextStep();
	}, [attenuuid, profileUpdateComplete, jwt]);

	useEffect(() => {
		const questions = props.ecommerce.menuObjects[0]?.fieldids;

		if (!questions) return;
		if (questions.some(question => question.required === 1)) setQuestionFormRequired(true);
	}, [props.ecommerce.menuObjects]);

	useEffect(() => {
		if (props.ecommerce.shippingDetailsStored && shippingDetailsProcessed) {
			setShippingDetailsComplete(true);
		}
	}, [props.ecommerce.shippingDetails]);

	useEffect(() => {
		if (shippingDetailsComplete) {
			goNextStep();
		}
	}, [shippingDetailsComplete]);

	return (
		<div className="container">
			{loader ||
				(loading && (
					<Widget styleName="gx-card">
						<CircularProgress className="gx-profile-content" />
					</Widget>
				))}

			{
				<Button
					className="button-primary gx-font-weight-semi-bold shadow-2 gx-mb-3"
					shape="round"
					onClick={() => clickBackToSelection(publicEmbed)}
				>
					Continue Shopping
				</Button>
			}
			{steps.length > 0 && (
				<Steps progressDot current={currentStep}>
					{steps.map(item => (
						<Step key={item.key} title={item.title} description={item.description} />
					))}
				</Steps>
			)}
			{steps.length > 0 && (
				<>
					<Row align="center">
						{steps[currentStep].key == 'sign_in' && !jwt ? (
							<CheckoutSignIn requestMaintainSignInStep={setMaintainSignInStep} />
						) : (
							<></>
						)}
					</Row>
					<Row align="center">
						{steps[currentStep].key == 'manage_profile' ? (
							profileUpdateLoader ? (
								<Widget styleName="gx-card">
									<CircularProgress className="gx-profile-content" />
								</Widget>
							) : (
								<ContactInfo
									Redirection="false"
									requestManageProfileStatus={setManageProfileProcessed}
								/>
							)
						) : (
							<></>
						)}
					</Row>
					<Row align="center">
						{steps[currentStep].key == 'shipping_details' ? (
							<ShippingDetails
								requestShippingDetailsStatus={setShippingDetailsProcessed}
							/>
						) : (
							<></>
						)}
					</Row>
					<Row>
						{steps[currentStep].key == 'checkout_payment' ? (
							<>
								{!paymentSuccess ? (
									<>
										<Col xl={12} lg={12} md={24} sm={24} xs={24}>
											{cartItems ? (
												<div>
													<Card className="card">
														<div>
															<div>
																<div
																	style={{
																		float: 'left',
																		paddingTop: '2%',
																	}}
																	className="gx-text-secondary gx-font-weight-semi-bold gx-page-title gx-ml-1 gx-mt-1"
																>
																	{props.languageObject?.title ??
																		'Checkout'}
																</div>
																<BagIcon
																	className="fg-primary-dark-light"
																	style={{
																		float: 'right',
																		width: '3em',
																	}}
																/>
															</div>

															<RegistrationItems
																languageObject={{
																	invoice_items_header:
																		languageObject?.Checkout_ItemsHeader ??
																		'',
																	invoice_amount_header:
																		languageObject?.Checkout_InvoiceStatementTotalAmount ??
																		'',
																	total_invoiced_header:
																		languageObject?.Checkout_TotalInvoiceAmount ??
																		'',
																	payments_header:
																		languageObject?.Checkout_PaymentsReceived ??
																		'',
																	non_processed_payment:
																		languageObject?.Checkout_NotProcessed ??
																		'',
																	net_owing_header:
																		languageObject?.Checkout_NetOwing ??
																		'',
																	pay_now_by:
																		languageObject?.Checkout_PayNowBy ??
																		'',
																	title: 'Check Out',
																}}
																cartItems={
																	props.ecommerce.oldCartItems
																		? props.ecommerce
																				.oldCartItems
																		: props.ecommerce.cartItems
																}
																paymentSettings={paymentSettings}
																onDelete={deleteItem}
																onDeletePayment={
																	deleteNonProcessedPayment
																}
																publicUrlCheck={publicEmbed}
																renderPaymentComponent={
																	setCurrentPaymentMethod
																}
															/>
														</div>

														{Object.entries(simpleinvoiceobj).length &&
														parseInt(cartItems?.NetOwing) === 0 &&
														profileUpdateComplete &&
														shippingDetailsComplete ? (
															<div className="gx-mt-5">
																<FreeItemPayment
																	moduleUrlId={moduleUrlId}
																	attenuuid={attenuuid}
																	moduleIdentifier={'ecommerce'}
																	paymentSettings={
																		paymentSettings
																	}
																	returnFreePaymentApproved={
																		setFreePaymentResult
																	}
																/>
															</div>
														) : (
															''
														)}

														<>
															<div className="gx-d-flex gx-flex-column">
																{!questionFormRequired && (
																	<div className="gx-ml-4">
																		{!skipCheckoutForm &&
																			props.ecommerce
																				.specialCheckoutFormSubmitted ===
																				0 &&
																			Object.entries(
																				simpleinvoiceobj,
																			).length &&
																			parseInt(
																				cartItems?.NetOwing,
																			) != 0 &&
																			jwt &&
																			profileUpdateComplete &&
																			shippingDetailsComplete &&
																			props.common
																				.paymentSuccess !==
																				1 && (
																				<Button
																					type="link"
																					className={
																						props
																							.ecommerce
																							.openShoppingStyle &&
																						props
																							.ecommerce
																							.menuObjects
																							.length >
																							0 &&
																						props
																							.ecommerce
																							.attenuuid &&
																						Object.entries(
																							simpleinvoiceobj,
																						).length > 0
																							? 'gx-text-black'
																							: 'gx-ml-5 gx-mt-4 button-primary gx-font-weight-semi-bold shadow-2'
																					}
																					shape={
																						props
																							.ecommerce
																							.openShoppingStyle &&
																						props
																							.ecommerce
																							.menuObjects
																							.length >
																							0 &&
																						props
																							.ecommerce
																							.attenuuid &&
																						Object.entries(
																							simpleinvoiceobj,
																						).length > 0
																							? ''
																							: 'round'
																					}
																					onClick={
																						clickSkipCheckoutForm
																					}
																				>
																					Proceed Directly
																					To Payment
																				</Button>
																			)}
																	</div>
																)}
																<div>
																	{props.ecommerce?.menuObjects[0]
																		?.screen_text &&
																		jwt &&
																		profileUpdateComplete &&
																		shippingDetailsComplete &&
																		props.ecommerce
																			.specialCheckoutFormSubmitted ===
																			0 &&
																		props.ecommerce.attenuuid &&
																		Object.entries(
																			simpleinvoiceobj,
																		).length > 0 &&
																		!skipCheckoutForm && (
																			<div
																				className="gx-ml-4 gx-mb-1"
																				dangerouslySetInnerHTML={{
																					__html:
																						props
																							.ecommerce
																							.menuObjects[0]
																							.screen_text,
																				}}
																			></div>
																		)}
																</div>
															</div>
															{/* if jwt !== '' and specialCheckout exist and has not been submitted yet */}
															{props.ecommerce.openShoppingStyle &&
																props.ecommerce.menuObjects.length >
																	0 &&
																jwt &&
																profileUpdateComplete &&
																shippingDetailsComplete &&
																props.ecommerce
																	.specialCheckoutFormSubmitted ===
																	0 &&
																props.ecommerce.attenuuid &&
																Object.entries(simpleinvoiceobj)
																	.length > 0 &&
																!skipCheckoutForm && (
																	<div className="gx-ml-4 gx-text-primary">
																		<FormComponent
																			form={formControl}
																			formData={parseScreen(
																				props.ecommerce
																					.menuObjects[0],
																			)}
																			validationConfig={{
																				module: 'attendee',
																				moduleurlid: moduleUrlId,
																				menuid:
																					props.ecommerce
																						.menuObjects[0]
																						.menuid,
																			}}
																			onChange={(
																				validations,
																				all,
																			) => {
																				onValidationChange(
																					validations,
																					all,
																				);
																			}}
																			validate={validate} // set as false initally, make it true when I need to validate. I just have to pass it true. It will validate by itself
																			secondaryColor={
																				'#808080'
																			}
																			textColor={'#404040'}
																		/>
																		<Button
																			className="gx-ml-auto button-primary gx-font-weight-semi-bold shadow-2"
																			shape="round"
																			onClick={
																				submitSpecialCheckoutForm
																			}
																		>
																			Submit
																		</Button>
																	</div>
																)}
														</>

														{(props.ecommerce
															.specialCheckoutFormSubmitted === 1 ||
															skipCheckoutForm) &&
															jwt &&
															profileUpdateComplete &&
															shippingDetailsComplete && (
																<Button
																	className="gx-ml-auto gx-mb-3 button-primary gx-font-weight-semi-bold shadow-2"
																	shape="round"
																	onClick={checkoutFormGoBack}
																>
																	Back
																</Button>
															)}
														{(props.ecommerce
															.specialCheckoutFormSubmitted === 1 ||
															skipCheckoutForm) &&
															Object.keys(paymentSettings).length !==
																0 &&
															jwt &&
															profileUpdateComplete &&
															shippingDetailsComplete && (
																<PaymentOptions
																	renderPaymentComponent={
																		setCurrentPaymentMethod
																	}
																	currentPaymentMethod={
																		currentPaymentMethod
																	}
																	cartItems={
																		props.ecommerce
																			.oldCartItems ||
																		props.ecommerce.cartItems
																	}
																	paymentSettings={
																		paymentSettings
																	}
																/>
															)}
													</Card>
												</div>
											) : (
												<Alert
													message="No Items Found"
													description="Please add items to the cart to purchase"
													type="warning"
												/>
											)}
										</Col>
									</>
								) : (
									<></>
								)}
								<Col
									xl={paymentSuccess ? 24 : 12}
									lg={paymentSuccess ? 24 : 12}
									md={24}
									sm={24}
									xs={24}
								>
									{currentPaymentMethod &&
										paymentSettings !== undefined &&
										Object.keys(paymentSettings).length !== 0 &&
										jwt &&
										(props.ecommerce.specialCheckoutFormSubmitted === 1 ||
											props.ecommerce.menuObjects?.length === 0 ||
											skipCheckoutForm ||
											paymentSuccess) && (
											<PayNow
												cartItems={
													Object.keys(props.ecommerce.cartItems)
														.length !== 0
														? props.ecommerce.cartItems
														: props.ecommerce.oldCartItems
												}
												paymentSettings={paymentSettings}
												netOwing={
													Object.keys(props.ecommerce.cartItems)
														.length !== 0
														? props.ecommerce.cartItems.NetOwing
														: props.ecommerce.oldCartItems.NetOwing
												}
												totalPaid={
													Object.keys(props.ecommerce.cartItems)
														.length !== 0
														? props.ecommerce.cartItems.TotalPayment
														: props.ecommerce.oldCartItems.TotalPayment
												}
												attenuuid={props.ecommerce.attenuuid}
												moduleIdentifier={'ecommerce'}
												appdir={props.initialData.appdir}
												currentPaymentMethod={currentPaymentMethod}
												returnPaymentApproved={setPaymentResult}
												publicEmbed={publicEmbed}
												status={attendeeStatus}
											/>
										)}
									{/* TODO: Need to check if we need to implement this same to Exhibitor Checkout and \src\components\Registration\Checkout\index.js */}
									{freePaymentResult && paymentSuccess && jwt && (
										<div className="gx-mt-5">
											<FreeItemPayment
												moduleUrlId={moduleUrlId}
												attenuuid={attenuuid}
												moduleIdentifier={'ecommerce'}
												paymentSettings={paymentSettings}
											/>
										</div>
									)}
								</Col>
							</>
						) : (
							<></>
						)}
					</Row>
				</>
			)}
		</div>
	);
};

const mapStateToProps = state => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
		ecommerce: myState.ecommerce,
		settings: myState.settings,
		common: myState.common,
	};
};

export default connect(mapStateToProps)(FreeCheckout);

// <DynamicSelectionCard
// selectionItems={selectionItems}
// fieldlabel={fieldlabel}
// />
