import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router';
import { Card, Typography, Avatar, Tag, Button, Alert, Space, Modal, Descriptions } from 'antd';
import MembershipModal from '@components/form/customLogicComponents/MembershipModal';
import LogoImage from '@components/Membership/LogoImage';
import {
	getCompanyState,
	getIsMemberGoodState,
} from '@components/Membership/helpers/generalHelpers';
import { EXPIRED, IN_RENEWAL_PERIOD } from '@components/Membership/helpers/constant';
import { isEmpty } from 'lodash';
import { requestToFetchRoles } from '@appRedux/actions';

const CompanyHeader = props => {
	const dispatch = useDispatch();
	const publicEmbed = useSelector(state => state.settings.publicEmbed);
	const [triggerModal, setTriggerModal] = useState(false);
	const [clickedGroupID, setClickedGroupID] = useState('');
	const [membershipState, setMembershipState] = useState({ message: 'Non-Member', color: 'error' });
	const [companyCreated, setCompanyCreated] = useState(false);
	const companyExpireDate = props.corporateMembership?.company?.expiry_date;
	const generalSettings = props.corporateMembership?.generalSettings;
	const showMembersModal =
		props.corporateMembership?.generalSettings?.corporate_membership?.company_members_modal
			?.show_individual_members_modal;

	const expirationState = getCompanyState({
		expiryDate: companyExpireDate,
		renewal: generalSettings.renewal_period_days,
	});
	const companyExpired = !companyExpireDate || expirationState === EXPIRED;
	const isCompanyInRenewalPeriod = expirationState === IN_RENEWAL_PERIOD;
	const isNewMember = !Boolean(props.corporateMembership?.company?.membership_type);
	const modalItem = props.corporateMembership?.menu
		.filter(obj => obj.screentype === 'SubMenu')[0]
		?.submenu[0]?.fieldids?.filter(obj => obj?.fieldtype === 'Multidrop');

	const appdir = useSelector(state => state.loginInfo.appdir);
	const sourceHex = useSelector(state => state.loginInfo.source_hex);
	const moduleUrlId = useSelector(state => state.loginInfo.corporateMembershipModuleUrlId);
	const corporateMembership = useSelector(state=> state.corporateMembership)
	const exhibitorCartDetailsFetched = useSelector(state=> state.corporateMembership.exhibitorCartDetailsFetched)
	const paymentSuccess = useSelector(state=> state.common.paymentSuccess)
	const [showPendingOrderModal, setShowPendingOrderModal] = useState(false);
	useEffect(() => {
		if(exhibitorCartDetailsFetched){
		if (
			!isEmpty(props.corporateMembership?.exhibitorCartDetails?.pending_payment_data) &&
			(companyExpired || isCompanyInRenewalPeriod)
		) {
			setMembershipState({ message: 'Pending', color: 'warning' });
		} else if (companyExpired) {
			setMembershipState({ message: 'Expired', color: 'error' });
		} else if (isCompanyInRenewalPeriod) {
			setMembershipState({ message: 'Renewal Required', color: 'warning' });
		} else {
			setMembershipState({ message: 'In Good Standing', color: 'success' });
		}
		}
	}, [companyExpired, companyExpireDate, isCompanyInRenewalPeriod, exhibitorCartDetailsFetched, paymentSuccess, corporateMembership.companyFetched]);

	useEffect(() => {
		if (!props.corporateMembership.userCompanyAffiliationFetched) return;
		if (!props.corporateMembership.userCompanyAffiliation?.comp_uuid) { return; }
		setCompanyCreated(true);
		if (!props.corporateMembership.roles?.length) {
			dispatch(
				requestToFetchRoles({
					compUuid: props.corporateMembership.userCompanyAffiliation.comp_uuid,
					sourceHex: sourceHex,
				}),
			);
		}
	}, [props.corporateMembership.userCompanyAffiliationFetched]);

	const onMemberAvatarClick = ({ event, grpID }) => {
		setClickedGroupID(grpID);
		setTriggerModal(true);
	};
	const closeModal = () => {
		setClickedGroupID('');
		setTriggerModal(false);
	};
	const loadAvatar = ({ contact_picture, uuid, firstName, groupID }) => (
		<Avatar
			onClick={e => onMemberAvatarClick({ event: e, grpID: groupID })}
			className="primary-background gx-pointer"
			src={contact_picture ? contact_picture : ''}
			key={uuid}
		>
			{firstName[0]}
		</Avatar>
	);

	const onDownloadInvoice = () => {
		// if (props.moduleIdentifier === 'ecommerce' || props.moduleIdentifier === 'registration') {
		// 	window.open(
		// 		`https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm?appdir=${appdir}&module=attendee&component=attendees&function=download_invoice&moduleurlid=${moduleUrlId}&attenuuid=${
		// 			props.attenuuid ? props.attenuuid : props.ecommerce.oldAttenuuid
		// 		}`,
		// 	);
		// } else if (props.moduleIdentifier === 'exhibitor') {
		// 	window.open(
		// 		`https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm?appdir=${appdir}&module=exhibitor&component=exhibitors&function=download_invoice&moduleurlid=${moduleUrlId}&compuuid=${props.compuuid}&exhibuuid=${props.exhibuuid}`,
		// 	);
		// }
		window.open(
			`https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm?appdir=${appdir}&module=exhibitor&component=exhibitors&function=download_invoice&moduleurlid=${moduleUrlId}&compuuid=${props.corporateMembership?.userCompanyAffiliation.comp_uuid}&exhibuuid=${props.corporateMembership?.exhibitorCartDetails?.pending_payment_data.exhib_uuid}`,
		);
	};

	const getRoleTypeWarning = (roleid) => {
		return generalSettings.role_type_warning.filter(role => `${role?.roleid}`.includes(roleid))
	}

	const getAllMemberRoles = (members) => {
		return [...new Set(members.map(({ roles }) => {
			return roles.map(role => role.roleid)
		}).flat())]
	}

	const getRequiredRoles = () => {
		let rolesRequired = [];
		if (props.corporateMembership?.roles.length && props.corporateMembership?.companyMembers.length) {
			let members = props.corporateMembership?.companyMembers.filter(data => data?.roles?.length);
			let roleIds = getAllMemberRoles(members);
			props.corporateMembership.roles.map(role => {
				if (!roleIds.includes(role?.roleid)) {
					rolesRequired.push(...getRoleTypeWarning(role?.roleid))
				}
			})
		}
		return rolesRequired
	}

	const renderRolesNotification = () => {
		if (companyCreated && generalSettings.role_type_warning && generalSettings.role_type_warning.length > 0) {
			return getRequiredRoles().length ? getRequiredRoles().map(data => {
				return <Alert message={data?.msg} type="warning" showIcon className='gx-ml-0 gx-mb-1 gx-pl-1 gx-pt-0 gx-pb-0 membership-plan'></Alert>
			}) : null
		}
		return null
	}
	const renderMembershipType = () => {
		if (props.corporateMembership.company.membership_type) {
			return <>
				<Typography className="fg-primary-gradient">
					{
						props.corporateMembership.company
							.membership_type
					}
				</Typography>
				<Tag
					className="gx-mb-0 gx-ml-1 no-border-radius"
					color={membershipState.color}
				>
					{membershipState.message}
				</Tag>
			</>
		}
		if(Object.keys(generalSettings).includes('HideNonMemberTag') && generalSettings.HideNonMemberTag ? false: true  ){
		return <Tag className="gx-mb-0 no-border-radius" color="error">
			Non-Member
		</Tag>
		}
	}

	const renderCompanyExpiryDate = () => {
		if (props.corporateMembership.company.expiry_date) {
			return <div className="gx-d-flex">
				<span className="gx-font-weight-medium">Expiry:</span>
				<Typography className="fg-primary-gradient gx-ml-1">
					{`${moment(
						props.corporateMembership.company.expiry_date,
					).format('MMM DD, YYYY')}`}
				</Typography>
			</div>
		}
		return null
	}

	const renderMembershipPending = () => {
		if (!isEmpty(props.corporateMembership?.exhibitorCartDetails?.pending_payment_data) && (companyExpired || isCompanyInRenewalPeriod)) {
			return <div className="gx-d-flex">
				<Alert
					message="Membership is pending the receipt of a payment."
					type="warning"
					action={
						<div>
							<Button
								size="small"
								type="text"
								onClick={() => {
									setShowPendingOrderModal(true);
								}}
								className="gx-mt-0 gx-mb-0"
							>
								View Details
							</Button>
						</div>
					}
					className="gx-ml-0 gx-pl-1 gx-pt-0 gx-pb-0"
					banner
					showIcon={false}
				></Alert>
			</div>
		}
		return null
	}

	const renderCompanyName = () => {
		if (props.corporateMembership.company.companyname) {
			return <h2 className="gx-font-weight-medium gx-mb-0">
				{props.corporateMembership.company.companyname}
				</h2>
		}
		return null
	}

	const renderLogo = () => {
		return <div style={{ width: '100px', height: '100px' }}>
			<LogoImage test="header" />
		</div>
	}

	const renderMemberGroupTypes = () => {
		if (!isNewMember && props.corporateMembership.generalSettings?.default_exhibitor_level_type !== 'Exhibitor' && props.corporateMembership.companyMembersFetched && props.corporateMembership.company?.memberships_available.length) {
			if (companyExpired || isCompanyInRenewalPeriod) {
				return null;
			}
			return <div className="gx-d-flex" style={{ flexWrap: 'wrap' }}>
				{props.corporateMembership.company.memberships_available.map(
					({ groupid, count, group_name }, inx) => {
						const membersOfThisGroup = props.corporateMembership.companyMembers.filter(
							({ groupID, expirationDate }) =>
								groupID === groupid &&
								getIsMemberGoodState({
									expiryDate: expirationDate,
									renewal: generalSettings.renewal_period_days,
								}),
						);
						let freeSlots = 0;
						let color = 'success';
						if (count) {
							freeSlots = count - membersOfThisGroup.length; // total slots available - occupied slots
							freeSlots = freeSlots < 0 ? 0 : freeSlots;
							if (!freeSlots) color = 'error';
							return membersOfThisGroup.length ? (
								<div key={inx} className="gx-p-3">
									<div className="gx-d-flex" style={{ flexWrap: 'wrap' }}>
										<Typography className="gx-font-weight-medium">
											{group_name}
										</Typography>
										{!generalSettings.no_individual_member_limit && (
											<Tag className="gx-ml-1 no-border-radius" color={color}>
												{freeSlots} Available
											</Tag>
										)}
									</div>

									<div className="gx-mt-2">
										{membersOfThisGroup.length ? (
											<Avatar.Group maxCount={2}>
												{membersOfThisGroup.map(loadAvatar)}
											</Avatar.Group>
										) : (
											<Typography.Text
												className="gx-font-weight-medium"
												type="success"
											>
												Members Not Assigned.
											</Typography.Text>
										)}
									</div>
								</div>
							) : null;
						} else return null;
					},
				)}
			</div>
		}
		return null
	}

	const renderCompanyAffiliateName = () => {
		if (!isNewMember && props.corporateMembership.company?.affiliate?.name) {
			return <div className="gx-d-flex  gx-mb-3 gx-mt-1">
				<span className="gx-font-weight-medium">Affiliate Of</span>
				<Typography className="fg-primary-gradient gx-ml-2">
					{props.corporateMembership.company?.affiliate?.name}
				</Typography>
			</div>
		}
		return null
	}

	const renderMembershipModal = () => {
		if (!isNewMember && props.corporateMembership.generalSettings?.default_exhibitor_level_type !== 'Exhibitor' && props.corporateMembership.company?.memberships_availablemodalItem && showMembersModal && modalItem && showMembersModal) {
			return <div className="gx-mb-3">
				<MembershipModal
					usegroupID={Boolean(clickedGroupID)}
					groupID={clickedGroupID}
					triggerModal={triggerModal}
					closeModal={closeModal}
				/>
			</div>
		}
	}

	const renderCompanyInfo = () => {
		if (props.corporateMembership.companyFetched) {
			return (
				<Card style={{ width: '100%' }} className="card card-shadow">
					<div
						style={{ flexWrap: 'wrap' }}
						className="gx-p-3 gx-d-flex gx-justify-content-between alternate-bg"
					>
						<div className="gx-d-flex">
							{renderLogo()}
							<div className="gx-pl-3 gx-pt-1">
								{renderCompanyName()}
								<div className="gx-mt-1 gx-d-flex" style={{ flexWrap: 'wrap' }}>
									{renderMembershipType()}
								</div>
								<div className="gx-mt-1">{renderCompanyExpiryDate()}</div>
								{!publicEmbed && (
									<div className="gx-mt-1">{renderMembershipPending()}</div>
								)}
								{!publicEmbed && (
									<div className="gx-mt-1">{renderRolesNotification()}</div>
								)}
							</div>
						</div>
						<div>
							{renderCompanyAffiliateName()}
							{/* {renderMembershipModal()} */}
						</div>
					</div>
					<div>{/* {renderMemberGroupTypes()} */}</div>
				</Card>
			);

		}
	}

	const renderPendingOrderModal = () => {
		if (showPendingOrderModal) {
			return <Modal
				title="Order Details"
				footer={null}
				visible={showPendingOrderModal}
				onCancel={() => setShowPendingOrderModal(false)}
				width="70%"
			>
				<div className="gx-d-flex">
					<Descriptions bordered className="gx-mt-2 gx-mb-2 gx-w-100">
						<Descriptions.Item label="Order">{`${props.corporateMembership.exhibitorCartDetails?.pending_payment_data.ordernumber}`}</Descriptions.Item>

						<Descriptions.Item label="Amount">{`$${props.corporateMembership.exhibitorCartDetails?.pending_payment_data.amount}`}</Descriptions.Item>

						<Descriptions.Item label="Date">{`${moment(
							props.corporateMembership.exhibitorCartDetails?.pending_payment_data
								.date_received,
						).format('MMM DD, YYYY')}`}</Descriptions.Item>
					</Descriptions>
					<br />
				</div>
				<div style={{ 'text-align': 'center' }}>
					<Button type="primary" onClick={() => onDownloadInvoice()}>Download Invoice</Button>
				</div>
			</Modal>
		}
		return null
	}

	return (
		<>
			{renderCompanyInfo()}
			{renderPendingOrderModal()}
		</>
	);
};

function mapStateToProps(state) {
	return {
		corporateMembership: state.corporateMembership,
		loginInfo: state.loginInfo,
		settings: state.settings,
		common: state.common,
	};
}

export default connect(mapStateToProps)(withRouter(CompanyHeader));
