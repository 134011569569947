import { all, call, fork, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import auth from 'loginAuth/loginAuth';

import {
	REQUEST_ECOMMERCE_STORE_PRODUCTS,
	REQUEST_ECOMMERCE_STORE_PRODUCT_PRICES,
	REQUEST_ATTENDEE_SETTINGS,
	REQUEST_ECOMMERCE_STORE_PRODUCT_TAGS,
} from 'constants/ActionTypes';
import {
	receiveStoreProducts,
	receiveStoreProductPrices,
	receiveAttendeeSettings,
	loadingAttendeeSettings,
	receiveStoreProductTags,
	loadingTags,
} from '../../appRedux/actions/EcommerceStore';

const api = 'https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm';

const authenticateUser = async ({ appdir, uuid, ssoToken }) => {
	const jwt = auth.getCookie('jwt').replace(/['"]+/g, '');
	if (jwt) return;
	if (!uuid || !ssoToken) return;
	const { data } = await axios.post(
		`${api}?appdir=${appdir}&module=login&component=login&function=authenticate_user`,
		{
			uuid,
			sso_token: ssoToken,
		},
	);
	auth.setCookie('contact_uuid', JSON.stringify(uuid ?? ''), 7);
	auth.setCookie('ssotoken', JSON.stringify(ssoToken ?? ''), 7);
	auth.setCookie('jwt', JSON.stringify(data.jwt ?? ''), 7);
};

const getSettings = async ({ appdir, moduleUrlId }) => {
	const jwt = auth.getCookie('jwt');
	const options = jwt
		? {
				headers: {
					Authorization: JSON.parse(jwt),
				},
		  }
		: {};
	return await axios.get(
		`${api}?appdir=${appdir}&module=attendee&component=attendee_site_settings&function=start&URLID=${moduleUrlId}`,
		options,
	);
};

const getProducts = async ({ appdir, moduleUrlId }) => {
	const jwt = auth.getCookie('jwt');
	const options = jwt
		? {
				headers: {
					Authorization: JSON.parse(jwt),
				},
		  }
		: {};
	return await axios.get(
		`${api}?appdir=${appdir}&module=attendee&component=ecommerce&function=get_products&URLID=${moduleUrlId}`,
		options,
	);
};

const getPrices = async ({ appdir, moduleUrlId, productIds }) => {
	const jwt = auth.getCookie('jwt');
	const options = jwt
		? {
				headers: {
					Authorization: JSON.parse(jwt),
				},
		  }
		: {};
	return await axios.get(
		`${api}?appdir=${appdir}&module=attendee&component=ecommerce&function=get_product_price&URLID=${moduleUrlId}&productid=${productIds.join(
			',',
		)}`,
		options,
	);
};

const getTags = async ({ source }) => {
	const jwt = auth.getCookie('jwt');
	const options = jwt
		? {
				headers: {
					Authorization: JSON.parse(jwt),
				},
		  }
		: {};
	return await axios.get(
		`${api}?source=${source}&module=client&component=tags&function=see_tags`,
		options,
	);
};

function* getProductsWorker({ payload }) {
	const fetched = yield select(state => state.ecommerce.storeProductsFetched);
	if (fetched) return;
	const query = yield select(state => state.router.location.query);
	yield call(authenticateUser, {
		appdir: payload.appdir,
		uuid: query.uuid,
		ssoToken: query.token,
	});
	const { data } = yield call(getProducts, payload);
	yield put(receiveStoreProducts(data));
}

export function* getProductsWatcher() {
	yield takeLatest(REQUEST_ECOMMERCE_STORE_PRODUCTS, getProductsWorker);
}

function* getPricesWorker({ payload }) {
	const { data } = yield call(getPrices, payload);
	yield put(receiveStoreProductPrices({ data, productIds: payload.productIds }));
}

export function* getPricesWatcher() {
	yield takeEvery(REQUEST_ECOMMERCE_STORE_PRODUCT_PRICES, getPricesWorker);
}

function* getSettingsWorker({ payload }) {
	const status = yield select(state => state.ecommerce.attendeeSettingsStatus);
	if (status === 'loading') return;
	yield put(loadingAttendeeSettings());
	const { data } = yield call(getSettings, payload);
	yield put(receiveAttendeeSettings(data));
}

export function* getSettingsWatcher() {
	yield takeEvery(REQUEST_ATTENDEE_SETTINGS, getSettingsWorker);
}

function* getTagsWorker({ payload }) {
	const status = yield select(state => state.ecommerce.tagsStatus);
	if (status === 'loading') return;
	yield put(loadingTags());
	const { data } = yield call(getTags, payload);
	yield put(receiveStoreProductTags(data));
}

export function* getTagsWatcher() {
	yield takeEvery(REQUEST_ECOMMERCE_STORE_PRODUCT_TAGS, getTagsWorker);
}

export default function* () {
	yield all([
		fork(getProductsWatcher),
		fork(getPricesWatcher),
		fork(getSettingsWatcher),
		fork(getTagsWatcher),
	]);
}
