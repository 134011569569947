import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, connect } from 'react-redux';
import FormComponent from '../../form';
import CircularProgress from '../../CircularProgress';
import CustomScrollbars from '../../../util/CustomScrollbars';
import Widget from '../../Widget';
import { parseScreen } from '../../../util/form';
import { Input, Card, Button, Form, notification, Modal, Checkbox } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import {
	requestRegisterProfileFields,
	requestCreateProfile,
} from '../../../appRedux/actions/LoginPageInfo';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const Register = props => {
	const firstname = props.initialData.firstName;
	const lastname = props.initialData.lastName;
	const email = props.initialData.email;
	const [checkboxToggle, setCheckboxToggle] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [validationLoading, setValidationLoading] = useState(true);

	const [loader, setLoader] = useState(true);
	const [formControl] = Form.useForm();
	const [validate, setValidate] = useState(false);
	const [formState, setFormState] = useState([]);
	const [submitLoad, setSubmitLoad] = useState(false);

	const [myPassword, setMyPassword] = useState('');
	const [myConfirmPassword, setMyConfirmPassword] = useState('');

	const [initialValues, setInitialValues] = useState({});
	const [finalValues, setFinalValues] = useState({});

	const dispatch = useDispatch();
	const history = useHistory();

	const [validationsObject, setValidationsObject] = useState({
		error: false,
		validationErrors: {},
	});

	const onPasswordChange = e => {
		setMyPassword(e.target.value);
	};

	const onConfirmPasswordChange = e => {
		setMyConfirmPassword(e.target.value);
	};

	const consentNotification = () => {
		notification['error']({
			message: 'Please agree to the system terms and conditions.',
		});
	};

	const passwordNotMatchNotification = () => {
		notification['error']({
			message: 'Your password does not match. Please try again.',
		});
	};
	const noPasswordNotification = () => {
		notification['error']({
			message: 'Please set your password.',
		});
	};

	const onCheckBoxChange = e => {
		setCheckboxToggle(e.target.checked);

		if (e.target.checked) {
			let tempValues = formState;
			tempValues.contact_consent = 1;
			setFinalValues(tempValues);
		} else {
			let tempValues = initialValues;
			tempValues.contact_consent = 0;
			setFinalValues(tempValues);
		}
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const showModal = () => {
		setIsModalVisible(true);
	};

	const submitUpdate = () => {
		if (myPassword !== myConfirmPassword) {
			passwordNotMatchNotification('error');
			setSubmitLoad(false);
		} else if (myPassword === '') {
			noPasswordNotification('error');
		} else {
			setSubmitLoad(true);
			let formData = new FormData();
			for (var key in formState) {
				formData.append(key, formState[key]);
			}

			if (checkboxToggle === true) {
				formData.append('contact_consent', 1);
				formData.append('returnQuery', 1);
				formData.append('Source', props.initialData.source_hex);
				formData.append('password', myPassword);

				// formData append password
				dispatch(
					requestCreateProfile({
						formData: formData,
						source_hex: props.initialData.source_hex,
					}),
				);
			} else {
				consentNotification('error');
				setSubmitLoad(false);
				formData.append('contact_consent', 0);
			}
		}
	};

	useEffect(() => {
		dispatch(requestRegisterProfileFields({ appdir: props.initialData.appdir }));
	}, []);

	useEffect(() => {
		let initialFormValues = {};
		initialFormValues['firstname'] = firstname;
		initialFormValues['lastname'] = lastname;
		initialFormValues['email'] = email;
		setInitialValues(initialFormValues);
	}, []);

	useEffect(() => {
		if (props.initialData.appdir === '') {
			history.push('/signin');
		}
	}, [props.initialData.appdir]);

	useEffect(() => {
		setSubmitLoad(false);
		if (props.initialData.userCreatedMsg === 'User created') {
			history.push('main/profilehome');
		}
	}, [props.initialData.userCreatedMsg]);

	useEffect(() => {
		if (props.initialData.registerProfileFields) {
			setLoader(false);
		}
	}, [props.initialData.registerProfileFields]);

	const parsed = useMemo(() => {
		if (
			props.initialData.registerProfileFields?.length !== 0 &&
			props.initialData.registerProfileFields
		) {
			return parseScreen({
				fieldids: props.initialData.registerProfileFields,
			});
		} else {
			return {};
		}
	}, [props.initialData.registerProfileFields]);

	return (
		<div>
			{loader || Object.keys(initialValues).length === 0 ? (
				<CircularProgress className="gx-profile-content" />
			) : (
				<div className="gx-app-login-container">
					{
						<div style={{ textAlign: 'center' }}>
							<img src={props.initialData.login_form.logo} />
						</div>
					}
					<CustomScrollbars className="gx-layout-sider-scrollbar">
						<Card
							className="gx-card"
							title="Create Profile"
							style={{ textAlign: 'left' }}
						>
							<div className="gx-mb-4">
								<div style={{ display: 'flex' }}>
									<Checkbox onChange={onCheckBoxChange}></Checkbox>
									<div
										className="gx-ml-2"
										dangerouslySetInnerHTML={{
											__html:
												props.initialData.consent_object.ConsentRowLabel,
										}}
									></div>
								</div>
								<Link onClick={showModal}>
									{props.initialData.consent_object.HyperlinkLabel}
								</Link>
								<Modal
									title={props.initialData.consent_object.DescriptionTitle}
									visible={isModalVisible}
									cancelButtonProps={{ style: { display: 'none' } }}
									okButtonProps={{ style: { display: 'none' } }}
									onCancel={handleCancel}
								>
									<div
										dangerouslySetInnerHTML={{
											__html:
												props.initialData.consent_object
													.ConsentDescriptiveText,
										}}
									></div>
								</Modal>
							</div>
							<FormComponent
								style={{ 'margin-left': '5%' }}
								initialValues={initialValues}
								form={formControl}
								formData={parsed}
								validationConfig={{
									module: 'contact',
									// moduleurlid: 'Rsi1dSW', // make it 0 ... 0 doesn't work
									// menuid: '', // don't need anything here
								}}
								onChange={(validations, all) => {
									setValidationsObject(validations);
									setFormState(all);
								}}
								validate={validate}
								validationLoading={val => setValidationLoading(val)}
								primaryColor={props.initialData.login_form.primary_color}
								secondaryColor={'#808080'}
								textColor={'#404040'}
								showLoading={true}
							/>
							{!validationLoading && (
								<div>
									<Form
										layout="vertical"
										className="gx-signin-form gx-form-row0"
										id="myForm"
									>
										<Form.Item
											initialValue={''}
											label={'Password'}
											rules={[
												{
													required: true,
													message: 'Please enter your password',
												},
												{
													min: 5,
													message:
														'Password must be minimum 5 characters',
												},
											]}
											name="password"
										>
											<Input.Password
												placeholder="Password"
												onChange={onPasswordChange}
												value={myPassword}
												iconRender={visible =>
													visible ? (
														<EyeTwoTone />
													) : (
														<EyeInvisibleOutlined />
													)
												}
												style={{ selection: 'white' }}
											/>
										</Form.Item>
										<Form.Item
											initialValue={''}
											label={'Confirm Password'}
											rules={[
												{
													required: true,
													message: 'Please enter your password',
												},
												{
													min: 5,
													message:
														'Password must be minimum 5 characters',
												},
											]}
											name="confirm_password"
										>
											<Input.Password
												placeholder="Confirm Password"
												onChange={onConfirmPasswordChange}
												value={myConfirmPassword}
												iconRender={visible =>
													visible ? (
														<EyeTwoTone />
													) : (
														<EyeInvisibleOutlined />
													)
												}
											/>
										</Form.Item>
									</Form>
									<Button
										onClick={submitUpdate}
										loading={submitLoad}
										className="login-form-button gx-mt-1"
										style={{
											backgroundColor: props.initialData
												? props.initialData.login_form.primary_color
												: '#33b4fd',
											color: '#fff',
											width: '100%',
										}}
										htmlType="submit"
									>
										Create Profile
									</Button>
								</div>
							)}
						</Card>
					</CustomScrollbars>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = state => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
	};
};

export default connect(mapStateToProps)(Register);
