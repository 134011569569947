import { useState, useEffect } from 'react';
import { debounce } from 'lodash';

/**
 *
 * @param {*} items Contains form item information. Refer to util/form.js
 * @param {*} initial initial data being passed(often from a separate API)
 * @returns {*} transformed initial values
 */
export const parseInitialValues = (items, initial) => {
	let transformed = {};
	initial &&
		items &&
		items.length &&
		items.map(item => {
			//checks whether the form item has content type, if yes then find that key('contentType') in the initialValues to replace it
			if (item.contentType) {
				transformed[item.name] = initial[item['contentType']];
			}
		});

	/* This check if there was any occurence of contentType.
	 * If not, there is not transformation needed. Use the original values
	 */
	if (!Object.keys(transformed).length) transformed = { ...initial };

	return transformed;
};

export default function useFormValues(initialValues, formItems) {
	const [values, setValues] = useState(parseInitialValues(formItems, initialValues));
	const [initial, setInitial] = useState(parseInitialValues(formItems, initialValues));

	//setting the initial values on change
	useEffect(() => {
		if (initialValues && values && values.length) {
			setInitial(parseInitialValues(formItems, initialValues));
		}
	}, [initialValues, values]);

	//adding debounce on setValues to not trigger on every small change.
	const setFormValues = debounce(
		newValues => {
			setValues({
				...values,
				...newValues,
			});
		},
		500,
		{
			trailing: true,
			maxWait: 1000,
		},
	);

	return [values, initial, setFormValues];
}
