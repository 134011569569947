import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestMenuData } from '@appRedux/actions';

export const useMenu = () => {
	const dispatch = useDispatch();
	const publicEmbed =
		useSelector(state => state.settings.publicEmbed) ||
		window.location?.search?.includes('public_embed=1');

	const appDir = useSelector(state => state.loginInfo.appdir);
	const compUuid = useSelector(
		state => state.corporateMembership.userCompanyAffiliation.comp_uuid,
	);
	const userCompanyAffiliationFetched = useSelector(
		state => state.corporateMembership.userCompanyAffiliationFetched,
	);

	const menu = useSelector(state => state.corporateMembership.menu);
	const generalSettings = useSelector(state => state.corporateMembership.generalSettings);
	const languageObject = useSelector(state => state.corporateMembership.languageObject);
	const jwt = useSelector(state => state.auth.jwt);

	const location = useSelector(state => state.router.location);
	const isTokenInUrl = location?.search?.includes('token');

	const exhibUuid = useSelector(
		state => state.corporateMembership.exhibitorCartDetails?.exhibUuid,
	);

	// loading is not fetched.
	const loading = !useSelector(state => state.corporateMembership.menuFetched);

	const moduleUrlId = useSelector(state => state.loginInfo.corporateMembershipModuleUrlId);
	useEffect(() => {
		if (userCompanyAffiliationFetched) {
			if (isTokenInUrl) {
				if (appDir && jwt && (publicEmbed || compUuid) && moduleUrlId) {
					dispatch(
						requestMenuData({
							appDir,
							urlId: moduleUrlId,
							compUuid,
							publicEmbed,
							exhibUuid,
							isTokenInUrl,
						}),
					);
				} else if (!jwt && appDir && publicEmbed && moduleUrlId) {
					dispatch(
						requestMenuData({
							appDir,
							urlId: moduleUrlId,
							compUuid,
							publicEmbed,
							exhibUuid,
							isTokenInUrl: '',
						}),
					);
				}
			} else {
				if (appDir && publicEmbed && moduleUrlId) {
					dispatch(
						requestMenuData({
							appDir,
							urlId: moduleUrlId,
							compUuid,
							publicEmbed,
							exhibUuid,
						}),
					);
				} else if (appDir && moduleUrlId && compUuid) {
					dispatch(
						requestMenuData({
							appDir,
							urlId: moduleUrlId,
							compUuid,
						}),
					);
				} else if (appDir && moduleUrlId) {
					dispatch(
						requestMenuData({
							appDir,
							urlId: moduleUrlId,
						}),
					);
				}
			}
		}
	}, [appDir, compUuid, publicEmbed, jwt, moduleUrlId]);
	return [loading, menu, generalSettings, languageObject];
};
