import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import auth from '../../../loginAuth/loginAuth';
import { Col, Row } from 'antd';

import NewProfileCard from './NewProfileCard';
import NotificationCenter from './NewNotificationCenter';

import ProfileHomeLinks from './ProfileHomeLinks';

import MessagesList from './MessagesList';
import {
	requestLoginFormData,
	requestProfileData,
	requestDeleteProfileMsg,
	requestToFetchNewsItems,
} from '../../../appRedux/actions/LoginPageInfo';

import { requestDeleteResetPasswordMsg } from '../../../appRedux/actions/Auth';
import ProfileSocialFeed from '../ProfileHome/ProfileSocialFeed';

import GoBack from '../../Common/GoBack';
import { requestResetScreenNum } from '../../../appRedux//actions/Ecommerce';
import { requestMenuData } from '../../../appRedux/actions';

const ProfileHome = props => {
	const [messagesView, setMessagesView] = useState(false);
	const [newsItemsCount, setNewsItemsCount] = useState(0);

	const dispatch = useDispatch();

	useEffect(() => {
		if (props.loginInfo.login_form === '') {
			dispatch(requestLoginFormData({ appdir: props.loginInfo.appdir }));
		}
		if (props.loginInfo.firstName == '' || props.loginInfo.firstName == undefined) {
			dispatch(
				requestProfileData({
					source_hex: JSON.parse(auth.getCookie('source_hex')),
					contact_uuid: JSON.parse(auth.getCookie('contact_uuid')),
				}),
			);
		}
	}, [props.loginInfo]);

	const enableMessagesView = isEnabled => {
		setMessagesView(isEnabled);
	};

	useEffect(() => {
		dispatch(requestDeleteProfileMsg());
		dispatch(requestDeleteResetPasswordMsg());
		dispatch(requestResetScreenNum(props));

		if (!props.loginInfo.newsItemsFetched) {
			dispatch(requestToFetchNewsItems());
		}
		if (props.loginInfo.appdir && props.loginInfo.corporateMembershipModuleUrlId) {
			dispatch(
				requestMenuData({
					appDir: props.loginInfo.appdir,
					urlId: props.loginInfo.corporateMembershipModuleUrlId,
				}),
			);
		}
	}, []);

	useEffect(() => {
		if (props.loginInfo.newsItems !== undefined) {
			setNewsItemsCount(props.loginInfo.newsItems.length);
		}
	}, [props.loginInfo.newsItems]);

	return (
		<div className="container">
			{newsItemsCount > 0 ? (
				<Row>
					<Col xl={14} lg={14} md={24} sm={24} xs={24} key={'profile_card'}>
						<NewProfileCard enableMessagesView={enableMessagesView} />
						<br />
						<br />

						{!messagesView && <ProfileHomeLinks />}
						{messagesView && (
							<div>
								<GoBack
									handleBackClick={() => {
										enableMessagesView(false);
									}}
								/>
								<MessagesList />
							</div>
						)}
					</Col>
					<Col xl={10} lg={10} md={24} sm={24} xs={24} key={'Notifications'}>
						{/* <ProfileSocialFeed /> */}

						{/* <NotificationCenter /> */}
					</Col>
				</Row>
			) : (
				''
			)}

			{newsItemsCount < 1 && (
				<div>
					<Row>
						<Col xl={14} lg={14} md={24} sm={24} xs={24}>
							<NewProfileCard enableMessagesView={enableMessagesView} />
						</Col>

						<Col xl={10} lg={10} md={24} sm={24} xs={24}>
							{/* {!messagesView && <ProfileHomeLinks />} */}
							{messagesView && (
								<div>
									<GoBack
										handleBackClick={() => {
											enableMessagesView(false);
										}}
									/>
									<MessagesList />
								</div>
							)}
						</Col>
					</Row>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		loginInfo: state.loginInfo,
	};
};

export default connect(mapStateToProps)(ProfileHome);
