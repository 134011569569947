import React, { useEffect, useState } from 'react';
import { Form } from 'antd';

interface Props {
	label: string;
	color: string;
	className?: string;
}

export default ({ label, color, className }: Props) =>
	!label || label === '' ? null : (
		<span
			dangerouslySetInnerHTML={{ __html: label }}
			style={{ fontSize: 14, margin: 0, color: color }}
			className={className}
		/>
	);
