import * as actionTypes from 'constants/ActionTypes'

export const fetchCompanyInfoRequest = (data) => ({
   type: actionTypes.FETCH_COMPANY_INFO_REQUEST,
   payload: data
})
export const fetchCompanyInfoSuccess = (data) => ({
   type: actionTypes.FETCH_COMPANY_INFO_SUCCESS,
   payload: data
})
export const fetchCompanyInfoFailure = (data) => ({
   type: actionTypes.FETCH_COMPANY_INFO_FAILURE,
   payload: data
})

export const fetchBoothConfigurationRequest = (data) => ({
   type: actionTypes.FETCH_BOOTH_CONFIGURATION_REQUEST,
   payload: data
})
export const fetchBoothConfigurationSuccess = (data) => ({
   type: actionTypes.FETCH_BOOTH_CONFIGURATION_SUCCESS,
   payload: data
})
export const fetchBoothConfigurationFailure = (data) => ({
   type: actionTypes.FETCH_BOOTH_CONFIGURATION_FAILURE,
   payload: data
})

export const updateExhibitorHashMap = (data) => ({
   type: actionTypes.UPDATE_EXHIBITOR_HASH_MAP,
   payload: data
})

export const handleBoothListLoader = (data) => ({
   type: actionTypes.HANDLE_BOOTH_LIST_LOADER,
   payload: data
})