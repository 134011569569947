import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { requestProducts, requestConfig } from 'appRedux/actions/Consumption';

export const useProducts = () => {
	const dispatch = useDispatch();
	const status = useSelector(state => state.consumption.productsStatus);
	const products = useSelector(state => state.consumption.products);
	const config = useSelector(state => state.consumption.config);
	const loading = status === 'loading' || status === 'idle';

	useEffect(() => {
		if (status === 'idle') dispatch(requestProducts());
		dispatch(requestConfig());
	}, [status]);

	return [loading, products, config];
};
