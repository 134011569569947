import firebase from 'firebase';

// Initialize Firebase
const config = {
	apiKey: 'AIzaSyAz-GPfA-hN74oFh3XvXsF9vQrlE5xpU10',
	authDomain: 'wieldy-4f59c.firebaseapp.com',
	databaseURL: 'https://wieldy-4f59c.firebaseio.com',
	projectId: 'wieldy-4f59c',
	storageBucket: 'wieldy-4f59c.appspot.com',
	messagingSenderId: '81949884261',
};

firebase.initializeApp(config);
const myAuth = firebase.auth();
const database = firebase.database();

export { database, myAuth };
