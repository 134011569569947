import {
	FORM_VALIDATION_FETCHING,
	FORM_VALIDATION_FETCH_SUCCESS,
	FORM_VALIDATION_FETCH_ERROR,
	UPDATE_FORM_VALIDATION_CONFIG,
} from '../../constants/ActionTypes';

export const formValidationFetching = (info, flag) => ({
	type: FORM_VALIDATION_FETCHING,
	info,
	flag,
});

export const formValidationFetchSuccess = (info, payload) => ({
	type: FORM_VALIDATION_FETCH_SUCCESS,
	info,
	payload,
});

export const formValidationFetchError = (info) => ({
	type: FORM_VALIDATION_FETCH_ERROR,
	info,
});

export const updateFormValidationData = (info) => ({
	type: UPDATE_FORM_VALIDATION_CONFIG,
	info,
});
