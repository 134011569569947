import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _reduce from 'lodash/reduce';
import _groupBy from 'lodash/groupBy';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import { PlayCircleOutlined, PictureOutlined, FilePdfOutlined, UserOutlined } from '@ant-design/icons';

import { FILE } from 'components/Common/constants';
import { useTags } from '../hooks/useTags';
import { useCategory } from '../hooks/useCategory';
import { useProducts } from '../hooks/useProducts';

import { Card, Image, Avatar } from 'antd';

const ProductCard = ({ product, goto }) => {
	const [, tagsMap] = useTags();
	const [, categoriesMap] = useCategory();
	const [prodsLoading, products] = useProducts();
	const [authorInfo, setAuthorInfo] = useState({});

	const category = categoriesMap[product.categoryId];
	const parentId = product.grandparentId ?? product.parentId;

	const { styling = {} } = useSelector(({ loginInfo }) => loginInfo);

	const bundleInfo = useMemo(() => products.find(prod => prod.id === parseInt(product.id)), [products, product.id]);

	useEffect(() => {
		if (product && product.authorInfo && product.authorInfo.length) {
			let data = _filter(product.authorInfo, (prod) => prod.presenter)[0];
			setAuthorInfo(data);
		}
	}, [product]);

	const getChildProducts = (bundle) => {
		let childData = [];
		if (bundle?.files?.length) {
			childData = [bundle];
		}
		if (bundle?.childrenIds?.length) {
			_forEach(_filter(products, (p) => bundle?.childrenIds.includes(p.id)), (p) => {
				childData = [...childData, ...getChildProducts(p)]
			});
		}
		return childData;
	};

	const constructItemCount = () => {
		const children = getChildProducts(bundleInfo);
		let stateCount = _reduce(children, (total, child) => {
			let contents = _groupBy(child.files, 'type');
			let temp = { ...total };
			_forEach(contents, (content, key) => {
				temp[key] = (total[key] || 0) + content.length;
			});
			return temp;
		}, {});
		return stateCount;
	};
	const productCount = useMemo(constructItemCount, [bundleInfo, products]);

	const parent = useMemo(
		() => !prodsLoading && parentId && products.find(product => product.id === parentId),
		[prodsLoading, parentId],
	);

	return (
		<Card className="card gx-mb-0 shadow-1 gx-pointer" bodyStyle={{ padding: '0px' }} onClick={() => goto(product, parent)}>
			{category && <span className="ribbon">{category}</span>}

			<div style={{ padding: '24px' }}>
				<div className="gx-d-flex" style={{ gap: "1.5em" }}>
					<div style={{ flex: '0 0 auto' }}>
						{product.image && (
							<>
								<Image
									className="card-image gx-my-2"
									style={{ width: '10em' }}
									alt="placeholder"
									src={product.image || require('assets/images/placeholder-image.png')}
									preview={false}
								/>
								{/* <hr className="gx-mb-0" /> */}
							</>
						)}
					</div>
					<div className="gx-d-flex gx-flex-column gx-h-100">
						<h4 className="fg-grey gx-mt-2 gx-font-weight-semi-bold">
							{product.title}
						</h4>

						{parent && (
							<p className="gx-text-primary gx-mt-1">Part of: {parent.title}</p>
						)}
						<div className="gx-d-flex gx-flex-1">
							{/* product description */}
							<p
								className="gx-mb-0"
								dangerouslySetInnerHTML={{
									__html: product.description,
								}}
							/>

						</div>

						{/* <Popover
					content={
						<p
							dangerouslySetInnerHTML={{
								__html: product.description,
							}}
							style={{
								maxWidth: '40em',
							}}
						></p>
					}
				>
					<h5 className="description-popover gx-mb-0 gx-mt-3">Description</h5>
				</Popover> */}

						{/* <div className="gx-mt-auto">
					{product.tagIds.map(tagId => (
						<Tag
							key={tagId}
							className="teaser-tag gx-text-center"
							style={{ whiteSpace: 'normal' }}
						>
							{tagsMap[tagId]}
						</Tag>
					))}
				</div> */}
					</div>
				</div>
				{/* <div className="gx-d-flex gx-justify-content-end">
					<Button
						size="large"
						className="button-secondary"
						onClick={goto}
					>
						View
					</Button>
				</div> */}
				<div style={{ marginLeft: "1.5em" }}>
					{!_isEmpty(authorInfo)
						? (<div className="gx-py-3 description-text gx-d-flex gx-align-items-center">
							<Avatar size="medium" src={authorInfo.picture} icon={<UserOutlined />} />
							<div className='gx-ml-2'>{`${authorInfo.firstname} ${authorInfo.lastname}`}</div>
							{authorInfo.degrees ? <div>{`, ${authorInfo.degrees}`}</div> : null}
						</div>)
						: null
					}
				</div>

			</div>
			{Object.keys(productCount).length ? (<div className="bottom-price-bar" style={{ backgroundColor: styling['primary-dark-color'] || "#993333" }}>
				<div className="gx-text-center gx-d-flex gx-align-items-center gx-justify-content-center">
					{Object.keys(productCount).length ? (<div>
						<span>
							{_map(productCount, (count, key) => (
								<span key={key}>
									{key === FILE.PDF &&
										<span className='gx-ml-3'><FilePdfOutlined /> {count} {key.toUpperCase()}</span>
									}
									{key === FILE.VIDEO &&
										<span className='gx-ml-3'><PlayCircleOutlined /> {count} {key}</span>
									}
									{key === FILE.IMAGE &&
										<span className='gx-ml-3'><PictureOutlined /> {count} {key}</span>
									}
								</span>
							))}
						</span>
					</div>) : null}
				</div>
			</div>) : null}
		</Card>
	);
};

export default ProductCard;
