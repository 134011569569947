import {
	FORM_DATA_FETCHING,
	FORM_DATA_FETCH_SUCCESS,
	FORM_DATA_FETCH_ERROR,
	SAVE_FORM_SCREEN_DATA,
} from 'constants/ActionTypes';
import { createHashKey } from '../../util/form';
const initialData = {
	data: {},
	userData: {},
};

const initialFetchData = {
	isFetching: false,
	isFetched: false,
	fetchError: false,
};

const createHash = (form) => ({
	[createHashKey(form)]: form,
});

const form = (state = initialData, action) => {
	let formObj;
	let key;

	switch (action.type) {
		case FORM_DATA_FETCHING:
			key = createHashKey(action.formInfo);
			formObj = state.data[key];
			if (!formObj) {
				formObj = initialFetchData;
			}
			return {
				...state,
				data: {
					...state.data,
					[key]: {
						...formObj,
						isFetching: action.flag,
					},
				},
			};
		case FORM_DATA_FETCH_SUCCESS:
			formObj = createHash(action.payload);
			formObj[createHashKey(action.payload)].isFetched = true;
			return {
				...state,
				data: {
					...state.data,
					...formObj,
				},
			};
		case FORM_DATA_FETCH_ERROR:
			key = createHashKey(action.formInfo);
			return {
				...state,
				data: {
					...state.data,
					[key]: {
						...state.data[key],
						fetchError: true,
					},
				},
			};
		case SAVE_FORM_SCREEN_DATA:
			return {
				...state,
				userData: {
					[action.formInfo.hashKey]: {
						...(state.userData[action.formInfo.hashKey] || {}),
						[action.formInfo.screenId]: action.formInfo.all,
					},
				},
			};
		default:
			return state;
	}
};

export default form;
