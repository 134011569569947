import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'antd';
import { requestToFetchProfileHomeLinks } from '../../../appRedux/actions';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import ProfileHomeLink from './ProfileHomeLink';
import '../../Ecommerce/Store/SelectionPage/selection.css';

const ProfileHomeLinks = (props) => {
	useEffect(() => {
		if (!props.loginInfo.profileHomeLinksFetched) {
			props.requestToFetchProfileHomeLinks();
		}
	}, []);

	return (
		<div className="gx-app-login-container" style={{ textAlign: 'left' }}>
			<Card className="gx-mb-0 gx-h-100 product-card card">
				<h2 className="title-text">Membership Options</h2>
				<br />
				{props.loginInfo.profileHomeLinksFetched &&
					props.loginInfo.profileHomeLinks.map((link, index) => {
						return (
							<ProfileHomeLink
								key={index}
								link={link}
								backgroundColor={props.loginInfo.login_form.primary_color}
							/>
						);
					})}
			</Card>
		</div>
	);
};

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		Object.assign({
			requestToFetchProfileHomeLinks: requestToFetchProfileHomeLinks,
		}),
		dispatch,
	);
}

function mapStateToProps(state) {
	return {
		loginInfo: state.loginInfo,
	};
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(ProfileHomeLinks));
