class UploadAdaptor {
	constructor(loader) {
		this.loader = loader;
		this.url =
			'https://z4v1tffmof.execute-api.us-east-1.amazonaws.com/Prod/api/file/upload/default/user';
	}

	uploadFile() {
		this.loader.file.then(
			(file) =>
				new Promise((resolve, reject) => {
					this.xhr.send(file);
				}),
		);
	}

	upload() {
		return new Promise((resolve, reject) => {
			const xhr = (this.xhr = new XMLHttpRequest());
			xhr.open('POST', this.url, true);
			xhr.responseType = 'json';
			const loader = this.loader;

			xhr.addEventListener('error', () => reject('Error Uploading'));
			xhr.addEventListener('abort', () => reject());
			xhr.addEventListener('load', () => {
				const response = xhr.response;

				if (!response || response.error) {
					return reject(
						response && response.error ? response.error.message : 'Error Uploading',
					);
				}
				resolve({
					default: response.url,
				});
			});

			if (xhr.upload) {
				xhr.upload.addEventListener('progress', (evt) => {
					if (evt.lengthComputable) {
						loader.uploadTotal = evt.total;
						loader.uploaded = evt.loaded;
					}
				});
			}
			this.uploadFile();
		});
	}

	abort() {
		if (this.xhr) {
			this.xhr.abort();
		}
	}
}

export default function MyCustomUploadAdapterPlugin(editor) {
	editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
		return new UploadAdaptor(loader);
	};
}
