import { put, takeLatest } from 'redux-saga/effects'

import * as actions from '../actions/floorPlan'
import * as actionTypes from 'constants/ActionTypes'
import FloorAPI from '../../api/FloorPlan'

function* fetchCompanyInfo(action) {
   try {
      const result = yield (FloorAPI.fetchCompanyDetails(action.payload))
      let exhibitor_hashmap = {};
      let exhibitorsInfo = {};
      result.map((exhibitor) => {
         exhibitor_hashmap[exhibitor.CompanyInfo.CompID] = {...exhibitor.CompanyInfo};
         exhibitorsInfo[exhibitor.CompanyInfo.CompID] = {...exhibitor};
      });
      let hashMap = {
         exhibitors: exhibitor_hashmap,
         exhibitorsInfo
      }
      if (result) {
         yield put(actions.fetchCompanyInfoSuccess(result))
         yield put(actions.updateExhibitorHashMap(hashMap))
      } else {
         yield put(actions.fetchCompanyInfoFailure(result))
      }
   } catch (err) {
      yield put(actions.fetchCompanyInfoFailure())
   }
}

function* fetchBoothConfiguration(action) {
   const result = yield (FloorAPI.fetchBoothConfigurations(action.payload))
   try {
      if (result) {
         yield put(actions.fetchBoothConfigurationSuccess(result))
      } else {
         yield put(actions.fetchBoothConfigurationFailure(result))
      }
   } catch (err) {
      yield put(actions.fetchBoothConfigurationFailure())
   }
}

export default function* FloorPlanSaga() {
   yield takeLatest(actionTypes.FETCH_COMPANY_INFO_REQUEST, fetchCompanyInfo);
   yield takeLatest(actionTypes.FETCH_BOOTH_CONFIGURATION_REQUEST, fetchBoothConfiguration)
}