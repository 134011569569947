import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import Title from '../components/Title';
import useRules from '../hooks/useRules';
import Description from '../components/Description';

/*
need api to pass back width
style={{width: '150px'}}

might need to use inputgroup
*/
interface Props {
	name: string;
	label: string;
	value: string;
	onChange: any;
	required: string;
	description: string;
	primaryColor: string;
	secondaryColor: string;
	width: string | number;
	error: string;
}

export const Text = ({
	name,
	label,
	value,
	onChange,
	required,
	description,
	primaryColor,
	secondaryColor,
	width,
	error,
}: Props) => {
	const rules = useRules(required);

	return (
		<div>
			<Title
				label={label}
				color={primaryColor}
				error={error}
				required={required}
				name={name}
			/>
			<Description label={description} color={secondaryColor} />
			<Form.Item rules={rules} name={name}>
				<Input
					className="form-component-radius fg-secondary-lighter"
					value={value}
					onChange={onChange}
				/>
			</Form.Item>
		</div>
	);
};
