export const formatPayment = amount => {
	return parseFloat(amount).toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

// 0 indexed page
export const paginate = (items, page, itemsPerPage) =>
	items.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage);
