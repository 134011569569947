import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Radio, List, Avatar } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import { requestAddCompanyUser, requestCreateMiniProfileFields } from '@appRedux/actions';
import Title from '@components/form/components/Title';
import { validateContactInfo, validateContactForm } from '@components/Membership/helpers/formValidation';
import { requestCompanyMembersData, resetDuplicateUserState } from '../../../appRedux/actions';
import { NotificationManager } from 'react-notifications';
const CorporateExhibitorAddContact = ({
	visible,
	closeModal,
	additionalSlotPage = false,
	updateMembership,
}) => {
	const dispatch = useDispatch();
	const loginInfo = useSelector(state => state.loginInfo);
	const moduleUrlId = loginInfo.corporateMembershipModuleUrlId;
	const corporateMembership = useSelector(state => state.corporateMembership);
	const newUserContactAdded = useSelector(state => state.corporateMembership.newUserContactAdded);
	const newUserDuplicateExists = useSelector(state => state.corporateMembership.newUserDuplicateExists);
	const newUserDuplicateUsers = useSelector(state => state.corporateMembership.newUserDuplicateUsers);
	const [duplicateUserReviewID, setDuplicateUserReviewID] = useState(null);
	const [userConfirmedNoDuplicate,setUserConfirmedNoDuplicate] = useState(false);
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [showDuplicateForm, setShowDuplicateForm] = useState(false);
	const [processCompanyMembers, setProcessCompanyMembers] = useState(false);
	const [constructForm, setConstructForm] = useState({
		firstname: { value: '', error: '' },
		lastname: { value: '', error: '' },
		email: { value: '', error: '' },
		company: { value: '' },
		contactTitle: { value: '' },
		membership: { value: '', error: '' },
	});

	const getAllMemberEmailIds = () => {
		return corporateMembership.companyMembers.map(data => data.email)
	}
	
	const onUpdateOrAddClicked = () => {
		if (newUserDuplicateExists && duplicateUserReviewID > 0) {
			const matchingRecord = newUserDuplicateUsers.find(
				user => user.reviewid === duplicateUserReviewID,
			);
			if (matchingRecord) {
				associateUserToCompany(
					corporateMembership?.userCompanyAffiliation?.comp_uuid,
					matchingRecord.uuid,
				);
				dispatch(
					requestCompanyMembersData({
						compuuid: corporateMembership?.userCompanyAffiliation?.comp_uuid,
						urlId: moduleUrlId,
					}),
				);
				setProcessCompanyMembers(true);
			}
		} else {
			let validatedForm;
			validatedForm = validateContactInfo(constructForm, getAllMemberEmailIds());
			setConstructForm(validatedForm.form);
			setIsFormTouched(true);
			if (validatedForm.valid) {
				let formData = { ...constructForm };

				formData = {
					email: formData['email'].value,
					firstname: formData['firstname'].value,
					lastname: formData['lastname'].value,
					company: formData['company'].value,
					contact_title: formData['contactTitle'].value,
					add_from: 'Corporate Membership',
					user_confirmed_no_duplicate: userConfirmedNoDuplicate,
				};

				dispatch(
					requestCreateMiniProfileFields({
						...formData,
					}),
				);
			}
		}
	};


	useEffect(() => {
        if(processCompanyMembers){
			const matchingRecord = corporateMembership.companyMembers.find(
				user => user.reviewID === duplicateUserReviewID,
			);
			if (additionalSlotPage) {
				let futureGroupName = 'No Membership';
				let futureGroupId = -1;
				let newMember = {
					...matchingRecord,
					futureGroupName,
					groupName: '',
					futureGroupId,
				};
				updateMembership(newMember);
			}
			dispatch(resetDuplicateUserState());
			closeModal();
		}
    }, [corporateMembership.companyMembers]);

	useEffect(() => {
		if(newUserDuplicateExists){
			setShowDuplicateForm(true)
		}
	},[newUserDuplicateExists])
	useEffect(() => {
		if (newUserContactAdded) {
			let newestMember = corporateMembership.companyMembers.slice(-1);
			associateUserToCompany(corporateMembership?.userCompanyAffiliation?.comp_uuid, newestMember[0]?.uuid)
			if (additionalSlotPage) {
				let futureGroupName = 'No Membership';
				let futureGroupId = -1;
				let newMember = newestMember.map(data => {
					return { ...data, futureGroupName, groupName: '', futureGroupId };
				});
				updateMembership(newMember[0]);
			}
			dispatch(resetDuplicateUserState())
			closeModal();
		}
	}, [newUserContactAdded]);

	const associateUserToCompany = (compuuid, contactUUID) => {
		dispatch(
			requestAddCompanyUser({
				compuuid: compuuid,
				companycontactUUID: contactUUID,
			}),
		);

	}

	useEffect(() => {
		if (visible) {
			let tempForm = { ...constructForm }
			Object.keys(tempForm).map(item => {
				tempForm[item].value = ""
				tempForm[item].error = ""
			}
			)
			setConstructForm(tempForm)
			setIsFormTouched(false)
		}
	}, [visible])

	const onUserSelectedChange  = (event)=>{
		if(event.target.value){
			if(event.target.value == "create_new"){
				setUserConfirmedNoDuplicate(true)
			}else{
				setUserConfirmedNoDuplicate(false)
			}
			setDuplicateUserReviewID(event.target.value == "create_new" ? 0 : event.target.value)
		}
	}
	const handleOk = () => {
		if(showDuplicateForm && newUserDuplicateUsers.length > 0){
			if(duplicateUserReviewID!=null){
				onUpdateOrAddClicked();
			}else{
				NotificationManager.error("Please select one option to continue");
			}
		}else{
			onUpdateOrAddClicked();
		}
	};

	const handleFormData = (value, key) => {
		let tempForm = { ...constructForm };
		tempForm[key].value = value;
		if (isFormTouched) {
			let validatedForm = validateContactForm(tempForm, getAllMemberEmailIds())
			tempForm = { ...validatedForm.form };
		}
		setConstructForm(tempForm);
	};

	return (
		<div className="gx-d-flex gx-align-items-start">
			{visible && (
				<Modal
					destroyOnClose={true}
					maskClosable={false}
					visible={visible}
					onCancel={
						()=>{
							dispatch(resetDuplicateUserState())
							closeModal()
						}}
					title="Add Contact"
					okText="Submit"
					onOk={handleOk}
				>
				<>
				{showDuplicateForm && newUserDuplicateUsers.length > 0 ? (
					<>
					<h2>Potential Matching Users Found - Confirm Your Identity</h2>
					<Radio.Group onChange={onUserSelectedChange} style={{ width: '100%' }}>
					<List
						itemLayout="horizontal"
						dataSource={newUserDuplicateUsers}
						renderItem={(item) => (
							<List.Item>
								<div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
								<Radio value={item.reviewid} style={{ marginRight: '10px' }} />
								<div style={{ flex: 1 }}>
									<List.Item.Meta
										avatar={<Avatar src={item.Picture} />}
										 title={ 
										`${item.firstname} ${item.lastname}`
										}
										description={  <>
											<div>{item.email}</div>
											<div>{item.Company}</div>
										</>}
									/>
								</div>
								</div>
							</List.Item>
							
						)}
					/>
					<List.Item>
						<Radio value={'create_new'} style={{ marginRight: '10px' }}>
							<div style={{"font-weight":"bold"}}>None of the above, create new user </div>
						</Radio>
					</List.Item>
    				</Radio.Group>
					</>
				):
					<div>
						<Form>
							<div>
								<Title
									label={'First Name'}
									color={'red'}
									error={constructForm.firstname.error}
									required={true}
									name={'firstname'}
								/>
								<Form.Item>
									<Input
										className="form-component-radius fg-secondary-lighter"
										value={constructForm.firstname.value}
										onChange={e => handleFormData(e.target.value, 'firstname')}
									/>
								</Form.Item>
							</div>
							<div>
								<Title
									label={'Last Name'}
									color={'red'}
									error={constructForm.lastname.error}
									required={true}
									name={'lastname'}
								/>
								<Form.Item>
									<Input
										className="form-component-radius fg-secondary-lighter"
										value={constructForm.lastname.value}
										onChange={e => handleFormData(e.target.value, 'lastname')}
									/>
								</Form.Item>
							</div>
							<div>
								<Title
									label={'Email'}
									color={'red'}
									error={constructForm.email.error}
									required={true}
									name={'email'}
								/>
								<Form.Item>
									<Input
										className="form-component-radius fg-secondary-lighter"
										value={constructForm.email.value}
										onChange={e => handleFormData(e.target.value, 'email')}
									/>
								</Form.Item>
							</div>
							<div>
								<Title
									label={'Company'}
									color={'red'}
									required={false}
									name={'company'}
								/>
								<Form.Item>
									<Input
										className="form-component-radius fg-secondary-lighter"
										value={constructForm.company.value}
										onChange={e => handleFormData(e.target.value, 'company')}
									/>
								</Form.Item>
							</div>
							<div>
								<Title
									label={'Contact Title'}
									color={'red'}
									required={false}
									name={'contactTitle'}
								/>
								<Form.Item>
									<Input
										className="form-component-radius fg-secondary-lighter"
										value={constructForm.contactTitle.value}
										onChange={e =>
											handleFormData(e.target.value, 'contactTitle')
										}
									/>
								</Form.Item>
							</div>
						</Form>
					</div>
				}
				</>
				</Modal>
			)}
		</div>
	);
};

export default CorporateExhibitorAddContact;
