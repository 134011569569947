import React, { useEffect, useState } from 'react';
import { Button, Input, message, Form, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const Support = (props) => {
	const [formLayout, setFormLayout] = useState('vertical');

	const handleOk = (values) => {
		const urlid = window.location.href.split('/')[4];
		let params = {
			Source: props.config.source_hex,
			Email: values.email,
			Name: values.name,
			Description: values.message,
			confurlid: urlid,
			user_uuid: props.user ? props.user.uuid : '',
		};
		fetch(
			'https://masterapp.econference.io/masterapp_summer2012/support/ajax/ajax_EmailSupportForm.cfm',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(params),
			},
		)
			.then((res) => res.text())
			.then((response) => {
				if (response) {
					NotificationManager.success('Support email sent');
				}
			});
		props.setVisible(false);
	};
	const handleCancel = () => {
		props.setVisible(false);
	};

	return (
		<div className="gx-app-login-wrap">
			<Modal
				visible={props.visible}
				title="Support"
				onCancel={handleCancel}
				footer={[
					<Button key="back" onClick={handleCancel}>
						Close
					</Button>,
					<Button key="submit" type="primary" form="support" htmlType="submit">
						Send
					</Button>,
				]}
			>
				<Form
					initialValues={{ remember: true }}
					name="support"
					layout={formLayout}
					onFinish={handleOk}
					className="gx-signin-form gx-form-row0"
				>
					<Form.Item
						label="Name: "
						initialValue=""
						rules={[{ required: true, message: 'Please input your name!' }]}
						name="name"
					>
						<Input placeholder="Please enter name" />
					</Form.Item>
					<Form.Item
						label="Email: "
						initialValue=""
						rules={[
							{
								type: 'email',
								message: 'The input is not valid E-mail!',
							},
							{
								required: true,
								message: 'Please input your E-mail!',
							},
						]}
						name="email"
					>
						<Input placeholder="Please enter email" />
					</Form.Item>
					<Form.Item
						label="Message:"
						initialValue=""
						name="message"
						rules={[
							{
								required: true,
								message: 'Please input your message!',
							},
						]}
					>
						<TextArea
							placeholder="Please enter message"
							autoSize={{ minRows: 2, maxRows: 6 }}
						/>
					</Form.Item>
				</Form>
			</Modal>
			<NotificationContainer />
		</div>
	);
};

export default Support;
