import React from 'react';
import { Button, Card, Table, Avatar } from 'antd';
import GoBack from '@components/Common/GoBack';
import { connect } from 'react-redux';
import { ReactComponent as UploadPlaceholderIcon } from '../../../assets/icons/uploadPlaceholder.svg';

const DuplicateCompanies = props => {
	const handleButtonClick = compUuid => {
		props.setSelectedCompanyUuid(compUuid);
	};

	const duplicateTableColumns = [
		{
			width: '20%',
			key: 'image',
			render: record => {
				return (
					record.compUUID !== 'NA' && (
						<Avatar size={64} src={record?.CompLogo ?? <UploadPlaceholderIcon />} />
					)
				);
			},
		},
		{
			width: '40%',
			key: 'companyData',
			render: record => {
				return (
					<div
						style={{
							whiteSpace: 'pre-line',
							textAlign: 'left',
						}}
					>
						<div style={{ fontSize: '16px', fontWeight: 'bold' }}>
							{`${record.companyName}\n`}
						</div>
						{record.compUUID !== 'NA' && (
							<div>
								{`${record.companyfirstname} ${record.companylastname}\n${record.companyEmail}\n`}
							</div>
						)}
					</div>
				);
			},
		},
		{
			width: '40%',
			key: 'myCompanyButton',
			render: record => {
				return (
					<Button
						className="login-form-button gx-mt-1 button-primary shadow-hard"
						htmlType="submit"
						shape={'round'}
						onClick={() => {
							handleButtonClick(record.compUUID);
						}}
						style={{
							border: 'transparent',
						}}
					>
						{record.compUUID === 'NA'
							? `Create Company`
							: props.corporateMembership.languageObject
									?.account_company_duplicate_creation_select_existing_company_msg ??
							  'This is my company'}
					</Button>
				);
			},
		},
	];

	return (
		<div>
			<div style={{ width: '10%' }}>
				<GoBack handleBackClick={props.handleBackClick} />
			</div>
			<Card className="card card-shadow">
				<div
					className="fg-primary-gradient"
					style={{ fontSize: '20px', fontWeight: 'bold' }}
				>
					{props.duplicateCompaniesMessage}
				</div>
				<Table
					className="gx-mt-2"
					rowKey="compUUID"
					columns={duplicateTableColumns}
					dataSource={props.duplicateCompanies}
					pagination={false}
				/>
			</Card>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		loginInfo: state.loginInfo,
		corporateMembership: state.corporateMembership,
	};
};

export default connect(mapStateToProps)(DuplicateCompanies);
