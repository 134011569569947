import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HeaderAndNavigation from '@components/Membership/CorporateMembership/HeaderAndNavigation';
import ExhibitorFinancialItemSteps from '@components/Membership/CorporateMembership/ExhibitorFinancialItemSteps';
// Only place we import Corporate Membership specific stylesheet.
import '../../../components/Membership/styles/custom.less';
import ExhibitorCheckout from '@components/Membership/CorporateMembership/ExhibitorCheckout';

const PublicCorporateMembership = ({ match, location }) => {
	// for public flow the component decalred twice with different urls as like old.
	const corporateMembership = useSelector(state => state.corporateMembership);
	return (
		<div className={corporateMembership.isIframe ? "gx-px-3 gx-py-3" : "container"}>
			<HeaderAndNavigation {...{ match, location }}>
				<Switch>
					<Route path={`${match.url}/:moduleUrlId/registration`} component={ExhibitorFinancialItemSteps} />
					<Route path={`${match.url}/:moduleUrlId/submenu`} component={ExhibitorFinancialItemSteps} />
					<Route path={`${match.url}/:moduleUrlId/checkout`} component={ExhibitorCheckout} />
				</Switch>
			</HeaderAndNavigation>
		</div>
	)
};

export default PublicCorporateMembership;
