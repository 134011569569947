import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Configure } from 'react-instantsearch-dom';
import { Pagination as AntDPagination } from 'antd';
import _sortBy from 'lodash/sortBy';

import SearchBox from 'components/Common/Algolia/SearchBox';
import Pagination from 'components/Common/Algolia/Pagination';

import FilterSidebar from '../FilterSidebar';
import GroupSelectionResults from '../../GroupSelectionPage/GroupSelectionResults';
import { useProducts } from '../../hooks/useProducts';
import { hitsPerPage } from '../../constants';
import BundleProductCard from '../../SelectionPage/BundleProductCard';
import { paginate } from '../../../Common/helpers/helper';
import { storeProductAnalysisRequest } from '../../../../appRedux/actions';
import auth from '../../../../loginAuth/loginAuth';

const BundleDetails = ({ page, setPage, handleTabChange, scrollToView }) => {
    const history = useHistory();
    const { bundleId, productId, superBundleId } = useParams();
    const [prodsLoading, products] = useProducts();
    const [tagsEmpty, setTagsEmpty] = useState(false);
    const dispatch = useDispatch();


    const appdir = useSelector(state => state.loginInfo.appdir);
    const contactUid = useSelector(state => state.auth.contact_uuid);

    const bundle = useMemo(() => {
        let id = (superBundleId && !bundleId) ? superBundleId : bundleId ? bundleId : productId;
        return products.find(prod => prod.id === parseInt(id))
    }, [products]);

    const children = useMemo(
        () => _sortBy(products.filter(p => bundle?.childrenIds.some(childId => childId === p.id)), [(items) => items.title]),
        [products, bundle],
    );

    const constructTagIds = useMemo(() => children.flatMap(p => p.tagIds), [children]);

    useEffect(() => {
        if (!prodsLoading && bundle?.id)
            storeProductAnalysis(bundle);
    }, [prodsLoading]);

    const storeProductAnalysis = (product) => {
        dispatch(storeProductAnalysisRequest({
            appdir,
            contactUid: contactUid === ''
                ? JSON.parse(auth.getCookie('contact_uuid'))
                : contactUid,
            productId: product.id
        }));
    };

    const gotoProduct = (product, searchData) => {
        if (!product.isSuperBundle && !product.isBundle && bundle?.isSuperBundle) {
            // Redirection for the product directly associated with the grand parent.
            history.push(`../product/${product.id}${history.location.search}`);
        } else if (!product.isSuperBundle && !product.isBundle && bundle?.isBundle && !superBundleId) {
            // to display the product in the bundle page
            if (productId) {
                history.push(
                    `/${history.location.pathname.split('/')[1]
                    }/consumption/bundle/${product.parentId}/${product.id}${history.location.search}`,
                );
            }
            handleTabChange('4', product);
            storeProductAnalysis(product);
        } else if (!product.isSuperBundle && !product.isBundle) {
            // to display the product in the group consumption(grand parent) page.
            if (superBundleId && bundleId && productId) {
                history.push(
                    `/${history.location.pathname.split('/')[1]
                    }/consumption/group/${superBundleId}/${bundleId}/${product.id}${history.location.search}`,
                );
            }
            handleTabChange('4', product);
            storeProductAnalysis(product);
        } else if (searchData) {
            // to redirect to the group consumption(grand parent) page while click on the bundle associated with grand parent.
            history.push(
                `../group/${product.parentId}/${searchData.id}${history.location.search}`,
            )
        } else {
            const route = product.isSuperBundle ? 'selection' : product.isBundle ? 'bundle' : 'product';
            history.push(`../${route}/${product.id}${history.location.search}`);
        }
        scrollToView();
    };
    const loading = prodsLoading;

    return (
        <div>
            {bundle?.description &&
                <>
                    <h3 className="gx-mb-2 gx-font-weight-semi-bold">Description</h3>
                    {/* description */}
                    <p
                        dangerouslySetInnerHTML={{
                            __html: bundle?.description,
                        }}
                        className="gx-mb-0"
                    />
                    <hr />
                </>
            }
            {/* display the childrens for bundle */}
            {children?.length && !bundle?.isSuperBundle ? (
                <>
                    <h3 className="gx-mb-2 gx-font-weight-semi-bold">Items</h3>
                    <section className="product-grid product-list-wrapper gx-mb-5">
                        {paginate(children, page, hitsPerPage).map((child, index) => {
                            return (
                                <BundleProductCard
                                    key={index}
                                    product={child}
                                    goto={gotoProduct}
                                />
                            )
                        })}
                        <div className='gx-d-flex gx-justify-content-center'>
                            <AntDPagination
                                pageSize={hitsPerPage}
                                total={children.length}
                                current={(page + 1)}
                                onChange={page => {
                                    setPage(page - 1);
                                    scrollToView();
                                }}
                                showSizeChanger={false}
                            />
                        </div>
                    </section>
                </>) : null}
            {/* section to display for the grand parent */}
            {bundle?.isSuperBundle && superBundleId && !bundleId && !productId ? (
                <main className="gx-py-5 gx-d-flex gx-justify-content-end">
                    <section className="gx-flex-1">
                        <div className="gx-mb-5 gx-d-flex">
                            {constructTagIds.length
                                ? (<FilterSidebar tags onTags={tags => setTagsEmpty(tags.length === 0)} />)
                                : null
                            }
                            <SearchBox />
                        </div>
                        <div className="gx-mb-4">
                            <GroupSelectionResults
                                loading={loading}
                                gotoProduct={gotoProduct}
                            />
                        </div>
                        <Configure hitsPerPage={hitsPerPage} />

                        <div className="gx-text-center">
                            <Pagination onPageChange={scrollToView} />
                        </div>
                    </section>
                </main>
            ) : null}
        </div>
    )
};

export default BundleDetails;
