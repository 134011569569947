import auth from '../loginAuth/loginAuth';

const xcdapi = 'https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm';
const socialapi = 'https://socialapi.econference.io/contact';

const getCheckAccount = async (appdir, formData) => {
	let result = await fetch(
		`${xcdapi}?appdir=${appdir}&module=login&Component=login&Function=find_existing_user_account`,
		{
			method: 'POST',
			body: formData,
		},
	);
	let resultJson = await result.json();
	return resultJson;
};

const getLoginFormData = async appdir => {
	let result = await fetch(
		`${xcdapi}?appdir=${appdir}&module=login&component=login&function=start`,
		{
			method: 'GET',
		},
	);
	let resultJson = await result.json();
	return resultJson;
};

const getProfileData = async (source_hex, contact_uuid) => {
	let result = await fetch(`${socialapi}/${source_hex}/${contact_uuid}`, {
		method: 'GET',
	});
	let resultJson = await result.json();
	return resultJson;
};

const getRegisterProfileFields = async appdir => {
	let result = await fetch(
		`${xcdapi}?module=contact&component=profile_fields&function=get_fields&appdir=${appdir}`,
		{
			method: 'GET',
		},
	);
	let resultJson = await result.json();

	return resultJson;
};

const getProfileInitialValues = async () => {
	let result = await fetch(
		`${xcdapi}?module=contact&component=profile_fields&function=get_user_values`,
		{
			method: 'GET',
			headers: {
				Authorization: JSON.parse(auth.getCookie('jwt')),
			},
		},
	);
	let resultJson = await result.json();

	return resultJson;
};

const getUpdateProfile = async formData => {
	let result = await fetch(`${xcdapi}?module=contact&component=users&function=update_contact`, {
		method: 'POST',
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		body: formData,
	});
	let resultJson = await result.json();

	return resultJson;
};

const getCreateProfile = async formData => {
	let result = await fetch(`${xcdapi}?module=contact&component=users&function=create_profile`, {
		method: 'POST',
		body: formData,
	});
	let resultJson = await result.json();

	return resultJson;
};

const getUploadProfilePic = async formData => {
	let result = await fetch(
		`https://masterapp.econference.io/masterapp_summer2012/controllers/mobileapp/profile-photo/process.cfm`,
		{
			method: 'POST',
			body: formData,
		},
	);
	let resultJson = await result.json();

	return resultJson;
};

const getOrderHistoryData = async appdir => {
	let result = await fetch(
		`${xcdapi}?appdir=${appdir}&module=contact&component=users&function=get_user_orders`,
		{
			method: 'GET',
			headers: {
				Authorization: JSON.parse(auth.getCookie('jwt')),
			},
		},
	);
	let resultJson = await result.json();

	return resultJson;
};

const getOrderHistoryViewData = async postData => {
	let result = await fetch(`${xcdapi}?module=contact&component=users&function=view_user_order`, {
		method: 'POST',
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		body: postData.body,
	});
	let resultJson = await result.json();

	return resultJson;
};
const getOrderHistoryEmailData = async postData => {
	let result = await fetch(`${xcdapi}?module=contact&component=users&function=email_user_order`, {
		method: 'POST',
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		body: postData.body,
	});
	let resultJson = await result.json();

	return resultJson;
};
const getOrderHistoryDownloadData = async postData => {
	let result = await fetch(`${xcdapi}?module=contact&component=users&function=view_user_order`, {
		method: 'POST',
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		body: postData.body,
	});
	let resultJson = await result.json();

	return resultJson;
};

const LoginAPIs = {
	getLoginFormData,
	getProfileData,
	getCheckAccount,
	getRegisterProfileFields,
	getProfileInitialValues,
	getUpdateProfile,
	getCreateProfile,
	getUploadProfilePic,
	getOrderHistoryData,
	getOrderHistoryViewData,
	getOrderHistoryEmailData,
	getOrderHistoryDownloadData,
};

export default LoginAPIs;
