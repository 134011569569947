import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Modal, Tag } from 'antd';
import { requestToUpdateRoles } from 'appRedux/actions';
import { PlusOutlined } from '@ant-design/icons';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';

export const ManageRoles = ({ record }) => {
	const dispatch = useDispatch();
	const corporateMembership = useSelector(state => state.corporateMembership);
	const loginInfo = useSelector(state => state.loginInfo);
	const [modalVisibility, setModalVisibility] = useState(false);
	const [checkList, setCheckList] = useState([]);
	const [tagList, setTagList] = useState([]);
	const toggleModalVisibility = () => { setModalVisibility(prev => !prev) };
	const updateStates = () => {
		let filteredRoles = typeof record.roles === 'object' ? record.roles.filter(Boolean) : [];
		setCheckList(_uniqBy(_map(filteredRoles, 'roleid')));
		setTagList(_uniqBy(filteredRoles, 'roleid'));
	}
	const updateRoles = (list) => {
		dispatch(
			requestToUpdateRoles({
				compUuid: corporateMembership.userCompanyAffiliation.comp_uuid,
				sourceHex: loginInfo.source_hex,
				uuid: record.uuid,
				roleIds: list,
			}),
		);
	}
	const handleOkClick = () => {
		setTagList(corporateMembership.roles.filter(({ roleid }) => checkList.includes(roleid)));
		updateRoles(checkList);
		toggleModalVisibility();
	}
	const handleTagClose = (e, id) => {
		e.preventDefault();
		const tempChkList = checkList.filter(role => role !== id);
		setCheckList(tempChkList);
		setTagList(tagList.filter(({ roleid }) => roleid !== id));
		updateRoles(tempChkList);
	}
	const closeModal = () => {
		setCheckList([]);
		toggleModalVisibility();
	}
	useEffect(updateStates, [record.roles]);
	useEffect(() => { if (modalVisibility) { updateStates() } }, [modalVisibility])
	return (
		<div>
			{Object.keys(tagList).length > 0 ? tagList.map(({ roleid, label }, inx) => {
				return <Tag key={inx} className='no-border-radius primary-tag cursor-pointer' closable onClose={(e) => handleTagClose(e, roleid, label)}>
					{label}
				</Tag>
			}) : null}
			<Tag className='no-border-radius cursor-pointer' icon={<PlusOutlined />} onClick={toggleModalVisibility}>
				Add Role
			</Tag>
			<Modal visible={modalVisibility} title='Add or Remove Roles' onOk={handleOkClick} onClose={closeModal} onCancel={closeModal}>
				<Checkbox.Group
					onChange={setCheckList}
					options={corporateMembership.roles.map(({ roleid, label }) => ({ label, value: roleid }))}
					value={checkList}
				/>
			</Modal>
		</div>
	);
};

export default ManageRoles

