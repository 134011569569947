import React from 'react';
import { Form, Checkbox } from 'antd';
import useRules from '../hooks/useRules';
import Description from '../components/Description';
import Title from '../components/Title';
import NormalText from '../components/NormalText';
import useFilteredOptions from '../hooks/useFilteredOptions';

interface Props {
	label: string;
	layout: any;
	onChange: any;
	primaryColor: string;
	secondaryColor: string;
	textColor: string;
	name: string;
	required: string;
	description: string;
	error: string;
	options: Array<{
		id: any;
		label: string;
		value: any;
		disabled: boolean;
		checked: boolean;
	}>;
	filteredValues: any;
}

export const Check = ({
	label,
	layout,
	onChange,
	name,
	required,
	description,
	options,
	primaryColor,
	secondaryColor,
	textColor,
	error,
	filteredValues,
}: Props) => {
	const rules = useRules(required);
	const filteredOptions: any = useFilteredOptions(options, filteredValues);

	if (!options || !options.length) {
		return null;
	}
	// checkbox data has to come in as an array, or else it wouldn't work.

	return (
		<div>
			{options?.length === 1 && !options[0].label ? (
				<div style={{ display: 'flex' }}>
					<Form.Item rules={rules} name={name}>
						<Checkbox.Group>
							{filteredOptions.map((option: any) => (
								<div key={option.id} style={{ margin: '5px 0', display: 'flex' }}>
									<Checkbox
										checked={option.checked}
										value={option.id}
										onChange={onChange}
									></Checkbox>
									<span style={{ marginLeft: 10 }}>
										<NormalText color={textColor} label={option.label} />
									</span>
								</div>
							))}
						</Checkbox.Group>
					</Form.Item>
					<Title
						label={options[0]?.value ? label + ' - $' + options[0]?.value : label} //if only single item, checker whether its a priced item or not
						color={primaryColor}
						error={error}
						required={required}
						name={name}
					/>
				</div>
			) : (
				<div>
					<Title
						label={label}
						color={primaryColor}
						error={error}
						required={required}
						name={name}
					/>
					<Description label={description} color={secondaryColor} />
					<Form.Item rules={rules} name={name}>
						<Checkbox.Group>
							{filteredOptions.map((option: any) => (
								<div key={option.id} style={{ margin: '5px 0', display: 'flex' }}>
									<Checkbox
										checked={option.checked}
										value={option.id}
										onChange={onChange}
									></Checkbox>
									<span style={{ marginLeft: 10 }}>
										{option?.value ? (
											<div className="gx-d-flex">
												<NormalText
													color={textColor}
													label={option.label}
												/>
												<div className="gx-d-flex gx-ml-3 fg-primary">
													{'$'}
													<p>
														{option?.value
															.toString()
															.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
													</p>
												</div>
											</div>
										) : (
											<NormalText color={textColor} label={option.label} />
										)}
									</span>
								</div>
							))}
						</Checkbox.Group>
					</Form.Item>
				</div>
			)}
		</div>
	);
};
