import React, { useState, useEffect } from 'react';
import { Tabs, Skeleton, Pagination, Card, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import _map from 'lodash/map';

import { FILE } from 'components/Common/constants';
import { paginate } from 'components/Common/helpers/helper';
import VideoPlayer from './VideoPlayer';
import PDFViewer from './PDFViewer';
import ImageGallery from './ImageGallery';
import FileLists from './FileLists';

import SidebarToggle from '../SidebarToggle';
import { sidebarWidth, toggleWidth, hitsPerPage } from '../../constants';
import { ReactComponent as Left } from 'assets/icons/left.svg';

const Presentations = ({ product, page, setPage, expanded, handleSelectProduct, children, loading }) => {
    const { superBundleId, bundleId, productId } = useParams();
    const [fileIdx, setFileIdx] = useState(-1);
    const [selectedFile, setSelectedFile] = useState(null);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [activeTab, setActiveTab] = useState('0');

    const history = useHistory();

    const [isPublic, setIsPublic] = useState(false);
    useEffect(() => {
        const params = new URLSearchParams(history.location.search);
        setIsPublic(!!params.get('public_embed'));
    }, [history]);

    const handleSelectFile = (data) => {
        if (data?.type === FILE.LINK) {
            window.open(data?.url);
            return;
        }
        setSelectedFile(data);
    };

    const handleTabChange = (activeKey) => {
        setActiveTab(activeKey);
        if (product?.files?.length === 1) {
            setSelectedFile(product?.files?.[0]);
        } else {
            setSelectedFile(null);
        }
    };

    return (
        <main>
            <section>
                <div className="gx-p-3 gx-my-auto gx-pl-2 gx-bg-light-grey">
                    <h3 className='gx-text-black gx-font-weight-semi-bold gx-mb-0 gx-ml-5'>
                        {product?.title}
                    </h3>
                </div>
            </section>
            <section className="gx-flex-1 gx-d-flex">
                <article
                    className="content-area"
                    style={{
                        width: `calc(100% - ${toggleWidth + (sidebarOpen ? sidebarWidth : 0)}px)`,
                        // width: `100%`,
                    }}
                >
                    <Tabs
                        activeKey={activeTab}
                        tabPosition={'left'}
                        tabBarStyle={{ marginTop: '2rem' }}
                        onChange={handleTabChange}
                    >
                        <Tabs.TabPane
                            key={0}
                            className="gx-px-5 gx-pt-2"
                            tab={
                                <div className="gx-d-flex gx-align-items-center fg-grey">
                                    <span>Description</span>
                                </div>
                            }
                        >
                            <h4
                                className="gx-font-weight-normal fg-grey"
                                dangerouslySetInnerHTML={{ __html: product?.description }}
                            />
                        </Tabs.TabPane>
                        {product?.files?.length ? (
                            <Tabs.TabPane
                                key={1}
                                className="gx-pl-2"
                                tab={
                                    <div className="gx-d-flex gx-align-items-center fg-grey">
                                        <span>Files</span>
                                    </div>
                                }
                            >
                                <FileLists
                                    bundle={product}
                                    selectedFile={selectedFile}
                                    expanded={expanded}
                                    handleSelectFile={handleSelectFile}
                                />
                            </Tabs.TabPane>
                        ) : null}
                        {product?.authorInfo?.length ? (
                            <Tabs.TabPane
                                key={2}
                                className="gx-pl-2"
                                tab={
                                    <div className="gx-d-flex gx-align-items-center fg-grey">
                                        <span>Authors</span>
                                    </div>
                                }
                            >
                                {_map(product?.authorInfo, (author, index) => (
                                    <div className="gx-px-4 gx-py-3 description-text gx-d-flex gx-align-items-center">
                                        <Avatar size="large" src={author.picture} icon={<UserOutlined />} />
                                        <div className='gx-ml-2'>{`${author.firstname} ${author.lastname}`}</div>
                                        {author.degrees ? <div>{`, ${author.degrees}`}</div> : null}
                                    </div>
                                ))}
                            </Tabs.TabPane>
                        ) : null}
                    </Tabs>
                </article>
                <SidebarToggle isOpen={sidebarOpen} close={() => setSidebarOpen(show => !show)} />

                <aside style={{ overflowX: 'hidden', flex: '1 0' }}>
                    <div className="gx-px-4 gx-py-5" style={{ width: sidebarWidth }}>
                        {loading ? (
                            <section>
                                {[...Array(hitsPerPage)].map((_, index) => (
                                    <Skeleton
                                        key={index}
                                        title={false}
                                        active
                                        paragraph={{ rows: 2 }}
                                        className="gx-mb-3"
                                    />
                                ))}
                            </section>
                        ) : (
                            <section>
                                <div className="gx-mb-4">
                                    {paginate(children, page, hitsPerPage).map((child, index) => (
                                        <p
                                            key={index}
                                            onClick={() => {
                                                handleTabChange('0');
                                                handleSelectFile(null);
                                                handleSelectProduct(child);
                                            }}
                                            className="gx-pointer gx-mb-3"
                                            style={{
                                                textDecoration:
                                                    product?.id === child.id && 'underline',
                                                fontSize: '.9rem',
                                            }}
                                        >
                                            {child.title}
                                        </p>
                                    ))}
                                </div>
                                <div className="gx-text-center">
                                    <Pagination
                                        pageSize={hitsPerPage}
                                        total={children.length}
                                        current={(page + 1)}
                                        onChange={page => setPage(page - 1)}
                                        showSizeChanger={false}
                                    />
                                </div>
                            </section>
                        )}
                    </div>
                </aside>
            </section>
        </main >
    )
}

export default Presentations;

