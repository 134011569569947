import {
	REQUEST_INITIAL_API,
	REQUEST_PROFILE_INFO,
	REQUEST_APPDIR_COOKIE,
	REQUEST_REGISTER_INITIAL_VALUES,
	REQUEST_EXISTING_ACCOUNTS,
	REQUEST_RETRIEVE_ACCOUNT,
	RECEIVE_LOGINFORM_DATA,
	RECEIVE_PROFILE_DATA,
	REQUEST_ERASE_PROFILE_DATA,
	RECEIVE_CHECK_ACCOUNT,
	PROFILE_HOME_LINKS_FETCHED,
	NEWS_ITEMS_FETCHED,
	EMAIL_MESSAGES_COUNT_FETCHED,
	FETCH_EMAIL_MESSAGES,
	EMAIL_MESSAGES_FETCHED,
	EMAIL_MARKED_READ,
	RECEIVE_REGISTER_PROFILE_FIELDS,
	RECEIVE_PROFILE_INITIAL_VALUES,
	RECEIVE_UPDATE_PROFILE,
	RECEIVE_CREATE_PROFILE,
	REQUEST_DELETE_PROFILE_MSG,
	RECEIVE_UPLOAD_PROFILE_PIC,
	REQUEST_ORDER_HISTORY_DATA,
	RECEIVE_ORDER_HISTORY_DATA,
	EMAIL_UPDATED,
	UPDATE_APPDIR,
	REQUEST_LOGINFORM_DATA,
	RECEIVE_ORDER_HISTORY_VIEW_DATA,
	REQUEST_ORDER_HISTORY_VIEW_DATA,
	RECEIVE_ORDER_HISTORY_EMAIL_DATA,
	REQUEST_ORDER_HISTORY_EMAIL_DATA,
	REQUEST_ORDER_HISTORY_DOWNLOAD_DATA,
	RECEIVE_ORDER_HISTORY_DOWNLOAD_DATA,
	ORDER_HISTORY_UPDATE_REDUCER,
	REQUEST_CLEAR_EXISTING_ACCOUNTS,
} from '../../constants/ActionTypes';

const initialSettings = {
	source_hex: '',
	login_form: '',
	appdir: '',
	firstName: '',
	lastName: '',
	email: '',
	reviewerID: '',
	company: '',
	city: '',
	picture: '',
	lastUpdated: '',
	country: '',
	existingAccounts: '',
	retrieveAccount: '',
	ads: '',
	appurl: '',
	logout_url: '',
	emailExists: '',
	profileHomeLinks: {},
	profileHomeLinksFetched: false,
	profile_home_config: {
		show_news: false,
		show_membership: false,
		show_personal_notifications: false,
		show_payment_label: '',
		show_payment: false,
	},
	newsItems: [],
	newsItemsFetched: false,
	emailMessagesCount: {},
	emailMessagesCountFetched: false,
	emailMessages: {},
	emailMessagesFetched: false,
	registerProfileFields: '',
	registerProfileFieldsFetched: false,
	profileInitialValues: '',
	profileInitialValuesFetched: false,
	consent_object: '',
	userCreated: '',
	userCreatedMsg: '',
	userUpdatedMsg: '',
	orderHistoryLoader: false,
	isLoading: false,
	orderHistoryViewData: {},
	orderHistoryViewLoader: false,
	orderHistoryEmailData: {},
	orderHistoryEmailoader: false,
	orderHistoryDownloadData: {},
	showOrderHistoryViewModal: false,
	corporateMembershipModuleUrlId: '',
	loginFormDataFetched: false,
};

const LoginPageInfo = (state = initialSettings, action) => {
	switch (action.type) {
		case REQUEST_INITIAL_API:
			return {
				...state,
				appdir: action.payload.appdir,
				login_form: action.payload.login_form,
				isLoading: true,
				source_hex: action.payload.source_hex,
			};
		case REQUEST_PROFILE_INFO:
			return {
				...state,
				firstName: action.payload.Firstname,
				lastName: action.payload.Lastname,
				company: action.payload.Company,
			};
		case REQUEST_APPDIR_COOKIE:
			return {
				...state,
				appdir: action.payload,
			};
		case REQUEST_REGISTER_INITIAL_VALUES:
			return {
				...state,
				firstName: action.payload.firstname,
				lastName: action.payload.lastname,
				email: action.payload.email,
			};
		case REQUEST_EXISTING_ACCOUNTS:
			return {
				...state,
				existingAccounts: action.payload,
			};
		case REQUEST_RETRIEVE_ACCOUNT:
			return {
				...state,
				retrieveAccount: action.payload,
			};
		case REQUEST_LOGINFORM_DATA:
			return {
				...state,
				isLoading: true,
			};
		case RECEIVE_LOGINFORM_DATA:
			return {
				...state,
				isLoading: false,
				ads: action.payload.ads,
				appdir: action.payload.appdir,
				appurl: action.payload.appurl,
				login_form: action.payload.login_form,
				logout_url: action.payload.logout_url,
				source_hex: action.payload.source_hex,
				profile_home_config: action.payload.profile_home_config,
				consent_object: action.payload.consent_object,
				styling: action.payload.TemplateMetaJSON
					? action.payload.TemplateMetaJSON.styling
					: null,
				// corporateMembershipModuleUrlId: 'sbmpzut', //dfi
				// corporateMembershipModuleUrlId: 'tu7TiE8', //dfi
				// corporateMembershipModuleUrlId: 'h8dcfav', // demo
				// corporateMembershipModuleUrlId: 'h8dcfav', // demo

				corporateMembershipModuleUrlId: action.payload.corp_member_store_urlid,
				loginFormDataFetched: true
			};
		case RECEIVE_PROFILE_DATA: {
			return {
				...state,
				firstName: action.payload.Firstname,
				lastName: action.payload.Lastname,
				company: action.payload.Company,
				reviewerID: action.payload.ReviewID,
				city: action.payload.City,
				email: action.payload.Email,
				lastUpdated: action.payload.LastUpdated,
				country: action.payload.Country,
				picture: action.payload.Picture
					? action.payload.Picture.includes('http')
						? action.payload.Picture
						: `${state.appurl}/pictures_mobile/${action.payload.Picture}`
					: 'https://masterapp.econference.io/images/default-avatar.png',
			};
		}
		case PROFILE_HOME_LINKS_FETCHED: {
			return {
				...state,
				profileHomeLinks: action.payload,
				profileHomeLinksFetched: true,
			};
		}
		case NEWS_ITEMS_FETCHED: {
			return {
				...state,
				newsItems: action.payload,
				newsItemsFetched: true,
			};
		}
		case EMAIL_MESSAGES_COUNT_FETCHED: {
			return {
				...state,
				emailMessagesCount: {
					...action.payload,
				},
				emailMessagesCountFetched: true,
			};
		}
		case FETCH_EMAIL_MESSAGES: {
			return {
				...state,
				emailMessages: {},
				emailMessagesFetched: false,
			};
		}
		case EMAIL_MESSAGES_FETCHED: {
			return {
				...state,
				emailMessages: {
					...action.payload,
				},
				emailMessagesFetched: true,
			};
		}
		case EMAIL_MARKED_READ: {
			return {
				...state,
				emailMessagesCount: {
					...state.emailMessagesCount,
					unread_emails: state.emailMessagesCount.unread_emails - 1,
				},
				emailMessages: {
					...state.emailMessages,
					summary: {
						...state.emailMessages.summary,
						total_unread_count: state.emailMessages.summary.total_unread_count - 1,
					},
					array_of_emails: state.emailMessages.array_of_emails.map(email => {
						if (email.id === action.payload.emailid) {
							return {
								...email,
								date_opened: action.payload.date_opened,
							};
						}
						return email;
					}),
				},
			};
		}
		case REQUEST_ERASE_PROFILE_DATA:
			return initialSettings;
		case RECEIVE_CHECK_ACCOUNT:
			if (action.payload.emailExists) {
				return {
					...state,
					retrieveAccount: action.payload.contactArray[0],
					emailExists: action.payload.emailExists,
				};
			} else if (action.payload.contactArray.length > 0) {
				return {
					...state,
					existingAccounts: action.payload.contactArray,
					emailExists: action.payload.emailExists,
				};
			} else {
				return {
					...state,
					emailExists: action.payload.emailExists,
				};
			}
		case RECEIVE_REGISTER_PROFILE_FIELDS:
			return {
				...state,
				registerProfileFields: action.payload,
				registerProfileFieldsFetched: true,
			};

		case RECEIVE_PROFILE_INITIAL_VALUES:
			let tempPayload = {
				...action.payload,
			};
			for (var [key, value] of Object.entries(tempPayload)) {
				tempPayload[key] = value.toString();
			}
			return {
				...state,
				profileInitialValues: {
					...tempPayload,
				},
				profileInitialValuesFetched: true,
			};

		case RECEIVE_UPDATE_PROFILE:
			return {
				...state,
				userCreated: action.payload.userCreated,
				userUpdatedMsg: action.payload.Message,
				firstName: action.payload.firstname,
				lastName: action.payload.lastname,
				email: action.payload.email,
				city: action.payload.city,
				company: action.payload.company,
				country: action.payload.country,
				profileInitialValues: action.payload,
			};

		case RECEIVE_CREATE_PROFILE:
			return {
				...state,
				firstName: action.payload.firstname,
				lastName: action.payload.lastname,
				email: action.payload.email,
				userCreated: action.payload.userCreated,
				userCreatedMsg: action.payload.Message,
			};

		case REQUEST_DELETE_PROFILE_MSG:
			return {
				...state,
				userUpdatedMsg: '',
				userCreatedMsg: '',
			};
		case RECEIVE_UPLOAD_PROFILE_PIC:
			return {
				...state,
				picture: action.payload.image_url,
			};
		case REQUEST_ORDER_HISTORY_DATA:
			return {
				...state,
				orderHistoryLoader: true,
				orderHistoryViewLoader: false,
			};
		case RECEIVE_ORDER_HISTORY_DATA:
			return {
				...state,
				orderHistory: action.payload,
				orderHistoryLoader: false,
			};
		case EMAIL_UPDATED: {
			return {
				...state,
				email: action.payload,
			};
		}
		case UPDATE_APPDIR: {
			return {
				...state,
				appdir: action.payload,
			};
		}
		case REQUEST_ORDER_HISTORY_VIEW_DATA:
			return {
				...state,
				orderHistoryEmailData: {},
				orderHistoryViewData: {},
				orderHistoryViewLoader: true,
			};
		case RECEIVE_ORDER_HISTORY_VIEW_DATA:
			return {
				...state,
				orderHistoryViewData: action.payload,
				orderHistoryViewLoader: false,
			};
		case REQUEST_ORDER_HISTORY_EMAIL_DATA:
			return {
				...state,
				orderHistoryEmailData: {},
				orderHistoryViewData: {},
				orderHistoryViewLoader: true,
			};
		case RECEIVE_ORDER_HISTORY_EMAIL_DATA:
			return {
				...state,
				orderHistoryEmailData: action.payload,
				orderHistoryViewLoader: false,
			};
		case REQUEST_ORDER_HISTORY_DOWNLOAD_DATA:
			return {
				...state,
				orderHistoryDownloadData: {},
				orderHistoryViewData: {},
				orderHistoryViewLoader: true,
			};
		case RECEIVE_ORDER_HISTORY_DOWNLOAD_DATA:
			return {
				...state,
				orderHistoryDownloadData: action.payload,
				orderHistoryViewLoader: false,
			};
		case ORDER_HISTORY_UPDATE_REDUCER:
			return { ...state, [action.payload.event]: action.payload.data };

		case REQUEST_CLEAR_EXISTING_ACCOUNTS:
			return {
				...state,
				emailExists: '',
				existingAccounts: '',
				retrieveAccount: '',
			};
		default:
			return state;
	}
};

export default LoginPageInfo;
