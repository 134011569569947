import { useState, useEffect } from 'react';

/**
 * This hook is used to handles rules passed to antd fields
 * @param {*} required
 * @param {*} message
 */
export default function useRules(required, message = 'This field is required.') {
	const [rules, setRules] = useState([]);

	//commented because this functionality is not being used currently

	// useEffect(() => {
	// 	if (required) {
	// 		setRules([{ required: true, message }]);
	// 	} else {
	// 		setRules([]);
	// 	}
	// }, [required]);

	return rules;
}
