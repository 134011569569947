/**
 * This function resolves graph dependency
 * @param {*} graph This contains a hashmap with dependencies.
 * For eg: input object is this:
 *  {
      html: ['foo'],
      value: ['options', 'html'],
      foo: ['options'],
      bar: ['options', 'html'],
      options: [],
      css: ['value'],
      elements: ['css', 'html', 'options']
    };
    Here, html is dependent on foo

    @returns [
      options,
      foo,
      html,
      value,
      bar,
      css,
      elements
    ]
 */
export default function resolve(graph) {
	var sorted = [], // sorted list of IDs ( returned value )
		visited = {}; // hash: id of already visited node => true

	// 2. topological sort
	Object.keys(graph).forEach(function visit(name, ancestors) {
		if (!Array.isArray(ancestors)) ancestors = [];
		ancestors.push(name);
		visited[name] = true;

    /**
     * 1. Go through all the dependencies in the array
     * 2. For each dep, check if its already visited(resolved)
     * 3. If it is not, then recursively try to follow the dependencies
     * 4. Otherwise, go to the next dependency
     */
		if (graph[name]) {
			graph[name].forEach(function (dep) {
				if (ancestors.indexOf(dep) >= 0)
					// if already in ancestors, a closed chain exists.
					throw new Error(
						'Circular dependency "' +
							dep +
							'" is required by "' +
							name +
							'": ' +
							ancestors.join(' -> '),
					);

				// if already exists, do nothing
				if (visited[dep]) return;
				visit(dep, ancestors.slice(0)); // recursive call
			});

      //If dependency is not already added, add it
			if (sorted.indexOf(name) < 0) sorted.push(name);
		}
  });

  //returns the sorted list of dependencies
	return sorted;
}
