import axios from 'axios';
import auth from '../loginAuth/loginAuth';
import FormData from 'form-data';

const xcdApi = 'https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm';

const masterappApi = 'https://bm6uvz69il.execute-api.us-east-1.amazonaws.com/dev/api';

const markEmailRead = payload =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=contact&component=profile_home&function=mark_email_read`,
		method: 'post',
		data: payload,
	});

const getEmailMessages = () =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `https://xcdsystem.com/masterapp_summer2012/apiv2/index.cfm?module=contact&component=profile_home&function=get_email_messages`, //temp fix masterapp.econference.io not working for this
		method: 'get',
	});

const getEmailMessagesCount = () =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=contact&component=profile_home&function=get_email_messages_count`,
		method: 'get',
	});

const getNewsItems = () =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=contact&component=profile_home&function=get_news_items`,
		method: 'get',
	});

const getProfileHomeLinks = () =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=contact&component=profile_home&function=get_profile_home_links`,
		method: 'get',
	});

const renewAll = payload =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=users&function=bulk_update_members`,
		method: 'post',
		data: payload,
	});

const updateCompanyLogo = payload =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=profile_fields&function=update_company_logo`,
		method: 'post',
		data: payload,
	});

const deleteRegistrationItemFromCart = (
	payload,
	urlId,
	exhibUuid,
	compUuid,
	appDir,
	publicEmbed,
	cartUuid,
	authUser,
) => {
	return axios({
		headers:
			publicEmbed && !authUser
				? {}
				: { Authorization: JSON.parse(auth.getCookie('jwt')), },
		url: `${xcdApi}?module=exhibitor&component=exhibitors&function=delete_from_cart&moduleURLID=${urlId}${exhibUuid ? `&exhibuuid=${exhibUuid}` : ''
			}${compUuid ? `&compuuid=${compUuid}` : ''}${appDir ? `&appdir=${appDir}` : ''}${publicEmbed && !authUser ? `&public=1` : ''
			}${cartUuid ? `&cart_uuid=${cartUuid}` : ''}`,
		method: 'post',
		data: payload,
	})
}

const addRegistrationItemsToCart = (
	payload,
	urlId,
	exhibUuid,
	compUuid,
	appDir,
	publicEmbed,
	cartUuid,
	isTokenInUrl,
) =>
	axios({
		headers: { Authorization: JSON.parse(auth.getCookie('jwt')), },
		url: `${xcdApi}?module=exhibitor&component=exhibitors&function=add_to_cart&moduleURLID=${urlId}${exhibUuid ? `&exhibuuid=${exhibUuid}` : ''
			}${compUuid ? `&compuuid=${compUuid}` : ''}${appDir ? `&appdir=${appDir}` : ''}${publicEmbed ? `&public=1` : ''
			}${cartUuid ? `&cart_uuid=${cartUuid}` : ''}`,
		method: 'post',
		data: payload,
	});

const sendEmailToUsers = (payload, urlId) =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=email&function=SendEmail&moduleurlid=${urlId}`,
		method: 'post',
		data: payload,
	});

const fetchExhibitorCart = (urlId, exhibUuid, compUuid, publicEmbed, cartUuid, appDir) =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=exhibitor&component=exhibitors&function=exhibitor_data${urlId ? `&moduleURLID=${urlId}` : ''
			}${exhibUuid ? `&exhibuuid=${exhibUuid}` : ''}${compUuid ? `&compuuid=${compUuid}` : ''}${publicEmbed ? `&public=1` : ''
			}${cartUuid ? `&cartuuid=${cartUuid}` : ''}${appDir ? `&appDir=${appDir}` : ''}`,
		method: 'get',
	});

const addNewUserContact = (payload, urlId) =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=users&function=add_new_user_to_company&moduleurlid=${urlId}`,
		method: 'post',
		data: payload,
	});

const updateUserContactInformation = (payload, urlId) =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=contact&component=users&function=update_contact&moduleurlid=${urlId}`,
		method: 'post',
		data: payload,
	});

const getPaymentSettings = (appDir, urlId) =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?appdir=${appDir}&module=exhibitor&component=exhibitor_menu&function=payment_settings&moduleurlid=${urlId}`,
		method: 'get',
	});

const getExhibitorCartDetails = (appDir, urlId, compUuid) =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?appdir=${appDir}&module=exhibitor&component=exhibitors&function=start&moduleurlid=${urlId}&compuuid=${compUuid}`,
		method: 'get',
	});

const addUserMembership = payload =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=users&function=add_company_member_to_group`,
		method: 'post',
		data: payload,
	});

const updateCompanyProfile = (payload, urlId, compUuid) =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=profile_fields&function=update_company_profile&moduleurlid=${urlId}&compuuid=${compUuid}`,
		method: 'post',
		data: payload,
	});

const getUserContactInfoFieldValues = (urlId, userUuid) =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=contact&component=profile_fields&function=get_user_values&moduleurlid=${urlId}&otheruuid=${userUuid}`,
		method: 'get',
	});

const getCompanyProfileFieldValues = (urlId, compUuid) =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=profile_fields&function=get_user_values&moduleurlid=${urlId}&compuuid=${compUuid}`,
		method: 'get',
	});

const getCompanyMediaValues = async (urlId, appDir, compUuid) => {

	const response = await axios.get(
		`${masterappApi}?appdir=${appDir}&module=company&component=company_profile&function=FetchMediaFiles&compuuid=${compUuid}`,
		{ Authorization: JSON.parse(auth.getCookie('jwt')) },
	);

	return response;
};

const getS3Signature = async (key, bucket, content_type) => {
	const nowPlusOneHr = new Date();
	nowPlusOneHr.setHours(nowPlusOneHr.getHours() + 1);

	const data = JSON.stringify({
		expiration: nowPlusOneHr,
		conditions: [
			{
				acl: 'private',
			},
			{
				bucket: bucket,
			},
			{
				'Content-Type': content_type,
			},
			{
				success_action_status: '200',
			},
			{
				key: key,
			},
			{
				'x-amz-meta-qqfilename': 'state.json',
			},
		],
	});

	const axiosBody = {
		method: 'post',
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url:
			'https://cors.econference.io/https://www.xcdsystem.com/masterapp_summer2012/controllers/amazonS3/sign.php',
		// 'https://cors.econference.io/https://www.xcdsystem.com/masterapp_summer2012/controllers/amazonS3/sign.php',
		data,
	};

	const response = await axios(axiosBody);

	return response.data;
};
const uploadTos3 = async (key, bucket, content_type, file, sign_params) => {
	const data = new FormData();
	data.append('key', key);
	data.append('Content-Type', content_type);
	data.append('acl', 'private');
	data.append('x-amz-meta-qqfilename', 'state.json');
	data.append('AWSAccessKeyId', 'AKIAYUGOQI43NACWZLHQ');
	data.append('policy', sign_params.policy);
	data.append('signature', sign_params.signature);
	data.append('success_action_status', '200');
	data.append('file', file.fileList[0].originFileObj);

	const config = {
		method: 'post',
		url: `https://s3.amazonaws.com/${bucket}`,
		data: data,
	};

	await axios(config);

	return { url: `https://s3.amazonaws.com/${bucket}/${key}`, key: key };
};

const deleteMediaResource = async (appdir, compuuid, resource) => {
	const data = {
		rowID: String(resource.RowID),
	};

	const axiosBody = {
		method: 'post',
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${masterappApi}?appdir=${appdir}&module=company&component=company_profile&function=DeleteMediaRecord&compuuid=${compuuid}`,
		data: data,
	};
	const response = await axios(axiosBody);

	return response;
};

const updateMediaResource = async (appdir, compuuid, resource) => {
	const data = {
		mediaType: resource.type,
		mediaPayloadData: JSON.stringify({ label: resource.label, url: resource.url }),
		rowID: String(resource.RowID),
	};

	const axiosBody = {
		method: 'post',
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${masterappApi}?appdir=${appdir}&module=company&component=company_profile&function=UpdateMediaRecord&compuuid=${compuuid}`,
		data: data,
	};
	const response = await axios(axiosBody);

	return response;
};

const addMediaResource = async (appdir, compuuid, resource) => {
	const data = {
		mediaType: resource.type,
		mediaPayloadData: JSON.stringify({ label: resource.label, url: resource.url }),
	};

	const axiosBody = {
		method: 'post',
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${masterappApi}?appdir=${appdir}&module=company&component=company_profile&function=AddMediaFiles&compuuid=${compuuid}`,
		data: data,
	};
	const response = await axios(axiosBody);

	return response;
};

const validateYoutubeVideo = async link =>
	axios.get(`https://www.youtube.com/oembed?url=${link}&format=json`);

const validateVimeoVideo = async link => axios.get(`https://vimeo.com/api/oembed.json?url=${link}`);

const sortMediaResource = async (appdir, compuuid, resource) => {
	const data = {
		RowOrderList: resource.RowOrderList,
		sortType: resource.sortType,
	};

	const axiosBody = {
		method: 'post',
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${masterappApi}?appdir=${appdir}&module=company&component=company_profile&function=SortMediaRecord&compuuid=${compuuid}`,
		data: data,
	};
	const response = await axios(axiosBody);

	response.sortOrder = resource.RowOrderList;

	return response;
};

const getUserContactInfoFields = (urlId, appDir, publicEmbed) =>
	axios({
		headers: publicEmbed
			? {}
			: { Authorization: JSON.parse(auth.getCookie('jwt')), },
		url: `${xcdApi}?module=contact&component=profile_fields&function=get_fields&moduleurlid=${urlId}&appdir=${appDir}`,
		method: 'get',
	});

const getCompanyProfileFields = (urlId, appDir, publicEmbed) =>
	axios({
		headers: publicEmbed
			? {}
			: { Authorization: JSON.parse(auth.getCookie('jwt')), },
		url: `${xcdApi}?module=company&component=profile_fields&function=get_fields&moduleurlid=${urlId}&appdir=${appDir}`,
		method: 'get',
	});

const removeUserFromGroup = payload =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=users&function=remove_company_contact`,
		method: 'post',
		data: payload,
	});

const removeUserMembership = payload =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=users&function=remove_membership_from_user`,
		method: 'post',
		data: payload,
	});

const removeUserAdmin = payload =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=users&function=remove_company_admin`,
		method: 'post',
		data: payload,
	});

const makeUserAdmin = payload =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=users&function=make_company_admin`,
		method: 'post',
		data: payload,
	});

const makeUserPublic = payload =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=users&function=make_contact_public`,
		method: 'post',
		data: payload,
	});

const removeUserPublic = payload =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=users&function=make_contact_private`,
		method: 'post',
		data: payload,
	});

const getCompanyMembers = payload =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=users&function=see_company_members`,
		method: 'post',
		data: payload,
	});

const getMenu = (appDir, urlId, compUuid, publicEmbed, exhibUuid, isTokenInUrl) =>
	axios({
		headers:
			publicEmbed && !isTokenInUrl
				? {}
				: { Authorization: JSON.parse(auth.getCookie('jwt')), },
		url: `${xcdApi}?appdir=${appDir}&module=exhibitor&component=exhibitor_menu&function=start&moduleurlid=${urlId}${compUuid ? `&compuuid=${compUuid}` : ''
			}${exhibUuid ? `&exhibUuid=${exhibUuid}` : ''}${publicEmbed ? `&publicEmbed=1` : ''}`,
		method: 'get',
	});

const getUserCompanyAffiliation = () =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=users&function=SeeUserCompanyAffiliation`,
		method: 'get',
	});

const getCompany = compUuid =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=company&function=FindCompanyByID&compuuid=${compUuid}`,
		method: 'get',
	});

const createCompany = payload =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=company&function=create_company`,
		method: 'post',
		data: payload,
	});

const fetchExhibitorCartUuid = (appDir, moduleUrlId, publicEmbed) =>
	axios({
		headers: publicEmbed
			? {}
			: { Authorization: JSON.parse(auth.getCookie('jwt')), },
		url: `${xcdApi}?appdir=${appDir}&module=exhibitor&component=exhibitors&function=start&moduleurlid=${moduleUrlId}&publicEmbed=${publicEmbed ? `1` : `0`}`,
		method: 'get',
	});

const exhibitorSignUp = (payload, appDir, moduleUrlId, userExists) =>
	axios({
		headers: userExists
			? { Authorization: JSON.parse(auth.getCookie('jwt')), }
			: {},
		url: `${xcdApi}?module=company&appdir=${appDir}&component=users&function=public_signup&URLID=${moduleUrlId}`,
		method: 'post',
		data: payload,
	});

const makeCartPublicToPrivate = (moduleUrlId, appDir, cartUuid, exhibUuid, compUuid) =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=exhibitor&component=exhibitors&function=publicCartToPrivate&moduleURLID=${moduleUrlId}&appdir=${appDir}&cart_uuid=${cartUuid}&exhibuuid=${exhibUuid}&compuuid=${compUuid}`,
		method: 'post',
		data: {},
	});

const sendMagicLink = (appDir, cartUuid, moduleUrlId, email) =>
	axios({
		url: `${xcdApi}?module=exhibitor&component=exhibitors&function=EmailMagicLink&appdir=${appDir}&public=1&cartuuid=${cartUuid}&moduleURLID=${moduleUrlId}`,
		method: 'post',
		data: {
			email,
		},
	});

const deleteExhibitorPayment = (moduleUrlId, exhibUuid, paymentId) =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=exhibitor&component=exhibitors&function=delete_payment&URLID=${moduleUrlId}&exhibuuid=${exhibUuid}`,
		method: 'post',
		data: {
			paymentid: paymentId,
		},
	});

const fetchRoles = (compUuid, sourceHex) =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=roles&function=see_contact_roles&compuuid=${compUuid}&source=${sourceHex}`,
		method: 'get',
	});

const updateRoles = (compUuid, sourceHex, uuid, roleids) =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=roles&function=update_roles&compuuid=${compUuid}&source=${sourceHex}&otheruuid=${uuid}`,
		method: 'post',
		data: {
			roleids,
		},
	});

const getMiniProfileFields = appdir =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?appdir=${appdir}&module=contact&component=profile_fields&function=get_basic_contact_form&origin=company`,
		method: 'get',
	});

const updateMiniProfileFields = payload =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=contact&component=users&function=update_mini_profile`,
		method: 'post',
		data: payload,
	});

const createMiniProfileFields = payload =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=contact&component=users&function=create_mini_profile&source=corporate_react_app`,
		method: 'post',
		data: payload,
	});

const addCompanyUser = payload =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?module=company&component=users&function=add_company_user`,
		method: 'post',
		data: payload,
	});

const fetchAttendeesInfo = ({ appdir, urlId, compuuid, exhibuuid }) =>
	axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?appdir=${appdir}&module=exhibitor&component=staff&function=find_conference_registered&moduleurlid=${urlId}&compuuid=${compuuid}&exhibuuid=${exhibuuid}`,
		method: 'get',
	});

const fetchCompanyDiscountInfo = ({ appdir, urlId, compuuid, exhibuuid }) => axios({
	headers: {
		Authorization: JSON.parse(auth.getCookie('jwt')),
	},
	url: `${xcdApi}?appdir=${appdir}&module=exhibitor&component=staff&function=find_company_staff_discounts&moduleurlid=${urlId}&exhibuuid=${exhibuuid}&compuuid=${compuuid}`,
	method: 'get',
});

const applyStaffDiscountInfo = ({ appdir, urlId, compuuid, exhibuuid, discountInfo }) => axios({
	headers: {
		Authorization: JSON.parse(auth.getCookie('jwt')),
	},
	url: `${xcdApi}?appdir=${appdir}&module=exhibitor&component=staff&function=apply_staff_discount&moduleurlid=${urlId}&exhibuuid=${exhibuuid}&compuuid=${compuuid}`,
	method: 'post',
	data: discountInfo
});

const applyStaffRegistrantInfo = ({ appdir, urlId, compuuid, exhibuuid, registrantInfo }) => axios({
	headers: {
		Authorization: JSON.parse(auth.getCookie('jwt')),
	},
	url: `${xcdApi}?appdir=${appdir}&module=exhibitor&component=staff&function=add_staff_registrant&moduleurlid=${urlId}&exhibuuid=${exhibuuid}&compuuid=${compuuid}`,
	method: 'post',
	data: registrantInfo
});

const fetchStaffRegistrantFormFields = ({ appdir, urlId, compuuid, exhibuuid, data }) => axios({
	headers: {
		Authorization: JSON.parse(auth.getCookie('jwt')),
	},
	url: `${xcdApi}?appdir=${appdir}&module=exhibitor&component=staff&function=view_staff_registration_form&moduleurlid=${urlId}&compuuid=${compuuid}&exhibuuid=${exhibuuid}`,
	method: 'post',
	data,
})

const updateStaffRegistrationFormFields = ({ appdir, urlId, compuuid, exhibuuid, data }) => axios({
	headers: {
		Authorization: JSON.parse(auth.getCookie('jwt')),
	},
	url: `${xcdApi}?appdir=${appdir}&module=exhibitor&component=staff&function=update_staff_registration&moduleurlid=${urlId}&exhibuuid=${exhibuuid}&compuuid=${compuuid}`,
	method: 'post',
	data,
});

const fetchAddSubstituteInfo = ({ appdir, urlId, compuuid, exhibuuid, data }) => {
	return axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?appdir=${appdir}&module=exhibitor&component=staff&function=substitute_staff&moduleurlid=${urlId}&exhibuuid=${exhibuuid}&compuuid=${compuuid}`,
		method: 'post',
		data,
	})
}

const getStaffRemovalOptionsInfo = ({ appdir, urlId, compuuid, exhibuuid, data }) => {
	return axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?appdir=${appdir}&module=exhibitor&component=staff&function=staff_removal_options&moduleurlid=${urlId}&exhibuuid=${exhibuuid}&compuuid=${compuuid}`,
		method: 'post',
		data,
	})
}

const fetchStaffRemoveInfo = ({ appdir, urlId, compuuid, exhibuuid, data }) => {
	return axios({
		headers: {
			Authorization: JSON.parse(auth.getCookie('jwt')),
		},
		url: `${xcdApi}?appdir=${appdir}&module=exhibitor&component=staff&function=staff_remove&moduleurlid=${urlId}&exhibuuid=${exhibuuid}&compuuid=${compuuid}`,
		method: 'post',
		data,
	})
}

const apis = {
	markEmailRead,
	getEmailMessages,
	getEmailMessagesCount,
	getNewsItems,
	getProfileHomeLinks,
	renewAll,
	updateCompanyLogo,
	deleteRegistrationItemFromCart,
	addRegistrationItemsToCart,
	sendEmailToUsers,
	fetchExhibitorCart,
	addNewUserContact,
	updateUserContactInformation,
	getPaymentSettings,
	getExhibitorCartDetails,
	addUserMembership,
	updateCompanyProfile,
	getUserContactInfoFieldValues,
	getCompanyProfileFieldValues,
	getUserContactInfoFields,
	getCompanyProfileFields,
	removeUserFromGroup,
	removeUserMembership,
	removeUserAdmin,
	makeUserAdmin,
	getCompanyMembers,
	getMenu,
	getUserCompanyAffiliation,
	getCompany,
	createCompany,
	fetchExhibitorCartUuid,
	exhibitorSignUp,
	makeCartPublicToPrivate,
	sendMagicLink,
	deleteExhibitorPayment,
	fetchRoles,
	updateRoles,
	getCompanyMediaValues,
	getS3Signature,
	uploadTos3,
	addMediaResource,
	updateMediaResource,
	deleteMediaResource,
	validateYoutubeVideo,
	validateVimeoVideo,
	sortMediaResource,
	makeUserPublic,
	removeUserPublic,
	getMiniProfileFields,
	updateMiniProfileFields,
	createMiniProfileFields,
	addCompanyUser,
	fetchAttendeesInfo,
	fetchCompanyDiscountInfo,
	applyStaffDiscountInfo,
	applyStaffRegistrantInfo,
	fetchStaffRegistrantFormFields,
	updateStaffRegistrationFormFields,
	fetchAddSubstituteInfo,
	getStaffRemovalOptionsInfo,
	fetchStaffRemoveInfo,
};

export default apis;
