import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import {
	requestResetPaymentSuccess,
	requestToRenewAll,
	requestToUpdateStepDetails,
	requestPaymentLoader,
	requestToRemoveUserMembership,
	requestExhibitorCartDetails
} from '@appRedux/actions';
import { membershipStepInitialState } from '@components/Membership/helpers/constant';
import { requestCompanyData } from '../../../appRedux/actions';

export default function useAfterPaymentProcess() {
	const dispatch = useDispatch();
	const corporateMembership = useSelector(state => state.corporateMembership);
	const common = useSelector(state => state.common);
	const { members, cartUpdated } = corporateMembership.membershipStepDetails;
	const compuuid = corporateMembership.userCompanyAffiliation.comp_uuid;
	const { moduleUrlId } = useParams();
	const loginInfo = useSelector(state => state.loginInfo);
	useEffect(() => {
		if (
			members.length &&
			common.paymentSuccess &&
			corporateMembership.userCompanyAffiliation.comp_uuid
		) {
			let filteredFutureMembers = corporateMembership.membershipStepDetails.members
				.filter(mem => parseInt(mem.futureGroupId) !== -1)
				.filter(mem => mem.inGoodStanding !== true)
				.map(item => {
					let newExpiryDate = item.expirationDate && item.expirationDate.length
						? moment(item.expirationDate).format('YYYY-MM-DD')
						: moment(corporateMembership.company.expiry_date).format('YYYY-MM-DD');
					return {
						uuid: item.uuid,
						groupid: item.futureGroupId,
						newExpiryDate,
					};
				});
			let RemoveMembersOldMembership = [];
			corporateMembership.companyMembers.filter(
				mem => parseInt(mem.groupID) !== 0,
			);
			corporateMembership.companyMembers &&
				corporateMembership.companyMembers.length > 0 &&
				corporateMembership.companyMembers.forEach(mem => {
					const memberDetails = corporateMembership.membershipStepDetails.members.find(m=> parseInt(m.reviewID) == parseInt(mem.reviewID))
					if (memberDetails && memberDetails.futureGroupId == -1 && parseInt(mem.groupID) != 0) {
						RemoveMembersOldMembership.push(mem);
					}
				});

			if (filteredFutureMembers.length || RemoveMembersOldMembership.length) {
				dispatch(requestPaymentLoader(true));
				const array_of_members_to_remove = RemoveMembersOldMembership.map(record => {
					return { groupid: record?.groupID, companycontactUUID: record?.uuid };
				});
				let ordernumber = '';
				if (
					common?.paymentProgress?.PaymentItemsArray &&
					common?.paymentProgress?.PaymentItemsArray.length > 1
				) {
					//Multiple PaymentItemsArray - pick the ordernumber with highest payment_id
					const sortedPaymentItemArray = common?.paymentProgress?.PaymentItemsArray.sort(
						(a, b) => b.payment_id - a.payment_id,
					);
					ordernumber = sortedPaymentItemArray[0]?.order_number;
				} else {
					ordernumber = common?.paymentProgress?.PaymentItemsArray[0]?.order_number;
				}
				dispatch(
					requestToRenewAll({
						compuuid,
						array_of_members: filteredFutureMembers,
						array_of_members_to_remove,
						ordernumber: ordernumber,
						moduleurlid: moduleUrlId,
					}),
				);
				dispatch(requestToUpdateStepDetails(membershipStepInitialState));
				//Update the group membership details such as slots available, free etc
				dispatch(
					requestCompanyData({
						compuuid: corporateMembership.userCompanyAffiliation.comp_uuid,
					}),
				);
				dispatch(requestExhibitorCartDetails({
					appDir: loginInfo.appdir,
					urlId: moduleUrlId,
					compUuid: corporateMembership.userCompanyAffiliation.comp_uuid,
				}))
				dispatch(requestResetPaymentSuccess());
			}
		}
	}, [cartUpdated, members.length, common.paymentSuccess]);
}
