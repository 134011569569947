/**
 * This component will take information regarding form structure, form data, configurations, and change handlers.
 * It will render the form based on the form structure and Data
 * Customize them based on the configurations
 * And trigger events through the change handlers
 */

import { Form, Alert, Modal, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import FormItem from './formItem';
import useValidation from './hooks/useValidation';
import useFormValues, { parseInitialValues } from './hooks/useFormValues';
import './custom.css';
import { generateRowsForForm } from './helpers/general';
import { useSelector } from 'react-redux';
import MembershipModal from './customLogicComponents/MembershipModal';
import _isEqual from 'lodash/isEqual';
import moment from 'moment';
interface Props {
	formData: {
		title: string;
		formItems: Array<any>;
	};
	form: any;
	layout?: any;
	initialValues: any;
	onSubmit: any;
	onChange?: any;
	primaryColor: string;
	secondaryColor: string;
	textColor: string;
	validate?: boolean;
	validationConfig?: any;
	validationLoading?: any;
	reloadInitialValues?: boolean;
	staffRegistration?: boolean;
	localValidation?: boolean;
	localValidationConfig?: any;
	filterOption: any;
}

export default ({
	formData, //stores structural information of all the form elements
	layout, //not integrated currently.
	initialValues, //initialValues for antd form
	onSubmit, //onSubmit handler if there was a submit button. Not there currently
	onChange, //onChange callback for form related changes
	primaryColor, //set Primary text color(Eg: Title)
	secondaryColor, //set Secondary text color(Eg: Description)
	textColor, // set normal text color(Eg: Paragraph)
	form, //antd form object
	validate, //if true, show validation errors if any otherwise not
	validationConfig, //provides config to make a fetch request to collect form validation data
	validationLoading, //provides callback to return showLoading
	reloadInitialValues, // reload the initial values in form after FormComponent re-render
	staffRegistration, // flat used to fetch attendee module form validation JSON from exhibitor module
	localValidation, // to prevent API hit and use the local redux values to do the validation
	localValidationConfig, // local validation-rules data
	filterOption
}: Props) => {
	if (!get(formData, 'formItems')) {
		return null;
	}

	/**
	 * @var formValues keeps updated form data.
	 * @var initialTransformedValues stores initialValues after necessary transformations
	 * @var setFormValues to set the @var formValues on any change in the form
	 */
	const [formValues, initialTransformedValues, setFormValues]: any = useFormValues(
		initialValues,
		formData.formItems,
	);

	useEffect(() => {
		if (reloadInitialValues) {
			const parsedInitial = parseInitialValues(formData.formItems, initialValues);
			if (!_isEqual(parsedInitial, formValues) && form) {
				form.setFieldsValue(parsedInitial);
				setFormValues(parsedInitial);
			}
		}
	}, [initialValues]);

	/**
	 * @var validations stores any validation information regarding the form elements
	 * @var showLoading determines whether to show loading on the complete form or not
	 */
	const [validations, showLoading]: any = useValidation(
		validate,
		formValues,
		validationConfig,
		onChange,
		setFormValues,
		formData,
		staffRegistration,
		localValidation,
		localValidationConfig,
	);
	const [openModal, setOpenModal] = useState(false);

	const companyMembers = useSelector(state => state.corporateMembership.companyMembers);
	const membershipsAvailable = useSelector(
		state => state.corporateMembership.company.memberships_available,
	);
	const companyMembersFetched = useSelector(
		state => state.corporateMembership.companyMembersFetched,
	);

	validationLoading?.(showLoading);

	if (showLoading) {
		return 'Loading...';
	}
	const grid = generateRowsForForm(formData.formItems);

	const clickOpenModal = () => {
		setOpenModal(true);
	};

	const closeModal = () => {
		setOpenModal(false);
	};

	const passDonationValue = obj => {
		let newObj = {};
		newObj['Dropdown' + obj.fieldid.toString()] = obj.amount;

		form.setFieldsValue(newObj);
		setFormValues(newObj);
	};

	const passDateValue = obj => {
		let newObj = {};
		newObj[obj.name] = moment(obj.date).format('YYYY-MM-DD');
		form.setFieldsValue(newObj);
		setFormValues(newObj);
	};

	const passPreselectedRadioFinancial = obj => {
		let newObj = {};
		newObj[obj.name] = obj.preSelectedValue;
		form.setFieldsValue(newObj);
		setFormValues(newObj);
	};

	return (
		<Form
			form={form}
			initialValues={initialTransformedValues}
			onValuesChange={(changed, all) => {
				setFormValues({
					...all,
				});
			}}
			onFinish={onSubmit}
		>
			{grid.map(row => {
				return (
					<div className="gx-d-flex">
						{row.map(item => {
							return (
								<div style={{ width: '100%', paddingRight: '2%' }}>
									{item?.fieldmetajson?.add_class === 'info' ? (
										<Alert
											message={
												<div>
													<FormItem
														key={item.id}
														item={item}
														value={
															formValues
																? formValues[item.name]
																: null
														}
														errors={validations.errors[item.name]}
														display={validations.display[item.name]}
														filteredValues={
															validations.filteredValues[item.name]
														}
														primaryColor={primaryColor}
														secondaryColor={secondaryColor}
														textColor={textColor}
														passDonationValue={passDonationValue}
														passDateValue={passDateValue}
														passPreselectedRadioFinancial={
															passPreselectedRadioFinancial
														}
														filters={filterOption}
													/>
													{item?.fieldmetajson?.corporate_membership
														?.show_individual_members_modal ? (
														<div>
															<MembershipModal item={item} />
														</div>
													) : (
														''
													)}
												</div>
											}
										/>
									) : (
										<FormItem
											key={item.id}
											item={item}
											value={formValues ? formValues[item.name] : null}
											errors={validations.errors[item.name]}
											display={validations.display[item.name]}
											filteredValues={validations.filteredValues[item.name]}
											primaryColor={primaryColor}
											secondaryColor={secondaryColor}
											textColor={textColor}
											passDonationValue={passDonationValue}
											passDateValue={passDateValue}
											passPreselectedRadioFinancial={
												passPreselectedRadioFinancial
											}
											filters={filterOption}
										/>
									)}
								</div>
							);
						})}
					</div>
				);
			})}
		</Form>
	);
};
