import { useSettings } from './useSettings';

export const useContentType = () => {
	const [settingsLoading, , printTagIds, digitalTagIds] = useSettings();

	const CONTENT = { DIGITAL: 0, PRINT: 1, VIDEO: 2, AUDIO: 3 };
	const loading = settingsLoading;

	const getContentTypes = (product) => {
		const tagIds = new Set(product.tagIds);
		return getContentFromTags(tagIds);
	};

	const getFascadeContentTypes = (product) => {
		const tagIds = new Set([...product.tagIds, ...product.sourceTagIds]);
		return getContentFromTags(tagIds);
	};

	const getContentFromTags = (tagIds) => {
		const contentTypes = new Set();
		if (digitalTagIds?.filter((id) => tagIds.has(id)).length > 0)
			// intersect
			contentTypes.add(CONTENT.DIGITAL);
		if (printTagIds?.filter((id) => tagIds.has(id)).length > 0) contentTypes.add(CONTENT.PRINT);
		return contentTypes;
	};

	return [loading, CONTENT, getContentTypes, getFascadeContentTypes];
};
