const xcdapi = 'https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm';

const fetchCompanyDetails = (info) => {
   return new Promise((resolve, reject) => {
      let result = fetch(`${xcdapi}?appdir=${'demo'}&module=company&component=exhibitor_directory&function=exhib_directory_app&conferenceid=${info.conferenceid}`, {
         body: JSON.stringify({ ConfID: info.confid }),
         method: "POST"
      })
         .then(res => res.json())
      resolve(result)
   })
}

const fetchBoothConfigurations = (info) => {
   const api_url='https://xcdsystem.com/masterapp_summer2012/admin2015/exhibitors/new_floorplan/booth_ajax.cfc';
   const {source_hex, confid} = info;
   let sending_data = {
      ConfID: confid
   };
   return new Promise((resolve, reject) => {
      let result = fetch(api_url + "?method=getConfiguration&returnFormat=json&Source=" + source_hex, {
         body: JSON.stringify(sending_data),
         method: "POST"
      }).then(res=>res.json())
      resolve(result)
   })
}

const FloorAPI = {
   fetchCompanyDetails,
   fetchBoothConfigurations
}

export default FloorAPI
