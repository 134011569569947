import {
	SWITCH_LANGUAGE,
	TOGGLE_COLLAPSED_NAV,
	WINDOW_WIDTH,
	PUBLIC_EMBED,
} from 'constants/ActionTypes';
import {
	LAYOUT_TYPE,
	LAYOUT_TYPE_FULL,
	LAYOUT_TYPE_FRAMED,
	LAYOUT_TYPE_BOXED,
	NAV_STYLE,
	NAV_STYLE_FIXED,
	NAV_STYLE_MINI_SIDEBAR,
	NAV_STYLE_DRAWER,
	NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
	NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
	THEME_COLOR,
	THEME_TYPE,
	THEME_TYPE_SEMI_DARK,
} from '../../constants/ThemeSetting';

const initialSettings = {
	navCollapsed: true,
	navStyle: NAV_STYLE_FIXED,
	layoutType: LAYOUT_TYPE_FULL,
	themeType: THEME_TYPE_SEMI_DARK,
	themeColor: THEME_COLOR,
	pathname: '/',
	width: window.innerWidth,
	isDirectionRTL: false,
	publicEmbed: false, // starts as false then set to true via action.
	locale: {
		languageId: 'english',
		locale: 'en',
		name: 'English',
		icon: 'us',
	},
};

const settings = (state = initialSettings, action) => {
	switch (action.type) {
		case '@@router/LOCATION_CHANGE':
			return {
				...state,
				pathname: action.payload.location.pathname,
				navCollapsed: false,
			};
		case TOGGLE_COLLAPSED_NAV:
			return {
				...state,
				navCollapsed: action.navCollapsed,
			};
		case WINDOW_WIDTH:
			if (action.width < 900) {
				return {
					...state,
					width: action.width,
					navStyle: NAV_STYLE_MINI_SIDEBAR,
				};
			} else {
				return {
					...state,
					width: action.width,
				};
			}

		case THEME_TYPE:
			return {
				...state,
				themeType: action.themeType,
			};
		case THEME_COLOR:
			return {
				...state,
				themeColor: action.themeColor,
			};

		case NAV_STYLE:
			return {
				...state,
				navStyle: action.navStyle,
			};
		case LAYOUT_TYPE:
			return {
				...state,
				layoutType: action.layoutType,
			};

		case SWITCH_LANGUAGE:
			return {
				...state,
				locale: action.payload,
			};
		case PUBLIC_EMBED:
			return {
				...state,
				publicEmbed: action.payload,
			};

		default:
			return state;
	}
};

export default settings;
