import auth from '../loginAuth/loginAuth';

const xcdapi = 'https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm';

const getMakePayment = async (
	appdir,
	moduleUrlId,
	attenuuid,
	formData,
	publicEmbed,
	publicCheckout,
) => {
	let paymentResponse = await fetch(
		`${xcdapi}?appdir=${appdir}&module=attendee&component=attendees&function=add_payment&moduleurlid=${moduleUrlId}${
			attenuuid ? `&attenuuid=${attenuuid}` : ''
		}  `,
		{
			method: 'POST',
			headers:
				publicEmbed && publicCheckout
					? {}
					: {
							Authorization: JSON.parse(auth.getCookie('jwt')),
					  },
			body: formData,
		},
	);
	let paymentResponseJson = await paymentResponse.json();

	return paymentResponseJson;
};

const getMakePaymentExhibitor = async (appdir, moduleUrlId, compuuid, exhibuuid, formData) => {
	let paymentResponse = await fetch(
		`${xcdapi}?appdir=${appdir}&module=exhibitor&component=exhibitors&function=add_payment&moduleurlid=${moduleUrlId}&compuuid=${compuuid}&exhibuuid=${exhibuuid}`,
		{
			method: 'POST',
			headers: {
				Authorization: JSON.parse(auth.getCookie('jwt')),
			},
			body: formData,
		},
	);
	let paymentResponseJson = await paymentResponse.json();

	return paymentResponseJson;
};

const CommonAPIs = {
	getMakePayment,
	getMakePaymentExhibitor,
};

export default CommonAPIs;
