import React from 'react';
import { connect } from 'react-redux';
import InitialSignUp from '../components/Login/InitialSignUp';

const FindAccount = (props) => {
	const login_form = props.initialData.login_form;
	const appdir = props.initialData.appdir;
	const source_hex = props.initialData.source_hex;

	return (
		<div className="">
			<InitialSignUp
				login_form={login_form}
				appdir={appdir}
				source_hex={source_hex}
				title_label={'Find Account'}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
	};
};

export default connect(mapStateToProps)(FindAccount);
