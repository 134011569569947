import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { fetchFormData } from '../../appRedux/effects';
import { saveFormScreenData } from '../../appRedux/actions';
import { get } from 'lodash';
import { createHashKey } from '../../util/form';
import FormComponent from '../form';
import { Form } from 'antd';

/**
 * This component is used as a reference to test the functionality for the form feature
 * This will be rendered on hitting the route: /main/form
 */
const FormReference = props => {
	const { fetchFormData, form, saveFormScreenData } = props;
	const [validate, setValidate] = useState(false);

	//This config is used to fetch the form structure data via API
	let formConfig = {
		appdir: 'demo',
		module: 'attendee',
		component: 'attendee_menu',
		function: 'start',
		URLID: 'Rsi1dSW',
		// appdir: 'demo',
		// module: 'contact',
		// component: 'profile_fields',
		// function: 'get_fields',
		// moduleurlid: 'qKSm9yQ',
	};

	const [screenId, setScreenId] = useState(1);
	const [formControl] = Form.useForm();

	useEffect(() => {
		fetchFormData(formConfig);
	}, []);

	//Create the unique key for the config and fetch the form data from the store
	const hashKey = createHashKey(formConfig);
	const formObj = get(form, `data.${hashKey}`);
	const savedFormState = get(form, `userData.${hashKey}`);

	if (!formObj) {
		return null;
	}
	if (formObj.isFetching) {
		return <h1>Loading...</h1>;
	}
	if (!formObj.isFetched) {
		return null;
	}

	if (!formObj.screens) {
		return <h1>No screens</h1>;
	}

	const onChange = (validations, all) => {
		saveFormScreenData({
			screenId,
			hashKey,
			all,
			all,
		});
	};

	const onValidate = () => {
		setValidate(true);
	};

	const screen = form.data[hashKey].screens.find(screen => screen.id == screenId);

	//Validation config to fetch validation data from API
	let validationConfig = {
		module: 'attendee',
		moduleurlid: 'Rsi1dSW',
		menuid: 99,
		// module: 'contact',
		// moduleurlid: props.urlId,
		// menuid: 551,
	};

	return (
		<>
			<button onClick={onValidate}>Validate</button>
			<button
				onClick={() => {
					let newScreen = (screenId + 1) % (form.data[hashKey].screens.length + 1);
					if (!newScreen) newScreen = 1;
					setScreenId(newScreen);
				}}
			>
				Next Screen
			</button>
			<FormComponent
				formData={screen}
				initialValues={{
					Check1401: [3679],
					Radio201: 387,
					Radio207: 471,
					Radio247: 423,
					Radio249: 435,
				}}
				form={formControl}
				validationConfig={validationConfig}
				onChange={onChange}
				validate={validate}
				primaryColor="#0E3366"
				secondaryColor="#404040"
				textColor="black"
			/>
		</>
	);
};

const mapStateToProps = state => {
	return {
		auth: state.auth,
		initialData: state.loginInfo,
		form: state.form,
		formValidation: state.formValidation,
	};
};

const mapDispatchToProps = dispatch => ({
	fetchFormData: data => dispatch(fetchFormData(data)),
	saveFormScreenData: data => dispatch(saveFormScreenData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormReference);
