import React from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumb } from 'antd';

const Header = () => {
	return (
		<section className="gx-mb-4">
			<h1 className="gx-font-weight-semi-bold">My Products</h1>
			<Breadcrumb>
				<Breadcrumb.Item>
					<Link to={'/main/consumption/selection'}>Main</Link>
				</Breadcrumb.Item>
			</Breadcrumb>
		</section>
	);
};

export default Header;
