import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Widgets from './Widgets';
import Dashboard from './dashboard';
import Data from './data';
import ProfileHome from '../../components/Profile/ProfileHome';
import UpdateAccount from '../../components/UpdateAccount';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Form from '../../components/formReference';

import auth from '../../loginAuth/loginAuth';

import { requestLogoutUser, requestEraseAuthData } from '../../appRedux/actions/Auth';
import { requestEraseProfileData } from '../../appRedux/actions/LoginPageInfo';
import { requestEraseEcommerceData } from '../../appRedux/actions/Ecommerce';
import { requestEraseCorporateMembershipData } from '../../appRedux/actions/CorporateMembership';
import { requestEraseCommonData } from '../../appRedux/actions/Common';
import { requestEraseUserProductsData } from '../../appRedux/actions/UserProducts';


const PrivateCorporateMembership = React.lazy(() => import('./corporatemembership/PrivateCorporateMembership'));
const ExhibitorRoutes = React.lazy(() => import('./corporatemembership/ExhibitorRoutes'));
const Ecommerce = React.lazy(() => import('./ecommerce'));
const UserProducts = React.lazy(() => import('./userproducts'));
const Consumption = React.lazy(() => import('./consumption')); // Consumption will eventaully replace UserProducts
const Registration = React.lazy(() => import('./registration'));

const Logout = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const loginInfo = useSelector(state => state.loginInfo);
	useEffect(()=>{
		auth.deleteCookie('jwt');
		auth.deleteCookie('ssotoken');
		auth.deleteCookie('contact_uuid');
		auth.deleteCookie('appdir');
		auth.deleteCookie('source_hex');
		dispatch(requestLogoutUser({...props, loginInfo}));
		dispatch(requestEraseProfileData({...props, loginInfo}));
		dispatch(requestEraseAuthData({...props, loginInfo}));
		dispatch(requestEraseEcommerceData({...props, loginInfo}));
		dispatch(requestEraseCorporateMembershipData({...props, loginInfo}));
		dispatch(requestEraseCommonData({...props, loginInfo}));
		dispatch(requestEraseUserProductsData({...props, loginInfo}));
		history.push('/');
	}, []);
	return (<></>);
}
//match prepends that to dashboard
const Main = (props) => {
	
	const corporateMembership = useSelector(state => state.corporateMembership);
	const { match } = props;
	const renderComponent = () =>{
		if(corporateMembership?.generalSettings?.moduletype_internal === "membership"){
			return PrivateCorporateMembership
		}
		if(corporateMembership?.generalSettings?.moduletype_internal === ""){
			return ExhibitorRoutes
		}
	}
	return (
	<Switch>
		<Route path={`${match.url}/form`} component={Form} />
		<Route path={`${match.url}/widgets`} component={Widgets} />
		<Route path={`${match.url}/dashboard`} component={Dashboard} />
		<Route path={`${match.url}/data`} component={Data} />
		<Route path={`${match.url}/profilehome`} component={ProfileHome} />
		<Route path={`${match.url}/ecommerce`} component={Ecommerce} />
		<Route path={`${match.url}/registration`} component={Registration} />
			
			{/* separate the corporate membership and exhibitor registartion logged in module here */}
			<Route path={`${match.url}/company`} component={renderComponent() } />
			
			{/* <Route path={`${match.url}/company`} component={corporateMembership?.generalSettings?.moduletype_internal ? PrivateCorporateMembership : ExhibitorRoutes} /> */}


		<Route path={`${match.url}/updateaccount`} component={UpdateAccount} />
		<Route path={`${match.url}/userproducts`} component={UserProducts} />
		<Route path={`${match.url}/consumption`} component={Consumption} />
		<Route path={`${match.url}/logout`} component={Logout} />
	</Switch>
);
	}

export default Main;
