import React, { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import auth from '../../../loginAuth/loginAuth';
import { Button, Card, Modal, Radio, Input } from 'antd';
import { useHistory, Redirect } from 'react-router-dom';
import {
	requestClearExistingAccounts,
	requestProfileData,
} from '../../../appRedux/actions/LoginPageInfo';
import {
	requestSendEmail,
	requestSendPasscode,
	requestAuthenticateUser,
} from '../../../appRedux/actions/Auth';
import { checkToken } from '../helpers';

/*
After the passcode has been validated via email or passcode
we need another page to get the user to reset his password
Need another page and another api for this.
*/

const RetrieveAccount = props => {
	const [submitLoad, setSubmitLoad] = useState(false);

	const [sendEmailBox, setSendEmailBox] = useState(false);
	const [sendSMSCode, setSendSMSCode] = useState(false);
	const [enterPasscode, setEnterPasscode] = useState(false);

	const [phoneRadio, setPhoneRadio] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [passcodeValue, setPasscodeValue] = useState('');

	const history = useHistory();
	const dispatch = useDispatch();
	const retrieve_account = props.initialData.retrieveAccount;

	useEffect(() => {
		if (props.initialData.firstName !== '') {
			history.push('/resetpassword');
		}
	}, [props.initialData.firstName]);

	if (retrieve_account === '') {
		return (
			<Redirect
				to={{
					pathname: '/signin',
				}}
			/>
		);
	}

	// will mess things up on localhost, for dfi.econfi.io it grabs the appdir directly
	let appdir = props.initialData.appdir;

	const phoneArray = retrieve_account.phonearray;
	let login_form = props.initialData.login_form;

	const radioStyle = {
		display: 'block',
		height: '30px',
		lineHeight: '30px',
	};

	const goBack = () => {
		dispatch(requestClearExistingAccounts(props));
		history.push('/findaccount');
	};

	const sendSMS = () => {
		setSendSMSCode(true);
	};

	const sendPassCode = () => {
		let UUID = retrieve_account.UUID;
		dispatch(
			requestSendPasscode({
				appdir: appdir,
				uuid: UUID,
				phoneNumber: phoneNumber,
			}),
		);
		setSendSMSCode(false);
		setEnterPasscode(true);
	};

	const validatePassCode = () => {
		setSubmitLoad(true);

		let UUID = retrieve_account.UUID;
		// leaving this fetch here because i don't want to store the SSO token in redux
		// also this api isn't used anywhere else
		fetch(
			`https://masterapp.econference.io/masterapp_summer2012/apiv2/index.cfm?appdir=${appdir}&module=login&component=login&function=send_sms&uuid=${UUID}&validateSMS=1&passCode=${passcodeValue}`,
			{
				method: 'GET',
			},
		)
			.then(response => response.text())
			.then(responseText => {
				let responseObj = JSON.parse(responseText);
				if (responseObj.ssotoken) {
					let formData = checkToken(responseObj.contact_uuid, responseObj.ssotoken);
					dispatch(
						requestAuthenticateUser({
							appdir: appdir,
							formData: formData,
						}),
					);
				} else {
					alert('Code incorrect. Please try again.');
					setSubmitLoad(false);
				}
			})
			.catch(error => {
				console.error(error);
			});
	};

	const requestProfile = (source_hex, uuid) => {
		dispatch(requestProfileData({ source_hex: source_hex, contact_uuid: uuid }));
	};

	const sendEmail = () => {
		let UUID = retrieve_account.UUID;

		dispatch(requestSendEmail({ appdir: appdir, uuid: UUID }));
		setSendEmailBox(true);
	};

	const handleCancel = () => {
		setSendEmailBox(false);
		setSendSMSCode(false);
		setEnterPasscode(false);
	};

	const handleOk = () => {
		setSendEmailBox(false);
		setSendSMSCode(false);
		setEnterPasscode(false);
	};

	const onRadioChange = e => {
		setPhoneRadio(e.target.value);
		//removing all empty spaces and anything that's not a number
		let myNumber = e.target.value.replace(/[^0-9]/g, '');
		setPhoneNumber(myNumber);
	};

	const onInputChange = e => {
		setPasscodeValue(e.target.value);
	};

	// create button here with API call
	return (
		<div className="gx-app-login-container">
			<Button onClick={goBack}>Back</Button>

			<Card className="gx-card" style={{ textAlign: 'center' }}>
				{
					<div style={{ textAlign: 'center' }}>
						<img src={login_form.logo} />
					</div>
				}
				{sendEmailBox == true ? (
					<div style={{ textAlign: 'center' }}>
						<p>Send Email Box</p>
					</div>
				) : (
					''
				)}
				<h2>Retrieve Account</h2>
				<p>Your email address is already in the system: {retrieve_account.email}</p>
				<hr />
				<br />
				<div>
					<Button
						onClick={sendEmail}
						type="default"
						style={{
							backgroundColor: login_form.primary_color,
							color: '#fff',
							width: '50%',
						}}
					>
						Send Reset Password Link
					</Button>
					<p>Click link in email to reset password</p>
					<Modal
						title="Email Reset Password Link"
						visible={sendEmailBox}
						onOk={handleOk}
						onCancel={handleCancel}
						footer={null}
					>
						<p>Email has been sent.</p>
					</Modal>
					<br />
					<br />
				</div>

				{props.initialData.retrieveAccount.phonearray?.length > 0 ? (
					<div>
						<Button
							onClick={sendSMS}
							type="default"
							style={{
								backgroundColor: login_form.primary_color,
								color: '#fff',
								width: '50%',
							}}
						>
							Text Me Temporary Passcode
						</Button>
						<p>Receive an SMS message to login. Mobile charges may apply.</p>
						<Modal
							title="Send SMS Passcode"
							visible={sendSMSCode}
							onOk={handleOk}
							onCancel={handleCancel}
							footer={null}
							style={{ textAlign: 'center' }}
						>
							<h2>Select number to receive SMS.</h2>
							<p>Mobile carrier SMS charges may apply.</p>
							<Radio.Group onChange={onRadioChange} value={phoneRadio}>
								{phoneArray.map(item => (
									<Radio style={radioStyle} value={item}>
										{item}
									</Radio>
								))}
							</Radio.Group>
							<br />
							<br />
							<Button
								onClick={sendPassCode}
								type="default"
								style={{
									backgroundColor: login_form.primary_color,
									color: '#fff',
									width: '50%',
								}}
							>
								Send
							</Button>
						</Modal>

						<Modal
							title="Enter Passcode"
							visible={enterPasscode}
							onOk={handleOk}
							onCancel={handleCancel}
							footer={null}
							style={{ textAlign: 'center' }}
						>
							<h2>Please enter your 6-digit passcode</h2>

							<Input
								style={{ width: 100, marginLeft: 10 }}
								placeholder={'6-digit passcode'}
								onChange={onInputChange}
								value={passcodeValue}
							/>

							<br />
							<br />
							<Button
								onClick={validatePassCode}
								type="default"
								loading={submitLoad}
								style={{
									backgroundColor: login_form.primary_color,
									color: '#fff',
									width: '35%',
								}}
							>
								Submit
							</Button>
						</Modal>

						<br />
						<br />
					</div>
				) : (
					''
				)}

				{/* <div>
					<Button
						type="default"
						style={{
							backgroundColor: login_form.primary_color,
							color: '#fff',
							width: '50%',
						}}
					>
						I Don't Have Access To This Email
					</Button>
					<p>Contact support@uscap for assistance</p>

					<br />
					<br />
				</div> */}
			</Card>
		</div>
	);
};

const mapStateToProps = state => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
	};
};

export default connect(mapStateToProps)(RetrieveAccount);
