import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import auth from '../../../loginAuth/loginAuth';
import { useHistory } from 'react-router-dom';
import { requestAppdirCookie, requestProfileData } from '../../../appRedux/actions/LoginPageInfo';
import { checkToken } from '../helpers';
import { requestAuthenticateUser } from '../../../appRedux/actions/Auth';
/*
What's the actual API call? Is there an actual API call for this? Whats the response?
If I change the token it still works because the cookie detects it.



After the passcode has been validated via email or passcode
we need another page to get the user to reset his password
Need another page and another api for this.
*/

const TokenLogin = props => {
	const dispatch = useDispatch();
	const history = useHistory();

	let uuid = props.location.search.split('?uuid=');
	uuid = uuid[1].split('&')[0];
	let sso_token = props.location.search.split('token=')[1];

	let appdir = JSON.parse(auth.getCookie('appdir'));
	const source_hex = JSON.parse(auth.getCookie('source_hex'));

	useEffect(() => {
		let formData = checkToken(uuid, sso_token);
		if (!appdir) return;
		dispatch(
			requestAuthenticateUser({
				appdir: appdir,
				formData: formData,
			}),
		);
	}, []);

	useEffect(() => {
		if (props.auth.alertMessage == 'Token has expired.') {
			history.push('/resetpassword');
		}
	}, [props.auth.alertMessage]);

	useEffect(() => {
		dispatch(
			requestProfileData({
				source_hex: source_hex,
				contact_uuid: props.auth.contact_uuid,
			}),
		);
	}, [props.auth.contact_uuid]);

	useEffect(() => {
		if (props.initialData.firstName !== '') {
			history.push('/resetpassword');
		}
	}, [props.initialData.firstName]);

	return <div></div>;
};

const mapStateToProps = state => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
	};
};

export default connect(mapStateToProps)(TokenLogin);
