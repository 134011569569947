import React, { useEffect, useState } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import FormComponent from '../../form/index';
import { parseScreen } from '../../../util/form';
import { Card, Button, Form, Alert, Radio, Space, message, Col, Row } from 'antd';
import { useSettings } from '../Store/hooks/useSettings';
import { requestProfileInitialValues } from '../../../appRedux/actions/LoginPageInfo';
import { isEmpty } from 'lodash';
import { requestStoreShippingDetails } from '../../../appRedux/actions';
const ShippingDetails = props => {
	const [initialValues, setInitialValues] = useState({
		name: '',
		address: '',
		city: '',
		state: '',
		zip: '',
		country: '',
		phone: '',
	});
	const [userDetails, setUserDetails] = useState({});
	const [userAddresses, setUserAddresses] = useState([]);
	const [formState, setFormState] = useState([]);
	const [validate, setValidate] = useState(false);
	const [submitLoad, setSubmitLoad] = useState(false);
	const [validationsObject, setValidationsObject] = useState({
		error: false,
		validationErrors: {},
	});

	const [, , , , , shippingAddressConfig] = useSettings();
	const [formControl] = Form.useForm();
	const profileInitialValuesFetched = useSelector(
		state => state.loginInfo.profileInitialValuesFetched,
	);
	const languageObject = useSelector(
		state => state.ecommerce.attendeeSettings?.pagelanguagestruct,
	);
	const profileInitialValues = useSelector(state => state.loginInfo.profileInitialValues);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!profileInitialValuesFetched) {
			dispatch(requestProfileInitialValues());
		}
	}, []);

	useEffect(() => {
		if (isEmpty(userDetails)) {
			setUserDetails(profileInitialValues);
		}
	}, [profileInitialValues]);

	useEffect(() => {
		if (profileInitialValuesFetched) {
			let tempUserAddresses = [];

			if (userDetails && shippingAddressConfig) {
				shippingAddressConfig.forEach(address => {
					let tempAddress = {};
					for (const [key, valueArray] of Object.entries(address.fields)) {
						let finalValue = '';
						valueArray.forEach(field => {
							if (userDetails[field.toLowerCase()])
								finalValue = finalValue.concat(
									` ${userDetails[field.toLowerCase()]}`,
								);
						});
						tempAddress['name'] = ''
							.concat(` ${userDetails['firstname'] ?? ''}`)
							.concat(` ${userDetails['middle'] ?? ''}`)
							.concat(` ${userDetails['lastname'] ?? ''}`)
							.trim();
						tempAddress[key] = finalValue;
					}
					let emptyAddress = true;
					for (const [key, value] of Object.entries(tempAddress)) {
						if (!isEmpty(value)) {
							emptyAddress = false;
						}
					}

					if (!emptyAddress) {
						tempUserAddresses.push(tempAddress);
					}
				});
			}
			setUserAddresses(tempUserAddresses);
		}
	}, [userDetails]);

	const submitUpdate = () => {
		setSubmitLoad(true);

		if (
			formState.name == '' ||
			formState.address == '' ||
			formState.city == '' ||
			formState.state == '' ||
			formState.zip == '' ||
			formState.country == ''
		) {
			message.error('Please fill required fields');
			setSubmitLoad(false);
			return false;
		}
		if (props.requestShippingDetailsStatus) {
			props.requestShippingDetailsStatus(true);
		}
		dispatch(requestStoreShippingDetails(formState));
		setSubmitLoad(false);
	};
	const onAddressChange = e => {
		setInitialValues(userAddresses[e.target.value]);
	};
	return (
		<Card
			className="card"
			style={{ textAlign: 'left', width: '100%' }}
			bodyStyle={{ marginLeft: '1px' }}
		>
			<h2 className="gx-text-secondary" style={{ marginBottom: '1rem' }}>
				{languageObject?.Checkout_ShippingHeader ?? 'Shipping Details'}
			</h2>
			<div
				style={{ marginLeft: '2%' }}
				dangerouslySetInnerHTML={{
					__html: languageObject?.Checkout_ShippingHelpText ?? '<p></p>',
				}}
			></div>
			<br />
			<Row>
				{isEmpty(userAddresses) ? (
					<></>
				) : (
					<Col xl={12} lg={12} md={24} sm={24} xs={24}>
						<Card
							className="card"
							style={{ textAlign: 'left' }}
							bodyStyle={{ marginLeft: '1px' }}
						>
							<>
								<h4 className="gx-text-secondary" style={{ marginBottom: '1rem' }}>
									Select Existing Address
								</h4>

								<Radio.Group onChange={onAddressChange}>
									<Space direction="vertical">
										{userAddresses.map((address, index) => {
											return (
												<Radio value={index}>
													<span>
														{address.name}
														<br />
														{address.address}
														<br /> {address.city} {address.state}{' '}
														{address.country} <br /> {address.zip}
														<br />
														{address.phone}
													</span>
												</Radio>
											);
										})}
									</Space>
								</Radio.Group>
							</>
						</Card>
					</Col>
				)}

				<Col xl={12} lg={12} md={24} sm={24} xs={24}>
					{initialValues && Object.keys(initialValues).length !== 0 && (
						<div style={{ marginLeft: '1%' }}>
							<FormComponent
								form={formControl}
								initialValues={initialValues}
								reloadInitialValues={true}
								formData={parseScreen({
									fieldids: [
										{
											AutoSuggestID: 0,
											HorizontalClass: '',
											ReadOnly: 0,
											field_content_type: 'name',
											fielddescription: '',
											fieldid: 1,
											fieldlabel: 'Name',
											fieldname: 'name',
											fieldorder: 1,
											fieldtype: 'Text',
											required: 1,
											richtext: 0,
										},
										{
											AutoSuggestID: 0,
											HorizontalClass: '',
											ReadOnly: 0,
											field_content_type: 'address',
											fielddescription: '',
											fieldid: 2,
											fieldlabel: 'Address',
											fieldname: 'address',
											fieldorder: 2,
											fieldtype: 'Text',
											required: 1,
											richtext: 0,
										},
										{
											AutoSuggestID: 0,
											HorizontalClass: '',
											ReadOnly: 0,
											field_content_type: 'city',
											fielddescription: '',
											fieldid: 3,
											fieldlabel: 'City',
											fieldname: 'city',
											fieldorder: 3,
											fieldtype: 'Text',
											required: 1,
											richtext: 0,
										},
										{
											AutoSuggestID: 0,
											HorizontalClass: '',
											ReadOnly: 0,
											field_content_type: 'state',
											fielddescription: '',
											fieldid: 4,
											fieldlabel: 'State',
											fieldname: 'state',
											fieldorder: 4,
											fieldtype: 'Text',
											required: 1,
											richtext: 0,
										},
										{
											AutoSuggestID: 0,
											HorizontalClass: '',
											ReadOnly: 0,
											field_content_type: 'country',
											fielddescription: '',
											fieldid: 5,
											fieldlabel: 'Country',
											fieldname: 'country',
											fieldorder: 5,
											fieldtype: 'Text',
											required: 1,
											richtext: 0,
										},
										{
											AutoSuggestID: 0,
											HorizontalClass: '',
											ReadOnly: 0,
											field_content_type: 'zip',
											fielddescription: '',
											fieldid: 5,
											fieldlabel: 'Zip',
											fieldname: 'zip',
											fieldorder: 6,
											fieldtype: 'Text',
											required: 1,
											richtext: 0,
										},
										{
											AutoSuggestID: 0,
											HorizontalClass: '',
											ReadOnly: 0,
											field_content_type: 'phone',
											fielddescription: '',
											fieldid: 7,
											fieldlabel: 'Phone',
											fieldname: 'phone',
											fieldorder: 7,
											fieldtype: 'Text',
											required: 0,
											richtext: 0,
										},
									],
								})}
								validationConfig={{
									module: 'contact',
									// moduleurlid: 'Rsi1dSW', // make it 0 ... 0 doesn't work
									// menuid: '', // don't need anything here
								}}
								onChange={(validations, all) => {
									setValidationsObject(validations);
									setFormState(all);
								}}
								validate={validate}
								primaryColor={props.initialData.login_form?.primary_color}
								secondaryColor={'#808080'}
								textColor={'#404040'}
							/>
							<Button
								onClick={submitUpdate}
								loading={submitLoad}
								className="gx-ml-auto button-primary gx-font-weight-semi-bold shadow-2"
								shape="round"
								style={{
									border: 'none',
									padding: '0 2.5rem',
								}}
								htmlType="submit"
							>
								Submit
							</Button>
						</div>
					)}
				</Col>
			</Row>
		</Card>
	);
};

const mapStateToProps = state => {
	const myState = { ...state };

	return {
		auth: myState.auth,
		initialData: myState.loginInfo,
		ecommerce: myState.ecommerce,
	};
};

export default connect(mapStateToProps)(ShippingDetails);
