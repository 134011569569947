import {
	REQUEST_INITIAL_API,
	RECEIVE_INITIAL_API,
	REQUEST_PROFILE_INFO,
	RECEIVE_PROFILE_INFO,
	REQUEST_APPDIR_COOKIE,
	RECEIVE_APPDIR_COOKIE,
	REQUEST_REGISTER_INITIAL_VALUES,
	RECEIVE_REGISTER_INITIAL_VALUES,
	REQUEST_EXISTING_ACCOUNTS,
	RECEIVE_EXISTING_ACCOUNTS,
	REQUEST_RETRIEVE_ACCOUNT,
	RECEIVE_RETRIEVE_ACCOUNT,
	REQUEST_LOGINFORM_DATA,
	RECEIVE_LOGINFORM_DATA,
	REQUEST_PROFILE_DATA,
	RECEIVE_PROFILE_DATA,
	REQUEST_ERASE_PROFILE_DATA,
	RECEIVE_ERASE_PROFILE_DATA,
	REQUEST_CHECK_ACCOUNT,
	RECEIVE_CHECK_ACCOUNT,
	FETCH_PROFILE_HOME_LINKS,
	PROFILE_HOME_LINKS_FETCHED,
	FETCH_NEWS_ITEMS,
	NEWS_ITEMS_FETCHED,
	FETCH_EMAIL_MESSAGES_COUNT,
	EMAIL_MESSAGES_COUNT_FETCHED,
	FETCH_EMAIL_MESSAGES,
	EMAIL_MESSAGES_FETCHED,
	MARK_EMAIL_READ,
	EMAIL_MARKED_READ,
	REQUEST_REGISTER_PROFILE_FIELDS,
	RECEIVE_REGISTER_PROFILE_FIELDS,
	REQUEST_PROFILE_INITIAL_VALUES,
	RECEIVE_PROFILE_INITIAL_VALUES,
	REQUEST_UPDATE_PROFILE,
	RECEIVE_UPDATE_PROFILE,
	REQUEST_CREATE_PROFILE,
	RECEIVE_CREATE_PROFILE,
	REQUEST_DELETE_PROFILE_MSG,
	RECEIVE_DELETE_PROFILE_MSG,
	REQUEST_UPLOAD_PROFILE_PIC,
	RECEIVE_UPLOAD_PROFILE_PIC,
	REQUEST_ORDER_HISTORY_DATA,
	RECEIVE_ORDER_HISTORY_DATA,
	UPDATE_EMAIL,
	EMAIL_UPDATED,
	UPDATE_APPDIR,
	REQUEST_ORDER_HISTORY_VIEW_DATA,
	RECEIVE_ORDER_HISTORY_VIEW_DATA,
	REQUEST_ORDER_HISTORY_EMAIL_DATA,
	RECEIVE_ORDER_HISTORY_EMAIL_DATA,
	REQUEST_ORDER_HISTORY_DOWNLOAD_DATA,
	RECEIVE_ORDER_HISTORY_DOWNLOAD_DATA,
	ORDER_HISTORY_UPDATE_REDUCER,
	REQUEST_CLEAR_EXISTING_ACCOUNTS,
	RECEIVE_CLEAR_EXISTING_ACCOUNTS,
} from '../../constants/ActionTypes';

export const requestInitialAPI = data => {
	return {
		type: REQUEST_INITIAL_API,
		payload: data,
	};
};

export const receiveInitialAPI = data => {
	return {
		type: RECEIVE_INITIAL_API,
		payload: data,
	};
};

export const requestProfileInfo = data => {
	return {
		type: REQUEST_PROFILE_INFO,
		payload: data,
	};
};

export const receiveProfileInfo = data => {
	return {
		type: RECEIVE_PROFILE_INFO,
		payload: data,
	};
};

export const requestAppdirCookie = data => {
	return {
		type: REQUEST_APPDIR_COOKIE,
		payload: data,
	};
};

export const receiveAppdirCookie = data => {
	return {
		type: RECEIVE_APPDIR_COOKIE,
		payload: data,
	};
};

export const requestInitialRegisterInfo = data => {
	return {
		type: REQUEST_REGISTER_INITIAL_VALUES,
		payload: data,
	};
};

export const receiveInitialRegisterInfo = data => {
	return {
		type: RECEIVE_REGISTER_INITIAL_VALUES,
		payload: data,
	};
};

export const requestExistingAccounts = data => {
	return {
		type: REQUEST_EXISTING_ACCOUNTS,
		payload: data,
	};
};

export const receiveExistingAccounts = data => {
	return {
		type: RECEIVE_EXISTING_ACCOUNTS,
		payload: data,
	};
};

export const requestRetrieveAccount = data => {
	return {
		type: REQUEST_RETRIEVE_ACCOUNT,
		payload: data,
	};
};

export const receiveRetrieveAccount = data => {
	return {
		type: RECEIVE_RETRIEVE_ACCOUNT,
		payload: data,
	};
};

export const requestLoginFormData = data => {
	return {
		type: REQUEST_LOGINFORM_DATA,
		data,
	};
};

export const receiveLoginFormData = data => {
	return {
		type: RECEIVE_LOGINFORM_DATA,
		payload: data,
	};
};

export const requestProfileData = data => {
	return {
		type: REQUEST_PROFILE_DATA,
		data,
	};
};

export const receiveProfileData = data => {
	return {
		type: RECEIVE_PROFILE_DATA,
		payload: data,
	};
};

export const requestEraseProfileData = data => {
	return {
		type: REQUEST_ERASE_PROFILE_DATA,
		payload: data,
	};
};

export const receiveEraseProfileData = data => {
	return {
		type: RECEIVE_ERASE_PROFILE_DATA,
		payload: data,
	};
};

export const requestCheckAccount = data => {
	return {
		type: REQUEST_CHECK_ACCOUNT,
		data,
	};
};

export const receiveCheckAccount = data => {
	return {
		type: RECEIVE_CHECK_ACCOUNT,
		payload: data,
	};
};

export const fetchProfileHomeLinksSuccess = response => {
	return {
		type: PROFILE_HOME_LINKS_FETCHED,
		payload: response,
	};
};

export const requestToFetchProfileHomeLinks = payload => {
	return {
		type: FETCH_PROFILE_HOME_LINKS,
		payload,
	};
};

export const fetchNewsItemsSuccess = response => {
	return {
		type: NEWS_ITEMS_FETCHED,
		payload: response,
	};
};

export const requestToFetchNewsItems = payload => {
	return {
		type: FETCH_NEWS_ITEMS,
		payload,
	};
};

export const fetchEmailMessagesCountSuccess = response => {
	return {
		type: EMAIL_MESSAGES_COUNT_FETCHED,
		payload: response,
	};
};

export const requestToFetchEmailMessagesCount = payload => {
	return {
		type: FETCH_EMAIL_MESSAGES_COUNT,
		payload,
	};
};

export const fetchEmailMessagesSuccess = response => {
	return {
		type: EMAIL_MESSAGES_FETCHED,
		payload: response,
	};
};

export const requestToFetchEmailMessages = payload => {
	return {
		type: FETCH_EMAIL_MESSAGES,
		payload,
	};
};

export const markEmailReadSuccess = response => {
	return {
		type: EMAIL_MARKED_READ,
		payload: response,
	};
};

export const requestToMarkEmailRead = payload => {
	return {
		type: MARK_EMAIL_READ,
		payload,
	};
};

export const requestRegisterProfileFields = data => {
	return {
		type: REQUEST_REGISTER_PROFILE_FIELDS,
		data,
	};
};

export const receiveRegisterProfileFields = data => {
	return {
		type: RECEIVE_REGISTER_PROFILE_FIELDS,
		payload: data,
	};
};

export const requestProfileInitialValues = data => {
	return {
		type: REQUEST_PROFILE_INITIAL_VALUES,
		data,
	};
};

export const receiveProfileInitialValues = data => {
	return {
		type: RECEIVE_PROFILE_INITIAL_VALUES,
		payload: data,
	};
};

export const requestUpdateProfile = data => {
	return {
		type: REQUEST_UPDATE_PROFILE,
		data,
	};
};

export const receiveUpdateProfile = data => {
	return {
		type: RECEIVE_UPDATE_PROFILE,
		payload: data,
	};
};

export const requestCreateProfile = data => {
	return {
		type: REQUEST_CREATE_PROFILE,
		data,
	};
};

export const receiveCreateProfile = data => {
	return {
		type: RECEIVE_CREATE_PROFILE,
		payload: data,
	};
};

export const requestDeleteProfileMsg = data => {
	return {
		type: REQUEST_DELETE_PROFILE_MSG,
		data,
	};
};

export const receiveDeleteProfileMsg = data => {
	return {
		type: RECEIVE_DELETE_PROFILE_MSG,
		payload: data,
	};
};

export const requestUploadProfilePic = data => {
	return {
		type: REQUEST_UPLOAD_PROFILE_PIC,
		data,
	};
};

export const receiveUploadProfilePic = data => {
	return {
		type: RECEIVE_UPLOAD_PROFILE_PIC,
		payload: data,
	};
};

export const requestOrderHistoryData = data => {
	return {
		type: REQUEST_ORDER_HISTORY_DATA,
		data,
	};
};

export const receiveOrderHistoryData = data => {
	return {
		type: RECEIVE_ORDER_HISTORY_DATA,
		payload: data,
	};
};

export const updateEmailSuccess = response => {
	return {
		type: EMAIL_UPDATED,
		payload: response,
	};
};

export const requestToUpdateEmail = payload => {
	return {
		type: UPDATE_EMAIL,
		payload,
	};
};

export const updateAppdir = response => {
	return {
		type: UPDATE_APPDIR,
		payload: response,
	};
};
export const requestOrderHistoryViewData = data => {
	return {
		type: REQUEST_ORDER_HISTORY_VIEW_DATA,
		payload: data,
	};
};

export const updateOrderHistoryRedcucer = (data, event) => {
	return {
		type: ORDER_HISTORY_UPDATE_REDUCER,
		payload: { data, event },
	};
};

export const receiveOrderHistoryViewData = data => {
	return {
		type: RECEIVE_ORDER_HISTORY_VIEW_DATA,
		payload: data,
	};
};
export const requestOrderHistoryEmailData = data => {
	return {
		type: REQUEST_ORDER_HISTORY_EMAIL_DATA,
		payload: data,
	};
};

export const receiveOrderHistoryEmailData = data => {
	return {
		type: RECEIVE_ORDER_HISTORY_EMAIL_DATA,
		payload: data,
	};
};

export const requestOrderHistoryDownloadData = data => {
	return {
		type: REQUEST_ORDER_HISTORY_DOWNLOAD_DATA,
		payload: data,
	};
};

export const receiveOrderHistoryDownloadData = data => {
	return {
		type: RECEIVE_ORDER_HISTORY_DOWNLOAD_DATA,
		payload: data,
	};
};

export const requestClearExistingAccounts = data => {
	return {
		type: REQUEST_CLEAR_EXISTING_ACCOUNTS,
		payload: data,
	};
};

export const receiveClearExistingAccounts = data => {
	return {
		type: RECEIVE_CLEAR_EXISTING_ACCOUNTS,
		payload: data,
	};
};
