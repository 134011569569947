import {
	formValidationFetching,
	formValidationFetchSuccess,
	formValidationFetchError,
} from '../actions';
import { get } from 'lodash';
import { createValidationHashKey } from '../../util/form';

function encodeQueryData(data) {
	const ret = [];
	for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
	return ret.join('&');
}

export const formValidationRequest = formInfo => {
	var requestOptions = {
		method: 'GET',
		redirect: 'follow',
	};
	return fetch(
		`https://masterapp.econference.io/masterapp_summer2012/controllers/formcontrols/ValidateJSON.cfm?${encodeQueryData(
			formInfo,
		)}`,
		{
			method: 'GET',
		},
		requestOptions,
	).then(response => response.json());
};

export const fetchFormValidationData = ({ staff_registration, localValidation, localValidationConfig, ...info }) => async (dispatch, getState) => {

	if (
		get(getState().formValidation.data, `${[createValidationHashKey(info)]}.isFetched`) ||
		get(getState().formValidation.data, `${[createValidationHashKey(info)]}.isFetching`) ||
		get(getState().formValidation.data, `${[createValidationHashKey(info)]}.fetchError`)
	)
		return;

	dispatch(formValidationFetching(info, true));
	const otherQueries = staff_registration ? { staff_registration } : {}
	try {
		if (localValidation) {
			dispatch(formValidationFetchSuccess(info, localValidationConfig));
			dispatch(formValidationFetching(info, false));
		} else {
			let formValidation = await formValidationRequest({
				...info,
				...otherQueries,
				source: get(getState(), 'loginInfo.source_hex'),
				user_parameter: get(getState(), 'auth.contact_uuid'),
				comp_uuid: get(getState(), 'corporateMembership.userCompanyAffiliation.comp_uuid'),
			});
			dispatch(formValidationFetchSuccess(info, formValidation));
			dispatch(formValidationFetching(info, false));
		}
	} catch (e) {
		dispatch(formValidationFetching(info, false));
		dispatch(formValidationFetchError(info));
	}
};
