import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Tabs, Card, Table, Empty, Input, Spin, Button, Tooltip } from 'antd';
import { jsPDF } from 'jspdf';
import {
	requestOrderHistoryData,
	requestOrderHistoryViewData,
	requestOrderHistoryDownloadData,
	requestOrderHistoryEmailData,
	updateOrderHistoryRedcucer,
} from '../../../appRedux/actions/LoginPageInfo';
import { DownloadOutlined, EyeOutlined, MailOutlined } from '@ant-design/icons';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { SearchOutlined } from '@ant-design/icons';
import CircularProgress from '../../CircularProgress';
import Modal from 'antd/lib/modal/Modal';

import { ReactComponent as ManageProfileOrderHistory } from '../../../assets/icons/ManageProfileOrderHistory.svg';

import '../../UpdateAccount/styles/custom.less';

const { TabPane } = Tabs;

const OrderHistory = props => {
	const [orderHistoryData, setOrderHistoryData] = useState({});
	const [searched, setSearched] = useState(false);
	const [isModalVisible, setModalVisibility] = useState(false);
	const [download, setDownload] = useState(true);
	const dispatch = useDispatch();
	const reducerData = _get(props, 'initialData.orderHistory', {});
	const primaryColor = props.initialData.login_form.primary_color;
	const { conference = [], dues = [], donations = [] } = orderHistoryData;
	const TAB_TYPE_CONFERENCE = 'conference';
	const TAB_TYPE_DUES = 'dues';
	const TAB_TYPE_DONTAIONS = 'donations';
	const ACTION_TYPE_VIEW = 'View';
	const ACTION_TYPE_DOWNLOAD = 'Download';
	const ACTION_TYPE_EMAIL = 'Email';

	const width = props.settings.width;
	const mobileThreshold = 500;

	const handleOnclick = (page, record, action) => {
		let formData = new FormData();
		if (page === TAB_TYPE_CONFERENCE) {
			formData.append('invoiceType', page);
			formData.append('attenid', [record.AttenID]);
			formData.append('confid', [record.ConfID]);
		} else if (page === TAB_TYPE_DUES || page === TAB_TYPE_DONTAIONS) {
			formData.append('invoiceType', page);
			formData.append('ordernumber', record.ordernumber);
		}
		if (action === ACTION_TYPE_VIEW) {
			dispatch(requestOrderHistoryViewData({ body: formData }));
		} else if (action === ACTION_TYPE_DOWNLOAD) {
			dispatch(requestOrderHistoryDownloadData({ body: formData }));
			setDownload(true);
		} else if (action === ACTION_TYPE_EMAIL) {
			dispatch(requestOrderHistoryEmailData({ body: formData }));
		}
	};

	const RenderButton = (text, icon, record, page) => {
		return (
			<div style={{ marginLeft: '10px' }}>
				<Tooltip title={text}>
					<Button
						shape="circle"
						size="medium"
						icon={icon}
						style={{
							color: 'white',
							backgroundColor: primaryColor,
							margin: '0px',
						}}
						onClick={() => handleOnclick(page, record, text)}
					/>
				</Tooltip>
			</div>
		);
	};

	const conferenceColumns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			width: width > mobileThreshold ? '' : 100,
		},
		{
			title: 'Event Name',
			dataIndex: 'event_name',
			key: 'event_name',
			width: width > mobileThreshold ? '' : 100,
		},
		{
			title: 'Event Date',
			dataIndex: 'event_date',
			key: 'event_date',
			width: width > mobileThreshold ? '' : 100,
		},
		{
			title: 'Status',
			dataIndex: 'attendee_status',
			key: 'attendee_status',
			width: width > mobileThreshold ? '' : 100,
		},
		{
			title: 'Payment',
			dataIndex: 'payment_amount',
			key: 'payment_amount',
			width: width > mobileThreshold ? '' : 100,
		},
		{
			title: '',
			dataIndex: '',
			key: 'x',
			render: (_, record) => (
				<div className="gx-d-flex gx-justify-content-start">
					{RenderButton(ACTION_TYPE_VIEW, <EyeOutlined />, record, TAB_TYPE_CONFERENCE)}
					{RenderButton(
						ACTION_TYPE_DOWNLOAD,
						<DownloadOutlined />,
						record,
						TAB_TYPE_CONFERENCE,
					)}
					{RenderButton(ACTION_TYPE_EMAIL, <MailOutlined />, record, TAB_TYPE_CONFERENCE)}
				</div>
			),
		},
	];
	const membershipColumns = [
		{
			title: 'Type',
			dataIndex: 'membership_type',
			key: 'membership_type',
			width: width > mobileThreshold ? '' : 100,
		},
		{
			title: 'Amount',
			dataIndex: 'payment_amount',
			key: 'payment_amount',
			width: width > mobileThreshold ? '' : 100,
		},
		{
			title: 'Date Paid',
			dataIndex: 'date_paid',
			key: 'date_paid',
			width: width > mobileThreshold ? '' : 100,
		},
		{
			title: 'Order Number',
			dataIndex: 'ordernumber',
			key: 'ordernumber',
			width: width > mobileThreshold ? '' : 100,
		},
		{
			title: '',
			dataIndex: '',
			key: 'x',
			render: (_, record) => (
				<div className="gx-d-flex">
					{RenderButton(ACTION_TYPE_VIEW, <EyeOutlined />, record, TAB_TYPE_DUES)}
					{RenderButton(
						ACTION_TYPE_DOWNLOAD,
						<DownloadOutlined />,
						record,
						TAB_TYPE_DUES,
					)}
					{RenderButton(ACTION_TYPE_EMAIL, <MailOutlined />, record, TAB_TYPE_DUES)}
				</div>
			),
		},
	];
	const donationsColumns = [
		{
			title: 'Pay Method',
			dataIndex: 'pay_method',
			key: 'pay_method',
			width: 100,
		},
		{
			title: 'Date',
			dataIndex: 'date_paid',
			key: 'date_paid',
			width: 100,
		},
		{
			title: 'Amount',
			dataIndex: 'payment_amount',
			key: 'payment_amount',
			width: 100,
		},
		{
			title: 'Order',
			dataIndex: 'ordernumber',
			key: 'ordernumber',
			width: 100,
		},
		{
			title: '',
			dataIndex: '',
			key: 'x',
			render: (_, record) => (
				<div className="gx-d-flex">
					{RenderButton(ACTION_TYPE_VIEW, <EyeOutlined />, record, TAB_TYPE_DONTAIONS)}
					{RenderButton(
						ACTION_TYPE_DOWNLOAD,
						<DownloadOutlined />,
						record,
						TAB_TYPE_DONTAIONS,
					)}
					{RenderButton(ACTION_TYPE_EMAIL, <MailOutlined />, record, TAB_TYPE_DONTAIONS)}
				</div>
			),
		},
	];

	useEffect(() => {
		let appdir = props.initialData.appdir;
		dispatch(requestOrderHistoryData({ appdir }));
	}, []);

	useEffect(() => {
		const {
			orderHistoryViewData,
			orderHistoryEmailData,
			orderHistoryDownloadData,
			showOrderHistoryViewModal,
		} = props.initialData;
		if (!_isEmpty(reducerData)) {
			setOrderHistoryData({ ...reducerData });
		}

		if (!_isEmpty(orderHistoryViewData) && showOrderHistoryViewModal) {
			if (
				orderHistoryViewData.return_title !== '' &&
				orderHistoryViewData.return_text !== ''
			) {
				setModalVisibility(true);
			} else {
				NotificationManager.error('No content available', 'Failed');
			}
		} else if (!showOrderHistoryViewModal && !_isEmpty(orderHistoryEmailData)) {
			if (orderHistoryEmailData?.result) {
				NotificationManager.success(orderHistoryEmailData.result, 'Success');
			}
		} else if (!_isEmpty(orderHistoryDownloadData) && !showOrderHistoryViewModal) {
			if (
				download &&
				orderHistoryDownloadData.return_title !== '' &&
				orderHistoryDownloadData.return_text !== ''
			) {
				let doc = new jsPDF('p', 'pt', 'a4');
				let el = ReactDOMServer.renderToString(
					<div
						style={{ width: '580px', height: '550px' }}
						dangerouslySetInnerHTML={{
							__html: orderHistoryDownloadData?.return_text,
						}}
					/>,
				);
				doc.html(el, {
					callback: function (doc) {
						doc.save(`${Date.now()}.pdf`);
					},
					x: 10,
					y: 10,
				});
				setDownload(false);
			}
		}
	}, [props.initialData]);

	const handleSearch = (searchValue, dataIndex) => {
		let tempOrderHistoryData = { ...reducerData };
		if (searchValue.trim() !== '') {
			let flagArr = [];
			let temp = tempOrderHistoryData[dataIndex].filter(obj => {
				flagArr = Object.values(obj).filter(val => {
					let value = val.toString().toLowerCase();
					return value.includes(searchValue.toLowerCase());
				});
				return !_isEmpty(flagArr);
			});
			tempOrderHistoryData[dataIndex] = [...temp];
			setOrderHistoryData({ ...tempOrderHistoryData });
			setSearched(true);
		} else {
			setOrderHistoryData({ ...reducerData });
			setSearched(false);
		}
	};

	const renderTabPanes = () => {
		let res = [];
		const tabPaneData = [
			{
				key: '1',
				title: 'Conference',
				dataIndex: TAB_TYPE_CONFERENCE,
				dataSource: conference,
				columns: conferenceColumns,
				isShow: !_isEmpty(reducerData[TAB_TYPE_CONFERENCE]),
			},
			{
				key: '2',
				title: 'Membership',
				dataIndex: TAB_TYPE_DUES,
				dataSource: dues,
				columns: membershipColumns,
				isShow: !_isEmpty(reducerData[TAB_TYPE_DUES]),
			},
			{
				key: '3',
				title: 'Donations',
				dataIndex: TAB_TYPE_DONTAIONS,
				dataSource: donations,
				columns: donationsColumns,
				isShow: !_isEmpty(reducerData[TAB_TYPE_DONTAIONS]),
			},
		];
		tabPaneData.map(data => {
			const { key, title, dataSource, columns, dataIndex, isShow } = data;
			if (isShow) {
				res.push(
					<TabPane tab={title} key={key}>
						<Input
							className="gx-mb-3 gx-rounded-xxl"
							onChange={e => handleSearch(e.target.value.toString(), dataIndex)}
							placeholder={`Search ${title}`}
							prefix={<SearchOutlined />}
						/>
						<Table
							className="gx-order-table"
							dataSource={dataSource}
							columns={columns}
							pagination={{
								total: dataSource.length,
								showTotal: (total, range) =>
									`Showing ${range[0]} to ${range[1]} of ${total} entries`,
							}}
							size="small"
							scroll={{ x: 130 }}
						/>
					</TabPane>,
				);
			}
		});
		return res;
	};

	const checkIsNoData = () => {
		let result = [];
		let orderHistory = orderHistoryData;

		if (!_isEmpty(orderHistory)) {
			result = Object.keys(orderHistory).filter(key => !_isEmpty(orderHistory[key]));
		}
		return !_isEmpty(result);
	};

	const onClose = () => {
		setModalVisibility(false);
		dispatch(updateOrderHistoryRedcucer(false, 'showOrderHistoryViewModal'));
	};

	const { orderHistoryLoader, orderHistoryViewLoader, orderHistoryViewData } = props.initialData;
	return (
		<>
			<Card>
				{orderHistoryLoader ? (
					<CircularProgress className="gx-profile-content" />
				) : (
					<>
						{' '}
						<ManageProfileOrderHistory
							className="manage-profile-page-icons gx-mr-2 gx-mt-1 fg-primary-dark-light"
							style={{ float: 'right' }}
						/>
						<h2 className="gx-text-secondary" style={{ marginBottom: '1rem' }}>
							Order History
						</h2>
						{checkIsNoData() ? (
							<Spin spinning={orderHistoryViewLoader}>
								<Tabs defaultActiveKey="1" className="gx-tabs-horizontal">
									{renderTabPanes()}
								</Tabs>
							</Spin>
						) : (
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Orders" />
						)}
					</>
				)}
			</Card>
			<Modal
				style={{ width: 'fit-content' }}
				visible={isModalVisible}
				title={<h2>{orderHistoryViewData?.return_title}</h2>}
				footer={
					<Button
						style={{ backgroundColor: primaryColor, color: 'white' }}
						onClick={() => onClose()}
					>
						Close
					</Button>
				}
				onCancel={() => onClose()}
			>
				<div
					dangerouslySetInnerHTML={{
						__html: '<div>' + orderHistoryViewData?.return_text + '</div>',
					}}
				/>
			</Modal>
			<NotificationContainer />
		</>
	);
};

const mapStateToProps = state => {
	return {
		settings: state.settings,
		initialData: state.loginInfo,
	};
};

export default connect(mapStateToProps)(OrderHistory);
