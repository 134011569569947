import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as AddMembershipIcon } from '../../assets/icons/addMembership.svg';
import { ReactComponent as CloseModalIcon } from '../../assets/icons/modalClose.svg';

const OpenModal = props => {
	const [modalVisible, setModalVisible] = useState(false);
	const triggerOnClick = () => {
		setModalVisible(true);
		if (props.handleOnClick) {
			props.handleOnClick();
		}
	};

	const cancelModal = () => {
		setModalVisible(false);

		if (props?.okCancel) {
			props.okCancel();
		}
	};

	const buttonBaseProps = isCancel => {
		return {
			className: `gx-mt-1 ${isCancel ? 'button-secondary-outline' : 'button-primary'}`,
			shape: 'round',
		};
	};

	return (
		<div>
			{props['isButton'] ? (
				<Button
					className="login-form-button gx-mt-1 button-primary"
					onClick={triggerOnClick}
					shape={'round'}
					size={'small'}
					style={{
						boxShadow: modalVisible
							? 'inset 5px 5px 2px 1px rgba(45, 45, 45, 0.4)'
							: '',
					}}
				>
					<div style={{ marginTop: '-3px' }}>{props.title}</div>
				</Button>
			) : props.isTextLink ? (
				<a onClick={triggerOnClick}>{props.title}</a>
			) : props.icon === 'edit' ? (
				<EditIcon
					title={props.title}
					className="gx-icon-btn icon exhibitor-staff-header-icon"
					onClick={triggerOnClick}
				/>
			) : props.icon === 'add' ? (
				<AddMembershipIcon
					title={props.title}
					className="gx-icon-btn icon exhibitor-staff-header-icon fg-secondary-lighter"
					onClick={triggerOnClick}
				/>
			) : (
				''
			)}
			<Modal
				key={props.key}
				className="custom-ant-modal-content"
				// style={{height: '500px', overflow: 'scroll'}}
				closeIcon={<CloseModalIcon className="gx-mt-2" style={{ width: '2em' }} />}
				visible={modalVisible}
				onCancel={cancelModal}
				title={props.title}
				cancelText={'CANCEL'}
				okText={props.action}
				onOk={() => {
					setModalVisible(false);
					if (props.okClicked) {
						props.okClicked();
					}
				}}
				cancelButtonProps={{
					...buttonBaseProps(true),
					style: props.hideFooter && { display: 'none' },
				}}
				okButtonProps={{
					...buttonBaseProps(false),
					style: props.hideFooter
						? {
								display: 'none',
						  }
						: {
								boxShadow: '6px 6px 2px rgba(45, 45, 45, 0.65)',
						  },
				}}
				destroyOnClose={true}
				maskClosable={false}
			>
				{props.modalContent}
			</Modal>
		</div>
	);
};

export default OpenModal;
