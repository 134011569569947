import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { requestAttendeeSettings } from '../../../../appRedux/actions/EcommerceStore';

export const useSettings = () => {
	const dispatch = useDispatch();
	const { moduleUrlId } = useParams();

	const appdir = useSelector(state => state.loginInfo.appdir);
	const fetched = useSelector(state => state.ecommerce.attendeeSettingsFetched);
	const storeName = useSelector(state => state.ecommerce.attendeeSettings.seestore?.eventname);
	const config = useSelector(
		state => state.ecommerce.attendeeSettings?.attendeesiteadmin?.generalconfigobject,
	);

	const loading = !fetched;

	useEffect(() => {
		if (!appdir) return;
		if (fetched) return;
		dispatch(requestAttendeeSettings({ appdir, moduleUrlId }));
	}, [appdir]);

	// storeName, printTagIds, digitalTagIds, hiddenTagIds, shipping_address_config
	return [
		loading,
		storeName,
		config?.print_tag,
		config?.digital_tag,
		config?.hide_tag,
		config?.shipping_address_config,
	];
};
