import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Radio } from 'antd';
import Title from '../components/Title';
import Description from '../components/Description';
import useRules from '../hooks/useRules';
import NormalText from '../components/NormalText';
import RadioLabel from '../components/RadioLabel';
import useFilteredOptions from '../hooks/useFilteredOptions';
import { currencyFormatter } from '../../../components/Membership/helpers/generalHelpers';

interface Props {
	name: string;
	label: string;
	options: Array<{
		id: any;
		label: string;
		value: any;
		disabled: boolean;
		text: string;
	}>;
	value: any;
	onChange: any;
	required: string;
	description: string;
	primaryColor: string;
	secondaryColor: string;
	textColor: string;
	error: string;
	filteredValues: any;
}

export const RadioButton = ({
	label,
	name,
	options,
	value,
	onChange,
	required,
	description,
	primaryColor,
	secondaryColor,
	textColor,
	error,
	filteredValues,
}: Props) => {
	const loginInfo = useSelector(state => state.loginInfo);
	const rules = useRules(required);
	const filteredOptions: any = useFilteredOptions(options, filteredValues);

	return (
		<div>
			<Title
				label={label}
				color={primaryColor}
				error={error}
				required={required}
				name={name}
			/>

			<Description label={description} color={secondaryColor} />
			<Form.Item name={name} rules={rules}>
				<Radio.Group onChange={onChange} value={value}>
					{filteredOptions.map((option: any) => (
						<div key={option.id} style={{ margin: '7px 0px' }}>
							<Radio key={option.id} value={option.id}>
								<NormalText label={option.label} color={textColor} />
								<span
									className="gx-ml-2"
									style={{ color: loginInfo.login_form?.primary_color }}
								>
									{option.initialAmount > 0
										? currencyFormatter(option.initialAmount)
										: ''}
								</span>
							</Radio>
							<RadioLabel label={option.text} color={secondaryColor} />
						</div>
					))}
				</Radio.Group>
			</Form.Item>
		</div>
	);
};
